import { createStyles, makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    select: {
      width: 200
    },
    greenButton: {
      backgroundColor: green[300],
      color: theme.palette.common.white,
      textTransform: 'none'
    },
    cursorPointer: {
      cursor: 'pointer'
    },
    table: {
      minWidth: 750,
    },
    tableHead:{
      backgroundColor:"#3C6AB5",
    },
    tableHeadCell:{
      color:"#fff !important"
    },
    actions: {
      display: 'flex',
      flexDirection: 'column'
    },
    actionLabels: {
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    rgtMargin: {
      marginRight: '10px',
    },
    flexColumn: {
      display: 'flex',
      flexDirection: 'column'
    },
    cellPadding:{
      padding:"15px 3px",
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    }
  })
);

export { useStyles };

