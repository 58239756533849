import React from 'react';
import { useRecoilState } from 'recoil';
import { FormControl, MenuItem, InputLabel, Select } from '@material-ui/core';

import { currentOrder } from '../../../../Recoil/Atoms/Orders';

export const OrderChangeStatus = () => {
  const [order, setOrder] = useRecoilState(currentOrder);
  const totalStatuses = ['In Process', 'Assigned', 'Signed','Duplicate', 'Borrower Would Not Sign', 'Borrower No Show',
    'Cancelled By Borrower', 'Cancelled By Client', 'Late Cancellation - Print Fee', 'No Docs'];
  const updateStatus = (e) => {
    const { value } = e.target
    setOrder({ ...order, f_status_web: value });
  }

  return (
    <FormControl
      fullWidth
      margin='dense'
      variant='outlined'
    >
      <InputLabel>Status</InputLabel>
      <Select label='Clients' value={order?.f_status_web} onChange={updateStatus}>
        {totalStatuses.map(s => <MenuItem key={s} value={s}>{s}</MenuItem>)}
      </Select>
    </FormControl>
  );
};
