import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Theme } from 'src/ThemeComponents/theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    table: {
      minWidth: 350,
    },
    list: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    pointer: {
      cursor: 'pointer'
    },
    removeTextDecoration: {
      textDecoration: 'none'
    },
    windowHeight: {
      height: '100vh'
    },
    rowStyle: {
      overflow: 'auto',
      maxHeight: '76px'
    }
  })
);

export { useStyles };
