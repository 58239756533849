import React, { useState } from 'react';
import { useRecoilValueLoadable } from 'recoil'
import clsx from 'clsx';

import { Box, TextField, Typography, Select, MenuItem } from '@material-ui/core';

import { GeneralModal } from '../../Modal';
import { ViewInvoice } from '../../Orders/FeeAndInstructions/ViewInvoice';
import { changePaymentStatusPopup } from './../../../Recoil/Selectors/Accounting'

import { PageLoad } from 'src/Components/LoadingScreen/PageLoad';

import { marginStyles } from '../../../Common/Styles/Margins';

const ChangePaymentStatusModel = ({
  changeRowPaymentStatus,
  handleChangeRowPaymentStatus,
  check,
  savePaymentStatus
}) => {

  const marginClasses = marginStyles();
  const [viewInvoice, setViewInvoice] = useState(false)

  const paymentPopupRequest: any = useRecoilValueLoadable(changePaymentStatusPopup)
  // const paymentPopup = paymentPopupRequest?.contents?.result || {};

  return (
    <GeneralModal
      open={changeRowPaymentStatus?.id ? true : false}
      title='PacDocSign : Change Payment Status'
      loading={check}
      openModal={() => handleChangeRowPaymentStatus({})}
      noSubmit={false}
      noFooter={false}
      maxWidth='sm'
      submitLabel='Save'
      handleSubmit={savePaymentStatus}
    >
      {
        paymentPopupRequest.state === 'loading'
          ?
          <PageLoad />
          :
          <>
            <Box display='flex' p={2}>
              <Typography
                variant='body1'
                color='textSecondary'
                className={clsx(
                  marginClasses.mt2,
                  marginClasses.mb0,
                  marginClasses.mr1
                ) + ' status-labels'}
              >
                <b>Payment Status</b>
              </Typography>
              <Select
                fullWidth
                variant='outlined'
                value={changeRowPaymentStatus?.payment_status || ''}
                onChange={e =>
                    handleChangeRowPaymentStatus({
                    ...changeRowPaymentStatus,
                    payment_status: e.target.value
                  })
                }
              >
                <MenuItem value='Invoice Disputed'>Invoice Disputed</MenuItem>
                <MenuItem value='Invoice Submitted'>Invoice Submitted</MenuItem>
                <MenuItem value='Paid'>Paid</MenuItem>
                <MenuItem value='Pending'>Pending</MenuItem>
              </Select>
            </Box>
            <Box display='flex' p={2}>
              <Typography
                variant='body1'
                color='textSecondary'
                className={clsx(
                  marginClasses.mt2,
                  marginClasses.mb0,
                  marginClasses.mr1
                ) + ' status-labels'}
              >
                <b> Payment check Number</b>
              </Typography>
              <TextField
                id='standard-basic'
                variant='outlined'
                fullWidth
                size='small'
                value={changeRowPaymentStatus?.payment_check_no || ''}
                onChange={e =>
                  handleChangeRowPaymentStatus({
                    ...changeRowPaymentStatus,
                    payment_check_no: e.currentTarget.value
                  })
                }
              />
            </Box>
            <Box display='flex' p={2}>
              <Typography
                variant='body1'
                color='textSecondary'
                className={clsx(
                  marginClasses.mt2,
                  marginClasses.mb0,
                  marginClasses.mr1
                ) + ' status-labels'}
              >
                <b>Payment Check Date</b>
              </Typography>
              <TextField
                id='standard-basic'
                variant='outlined'
                fullWidth
                size='small'
                type='date'
                value={changeRowPaymentStatus?.payment_check_date || ''}
                onChange={e =>
                  handleChangeRowPaymentStatus({
                    ...changeRowPaymentStatus,
                    payment_check_date: e.currentTarget.value
                  })
                }
              />
            </Box>
            <Box display='flex' p={2}>
              <Typography
                variant='body1'
                color='textSecondary'
                className={clsx(
                  marginClasses.mt2,
                  marginClasses.mb0,
                  marginClasses.mr1
                ) + ' status-labels'}
              >
                <b>Payment Note</b>
              </Typography>
              <TextField
                id='standard-basic'
                variant='outlined'
                fullWidth
                size='small'
                value={changeRowPaymentStatus?.payment_note || ''}
                onChange={e =>
                  handleChangeRowPaymentStatus({
                    ...changeRowPaymentStatus,
                    payment_note: e.currentTarget.value
                  })
                }
              />
            </Box>
            <small className='show-invoice'>
              Could not find invoice file. <span onClick={() => setViewInvoice(true)}>Click to view invoice </span>
              <ViewInvoice open={viewInvoice} close={e => setViewInvoice(false)} orderDetails={changeRowPaymentStatus}></ViewInvoice>
            </small>
          </>
      }
    </GeneralModal>
  );
};

export { ChangePaymentStatusModel };
