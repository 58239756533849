import React, { Fragment } from 'react';
import {
  Grid,
  Typography,
  Button,
  SvgIcon,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Radio,
  FormHelperText
} from '@material-ui/core';
import { FieldArray } from 'formik';
import { Plus, XCircle } from 'react-feather';
import clsx from 'clsx';

import { displayStyles } from '../../../Common/Styles/Display';
import { marginStyles } from '../../../Common/Styles/Margins';

const RelatedContacts = ({ values, errors, handleChange, setFieldValue, clientContacts }) => {
  const displayClasses = displayStyles();
  const marginClasses = marginStyles();

  let contacts = []
  if (Array.isArray(clientContacts.rows)) {
    contacts = clientContacts.rows
  }

  return (
    <>
      <Grid className={displayClasses.flexRowCE} item xs={12}>
        <Button
          color='primary'
          variant='outlined'
          onClick={
            () => setFieldValue(`contacts[${values.contacts.length}]`, {
              Id: '',
              privilege: 'Self',
              status: 'A'
            })
          }
        >
          <SvgIcon fontSize='small'><Plus /></SvgIcon> Add
        </Button>
      </Grid>
      <FieldArray
        name='contacts'
        render={(helper) => (
          values.contacts.map((val, i) =>
            <Fragment key={`contacts-${i}`}>
              <Grid item xs={12} md={5}>
                <FormControl className={clsx(marginClasses.mb0, marginClasses.mt0)} margin='dense' variant='outlined' fullWidth>
                  <InputLabel>Contact</InputLabel>
                  <Select
                    label='Contact'
                    name={`contacts[${i}].Id`}
                    fullWidth
                    value={val.Id}
                    onChange={handleChange}
                    error={Boolean(errors.contacts && errors.contacts[i] && errors.contacts[i].Id)}
                  >
                    {contacts.map((contact) => {
                      return (
                        <MenuItem key={contact.Id} value={contact.Id}>
                          {contact.Contact_First_Name} {contact.Contact_Last_Name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {errors.contacts && errors.contacts[i] && errors.contacts[i].Id &&
                    <FormHelperText error>
                      {errors.contacts[i].Id}
                    </FormHelperText>
                  }
                </FormControl>
              </Grid>
              <Grid item xs={4} md={2}>
                <Typography variant='body2'>Privilege</Typography>
              </Grid>
              <Grid item xs={4} md={2}>
                <FormControlLabel
                  label='Self'
                  control={
                    <Radio
                      name={`contacts[${i}].privilege`}
                      value='Self'
                      checked={val?.privilege === 'Self'}
                      onChange={handleChange}
                    />
                  }
                />
              </Grid>
              <Grid item xs={i > 0 ? 3 : 4} md={2}>
                <FormControlLabel
                  label='All'
                  control={
                    <Radio
                      name={`contacts[${i}].privilege`}
                      value='All'
                      checked={val?.privilege === 'All'}
                      onChange={handleChange}
                    />
                  }
                />
              </Grid>
              {i > 0 &&
                <Grid item xs={1}>
                  <SvgIcon
                    fontSize='small'
                    color='primary'
                    onClick={() => helper.remove(i)}
                  >
                    <XCircle />
                  </SvgIcon>
                </Grid>
              }
            </Fragment>
          )
        )}
      />
    </>
  );
}

export { RelatedContacts };
