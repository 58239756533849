import React, { useEffect } from 'react';
import { CheckCircle } from 'react-feather';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';

import { getMatchedOrders } from '../../Recoil/Selectors/Statistics';
import { matchedOrders } from '../../Recoil/Atoms/Stats';
import { orderChannel } from './../../Common/NotificationService'

import { Icon } from './Icon';

const Matched = () => {

  const [unsignedOrdersCount, setUnassinedOrders] = useRecoilState(matchedOrders);
  const count: any = useRecoilValueLoadable(getMatchedOrders);

  useEffect(() => {
    if (count.state !== 'loading' && unsignedOrdersCount === 0) {
      setUnassinedOrders(count?.contents?.result)
    }
  })
  orderChannel.bind('matchedOrders', data => {
    setUnassinedOrders(data.count)
  });


  return (
    <Icon title='Matched' count={unsignedOrdersCount}>
      <CheckCircle />
    </Icon>
  );
};

export { Matched };
