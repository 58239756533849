import * as Yup from 'yup';

const initialValues = {
  nightlyReportEmail: '',
  signing_request: '1',
  client_survey: '1',
  update_orders_check: '1',
};

const validationSchema = Yup.object().shape({
  nightlyReportEmail: Yup
    .string()
    .required('Cannot be empty'),
});

export { initialValues, validationSchema };
