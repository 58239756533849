import React, { useState } from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { Typography, Box, Divider } from '@material-ui/core';

import { NewUser } from './NewUser';
import { Page } from '../../Components/Page';
import { GeneralModal } from '../../Components/Modal';
import { UserActions } from '../../Components/Admin/UserActions';
import { UserData } from '../../Components/Admin/UserData';
import { Toast } from '../../Components/Toasts';

import { getUsers } from '../../Recoil/Selectors/User';

import { useStyles } from '.././Styles/Page';

export const User = () => {
  const classes = useStyles();
  const [modal, handleModal] = useState(false);
  const [isEditMode, setEditMode] = useState(false);

  const [selectedUser, handleSelectedUser] = useState({});
  const [toast, setToast] = useState({
    message: '',
    type: 'success',
    open: false
  });

  const userData: any = useRecoilValueLoadable(getUsers);

  const data = userData?.contents?.result?.rows || [];
  const total = userData?.contents?.result?.count || 0;

  const openModal = open => {
    handleSelectedUser({});
    handleModal(open);
    setEditMode(false);
  };

  const handleEdit = params => {
    handleSelectedUser(params);
    handleModal(true);
    setEditMode(true);
  };

  return (
    <Page className={classes.paper} title='Users'>
      <Typography variant='h3'>Users</Typography>
      <Box my={2}>
        <Divider variant='fullWidth' />
      </Box>
      <UserActions handleModal={handleModal} />
      <UserData
        loading={userData.state === 'loading'}
        data={data}
        total={total}
        handleEdit={handleEdit}
      />
      <GeneralModal
        open={modal}
        title={isEditMode ? 'Edit User' : 'New User'}
        loading={false}
        openModal={openModal}
        noSubmit={true}
      >
        <NewUser
          selectedUser={selectedUser}
          openModal={openModal}
          setToast={setToast}
        />
      </GeneralModal>
      <Toast
        message={toast.message}
        type={toast.type}
        open={toast.open}
        setToast={setToast}
      />
    </Page>
  );
};
