import { makeStyles } from '@material-ui/core';
import { colorLegends } from 'src/Common/Rules/ColorLegends';

const colors: any = {}
colorLegends.map(c => colors[c.colorName] = c.color)

export const useStyles = makeStyles((theme) => ({
  colorlegend: {
    height: '10px',
    width: '10px',
    display: 'inline-block',
    marginRight: '4px',
    marginLeft: '12px',
    '&.bg-mauve': {
      background: colors.mauve
    },
    '&.bg-azure': {
      background: colors.azure
    },
    '&.bg-green': {
      background: colors.green
    },
    '&.bg-yellow': {
      background: colors.yellow
    },
    '&.bg-brass': {
      background: colors.brass
    },
    '&.bg-coral': {
      background: colors.coral
    },
    '&.bg-salmon': {
      background: colors.salmon
    },
    '&.bg-pink': {
      background: colors.pink
    },
    '&.bg-red': {
      background: colors.red
    },
    '&.bg-purple': {
      background: colors.purple
    },
    '&.bg-riptide': {
      background: colors.riptide
    },
  },
  wrapper: {
    paddingTop: '10px'
  }
}));



