import React from 'react';
import { Grid, TextField } from '@material-ui/core';

const ShippingAccount = ({ values, errors, touched, handleChange, handleBlur }) => {
  return (
    <Grid item xs={12} md={6}>
      <TextField
        label='Shipping Account Number'
        name='shippingAccount'
        variant='outlined'
        size='small'
        fullWidth
        value={values.shippingAccount || ''}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.shippingAccount && errors.shippingAccount}
        error={Boolean(touched.shippingAccount && errors.shippingAccount)}
      />
    </Grid>
  );
}

export { ShippingAccount };
