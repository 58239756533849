import React, { Fragment } from 'react';
import { Grid, TextField, Button, SvgIcon } from '@material-ui/core';
import { FieldArray } from 'formik';
import { Plus, XCircle } from 'react-feather';

const AdditionalBorrowers = ({ isViewOnlyMode, location, values, handleChange, setFieldValue }) => {
  const list = Array.isArray(values.additionalBorrowersModel) ? values.additionalBorrowersModel : []
  return (
    <>
      <FieldArray
        name={`${location}.additionalBorrowersModel`}
        render={(helper) => (
          list.map((val, i) =>
            <Fragment key={`${location}-additionalBorrowersModel-${i}`}>
              <Grid item md={6}>
                <TextField
                  disabled={isViewOnlyMode}
                  label='First Name'
                  name={`${location}.additionalBorrowersModel[${i}].first_name`}
                  variant='outlined'
                  size='small'
                  fullWidth
                  value={val.first_name}
                  onChange={ e => { 
                    e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.substr(1).toLowerCase();
                    handleChange(e)}}
                />
              </Grid>
              <Grid item md={5}>
                <TextField
                  disabled={isViewOnlyMode}
                  label='Last Name'
                  name={`${location}.additionalBorrowersModel[${i}].last_name`}
                  variant='outlined'
                  size='small'
                  fullWidth
                  value={val.last_name}
                  onChange={ e => { 
                    e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.substr(1).toLowerCase();
                    handleChange(e)}}
                />
              </Grid>
              <Grid item md={1}>
                <SvgIcon
                  fontSize='small'
                  color='primary'
                  onClick={() => helper.remove(i)}
                >
                  <XCircle />
                </SvgIcon>
              </Grid>
            </Fragment>
          )
        )}
      />
      <Grid item xs={12}>
        <Button
          color='primary'
          variant='outlined'
          disabled={isViewOnlyMode}
          onClick={
            () => setFieldValue(`${location}.additionalBorrowersModel[${values.additionalBorrowersModel.length}]`, {
              first_name: '',
              last_name: ''
            })
          }
        >
          <SvgIcon fontSize='small'><Plus /></SvgIcon> Additional Borrower
        </Button>
      </Grid>
    </>
  );
}

export { AdditionalBorrowers };
