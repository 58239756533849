import React, { useState } from 'react';
import { Grid, Typography, FormControlLabel, Radio, Button } from '@material-ui/core';

import { SectionTitle } from '../../SectionTitle';

import { marginStyles } from '../../../Common/Styles/Margins';
import { displayStyles } from '../../../Common/Styles/Display';
import { ViewInvoice } from './ViewInvoice';

const InvoiceInfo = (props) => {
  const marginClasses = marginStyles();
  const displayClasses = displayStyles();
  const { values, setFieldValue, location, isViewOnlyMode } = props
  const [viewInvoice, setViewInvoice] = useState(false)

  return (
    <>
      <ViewInvoice open={viewInvoice} close={e => setViewInvoice(false)} orderDetails={values}></ViewInvoice>
      <Grid item xs={12}>
        <SectionTitle title='Payment Information' variant='h4' />
      </Grid>
      <Grid item xs={6} md={3}>
        <Typography variant='body2'>Invoice Ref #</Typography>
      </Grid>
      <Grid className={displayClasses.flexRowC} item xs={6} md={9}>
        <Typography variant='body2'>{values.invoice_ref}</Typography>

        <Button
          disabled={!values?.signer}
          className={marginClasses.ml2}
          color='primary'
          onClick={e => setViewInvoice(true)}
        >
          View Invoice
        </Button>

      </Grid>
      <Grid item md={3}>
        <Typography variant='body2'>Allowed Signer Invoice</Typography>
      </Grid>
      <Grid item md={2}>
        <FormControlLabel
          label='Yes'
          control={
            <Radio
              disabled={isViewOnlyMode}
              onChange={e => { setFieldValue(`${location}.cansubmitinvoice`, e.target.checked ? '1' : '0') }}
              checked={parseInt(values.cansubmitinvoice) === 1}
              name={`${location}.cansubmitinvoice`}
              value='1'
            />
          }
        />
      </Grid>
      <Grid item md={7}>
        <FormControlLabel
          label='No'
          control={
            <Radio
              disabled={isViewOnlyMode}
              onChange={e => { setFieldValue(`${location}.cansubmitinvoice`, e.target.checked ? '0' : '1') }}
              checked={parseInt(values.cansubmitinvoice) === 0}
              name={`${location}.cansubmitinvoice`}
              value='0'
            />
          }
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <Typography variant='body2'>Total Notary Fee</Typography>
      </Grid>
      <Grid item xs={6} md={9}>
        <Typography variant='body2'>{values.totalNotaryFee}</Typography>
      </Grid>
    </>
  );
}

export { InvoiceInfo };
