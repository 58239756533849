import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

import { Page } from '../Components/Page';
import { Summary } from '../Components/Dashboard/Summary';
import { Timezones } from '../Components/Dashboard/Timezones';
import { SignedAndOrdersSummary } from '../Components/Dashboard/SignedAndOrdersSummary';
import { Goal } from '../Components/Dashboard/Goal';
import { SignerFeeRatio } from '../Components/Dashboard/SignerFeeRatio';
import { SatisfactionRating } from '../Components/Dashboard/SatisfactionRating';

import { useStyles } from './Styles/Page';
import { marginStyles } from '../Common/Styles/Margins';
import { displayStyles } from '../Common/Styles/Display';

export const Dashboard = () => {
  const classes = useStyles();
  const marginClasses = marginStyles();
  const displayClasses = displayStyles();

  
  return (
    <Page className={classes.noBgPage} title='Dasboard'>
      <Box my={2}>
        <Typography variant='h4'>DASHBOARD</Typography>
        <Typography className={marginClasses.mt2} variant='body2'>Welcome to PacDocSign Admin Panel!</Typography>
      </Box>
      <Grid container spacing={2}>
        <Summary />
        <Grid item md={12}>
          <Timezones />
        </Grid>
        <Grid item sm={12} md={6}>
          <SignedAndOrdersSummary />
        </Grid>
        <Grid item sm={12} md={6}>
          <Goal />
        </Grid>
        <Grid item sm={12} md={6}>
          <SignerFeeRatio />
        </Grid>
        <Grid item sm={12} md={6}>
          <SatisfactionRating />
        </Grid>

        <Grid item sm={12} md={12}>
          <Box className={displayClasses.themeBG} p={2}>
            <Typography variant='h4'>EVENTS</Typography>
            <iframe
              src='https://www.google.com/calendar/embed?title=PacDocSign%20Calendar&amp;height=600&amp;wkst=1&amp;bgcolor=%23FFFFFF&amp;src=Info.PacDocSign%40gmail.com&amp;color=%232952A3&amp;src=en.usa%23holiday%40group.v.calendar.google.com&amp;color=%232952A3&amp;ctz=America/Los_Angeles'
              style={{ borderWidth: 0, width: ' 100%', height: '600px' }}
              frameBorder={0}
              title='calendar'
              scrolling='no'
            />
          </Box>
        </Grid>
      </Grid>
    </Page>
  );
}
