const colorLegends = [
  { shortName: 'RSS', name: 'Reminder SMS Sent', color: '#E0B0FF', colorName: 'mauve' },
  { shortName: 'CSS', name: 'Confirmation SMS Sent', color: 'azure', colorName: 'azure' },
  { shortName: 'C', name: 'Confirmed', color: '#0fc561', colorName: 'green' },
  { shortName: 'RNR', name: 'Not Answered To Reminder', color: '#fffa7b', colorName: 'yellow' },
  { shortName: 'NR', name: 'Not Answered To Confirmation', color: '#3fe0cb', colorName: 'brass' },
  { shortName: 'D', name: 'Denied', color: '#E77471', colorName: 'coral' },
  // { shortName: 'BWNS', name: 'Borrower Would Not Sign', color: '#F9966B', colorName: 'salmon' },
  { shortName: 'OPTN', name: 'options', color: '#70daca', colorName: 'riptide', hidden: true },
  { shortName: 'WorkedOn', name: '', color: '#4caf50', colorName: 'madang', hidden: true },
  { shortName: 'WorkedOn', name: '', color: '#ff9393', colorName: 'red', hidden: true },
  { shortName: 'WorkedOn', name: '', color: '#d791e9', colorName: 'purple', hidden: true },
]

const colorLegendsUnassignedOrders = [
  { shortName: 'Acc', name: 'Accepted', color: '#d791e9', colorName: 'purple' },
  { shortName: 'NA/VM', name: 'No Answer/Voice Mail', color: '#0fc561', colorName: 'green' },
  { shortName: 'NA', name: 'Not Available', color: '#fffa7b', colorName: 'yellow' },
  { shortName: 'FTH', name: 'Fee Too High', color: '#ff9393', colorName: 'red' },
  { shortName: 'Other', name: 'Other (read comment)', color: '#70daca', colorName: 'riptide' },
  { shortName: 'WorkedOn', name: '', color: '#4caf50', colorName: 'madang', hidden: true },
  { shortName: 'WorkedOn', name: '', color: '#3fe0cb', colorName: 'brass', hidden: true },
]


const getColorFromStatus = (status) => {
  const color = colorLegends.filter(c => c.shortName === status || c.name === status)?.[0]?.colorName || ''
  return color
}
export { colorLegends, getColorFromStatus, colorLegendsUnassignedOrders }


