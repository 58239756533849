import React from 'react';
import { useRecoilValue } from "recoil";
import { DialogContentText } from "@material-ui/core";
import { currentOrder as currentOrderState} from "../../../../Recoil/Atoms/Orders";

export const OrderLock = () => {
  const currentOrder = useRecoilValue(currentOrderState);
  const isLocked = currentOrder?.order_locked === 'Y'

  return (
    <DialogContentText id="alert-dialog-description">
      Are you sure you want to {isLocked ? 'unlock' : 'lock'} this order?
    </DialogContentText>
  );
};
