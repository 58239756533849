import { makeStyles } from '@material-ui/core';

import type { Theme } from '../../ThemeComponents/theme';

export const displayStyles = makeStyles((theme: Theme) => ({
  w100: {
    width: '100% !important'
  },
  whiteColor: {
    color: '#ffffff'
  },
  noTextDecor: {
    textDecoration: 'none'
  },
  themeBG: {
    backgroundColor: theme.palette.background.paper
  },
  textArea: {
    fontSize: '1.3rem',
    width: '100%',
    padding: '8px 10px'
  },
  gridRoot: {
    flexGrow: 1
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column'
  },
  flexRowC: {
    display: 'flex',
    alignItems: 'center',
  },
  flexRowE: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  flexRowCC: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  flexRowCE: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  flexRowEC: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center'
  },
  justifyBetween: {
    justifyContent: 'space-between'
  },
  justifyAround: {
    justifyContent: 'space-around'
  }
}));
