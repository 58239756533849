import { makeStyles } from '@material-ui/core';

import type { Theme } from 'src/ThemeComponents/theme';

export const useStyles = makeStyles((theme: Theme) => ({
  popover: {
    width: 320
  },
  badge: {
    borderRadius: '50%',
    marginTop: 10,
    marginRight: 5,
    fontSize:'20px',
    height: '30px',
  },
  iconColor: {
    color: '#fff'
  },
  root:{
    margin: 'auto 5px'
  }
}));
