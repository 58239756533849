import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import moment from 'moment-timezone';

import { useStyles } from './Styles/Timezones';
import { displayStyles } from '../../Common/Styles/Display';
import { marginStyles } from '../../Common/Styles/Margins';

const Timezones = () => {
  const classes = useStyles();
  const displayClasses = displayStyles();
  const marginClasses = marginStyles();

  const [pst, setPst] = useState(moment.tz('America/Los_Angeles').format('hh:mm:ss A'));
  const [mst, setMst] = useState(moment.tz('America/Denver').format('hh:mm:ss A'));
  const [cst, setCst] = useState(moment.tz('America/Chicago').format('hh:mm:ss A'));
  const [est, setEst] = useState(moment.tz('America/New_York').format('hh:mm:ss A'));

  useEffect(() => {
    const interval = setInterval(() => {
      setPst(moment.tz('America/Los_Angeles').format('hh:mm:ss A'));
      setMst(moment.tz('America/Denver').format('hh:mm:ss A'));
      setCst(moment.tz('America/Chicago').format('hh:mm:ss A'));
      setEst(moment.tz('America/New_York').format('hh:mm:ss A'));
    }, 1000);

    return () => clearInterval(interval);
  }, [setPst, setMst, setCst, setEst]);

  return (
    <>
      <Box className={classes.topBorder} p={2}>
        <Typography className={displayClasses.whiteColor} variant='h5'>TIMEZONE</Typography>
      </Box>
      <Box className={classes.contentArea} py={5} display='flex' justifyContent='space-around'>
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Typography variant='h4'>Pacific Time Zone</Typography>
          <Typography className={marginClasses.mt2} variant='h4'>{pst}</Typography>
        </Box>
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Typography variant='h4'>Mountain Time Zone</Typography>
          <Typography className={marginClasses.mt2} variant='h4'>{mst}</Typography>
        </Box>
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Typography variant='h4'>Central Time Zone</Typography>
          <Typography className={marginClasses.mt2} variant='h4'>{cst}</Typography>
        </Box>
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Typography variant='h4'>Eastern Time Zone</Typography>
          <Typography className={marginClasses.mt2} variant='h4'>{est}</Typography>
        </Box>
      </Box>
    </>
  );
}

export { Timezones };
