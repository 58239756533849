import React, { useCallback } from 'react';
import { Paper, TablePagination } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { DataGrid } from '@material-ui/data-grid';
import clsx from 'clsx';

import { useStyles } from './Styles';
import { colorLegends } from 'src/Common/Rules/ColorLegends';

const Table = (props: any) => {
  const classes = useStyles();
  const {
    customRowHeight = 70,
    columns,
    total,
    pageInfo,
    handlePage,
    handleRowsPerPage,
    pagination = true,
    useCustomStyles = true,
    uniqueKey,
    sortModel = [],
    onSortingChange,
    loading,
    checkboxSelection,
    rowsPerPageOptions = [10, 20, 30],
    onSelectionModelChange,
    /**
     *  getRowBackGroundColor(row, colorOptions)
     *  @returns name of color one {rowBackgroundOptions}
     */
    getRowBackGroundColor
  } = props;
  let { rows } = props;

  // Ensure each row has a unique id
  rows = rows.map((r: any, index: number) => {
    const uniqueId = r[uniqueKey] || r.id || `${index + 1}`;
    return {
      ...r,
      id: getRowBackGroundColor
        ? `${uniqueId} row-bg-${getRowBackGroundColor(r, colorLegends)}`
        : uniqueId
    };
  });

  const fullWidthColumns = columns.map((column: any) => {
    return {
      ...column,
      headerClassName: classes.columnWidths,
      cellClassName: classes.columnWidths
    };
  });

  const TablePaginationActions = useCallback(
    (action: any) => (
      <Pagination
        className={action.className}
        count={Math.ceil(action.count / action.rowsPerPage)}
        page={action.page + 1}
        onChange={handlePage}
      />
    ),
    [handlePage]
  );

  const paginationBlock = (
    <TablePagination
      className={classes.pagination}
      hidden={false}
      rowsPerPageOptions={rowsPerPageOptions}
      component="div"
      count={total || (rows?.length ?? 0)}
      rowsPerPage={pageInfo ? pageInfo.limit : 20}
      page={pageInfo ? pageInfo.page - 1 : 0}
      onPageChange={(event, newPage) => handlePage(event, newPage)}  // Corrected onPageChange
      onChangeRowsPerPage={handleRowsPerPage || null}
      labelRowsPerPage={
        <>
          <div className={classes.paginationTotalRows}>
            Total Rows: {(rows?.length ?? 0)?.toLocaleString()}
          </div>
          <div>Rows per page</div>
        </>
      }
      ActionsComponent={TablePaginationActions}
    />
  );

  return (
    <Paper className={classes.paper} elevation={0} square={true}>
      <DataGrid
        loading={loading}
        className={useCustomStyles ? clsx(
          classes.datagrid,
          classes.datagridFluidHeight,
          classes.datagridFluidWidth,
          classes.generalGridClasses
        ) : classes.generalGridClasses}
        rows={rows}
        rowHeight={customRowHeight}
        headerHeight={50}
        columns={useCustomStyles ? fullWidthColumns : columns}
        autoHeight
        disableSelectionOnClick={true}
        disableExtendRowFullWidth={true}
        checkboxSelection={checkboxSelection}
        sortingOrder={['desc', 'asc']}
        onSelectionModelChange={onSelectionModelChange}
        sortingMode={onSortingChange ? 'server' : 'client'}
        sortModel={sortModel}
        onSortModelChange={onSortingChange ? onSortingChange : null}
        components={{
          Footer: () => (pagination && paginationBlock)
        }}
      />
    </Paper>
  );
};

export { Table };
