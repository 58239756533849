import React from 'react';
import {
  Grid,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';

import { useRecoilValue } from 'recoil';

import { accessGroupResources } from '../../../Recoil/Atoms/AccessGroups';

const AccessGroupResources = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur
}) => {
  const resources = useRecoilValue(accessGroupResources);

  return (
    <Grid container xs={12}>
      <FormControl
        component='fieldset'
        error={Boolean(
          touched.accessGroupResources && errors.accessGroupResources
        )}
      >
        <FormGroup>
          {resources.map((resource, idx) => {
            return (
              <FormControlLabel
                key={idx}
                control={
                  <Checkbox
                    onChange={handleChange}
                    name='accessGroupResources'
                    value={resource.id}
                  />
                }
                label={resource.name}
              />
            );
          })}
        </FormGroup>
      </FormControl>
    </Grid>
  );
};

export { AccessGroupResources };
