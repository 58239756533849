import React from 'react';
import { Box } from '@material-ui/core';
import { Pie } from 'react-chartjs-2';

const PieChart = ({ data }: any) => {
  return (
    <Box minHeight={400}>
      <Pie data={data} />
    </Box>
  );
};

export { PieChart };
