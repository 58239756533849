import React, { useState, useEffect } from 'react';
import { useSetRecoilState, useRecoilState } from 'recoil';
import { Box, IconButton, Tooltip } from '@material-ui/core';

import { List, Eye, DollarSign, Lock, Unlock, CreditCard } from 'react-feather';

import { currentOrder } from '../../../Recoil/Atoms/Orders';
import { dialog as dialogState } from '../../../Recoil/Atoms/Modals';
import {
  changePaymentStatusPopupRefetch,
  paySingleBillPopup
} from './../../../Recoil/Atoms/Accounting';

import { NewOrder } from '../../../Pages/Orders/AddEditViewOrder';
import { SlidingPane } from '../../SlidingPane';

const PendocVendorInvoiceDataActions = ({
  row,
  handleChangeRowReferenceNumber,
  handleChangeRowPaymentStatus,
  handleLockInvoice,
  handlePayBillModal
}) => {
  const [order, setOrder] = useState(row);
  const [isLocked, setIsLocked] = useState(false);
  const [userRole, setUserRole] = useState(
    JSON.parse(localStorage.getItem('user'))
  );

  const [currentOrderId, setCurrentOrderId] = useState(null);
  const [paneOpen, setPaneOpen] = useState(false);

  const handlePaneOpen = (orderId) => {
    setCurrentOrderId(orderId);
    setPaneOpen(true);
  };

  const handlePaneClose = () => {
    setPaneOpen(false);
    setCurrentOrderId(null); // Reset current order ID when closing the pane
  };
  const [dialog, setDialog] = useRecoilState(dialogState);
  const setCurrentInvoiceData = useSetRecoilState(currentOrder);

  const setChangePaymentStatusPopup = useSetRecoilState(
    changePaymentStatusPopupRefetch
  );
  const setSinglePayBill = useSetRecoilState(paySingleBillPopup);

  useEffect(() => {
    setOrder(row);
    setIsLocked(order?.order_locked === 'Y');
    let getRole = JSON.parse(localStorage.getItem('user'));
    setUserRole(getRole);
  }, [row, order]);

  const handleLockClick = (row: any, isLocked: Boolean) => {
    setCurrentInvoiceData({ ...row, order_locked: isLocked ? 'Y' : 'N' });
    setDialog({
      ...dialog,
      open: true,
      type: 'lock',
      title: isLocked ? 'Lock Invoice' : 'Unlock Invoice',
      submitLabel: 'Yes',
      handleSubmit: () => {
        setIsLocked(!isLocked);
        handleLockInvoice();
      }
    });
  };

  return (
    <Box display='flex' flexWrap='wrap'>
      <Tooltip title='View'>
        <IconButton
          size='small'
          color='primary'
          onClick={() => {
            handlePaneOpen(row?.Id);
          }}
        >
          <Eye width='25' height='25' />
        </IconButton>
      </Tooltip>

      <Tooltip title='Change Payment Status'>
        <IconButton
          size='small'
          color='primary'
          onClick={() => {
            setChangePaymentStatusPopup((values) => {
              return { Id: row?.Id, refetch: values?.refetch + 1 };
            });
            handleChangeRowPaymentStatus(row);
          }}
        >
          <DollarSign width='25' height='25' />
        </IconButton>
      </Tooltip>

      {userRole && userRole.role && userRole.role.name === 'Accountant' && (
        <Tooltip title={isLocked ? 'Unlock' : 'Lock'}>
          <IconButton
            size='small'
            color='primary'
            onClick={() => handleLockClick(row, isLocked)}
          >
            {isLocked ? (
              <Lock width='25' height='25' />
            ) : (
              <Unlock width='25' height='25' />
            )}
          </IconButton>
        </Tooltip>
      )}

      {row?.qb_status === '1' && (
        <>
          <Tooltip title='Pay Bill'>
            <IconButton
              size='small'
              color='primary'
              onClick={() => {
                setSinglePayBill((values) => {
                  return { Id: row?.Id, refetch: values?.refetch + 1 };
                });
                handlePayBillModal(row);
              }}
            >
              <CreditCard width='25' height='25' />
            </IconButton>
          </Tooltip>
          <Tooltip title='Change Reference Number'>
            <IconButton
              size='small'
              color='primary'
              onClick={() => handleChangeRowReferenceNumber(row)}
            >
              <List width='25' height='25' />
            </IconButton>
          </Tooltip>
        </>
      )}
      <SlidingPane
        title={'View Invoice'}
        open={paneOpen}
        handlePane={handlePaneClose}
        width='95%'
      >
        {paneOpen && (
          <NewOrder
            key={currentOrderId || 'new'}
            id={currentOrderId}
            open={paneOpen}
            justView={true}
          />
        )}
      </SlidingPane>
    </Box>
  );
};

export { PendocVendorInvoiceDataActions };
