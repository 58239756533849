import * as Yup from 'yup';
import moment from 'moment';

const fLoanNumber = [''];

const fields = {
  w_Appointment_Date: moment(new Date(), 'MM/DD/YYYY'),
  w_Appointment_Time: '',
  w_Appointment_Time_type: 'SC',
  w_Borrower_First_Name: '',
  w_Borrower_Last_Name: '',
  clientID: '',
  Contact_ID: '',
  division_id: '',
  Closing_Type: '',
  w_Co_Borrower_First_Name: '',
  w_Co_Borrower_Last_Name: '',
  additionalBorrowersModel: [],
  Signer_Type: 'Notary',
  Partial_Signing: '',
  additional_client_email: '',
  w_Signing_Location_Name: '',
  w_Signing_Street_Address: '',
  w_Signing_City: '',
  w_Signing_State: '',
  w_Signing_Zip: '',
  property_address: '',
  property_city: '',
  property_state: '',
  property_zip: '',
  w_Signing_Phone_Number: '',
  w_Borrower_Home_Phone: '',
  f_borrower_email: '',
  w_Additional_Information: '',
  f_status_web: 'In Process',
  latitude: '',
  longitude: '',
  fax_back: 'N',
  w_outsource: 'N',
  w_record_state: 'New',
  w_Return_Tracking: '',
  S2C_Tracking_Note: '',
  S2C_Tracking_Status: '',
  cansubmitinvoice: '0',
  payment_note: '',
  payment_check_date: '',
  payment_check_no: '',
  payment_status: '',
};

const locationOne = Object.assign({}, fields);
const locationTwo = Object.assign({}, fields);

const locationOneSchema = {
  location_one: Yup.object().shape({
    w_Appointment_Date: Yup
      .date().typeError('Please Enter a Valid Appointment Date').required('Please Enter Appointment Date'),
    w_Appointment_Time: Yup
      .mixed()
      .when('w_Appointment_Time_type', (type : any) => {
        if(type == 'SC'){
          return Yup.date().typeError('Please Enter a Valid Appointment Time').required('Please Enter Appointment Time')
        }
        else{
          return Yup.mixed()
        }
      }
      ),
    clientID: Yup
      .number().required('Please Select a Client'),
    Contact_ID: Yup
      .number().required('Please Select a Contact'),
    division_id: Yup
      .number().required('Please Select a Division'),
    w_Appointment_Time_type: Yup
      .string().nullable(),
    w_Borrower_First_Name: Yup
      .string().max(255).required('Please Enter Borrower First Name'),
    w_Borrower_Last_Name: Yup
      .string().max(255).required('Please Enter Borrower Last Name'),
    w_Co_Borrower_First_Name: Yup
      .string().max(255).nullable(),
    w_Co_Borrower_Last_Name: Yup
      .string().max(255).nullable(),
    additionalBorrowersModel: Yup
      .array()
      .of(
        Yup.object({
          first_name: Yup.string().max(255).nullable(),
          last_name: Yup.string().max(255).nullable(),
        })
      )
      .optional(),
    Signer_Type: Yup
      .string().required('Signer Type Missing'),
    Closing_Type: Yup
      .string().required('Closing Type Missing'),
    Partial_Signing: Yup
      .number().required('Please select signing type'),
    additional_client_email: Yup
      .string().email('Email is Invalid').nullable(),
    w_Signing_Location_Name: Yup
      .string().max(255).nullable(),
    w_Signing_Street_Address: Yup
      .string().max(255).required('Please Enter a Valid Address'),
    w_Signing_City: Yup
      .string().max(255).required('Please Enter a City'),
    w_Signing_State: Yup
      .string().max(255).required('Please Select a State'),
    w_Signing_Zip: Yup
      .string().max(255).required('Please Enter a Valid Zip Code'),
    property_address: Yup
      .string().max(255).nullable(),
    property_city: Yup
      .string().max(255).nullable(),
    property_state: Yup
      .string().max(255).nullable(),
    property_zip: Yup
      .string().max(255).nullable(),
    w_Signing_Phone_Number: Yup
      .string().max(255).required('Please Enter a Valid Phone Number'),
    w_Borrower_Home_Phone: Yup
      .string().max(255).nullable(),
    f_borrower_email: Yup
      .string().email('Invalid Email').notRequired(),
    w_Additional_Information: Yup
      .string().nullable(),
  })
};

const locationTwoSchema = {
  location_two: Yup.object().shape({
    w_Appointment_Date: Yup
      .date().typeError('Please Enter a Valid Appointment Date').required('Please Enter Appointment Date'),
    w_Appointment_Time: Yup
      .mixed()
      .when('w_Appointment_Time_type', (type: any)=> {
        if(type === "SC"){
          return Yup.date().typeError('Please Enter a Valid Appointment Time').required('Please Enter Appointment Time')
        }else{
          return Yup.mixed()
        }
      }),
    w_Appointment_Time_type: Yup
      .string(),
    w_Borrower_First_Name: Yup
      .string().max(255).required('Please Enter Borrower First Name'),
    w_Borrower_Last_Name: Yup
      .string().max(255).required('Please Enter Borrower Last Name'),
    w_Co_Borrower_First_Name: Yup
      .string().max(255),
    Closing_Type: Yup
      .string().required('Closing Type Missing'),
    w_Co_Borrower_Last_Name: Yup
      .string().max(255),
    additionalBorrowersModel: Yup
      .array()
      .of(
        Yup.object({
          first_name: Yup.string().max(255),
          last_name: Yup.string().max(255),
        })
      )
      .optional(),
    Signer_Type: Yup
      .string().required('Signer Type Missing'),
    Partial_Signing: Yup
      .number().required('Please select signing type'),
    w_Signing_Location_Name: Yup
      .string().max(255),
    w_Signing_Street_Address: Yup
      .string().max(255).required('Please Enter a Valid Address'),
    w_Signing_City: Yup
      .string().max(255).required('Please Enter a City'),
    w_Signing_State: Yup
      .string().max(255).required('Please Select a State'),
    w_Signing_Zip: Yup
      .string().max(255).required('Please Enter a Valid Zip Code'),
    property_address: Yup
      .string().max(255),
    property_city: Yup
      .string().max(255),
    property_state: Yup
      .string().max(255),
    property_zip: Yup
      .string().max(255),
    w_Signing_Phone_Number: Yup
      .string().max(255).required('Please Enter a Valid Phone Number'),
    w_Borrower_Home_Phone: Yup
      .string().max(255),
    f_borrower_email: Yup
      .string().email('Invalid Email').required('Please Enter a Valid Email'),
    w_Additional_Information: Yup
      .string(),
  })
};

const validationSchemaWithoutSplit = Yup.object().shape({
  w_Loan_Number: Yup
    .array()
    .of(
      Yup.string()
        .required('Loan Number is Required')
    ),
  ...locationOneSchema
});

const validationSchemaWithSplit = Yup.object().shape({
  w_Loan_Number: Yup
    .array()
    .of(
      Yup.string()
        .required('Loan Number is Required')
    ),
  ...locationOneSchema,
  ...locationTwoSchema
});

const validationSchemaForExisting = Yup.object().shape({
  ...locationOneSchema
});
const allowedFieldsWhileSaving = [
  'w_Appointment_Date',
  'w_Appointment_Time',
  'w_Appointment_Time_type',
  'w_Borrower_First_Name',
  'w_Borrower_Last_Name',
  'clientID',
  'Contact_ID',
  'division_id',
  'Closing_Type',
  'w_Co_Borrower_First_Name',
  'w_Co_Borrower_Last_Name',
  'additionalBorrowersModel',
  'Signer_Type', 'Partial_Signing',
  'additional_client_email',
  'w_Signing_Location_Name',
  'w_Signing_Street_Address',
  'w_Signing_City',
  'w_Signing_State',
  'w_Signing_Zip',
  'property_address',
  'property_city',
  'property_state',
  'property_zip',
  'w_Signing_Phone_Number',
  'w_Borrower_Home_Phone',
  'f_borrower_email',
  'w_Additional_Information',
  'f_status_web',
  'latitude',
  'longitude',
  'fax_back',
  'w_outsource',
  'w_record_state',
  // 'Signing_Phone_Number',
  // 'Home_Phone'
]
export {
  fLoanNumber,
  locationOne,
  locationTwo,
  validationSchemaWithoutSplit,
  validationSchemaWithSplit,
  validationSchemaForExisting,
  allowedFieldsWhileSaving
};
