import React, { useState } from 'react'
import { useRecoilState, useSetRecoilState, useRecoilCallback } from 'recoil'
import {
    Snackbar,
    Box,
    Button,
    IconButton
} from '@material-ui/core'
import { Alert } from '@material-ui/lab';
import { XCircle } from 'react-feather'

import { CallSigner } from './../../Components/Orders/CallSigner'
import { getAssignSignerOrderById, getCallerProfile, removeCallAlert } from './../../Recoil/Selectors/Signer'

import { storage } from '../../Services/storage';
import { fetchCallerProfile, refetchAssignSignerOrder, forceRemoveCallAlert } from './../../Recoil/Atoms/Signers'
import { signerCallAlerts } from './../../Recoil/Atoms/Orders'
import { orderChannel } from './../../Common/NotificationService'

const CallAlerts = () => {

    const [alertList, setAlertList] = useRecoilState(signerCallAlerts)
    const refetchSignerProfile = useSetRecoilState(fetchCallerProfile);
    const refetchOrder = useSetRecoilState(refetchAssignSignerOrder)
    const callAlertUpdate = useSetRecoilState(forceRemoveCallAlert)
    const [openAssignModal, setOpenAssignModal] = useState(false)
    const [loading, setLoading] = useState(false)

    const [callSigner, setCallSigner] = useState({ signerDetails: {}, orderDetails: {} })

    const removeAlert = (data) => {
        let alerts = [...alertList]
        const index = alerts.findIndex(alert => alert.orderId === data.orderId && alert.signerId === data.signerId)
        alerts.splice(index, 1)
        setAlertList(alerts)
    }

    const defaultValues = {
        w_Appointment_Date: '',
        w_Appointment_Time: '',
        Signer_First: '',
        Signer_Last: '',
        Signer_Email: '',
        signer_documents: [],
        Closing_Type: '',
        f_loan_number: '',
        signer_id: '',
        Id: '',
        latitude: '',
        longitude: '',
        distance: 0,
        f_signer_fees: '',
        signer_type: ''
    }

    orderChannel.bind('call-alert', data => {
        const receiveCalls = storage.get('receiveCalls') || '0'

        if (receiveCalls === '1') {
            let alerts = [...alertList]
            const existingAlert = alerts.find(alert => alert.orderId === data.orderId && alert.signerId === data.signerId)
            if (!existingAlert) {
                alerts.push(data)
                setAlertList(alerts)
            }
        } else if (alertList.length > 0 && receiveCalls === '1') {
            setAlertList([])
        }
    });


    orderChannel.bind('remove-call-alert', data => {
        let alerts = [...alertList]
        const index = alerts.findIndex(alert => alert.orderId === data.orderId && alert.signerId === data.signerId)

        if (index > -1) {
            alerts.splice(index, 1)
            setAlertList(alerts)
        }
    });


    const preparePopup = useRecoilCallback(({ snapshot }) => async (alert: any) => {
        refetchSignerProfile(n => n + 1)
        refetchOrder(n => n + 1)
        setLoading(true)
        const signer = await snapshot.getPromise(getCallerProfile(alert.signerId))
        const order = await snapshot.getPromise(getAssignSignerOrderById({ orderId: alert.orderId }))

        if (signer.success && order.success) {
            callAlertUpdate(n => n + 1)
            await snapshot.getPromise(removeCallAlert({ ...alert }))
            setCallSigner({ signerDetails: { ...signer.result, ...order.result }, orderDetails: order.result })
            setOpenAssignModal(true)
        }
        setLoading(false)
    });

    return (
        <>
            <CallSigner
                selectedSigner={callSigner.signerDetails}
                isAssignModalOpen={openAssignModal}
                OpenAssignModal={setOpenAssignModal}
                orderDetails={callSigner.orderDetails}
                setSigner={(defaultValue) => { }}
                defaultValues={defaultValues}
            />
            <Snackbar open={alertList.length > 0} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Box display='flex' flexDirection={'column'}>
                    {alertList.map(alert => {
                        return (
                            <Box margin={1} key={`${alert.orderId}${alert.signerId}`}>
                                <Alert
                                    variant='filled'
                                    icon={false}
                                    action={
                                        <>
                                            <Button
                                                color="primary"
                                                size="small"
                                                variant='contained'
                                                onClick={() => preparePopup(alert)}
                                                disabled={loading}
                                            >
                                                Take Call
                                            </Button>
                                            <IconButton
                                                disabled={loading}
                                                onClick={() => removeAlert(alert)}
                                            >
                                                <XCircle size={16} />
                                            </IconButton>
                                        </>
                                    }
                                >
                                    {`${alert.signerName} is on a Call For Loan# ${alert.w_Loan_Number}`}
                                </Alert>
                            </Box>
                        )
                    })

                    }
                </Box>
            </Snackbar>
        </>
    )
}

export { CallAlerts }

