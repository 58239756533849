import React from 'react';
import type { FC } from 'react';
import { Bar } from 'react-chartjs-2';
import { Box } from '@material-ui/core';
import { Chart, BarController, BarElement, LinearScale, CategoryScale, ArcElement } from 'chart.js';
Chart.register(BarController, BarElement, LinearScale, CategoryScale, ArcElement);
interface ChartProps {
  data: any;
  labels: string[]
}

const BarChart: FC<ChartProps> = ({
  data: dataProp,
  labels
}) => {
  const data = {
    datasets: [
      {
        label: 'Total Collected',
        backgroundColor: '#5fbeaa',
        data: dataProp.data1,
        barThickness: 12,
        maxBarThickness: 10,
        barPercentage: 0.5,
        categoryPercentage: 0.5
      },
      {
        label: 'Total Payout',
        backgroundColor: '#5d9cec',
        data: dataProp.data2,
        barThickness: 12,
        maxBarThickness: 10,
        barPercentage: 0.5,
        categoryPercentage: 0.5
      }
    ],
    labels
  };

  const options : any = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
          },
          ticks: {
            padding: 20,
            beginAtZero: true,
            min: 0,
            maxTicksLimit: 10
          }
        }
      ]
    },
  };

  return (
    <Box minHeight={400}>
      <Bar
        data={data}
        options={options}
      />
    </Box>
  );
};

export { BarChart };
