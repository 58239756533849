import React from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

const ChangeOrderStatus = ({ isViewOnlyMode, location, values, handleChange }) => {

    const totalStatuses = ['In Process', 'Assigned', 'Duplicate', 'Signed', 'Borrower Would Not Sign', 'Borrower No Show',
        'Cancelled By Borrower', 'Cancelled By Client', 'Late Cancellation - Print Fee', 'No Docs'];

    return (
        <Grid container>
            <FormControl
                fullWidth
                margin='dense'
                variant='outlined'
            >
                <InputLabel>Status</InputLabel>
                <Select disabled={isViewOnlyMode} name={`${location}.f_status_web`} onChange={handleChange} label='Clients' value={values?.f_status_web}>
                    {
                        totalStatuses.map(status => <MenuItem selected={values.f_status_web === status} key={status} value={status}>{status}</MenuItem>)
                    }
                </Select>
            </FormControl>
        </Grid>
    );
}

export { ChangeOrderStatus };
