import React from 'react';
import { Grid, TextField } from '@material-ui/core';

const UserLastName = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur
}) => {
  return (
    <Grid container xs={12}>
      <TextField
        label='Last Name'
        name='lastName'
        variant='outlined'
        size='small'
        fullWidth
        value={values.lastName}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.lastName && errors.lastName}
        error={Boolean(touched.lastName && errors.lastName)}
      />
    </Grid>
  );
};

export { UserLastName };
