import React from 'react';
import { Grid } from '@material-ui/core';

import { storage } from '../../Services/storage';
import { OutlinedSection } from '../Section';

import { ClientName } from './ClientFields/ClientName';
import { ClientStatus } from './ClientFields/ClientStatus';
import { ClientPhone } from './ClientFields/ClientPhone';
// import { ClientEmail } from './ClientFields/ClientEmail';
import { ClientWebAddress } from './ClientFields/ClientWebAddress';
import { ClientAddress } from './ClientFields/ClientAddress';
import { LocalTime } from './ClientFields/LocalTime';

import { ManagerName } from './ClientFields/ManagerName';
import { ManagerPhone } from './ClientFields/ManagerPhone';
import { ManagerExt } from './ClientFields/ManagerExt';
import { ManagerCell } from './ClientFields/ManagerCell';
import { ManagerEmail } from './ClientFields/ManagerEmail';

import { ShippingInfo } from './ClientFields/ShippingInfo';
import { ShippingAccount } from './ClientFields/ShippingAccount';
import { ClientQbListID } from './ClientFields/ClientQbListID'
// import { QBAccount } from './ClientFields/QBAccount';

import { useStyles } from './Styles/Fields';

const ClientForm = (props) => {
  const classes = useStyles();
  const { values, errors, touched, handleChange, handleBlur, setFieldValue } = props;
  const role = storage.get('userRole') || 'unknown'

  return (
    <Grid className={classes.gridRoot} container spacing={2}>
      <Grid item xs={12} md={6}>
        <OutlinedSection title='Client Information'>
          <Grid container spacing={2}>
            <ClientName
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <ClientWebAddress
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <ClientStatus
              values={values}
              handleChange={handleChange}
            />
            <ClientPhone
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <ClientAddress
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
            />
            <LocalTime
              values={values}
              handleChange={handleChange}
            />
            {role.toLowerCase() === 'accountant' &&
              <ClientQbListID
                values={values}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
              />
            }
          </Grid>
        </OutlinedSection>
      </Grid>
      <Grid item xs={12} md={6}>
        <OutlinedSection title='Office Manager Information'>
          <Grid container spacing={2}>
            <ManagerName
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <ManagerEmail
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <ManagerPhone
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <ManagerCell
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <ManagerExt
              values={values}
              handleChange={handleChange}
            />
          </Grid>
        </OutlinedSection>
        <OutlinedSection title='Shipping Information'>
          <Grid container spacing={2}>
            <ShippingInfo
              values={values}
              handleChange={handleChange}
            />
            <ShippingAccount
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            {/* <QBAccount
              values={values}
              handleChange={handleChange}
            /> */}
          </Grid>
        </OutlinedSection>
      </Grid>
    </Grid>
  );
}

export { ClientForm };
