import React, { useState, useEffect } from 'react';
import { useLocation  } from 'react-router-dom';
import {
  useRecoilCallback,
  useRecoilValueLoadable,
  useSetRecoilState,
  useRecoilState
} from 'recoil';
import {  Typography, Box, Divider } from '@material-ui/core';

import { Page } from '../../Components/Page';
import { OrdersData } from '../../Components/Orders/OrdersData';
import { FilteredOrdersActions } from '../../Components/Orders/FilteredOrdersActions';
import { OrdersActions } from '../../Components/Orders/OrdersActions';
import { OrdersDialog } from '../../Components/Orders/OrdersDialog';
import { Toast } from '../../Components/Toasts';

import {
  getAllOrders,
  changeOrderStatus,
  cancelOrder,
  lockOrder
} from '../../Recoil/Selectors/Orders';
import { requestDocument } from '../../Recoil/Selectors/Clients';
import { refetchOrders, ordersPagination, forceLockOrder } from '../../Recoil/Atoms/Orders';
import { dialog as dialogState } from '../../Recoil/Atoms/Modals';

import { useStyles } from '../Styles/Page';

export const Orders = () => {

  const classes = useStyles();
  const { pathname } = useLocation();
  const filtered = pathname === '/unassigned-orders' || pathname === '/orders-monitoring' || pathname === '/orders-conflicts';
  const [pageInfo, setPageInfo]: any = useRecoilState(ordersPagination);
  const lock = useSetRecoilState(forceLockOrder)

  const setRefetchOrders = useSetRecoilState(refetchOrders);

  const allOrders: any = useRecoilValueLoadable(getAllOrders)
  const ordersData = allOrders?.contents?.result || [];
  const total = ordersData.length <= 2700 ? ordersData.length : 3000;

  const [toast, setToast] = useState({ message: '', type: 'success', open: false });
  const [dialog, setDialog] = useRecoilState(dialogState)

  const handleCancelOrder = useRecoilCallback(({ snapshot }) => async () => {
    setDialog({ ...dialog, loading: true })

    const { result, success } = await snapshot.getPromise(cancelOrder);

    if (!success) {
      setToast({ message: result.errors, type: 'warning', open: true });
    }

    setRefetchOrders((n) => ++n)
    setDialog({ ...dialog, loading: false, open: false })
  });

  const handleChangeStatus = useRecoilCallback(({ snapshot }) => async () => {
    setDialog({ ...dialog, loading: true })

    const { result, success } = await snapshot.getPromise(changeOrderStatus);
    if (!success) {
      setToast({
        message: result?.error?.message || 'Please select status and try again',
        type: 'warning',
        open: true
      });
    }

    setRefetchOrders((n) => ++n)
    setDialog({ ...dialog, loading: false, open: false })
  });

  const handleLockOrder = useRecoilCallback(({ snapshot }) => async () => {
    setDialog({ ...dialog, loading: true })
    lock((n) => ++n)

    const { result, success } = await snapshot.getPromise(lockOrder);
    const message = result.record.order_locked === 'N' ? 'Unlocked' : 'Locked'

    if (!success) {
      setToast({ message: result.errors, type: 'warning', open: true });
    }
    if (success) {
      setToast({ message: `Order ${message} Successfully`, type: 'success', open: true });
    }

    setDialog({ ...dialog, loading: false, open: false })
  });

  const handleRequestDoc = useRecoilCallback(({ snapshot }) => async (row: any) => {
    const res: any = await snapshot.getPromise(requestDocument({
      Contact_ID: row.Contact_ID,
      f_loan_number: row.f_loan_number,
      f_borrower_last_name: row.f_borrower_last_name,
      f_appointment_date: row.f_appointment_date,
      f_appointment_time: row.f_appointment_time
    }));
    if (res.success) {
      setToast({
        message: 'Email Send Successfully!',
        type: 'success',
        open: true
      });
    } else {
      setToast({
        message: 'An Error Occured!',
        type: 'error',
        open: true
      });
    }
  });

  useEffect(() => {
    return () => {
      setPageInfo({
        ...pageInfo,
        limit: 0,
        searchType: undefined,
        searchValue: undefined
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page className={classes.paper} title='Orders'>
      <Typography variant='h3'>Orders</Typography>
      <Box my={2}>
        <Divider variant='fullWidth' />
      </Box>

      { filtered ? <FilteredOrdersActions /> : <OrdersActions />}

      <OrdersData
        setRefetchOrders={setRefetchOrders}
        loading={allOrders?.state === 'loading'}
        rows={ordersData}
        total={total}
        handleCancelOrder={handleCancelOrder}
        handleChangeStatus={handleChangeStatus}
        handleLockOrder={handleLockOrder}
        handleChangeCallStatus={null}
        handleRequestDoc={handleRequestDoc}
      />
      <OrdersDialog />
      <Toast message={toast.message} type={toast.type} open={toast.open} setToast={setToast} />
    </Page>
  );
}
