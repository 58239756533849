import React from 'react';
import { Grid, Typography, FormControlLabel, Radio } from '@material-ui/core';

const ContactRadios = ({ values, handleChange }) => {
  return (
    <>
      <Grid item md={6}>
        <Typography variant='body2'>Show Signer Email</Typography>
      </Grid>
      <Grid item md={3}>
        <FormControlLabel
          label='Yes'
          control={
            <Radio
              name='Contact_Show_Signer_Email'
              value='Y'
              checked={values.Contact_Show_Signer_Email === 'Y'}
              onChange={handleChange}
            />
          }
        />
      </Grid>
      <Grid item md={3}>
        <FormControlLabel
          label='No'
          control={
            <Radio
              name='Contact_Show_Signer_Email'
              value='N'
              checked={values.Contact_Show_Signer_Email === 'N'}
              onChange={handleChange}
            />
          }
        />
      </Grid>
      <Grid item md={6}>
        <Typography variant='body2'>Show Signer Mobile</Typography>
      </Grid>
      <Grid item md={3}>
        <FormControlLabel
          label='Yes'
          control={
            <Radio
              name='Contact_Show_Signer_Tel'
              value='Y'
              checked={values.Contact_Show_Signer_Tel === 'Y'}
              onChange={handleChange}
            />
          }
        />
      </Grid>
      <Grid item md={3}>
        <FormControlLabel
          label='No'
          control={
            <Radio
              name='Contact_Show_Signer_Tel'
              value='N'
              checked={values.Contact_Show_Signer_Tel === 'N'}
              onChange={handleChange}
            />
          }
        />
      </Grid>
      <Grid item md={6}>
        <Typography variant='body2'>Access Signer Error Report</Typography>
      </Grid>
      <Grid item md={3}>
        <FormControlLabel
          label='Yes'
          control={
            <Radio
              name='Contact_Signer_Report'
              value='Y'
              checked={values.Contact_Signer_Report === 'Y'}
              onChange={handleChange}
            />
          }
        />
      </Grid>
      <Grid item md={3}>
        <FormControlLabel
          label='No'
          control={
            <Radio
              name='Contact_Signer_Report'
              value='N'
              checked={values.Contact_Signer_Report === 'N'}
              onChange={handleChange}
            />
          }
        />
      </Grid>
    </>
  );
}

export { ContactRadios };
