import React, { useState } from 'react';
import { Grid, Typography, FormControlLabel, Radio, DialogContentText } from '@material-ui/core';

import { GeneralModal } from '../../Modal';

import { displayStyles } from '../../../Common/Styles/Display';

const SignerType = ({ isViewOnlyMode, location, values, setFieldValue }) => {
  const displayClasses = displayStyles();

  const [model, openModal] = useState(false);
  const [value, setValue] = useState('');

  const onChange = (e) => {
    setValue(e.target.value)
    openModal(true)
  }

  return (
    <>
      <Grid item md={2} className={displayClasses.flexRowC}>
        <Typography variant='body2'>Signer Type</Typography>
      </Grid>
      <Grid item md={2}>
        <FormControlLabel
          label='Notary'
          control={
            <Radio
              disabled={isViewOnlyMode}
              name={`${location}.Signer_Type`}
              value='Notary'
              checked={values.Signer_Type === 'Notary'}
              onChange={onChange}
            />
          }
        />
      </Grid>
      <Grid item md={2}>
        <FormControlLabel
          label='Attorney'
          control={
            <Radio
              disabled={isViewOnlyMode}
              name={`${location}.Signer_Type`}
              value='Attorney'
              checked={values.Signer_Type === 'Attorney'}
              onChange={onChange}
            />
          }
        />
      </Grid>
      <GeneralModal
        handleSubmit={() => {
          setFieldValue(`${location}.Signer_Type`, value);
          openModal(false)
        }}
        open={model}
        openModal={openModal}
        submitLabel='Ok'
        title='Are you sure'
      >
        <DialogContentText>All fees will be reset, if you chose to change signer type</DialogContentText>
      </GeneralModal>
    </>
  );
}

export { SignerType };
