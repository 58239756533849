import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitle: {
      padding: theme.spacing(2),
    },
    dialogContent: {
      padding: theme.spacing(2),
      borderTop: '1px solid rgba(0, 0, 0, 0.12)',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    },
    dialogActions: {
      padding: theme.spacing(2),
    },
    progress: {
      position: 'absolute',
      top: '52px',
      left: 0,
      width: '100%',
      borderRadius: 0
    }
  })
);
