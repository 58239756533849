import React,{useState} from 'react';
import moment from 'moment';
import { Box, IconButton, Tooltip, Typography } from '@material-ui/core';
import { Eye } from 'react-feather';

import { Table } from '../Table';
import { SignerInfo } from './SignerInfo';
import { useStyles } from './Styles/style';

const DailyVendorBillData = ({
    setRefetchDailyVendorBill,
    rows,
    total,
    loading,
    setPageInfo,
    pageInfo
}) => {
    const classes = useStyles();

    const [signerInfoModal, handleSignerInfoModal] = useState(null);

    const actionsFormatter = params => {
        return (
            <Box display='flex'>
                <Tooltip title='View'>
                    <IconButton size='small' color='primary' onClick={() => { }}>
                        <Eye width='25' height='25' />
                    </IconButton>
                </Tooltip>
            </Box>
        );
    }

    const columns = [{
        field: 'w_Loan_Number',
        headerName: 'Loan/Order #',
        headerClassName: 'datagrid-header',
        cellClassName: 'datagrid-cell-custom',
        disableColumnMenu: true,
    }, {
        field: 'billRefNumber',
        headerName: 'Invoice #',
        headerClassName: 'datagrid-header',
        cellClassName: 'datagrid-cell-custom',
        disableColumnMenu: true,
    }, {
        field: 'contact',
        headerName: 'Contact',
        headerClassName: 'datagrid-header',
        cellClassName: 'datagrid-cell-custom',
        disableColumnMenu: true,
        renderCell: ({row}) => {
            return (
                <Typography>
                    {row?.contact?.Contact_First_Name} {row?.contact?.Contact_Last_Name}
                </Typography>
            );
        }
    }, {
        field: 'signer',
        headerName: 'Signer',
        headerClassName: 'datagrid-header',
        cellClassName: 'datagrid-cell-custom',
        disableColumnMenu: true,
        renderCell: ({row}) => {
            return (
                <Typography
                    className={classes.cursorPointer}
                    color='primary'
                    variant='body1'
                    variantMapping={{ body1: 'p' }}
                    key={row.f_signer_name}
                    onClick={() => handleSignerInfoModal(
                        { ...row?.signer, w_Loan_Number: row?.f_loan_number }
                        || { w_Loan_Number: row?.w_Loan_Number })
                    }
                >
                    {row?.signer?.Signer_First} {row?.signer?.Signer_Last}
                </Typography>
            );
        }
    }, {
        field: 'f_appointment_date',
        headerName: 'Appointment Date',
        headerClassName: 'datagrid-header',
        cellClassName: 'datagrid-cell-custom',
        disableColumnMenu: true,
    },{
        field: 'createdAt',
        headerName: 'Bill Created',
        headerClassName: 'datagrid-header',
        cellClassName: 'datagrid-cell-custom',
        disableColumnMenu: true,
        renderCell: ({row}) => {
            return (
                <Typography>
                    {moment(row?.createdAt).format("MM/DD/YYYY")}
                </Typography>
            );
        }
    }, {
        field: 'f_status_web',
        headerName: 'Status',
        headerClassName: 'datagrid-header',
        cellClassName: 'datagrid-cell-custom',
        disableColumnMenu: true,
    }, {
        field: 'payment_status',
        headerName: 'Payment Status',
        headerClassName: 'datagrid-header',
        cellClassName: 'datagrid-cell-custom',
        disableColumnMenu: true,
    }, {
        field: 'action',
        headerName: 'Action',
        sortable: false,
        disableColumnMenu: true,
        renderCell: actionsFormatter,
        headerClassName: 'datagrid-header',
        cellClassName: 'datagrid-cell-custom',
    }];

    const handlePage = (e: unknown, newPage: number) => {
        setPageInfo({
            ...pageInfo,
            page: newPage
        });
    };

    const handleRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPageInfo({
            ...pageInfo,
            limit: parseInt(e.target.value, 10)
        });
    };


    return (
        <>
            <Table
                useCustomStyles={false}
                customRowHeight={85}
                loading={loading}
                columns={columns}
                rows={rows}
                total={total}
                pageInfo={pageInfo}
                handlePage={handlePage}
                handleRowsPerPage={handleRowsPerPage}
            />
            <SignerInfo
            signerInfoModal={signerInfoModal}
            handleSignerInfoModal={handleSignerInfoModal}/>
        </>
    );
}

export { DailyVendorBillData };
