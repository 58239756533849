import React from 'react';
import { useRecoilValueLoadable, useRecoilCallback, useSetRecoilState } from 'recoil'
import { Typography, Box, Divider, Button } from '@material-ui/core';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import moment from 'moment';
import { Page } from '../../Components/Page';
import { NightlyData } from '../../Components/Reports/NightlyData';

import { getNightlyReports, sendNightlyReportEmail } from './../../Recoil/Selectors/Reports'
import { resendNightlyReport } from './../../Recoil/Atoms/Reports'

import { useStyles } from '.././Styles/Page';

export const Nightly = ((props) => {

  const reports: any = useRecoilValueLoadable(getNightlyReports)
  const day = moment().format('MM/DD/YYYY')

  const nightlyReport = reports?.contents?.result?.rows || []
  const total = reports?.contents?.result?.count || 0
  const classes = useStyles();
  const updateEmailAtom = useSetRecoilState(resendNightlyReport)

  const sendEmail = useRecoilCallback(({ snapshot }) => async () => {
    updateEmailAtom(n => ++n)
    const res = await snapshot.getPromise(sendNightlyReportEmail({ day }));
    if (res.success) {
      enqueueSnackbar(
        res?.result?.message || 'Email Send Successfully',
        {
          variant: 'success'
        }
      );
    }
    else {
      enqueueSnackbar(
        res?.result?.message || 'Failed To Send Email',
        {
          variant: 'error'
        }
      );
    }
  });

  return (
    <SnackbarProvider >
      <Page className={classes.paper} title='Nightly Report Email'>
        <Typography variant='h3'>Nightly Report Email</Typography>
        <Box my={2}>
          <Divider variant='fullWidth' />
        </Box>
        <Typography variant='body1'>Date: {day}</Typography>
        <NightlyData data={nightlyReport} loading={reports.state === 'loading'} total={total} />
        <Button variant="contained" color="primary" onClick={sendEmail}>
          Send Nightly Report Email
        </Button>
      </Page>
    </SnackbarProvider>

  );
})
