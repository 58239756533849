import React from 'react';
import { Grid, TextField } from '@material-ui/core';

const ContactEmail = ({ values, errors, touched, handleChange, handleBlur }) => {
  return (
    <>
      <Grid item xs={12} md={6}>
        <TextField
          label='Email'
          name='Contact_Email'
          variant='outlined'
          size='small'
          fullWidth
          value={values.Contact_Email}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.Contact_Email && errors.Contact_Email}
          error={Boolean(touched.Contact_Email && errors.Contact_Email)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label='Confirm Email'
          name='confirmation_email'
          variant='outlined'
          size='small'
          fullWidth
          value={values.confirmation_email}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.confirmation_email && errors.confirmation_email}
          error={Boolean(touched.confirmation_email && errors.confirmation_email)}
        />
      </Grid>
    </>
  );
}

export { ContactEmail };
