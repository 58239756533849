import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Box,
  Divider
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { storage } from '../../Services/storage';

import { NSANumber } from './SignerFields/NSANumber';
import { SignerType } from './SignerFields/SignerType';
import { FirstName } from './SignerFields/FirstName';
import { LastName } from './SignerFields/LastName';
import { CompanyName } from './SignerFields/CompanyName';
import { SignerStatus } from './SignerFields/SignerStatus';
import { SignerEmail } from './SignerFields/SignerEmail';
import { SignerNotes } from './SignerFields/SignerNotes';
import { CommissioningStates } from './SignerFields/CommissioningStates';
import { MobilePhone } from './SignerFields/MobilePhone';
import { HomePhone } from './SignerFields/HomePhone';
import { SignerAddress } from './SignerFields/SignerAddress';
import { PaymentAddress } from './SignerFields/PaymentAddress';
import { EmergencyContactFirstName } from './SignerFields/EmergencyContactFirstName';
import { EmergencyContactLastName } from './SignerFields/EmergencyContactLastName';
import { EmergencyContactPhone } from './SignerFields/EmergencyContactPhone';
import { ReceiveTextMessage } from './SignerFields/ReceiveTextMessage';
import { ConfirmationOptions } from './SignerFields/ConfirmationOptions';
import { PhoneType } from './SignerFields/PhoneType';
import { NNACertifiedNotary } from './SignerFields/NNACertifiedNotary';
import { FidelityApproved } from './SignerFields/FidelityApproved';
import { PreferredNotary } from './SignerFields/PreferredNotary';
import { SetsOfDocumentLoan } from './SignerFields/SetsOfDocumentLoan';
import { Languages } from './SignerFields/Languages';
import { OutlinedSection } from '../Section';
import { SignerQbListID } from './SignerFields/SignerQbListID'
import { ParentInformation } from './SignerFields/ParentInformation'

import { useStyles } from './Styles/Fields';
import { SignerAccountType } from './SignerFields/SignerAccountType';

const SignerForm = props => {
  const classes = useStyles();
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    isEditMode
  } = props;
  const [hideAddress, setHideAddress] = useState(values.SameAsSignerAddress);
  const role = storage.get('userRole') || 'unknown'

  const addressFields = JSON.stringify({
    address: values.address,
    city: values.city,
    state: values.state,
    zip: values.zip,
    pmntAdrsSameAsAdrs: values.pmntAdrsSameAsAdrs
  });

  useEffect(() => {
    const newValues = JSON.parse(addressFields);
    if (newValues.pmntAdrsSameAsAdrs) {
      setFieldValue('payment_address', newValues.address);
      setFieldValue('payment_city', newValues.city);
      setFieldValue('payment_state', newValues.state);
      setFieldValue('payment_zip', newValues.zip);
    }
  }, [addressFields, setFieldValue]);

  const setPaymentAddressAsSingerAddress = () => {
    setFieldValue('payment_address', values.address);
    setFieldValue('payment_city', values.city);
    setFieldValue('payment_state', values.state);
    setFieldValue('payment_zip', values.zip);
    setFieldValue('pmntAdrsSameAsAdrs', values.pmntAdrsSameAsAdrs ? false : true);
    setHideAddress(!values.pmntAdrsSameAsAdrs);
  };

  return (
    <>
      <Grid className={classes.gridRoot} container spacing={2}>
        <Grid item sm={12} xs={12} md={6}>
          <Typography variant='h4'>Signer Information</Typography>
          <Box mt={1} mb={2}>
            <Divider variant='fullWidth' />
          </Box>
          <OutlinedSection title='Signer Information'>
            {values?.Parent_ID &&
              <Box mb={3}>
                <Alert icon={false} severity={'warning'}>Sub Signer Profile</Alert>
              </Box>
            }
            <Grid container alignItems='center' spacing={2}>
              <SignerType touched={touched} errors={errors} values={values} handleChange={handleChange} />
              {isEditMode && (
                <SignerStatus
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  disabled={false}
                />
              )}
            </Grid>
            <Grid container spacing={2}>
              <FirstName
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                disabled={false}
              />
              <LastName
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                disabled={false}
              />
            </Grid>
            <Grid container spacing={2}>
              <SignerEmail
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                disabled={false}
              />
              <CompanyName
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                disabled={false}
              />
            </Grid>
            <Grid container spacing={2}>
              <MobilePhone
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                disabled={false}
              />
              <HomePhone
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                disabled={false}
              />
            </Grid>
            <Grid container spacing={2}>
              <NSANumber
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                disabled={false}
              />
              {role.toLowerCase() === 'accountant' &&
                <SignerQbListID
                  values={values}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
              }
            </Grid>
            <Grid container spacing={2}>
              <SignerAccountType
                values={values}
                errors={errors}
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                touched={touched}
                disabled={false}
              />
              {isEditMode && values?.Parent_ID && (
                <ParentInformation
                  values={values}
                />
              )}
            </Grid>
          </OutlinedSection>
          <OutlinedSection title='Signer Address'>
            <SignerAddress
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              disabled={false}
              hideAddress={hideAddress}
            />
            <Grid container spacing={2} alignItems='center'>
              <CommissioningStates
                values={values.commisioning_states}
                errors={errors}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                disabled={false}
              />
            </Grid>
            <Grid container spacing={2}>
              <SignerNotes values={values} handleChange={handleChange} />
            </Grid>
          </OutlinedSection>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant='h4'>Payment Information</Typography>
          <Box mt={1} mb={2}>
            <Divider variant='fullWidth' />
          </Box>
          <FormControlLabel
            control={
              <Checkbox
                //  checked={values.pmntAdrsSameAsAdrs}
                defaultChecked={values.pmntAdrsSameAsAdrs}
                onChange={setPaymentAddressAsSingerAddress}
                name='pmntAdrsSameAsAdrs'
                color='primary'
              />
            }
            label='Same as Signer Address'
          />
          {!values.pmntAdrsSameAsAdrs && (
            <OutlinedSection title='Payment Address'>
              <PaymentAddress
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                disabled={false}
              />
            </OutlinedSection>
          )}
          <OutlinedSection title='Emergency Contact Information'>
            <Grid container spacing={2}>
              <EmergencyContactFirstName
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                disabled={false}
              />
              <EmergencyContactLastName
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                disabled={false}
              />
              <EmergencyContactPhone
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                disabled={false}
              />
            </Grid>
          </OutlinedSection>
          <OutlinedSection title='Confirmation Options'>
            <Grid container alignItems='center' spacing={2}>
              <ReceiveTextMessage touched={touched} errors={errors} values={values} handleChange={handleChange} />
              <PhoneType
                touched={touched}
                values={values}
                label={true}
                errors={errors}
                handleChange={handleChange}
              />
              <ConfirmationOptions
                values={values}
                setFieldValue={setFieldValue}
              />
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <NNACertifiedNotary
                  errors={errors}
                  touched={touched}
                  values={values}
                  handleChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <PreferredNotary
                  values={values}
                  handleChange={handleChange}
                />
              </Grid>
              <FidelityApproved touched={touched} errors={errors} values={values} handleChange={handleChange} />
              <SetsOfDocumentLoan
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                disabled={false}
              />
            </Grid>
          </OutlinedSection>
          <OutlinedSection title='Languages'>
            <Languages values={values} setFieldValue={setFieldValue} />
          </OutlinedSection>
        </Grid>
      </Grid>
    </>
  );
};

export { SignerForm };
