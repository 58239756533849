import moment from 'moment';
import { atom } from 'recoil';

const pagination = atom({
  key: 'signerPagination',
  default: {
    limit: 20,
    page: 1,
    count: 0,
    orderBy: 'signer_id',
    orderType: 'DESC',
    firstName: '',
    lastName: '',
    search: '',
    secondaryCategory: ''
  }
});

const resendMasterLoginRequest = atom({
  key: 'resendMasterLoginRequestSigners',
  default: 0
});


const selectedSigner = atom({
  key: 'selectedSigner',
  default: {}
});

const refetchSigners = atom({
  key: 'refetchSigners',
  default: 0
});

const signerCommunicationPagination = atom({
  key: 'signerCommunicationPagination',
  default: {
    limit: 20,
    page: 1,
    orderBy: 'signer_id',
    orderType: 'DESC',
    clientID: '',
    start: moment().format('MM/DD/YYYY'),
    end: moment().format('MM/DD/YYYY')
  }
});
const clpagination = atom({
  key: 'clpagination',
  default: {
    page: 1,
    limit: 100
  }
});

const refetchCommunication = atom({
  key: 'refetchCommunication',
  default: 0
});

const selectedSignerErrors = atom({
  key: 'selectedSignerErrors',
  default: {
    signer_id: '',
    Signer_Last: '',
    Signer_First: '',
  }
});
const searchSignerPagination = atom({
  key: 'searchSignerPagination',
  default: {
    search: '',
    secondaryCategory: 'signer_id'
  }
});

const signerHistoryPagination = atom({
  key: 'SignerHistoryPagination',
  default: {
    signer_id: '',
    limit: 20,
    page: 1,
    count: 0,
    orderBy: '',
    orderType: '',
  }
});

const refetchSigner = atom({
  key: 'refetchSigner',
  default: 0
});

const completedSigningsId = atom({
  key: 'completedSigningsId',
  default: 0
});

const reloadCreds = atom({
  key: 'reloadCreds',
  default: 0
});

const vendorListPagination = atom({
  key: 'vendorListPagination',
  default: {
    limit: 500,
    search: ''
  }
});

const refetchSignerOrder = atom({
  key: 'refetchSignerOrder',
  default: 0
});

const signersActivityData = atom({
  key: 'signersActivityData',
  default: {
    ids: [],
    borrower_id: undefined
  }
});

const refetchSignerCredHistory = atom({
  key: 'refetchSignerCredHistory',
  default: 0
});

const fetchCallerProfile = atom({
  key: 'fetchCallerProfile',
  default: 0
});

const refetchAssignSignerOrder = atom({
  key: 'refetchAssignSignerOrder',
  default: 0
});

const forceRemoveCallAlert = atom({
  key: 'forceRemoveCallAlert',
  default: 0
});

const forceSignerLogin = atom({
  key: 'forceSignerLogin',
  default: 0
});

const fetchSignerDocument = atom({
  key: 'fetchSignerDocument',
  default: 0
});

export {
  pagination,
  selectedSigner,
  refetchSigners,
  signerCommunicationPagination,
  refetchCommunication,
  clpagination,
  selectedSignerErrors,
  searchSignerPagination,
  signerHistoryPagination,
  refetchSigner,
  completedSigningsId,
  reloadCreds,
  vendorListPagination,
  refetchSignerOrder,
  signersActivityData,
  resendMasterLoginRequest,
  refetchSignerCredHistory,
  fetchCallerProfile,
  refetchAssignSignerOrder,
  forceRemoveCallAlert,
  forceSignerLogin,
  fetchSignerDocument
};
