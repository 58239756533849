import React from 'react';
import {
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  TextField
} from '@material-ui/core';

import clsx from 'clsx';
import map from 'lodash/map';

import { marginStyles } from '../../../Common/Styles/Margins';

import { sortASC } from '../../../Common/Utilities/helper';

import { useStyles } from '../Styles/DocumentActivityActions';

const userTypes = [
  {
    value: 'type-1',
    label: 'Type 01'
  },
  {
    value: 'type-2',
    label: 'Type 02'
  },
  {
    value: 'type-3',
    label: 'Type 03'
  }
];

const SearchInput = ({ type, value, onChange }) => {
  const classes = useStyles();
  const marginClasses = marginStyles();

  const searchInputs = {
    userType: {
      type: 'select',
      label: 'User Type',
      options: map(sortASC(userTypes, 'label'), ({ value, label }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))
    },
    fullName: {
      type: 'text',
      label: 'Name'
    },
    borrowerId: {
      type: 'text',
      label: 'ID'
    }
  };

  const inputType = searchInputs[type]?.type || 'text';
  const inputLabel = searchInputs[type]?.label || 'Input';
  const selectOptions = searchInputs[type]?.options || (
    <MenuItem key='no-data' disabled value='no-data'>
      No data
    </MenuItem>
  );

  const select = (
    <FormControl
      className={clsx(marginClasses.my0, classes.select)}
      margin='dense'
      variant='outlined'
    >
      <InputLabel>{inputLabel}</InputLabel>
      <Select label={inputLabel} value={value} onChange={onChange}>
        {selectOptions}
      </Select>
    </FormControl>
  );

  const input = (
    <FormControl
      className={clsx(marginClasses.my0)}
      margin='dense'
      variant='outlined'
    >
      <TextField
        className={clsx(marginClasses.my0)}
        type={inputType}
        variant='outlined'
        label={inputLabel}
        margin='dense'
        value={value}
        onChange={onChange}
      />
    </FormControl>
  );

  return inputType === 'select' ? select : input;
};

export { SearchInput };
