import React, { useState, useEffect } from 'react';
import { useRecoilState, useRecoilValueLoadable } from 'recoil'

import {
  Box,
  TextField,
  SvgIcon,
  Typography
} from '@material-ui/core';
import { Edit, Save, Trash } from 'react-feather';

import { AddFee } from './AddFee'

import { getDefaultFee } from '../../../Recoil/Selectors/Orders'

import { Table } from '../../Table';

import { useStyles } from '../Styles/FeeAndInstructions';
import { feeDetails, forceFetchFees } from 'src/Recoil/Atoms/Orders';

const Fees = (props) => {
  const classes = useStyles();
  const defaultFee = {
    fee_name: '',
    fee_amount: 0,
    attorney_fee: 0,
    bill_amount: 0,
    attorney_bill: 0,
    key: new Date().getTime()
  }
  const { orderFees, isViewOnlyMode, feesRef } = props
  const { values, location } = props
  const isOutSource = values?.[location]?.w_outsource === 'Y'

  const { Signer_Type } = values[location] || {}
  const feeType = Signer_Type === 'Notary' ? 'fee_amount' : 'attorney_fee'
  const clientFeeType = Signer_Type === 'Notary' ? 'bill_amount' : 'attorney_bill'

  const [newFee, handleNewFee] = useState(false);
  const [totalFees, setFees] = useRecoilState(feeDetails);
  const [selectedFee, setSelectedFee] = useState(defaultFee);
  const [editFee, setEditFee] = useState(defaultFee);
  const [updateFee, setUpdateFee] = useState(-1);
  const [refetch, setRefetch] = useRecoilState(forceFetchFees)

  const feeRequest: any = useRecoilValueLoadable(getDefaultFee)
  const rows = feeRequest?.contents?.result || []

  const { fees } = values.location_one

  useEffect(() => {
    if (!totalFees.isLoaded && feeRequest.state === 'hasValue') {
      const fees = []
      if (orderFees) {
        orderFees.forEach(row => {
          fees.push(row)
        });
      } else {
        rows.forEach(row => {
          if (row.default === 'Y')
            fees.push(row)
        });
      }
      setFees({
        ...totalFees, fees: fees.map((a, idx) => ({ ...a, key: idx + 1 })), isLoaded: true
      })
    }

  }, [rows, setFees, totalFees, feeRequest, orderFees, feeType])

  useEffect(() => {
    if (fees === undefined) {
      setFees({ isLoaded: true, fees: [] })
    } else {
      setRefetch(refetch + 1)
    }
    // eslint-disable-next-line
  }, [])
  // This is a special case hook

  useEffect(() => {
    if (feeRequest.state === 'loading' && totalFees.isLoaded) {
      setFees({
        ...totalFees, isLoaded: false
      })
    }
  }, [feeRequest.state, totalFees, setFees])

  const saveFee = () => {
    if (!isViewOnlyMode) {
      const isEditing = updateFee !== -1
      if (!isEditing) {
        setFees({
          ...totalFees, fees: [...totalFees.fees, {
            ...selectedFee,
            id: new Date().getTime(),
            key: new Date().getTime()
          }]
        })

      } else {
        setFees({
          ...totalFees,
          fees: [
            ...totalFees.fees.map(f => {
              if ((f.key && f.key === editFee.key)) {
                return { ...editFee }
              } else {
                return { ...f }
              }
            })
          ]
        })
      }
      setEditFee(defaultFee)
      setUpdateFee(-1)
      setSelectedFee(defaultFee)
      handleNewFee(false)
    }
  }

  const deleteFee = (key) => {
    if (!isViewOnlyMode) {
      setFees({
        ...totalFees,
        fees: [...totalFees.fees.filter((item) => item.key !== key)]
      })
    }
  }
  const onEditFee = (details) => {
    if (!isViewOnlyMode) {
      setEditFee({ ...details.row })
      setUpdateFee(details.rowIndex)
    }
  }
  const feeEdit = (details, type) => {
    const updatableField = type === 'client' ? clientFeeType : feeType
    return (
      <>
        {updateFee === details.rowIndex ?
          <TextField
            disabled={isViewOnlyMode}
            placeholder='Fee'
            variant='outlined'
            size='small'
            type='number'
            onChange={e => setEditFee({ ...editFee, [updatableField]: e.target.value })}
            value={editFee[details.field]}
            fullWidth
          />
          :
          <Typography>{details?.value}</Typography>}
      </>
    )
  }
  const feeActions = (details) => {
    return (
      updateFee === details.rowIndex ?
        <SvgIcon fontSize='small' className={classes.pointer} color='action' onClick={saveFee} >
          <Save />
        </SvgIcon>
        :
        <>
          <SvgIcon fontSize='small' className={classes.pointer} color='action' onClick={e => { onEditFee(details) }} >
            <Edit />
          </SvgIcon>
          <SvgIcon fontSize='small' className={classes.pointer} color='action' onClick={e => deleteFee(details?.row?.key)} >
            <Trash />
          </SvgIcon>
        </>
    )
  }
  const closeModal = () => { setSelectedFee(defaultFee); handleNewFee(false) }
  const columns = [{
    field: 'fee_name',
    headerName: 'Service Type'
  }, {
    field: feeType,
    headerName: feeType === 'fee_amount' ? 'Notary Fee' : 'Attorney Fee',
    renderCell: (p) => feeEdit(p, 'fee')
  }, {
    field: feeType === 'fee_amount' ? 'bill_amount' : 'attorney_bill',
    headerName: Signer_Type === 'Notary' ? 'Client Notary Fee' : 'Client Attorney Fee',
    renderCell: isOutSource ? (p) => feeEdit(p, 'client') : undefined
  }, {
    field: 'action',
    headerName: 'Action',
    renderCell: (p) => feeActions(p)
  }];

  return (
    <div className={classes.root}>
      <Box itemRef={feesRef} px={1} my={2} display='flex' alignItems='center' justifyContent='space-between'>
        <AddFee
          isViewOnlyMode={isViewOnlyMode}
          rows={rows}
          feeType={feeType}
          selectedFee={selectedFee}
          handleNewFee={handleNewFee}
          newFee={newFee}
          setSelectedFee={setSelectedFee}
          saveFee={saveFee}
          closeModal={closeModal}
        />
      </Box>
      <Table
        columns={columns}
        uniqueKey='key'
        rows={totalFees.fees}
        pagination={false} />
    </div>
  );
}

export { Fees };
