import * as Yup from 'yup';

const initialValues = {
  name: '',
  resourceList:[]
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Role Name is Required.'),
  resourceList:Yup.array().nullable()
  });


export {
  initialValues,
  validationSchema
};