import { atom } from 'recoil';

const accessGroupResources = atom({
    key: 'accessGroupResources',
    default: [
      {
        id: 1,
        name: 'Dashboard'
      },
      {
        id: 2,
        name: 'change password'
      },
      {
        id: 3,
        name: 'update profile'
      }
    ]
  });

const accessgrouppagination = atom({
    key: 'accessgrouppagination',
    default: {
      page: 1,
      limit: 20,
      orderBy: 'accessgroupName',
      orderType: 'ASC',
      search: ''
    }
  });

  const refreshAccessGroups = atom({
    key: 'refreshAccessGroups',
    default: 0
  });

  export { accessGroupResources, accessgrouppagination,refreshAccessGroups };
