import React from 'react';
import { useRecoilState } from 'recoil';
import { Box, Tooltip, IconButton, SvgIcon } from '@material-ui/core';
import { Edit } from 'react-feather';

import { documentActivitypagination } from '../../Recoil/Atoms/DocumentActivity';

import { Table } from '../Table';

const DocumentActivityData = props => {
  const [pageInfo, setPageInfo] = useRecoilState(documentActivitypagination);
  const actionsFormatter = params => {
    return (
      <Box display='flex'>
        <Tooltip title='Edit'>
          <IconButton
            color='inherit'
            // onClick={() => props.handleEdit(params.row)}
          >
            <SvgIcon fontSize='small' color='primary'>
              <Edit />
            </SvgIcon>
          </IconButton>
        </Tooltip>
      </Box>
    );
  };

  const columns = [
    {
      field: 'da_orderID',
      headerName: 'Order ID',
      headerClassName: 'datagrid-header',
      cellClassName: 'datagrid-cell',
      disableColumnMenu: true
    },
    {
      field: 'da_fileName',
      headerName: 'Email',
      headerClassName: 'datagrid-header',
      cellClassName: 'datagrid-cell',
      disableColumnMenu: true
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: actionsFormatter
    },

    {
      field: 'da_dateTime',
      headerName: 'Date Time'
    },
    {
      field: 'da_userType',
      headerName: 'User Type'
    },
    {
      field: 'da_userID',
      headerName: 'User ID'
    }
  ];

  const handlePage = (e: unknown, newPage: number) => {
    setPageInfo({
      ...pageInfo,
      page: newPage
    });
  };

  const handleRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPageInfo({
      ...pageInfo,
      limit: parseInt(e.target.value, 10)
    });
  };

  return (
    <Table
      loading={props.loading}
      columns={columns}
      rows={props.data}
      total={props.total}
      pageInfo={{ page: pageInfo.page, limit: pageInfo.limit }}
      handlePage={handlePage}
      handleRowsPerPage={handleRowsPerPage}
    />
  );
};

export { DocumentActivityData };
