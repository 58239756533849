import React, { useEffect, useState } from 'react';
import {Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import {
  useRecoilCallback,
  useRecoilValueLoadable,
  useSetRecoilState,
  useRecoilState
} from 'recoil';

import { DashboardLayout } from '../Layout';
import { BasePage } from './BasePage';
import { Login } from '../Pages/Login';
import { ResetPassword } from '../Pages/ResetPassword';
import { RequestResetPassword } from '../Pages/RequestPasswordReset';
import { LoginPassword } from '../Pages/LoginPassword';
import { PageLoad } from '../Components/LoadingScreen/PageLoad';

import {
  loginWithPassword,
  refreshAuthToken,
  checkAuthType,
  sendEmail,
  verifyEmail,
  loginWithoutPassword
} from '../Recoil/Selectors/Auth';
import { userInfo, twoFactorLogin, forceLogin } from '../Recoil/Atoms/Login';

const RenderRoutes = () => {
  const [type, setType] = useState(null);
  const [user, setUser] = useRecoilState(userInfo);
  const location = useLocation();
  const navigate = useNavigate()
  const refreshToken = useRecoilValueLoadable(refreshAuthToken);
  const [userEmail, setUserEmail] = useState('');
  const handleTwoFactorLogin = useSetRecoilState(twoFactorLogin);
  const firebaseUser = useRecoilValueLoadable(verifyEmail(location?.search));
  const mysqlUser = useRecoilValueLoadable(loginWithoutPassword);
  const setForceLoginState = useSetRecoilState(forceLogin);
  const forceUpdate = () => setForceLoginState(n => n + 1);
  const handleSubmit = useRecoilCallback(
    ({ snapshot }) => async ({ email, setSubmitting, resetForm, setAlert }) => {
      const response = await snapshot.getPromise(checkAuthType(email));
      forceUpdate();
      if (response.success) {
        if (response.result.authenticationType === 'two-factor') {
          await snapshot.getPromise(sendEmail(email));
          setAlert({
            status: 'info',
            message:
              'An email has been sent to this email address, please open the link in the email'
          });
        } else {
          setUserEmail(email);
        }
        resetForm();
        setSubmitting(false);
        setType(response.result.authenticationType);
      } else {
        setSubmitting(false);
        setAlert({
          status: 'error',
          message: 'Invalid Email'
        });
      }
    }
  );

  const handleLoginWithPassword = useRecoilCallback(
    ({ snapshot }) => async ({
      password,
      setSubmitting,
      resetForm,
      setAlert
    }) => {
      const response = await snapshot.getPromise(
        loginWithPassword({ password, email: userEmail })
      );
      setSubmitting(false);
      if (response.success) {
        setUser(response.result.user);
        resetForm();
        setType(null);
        navigate('/');
      } else {
        setAlert({
          status: 'error',
          message: 'Invalid Email or Password'
        });
      }
    }
  );

  useEffect(() => {
    if (type === 'password') {
      navigate('/login-password');
    }
  }, [type, 
    navigate
  ]);

  useEffect(() => {
    if (firebaseUser.state === 'hasValue' && firebaseUser?.contents?.success) {
      handleTwoFactorLogin(1);
    }
  }, [firebaseUser, handleTwoFactorLogin]);

  useEffect(() => {
    if (mysqlUser.state !== 'loading' && mysqlUser.contents?.success) {
      setUser(mysqlUser.contents.result.user);
      navigate('/');
    }
  }, [mysqlUser, setUser, 
    navigate
  ]);

  useEffect(() => {
    if (refreshToken.contents && refreshToken.contents.success === false) {
      setUser(null);
      // location?.reload();
      navigate(0)
    }
  }, [refreshToken, setUser]);
  if (
    (refreshToken.state === 'loading' &&
      location?.pathname !== '/login' &&
      location?.pathname !== '/login-password') ||
    ((firebaseUser.state === 'loading' || mysqlUser.state === 'loading') &&
      location?.pathname === '/login')
  ) {
    return <PageLoad />;
  }
  return (
      <Routes>
<Route path='*' element={user ? (
              <DashboardLayout>
                <BasePage />
              </DashboardLayout>
            ) : (
              <Navigate to="/login"/>
            )} />
        <Route
          path="/login"
          element={<Login handleSubmit={handleSubmit} />}
        />
        <Route
          path="/resetpassword"
          element={<RequestResetPassword />}
        />
        <Route
          path="/reset-password/:token"
          element={<ResetPassword />}
        />
        {type === 'password' && (
          <Route
            path="/login-password"
            element={
              <LoginPassword submitPassword={handleLoginWithPassword} />
            }
          />
        )}
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
  );
};

export default RenderRoutes;
