import React from 'react';
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel
} from '@material-ui/core';
import { SignerStatuses } from '../../../Common/Rules/SignerForm';

import { useStyles } from '../Styles/Fields';

const SignerStatus = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  disabled
}) => {
  const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }
  const classes = useStyles();
  return (
    <Grid item md={6} xs={12} sm={12} lg={6}>
      <FormControl
        variant='outlined'
        size='small'
        classes={{ root: classes.selectWidth }}
        error={errors.Signer_Status}
      >
        <InputLabel>Signer Status</InputLabel>
        <Select
          label='Signer Status'
          fullWidth
          name='Signer_Status'
          value={capitalize(values.Signer_Status)}
          onChange={handleChange}
        >
          {SignerStatuses.map(s => (
            <MenuItem value={s}>{s}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
};

export { SignerStatus };
