import React from 'react';
import { Grid, TextField } from '@material-ui/core';

const AccessGroupName = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur
}) => {
  return (
    <Grid container xs={12}>
      <TextField
        label='Access Group Name'
        name='accessGroupName'
        variant='outlined'
        size='small'
        fullWidth
        value={values.accessGroupName}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.accessGroupName && errors.accessGroupName}
        error={Boolean(touched.accessGroupName && errors.accessGroupName)}
      />
    </Grid>
  );
};

export { AccessGroupName };
