import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilCallback } from 'recoil';
import { Typography, Box, Divider } from '@material-ui/core';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';

import { Page } from '../../Components/Page';
import { FormikComponent } from '../../Components/Formik';
import { SignerForm } from '../../Components/Signers/SignersForm';

import { initialValues, validationSchema } from '../../Common/Rules/SignerForm';
import { saveSigner } from '../../Recoil/Selectors/Signer';


import { useStyles } from '.././Styles/Page';

export const NewSigner = (props => {
  const classes = useStyles();
  const navigate = useNavigate()
  const onSubmit = useRecoilCallback(
    ({ snapshot }) => async ({ ...values }, { setSubmitting, resetForm }) => {
      const requiredFields = [
        'nsa_member_number',
        'signer_type',
        'company_name',
        'Signer_Email',
        'Signer_Account_Type',
        'home_phone',
        'mobile_phone',
        'address',
        'city',
        'state',
        'zip',
        'payment_address',
        'payment_city',
        'payment_state',
        'payment_zip',
        'Signer_First',
        'Signer_Last',
        'Signer_Status',
        'operation_notes',
        'certified_signing_agent',
        'fidelity_approved',
        'other_language',
        'commisioning_states',
        'username',
        'send_receive_sms',
        'emergency_first_name',
        'emergency_last_name',
        'emergency_phone_no',
        'pmntAdrsSameAsAdrs',
        'set_loan_document',
        'phone_type',
        'prfCom',
        'latitude',
        'longitude'
      ];
      if (values.commisioning_states.length === 0) {
        enqueueSnackbar('Commisioning state is required', {
          variant: 'error'
        });
      }
      else {
        const subset = Object.fromEntries(
          Object.entries({
            ...values,
            username: values.Signer_Email,
            other_language: values.other_language.toString(),
          }).filter(([key]) => requiredFields.includes(key))
        );
        const response = await snapshot.getPromise(saveSigner(subset));
        setSubmitting(false);
        if (response.success) {
          enqueueSnackbar(
            response.result.message || 'Signer added successfully',
            {
              variant: 'success'
            }
          );
          resetForm();
          navigate('/signers');
        } else {
          enqueueSnackbar(
            response?.result?.data?.message || response?.result?.message || 'Something went wrong',
            {
              variant: 'error'
            }
          );
        }
      }
    }
  );
  const onInvalid = e => {
    enqueueSnackbar('Please fill out all the required details', {
      variant: 'error'
    });
  };
  return (
    <SnackbarProvider>
      <Page className={classes.paper} title='New Signer'>
        <Typography variant='h3'>New Signer</Typography>
        <Box my={2}>
          <Divider variant='fullWidth' />
        </Box>
        <FormikComponent
          onInvalid={onInvalid}
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          <SignerForm />
        </FormikComponent>
      </Page>
    </SnackbarProvider>

  );
});
