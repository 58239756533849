import { selectorFamily, selector } from 'recoil';
import {jwtDecode} from 'jwt-decode';
import cookie from 'react-cookies';

import { userInfo, refetchToken, twoFactorLogin, refetchProfile, forceLogin, forceUpdateCallAlerts } from '../Atoms/Login';

import { api } from '../../Services/api';
import { storage } from '../../Services/storage';
import {
  sendSignInLinkToEmail,
  isSignInWithEmailLink
} from '../../Services/firebase';
import { decodeQueryString } from '../../Common/decodeQueryString';

const loginWithPassword = selectorFamily({
  key: 'loginWithPassword',
  get: ({ email, password }: any) => async ({ get }) => {
    try {
      get(forceLogin);

      const res: any = await api.POST('auth/employee/login', {
        email,
        password
      });

      const decodedToken: any = jwtDecode(res?.token);
      const role = decodedToken?.user?.role?.name || 'unknown'
      storage.set('userRole', role)
      storage.set('receiveCalls', decodedToken?.user?.receivingCalls)
      storage.set('user', JSON.stringify(res.user));
      storage.set('refreshToken', res.refreshToken);
      cookie.save('accessToken', res.token, {
        path: '/',
        expires: new Date(Date.now() + (5 * 60 * 1000))
      });

      return {
        result: res,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const checkAuthType = selectorFamily({
  key: 'checkAuthType',
  get: (email: string) => async () => {
    try {
      const res: any = await api.POST('auth/employee/auth-type', { email });
      storage.set('type', res.authenticationType);
      return {
        result: res,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const refreshAuthToken = selector({
  key: 'refreshAuthToken',
  get: async ({ get }) => {
    const refetchTokenValue = get(refetchToken);
    const user = get(userInfo);
    const refreshToken = storage.get('refreshToken');
    if (refetchTokenValue !== 0 && user && refreshToken) {
      try {
        const res: any = await api.POST('auth/employee/token', {
          email: user.email,
          refreshToken
        });
        
        const decodedToken: any = jwtDecode(res?.token);
        const role = decodedToken?.user?.role?.name || 'unknown'
        storage.set('userRole', role)

        cookie.save('accessToken', res.token, {
          path: '/',
          expires: new Date(Date.now() + (5 * 60 * 1000))
        });

        return {
          result: res,
          success: true
        };
      } catch (err) {
        localStorage.removeItem('user');
        localStorage.removeItem('refreshToken');
        cookie.remove('accessToken', { path: '/' });

        return {
          result: err,
          success: false
        };
      }
    }

    return null;
  }
});

const removeUser = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('refreshToken');
  cookie.remove('accessToken', { path: '/' });
  window.location.reload()
}

const getRole = async () => {
  try {

    const refreshToken = storage.get('refreshToken');
    const currentRole = storage.get('userRole')

    const res: any = await api.POST('auth/employee/token', { refreshToken });

    const decodedToken: any = jwtDecode(res?.token);
    const role = decodedToken?.user?.role?.name || 'unknown'

    if (currentRole !== role) {
      storage.set('userRole', role)
      window.location.reload()
    }

  } catch (error) {
    removeUser()
  }
}

const requestCache = {};

const verifyAuthToken = async () => {
  const accessToken = cookie.load('accessToken');
  const refreshToken = storage.get('refreshToken');

  if (requestCache['verifyAuthToken']) {
    return requestCache['verifyAuthToken'];
  }

  const promise = (async () => {
    if (!accessToken) {
      try {
        const res: any = await api.POST('auth/employee/token', { refreshToken });
        const currentDate = new Date();
        currentDate.setHours(23, 59, 59, 999);
        const remainingTime = currentDate.getTime() - new Date().getTime();

        cookie.save('accessToken', res.token, {
          path: '/',
          expires: new Date(Date.now() + remainingTime),
        });
      } catch (error) {
        removeUser();
      }
    }
  })();
  requestCache['verifyAuthToken'] = promise;

  promise.finally(() => {
    delete requestCache['verifyAuthToken'];
  });

  return promise;
};

const changePassword = selectorFamily({
  key: 'changePassword',
  get: (params: any) => async () => {
    if (params) {
      try {
        await verifyAuthToken()
        const res: any = await api.POST(
          'auth/employee/change-password',
          params
        );
        return {
          result: res,
          success: true
        };
      } catch (error) {
        return {
          result: error,
          success: false
        };
      }
    }
  }
});

const logout = selector({
  key: 'logout',
  get: async () => {
    try {
      await verifyAuthToken()
      const res: any = await api.PUT('auth/employee/logout');
      localStorage.removeItem('user');
      localStorage.removeItem('refreshToken');
      cookie.remove('accessToken', { path: '/' });
      return {
        result: res,
        success: true
      };
    } catch (error) {
      return {
        result: error,
        success: false
      };
    }
  }
});

const sendEmail = selectorFamily({
  key: 'sendEmail',
  get: (email: string) => async () => {
    try {
      await sendSignInLinkToEmail(email);
      return {
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const verifyEmail = selectorFamily({
  key: 'verify',
  get: (search: any) => async () => {
    const email = storage.get('userEmail');
    const params = decodeQueryString(search);
    if (email && params && params.apiKey && params.mode === 'signIn') {
      try {
        const res = await isSignInWithEmailLink();
        return res;
      } catch (err) {
        return err;
      }
    } else {
      return null;
    }
  }
});

const loginWithoutPassword = selector({
  key: 'loginWithoutPassword',
  get: async ({ get }) => {
    const value = get(twoFactorLogin);
    const email = storage.get('userEmail');
    if (email && value === 1) {
      try {
        get(forceLogin);
        const res: any = await api.POST('auth/employee/login', { email });

        const decodedToken: any = jwtDecode(res?.token);
        const role = decodedToken?.user?.role?.name || 'unknown'
        storage.set('userRole', role)

        storage.remove('userEmail');
        storage.set('user', JSON.stringify(res.user));
        storage.set('refreshToken', res.refreshToken);
        cookie.save('accessToken', res.token, {
          path: '/',
          expires: new Date(Date.now() + (5 * 60 * 1000))
        });

        return {
          result: res,
          success: true
        };
      } catch (err) {
        return {
          result: err,
          success: false
        };
      }
    } else {
      return null;
    }
  }
});

const getProfile = selector({
  key: 'getProfile',
  get: async ({ get }) => {
    get(refetchProfile)

    try {
      await verifyAuthToken()
      const res: any = await api.GET('/auth/employee/profile');

      return {
        result: res,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const updateProfile = selectorFamily({
  key: 'updateProfile',
  get: (params: any) => async ({ get }) => {
    if (params) {
      try {
        get(refetchProfile)
        await verifyAuthToken()
        const res: any = await api.PUT('/auth/employee/update', params);
        return {
          result: res,
          success: true
        };
      } catch (error) {
        return {
          result: error,
          success: false
        };
      }
    }
  }
});

const resetPasswordRequest = selectorFamily({
  key: 'resetPasswordRequest',
  get: ({ email }: any) => async () => {
    try {
      const res: any = await api.PUT('auth/employee/password/resetrequest', { email });
      return {
        result: res,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const resetPassword = selectorFamily({
  key: 'resetPassword',
  get: ({ new_password, token }: any) => async () => {
    try {
      const res: any = await api.PUT('auth/employee/resetpassword', { new_password, token });
      return {
        result: res,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const getAllEmployees = selector({
  key: 'getAllEmployees',
  get: async () => {
    try {
      await verifyAuthToken()
      const res: any = await api.GET('auth/employee/get/employees');
      return {
        result: res,
        success: true
      };
    } catch (error) {
      return {
        result: error,
        success: false
      };
    }
  }
});

const updateCallAlerts = selectorFamily({
  key: 'updateCallAlerts',
  get: (value: any) => async ({ get }) => {
    try {
      get(forceUpdateCallAlerts)
      await verifyAuthToken()
      const res = await api.PUT('auth/employee/receive/calls', { receivingCalls: value });
      storage.set('receiveCalls', res)
      return {
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

export {
  loginWithoutPassword,
  loginWithPassword,
  refreshAuthToken,
  changePassword,
  logout,
  checkAuthType,
  sendEmail,
  verifyEmail,
  getProfile,
  updateProfile,
  resetPasswordRequest,
  resetPassword,
  getAllEmployees,
  verifyAuthToken,
  getRole,
  updateCallAlerts
};