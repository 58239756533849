import React, { useState, useEffect, useRef } from 'react';
import { useRecoilCallback, useSetRecoilState } from 'recoil';
import moment from 'moment';

import { Formik, Form } from 'formik';
import {
  validationSchemaWithoutSplit,
} from '../../Common/Rules/OrdersForm';
import { OrdersForm } from '../../Components/Orders/OrdersForm';
import { PageLoad } from 'src/Components/LoadingScreen/PageLoad';

import { selectedClient } from 'src/Recoil/Atoms/Clients';
import { selectedDivision } from 'src/Recoil/Atoms/Divisions';
import { getOrderById } from '../../Recoil/Selectors/Orders'
import { enqueueSnackbar } from 'notistack'
const ViewOrderPopup = (props) => {

  const [order, setOrder] = useState({
    w_Loan_Number: '',
    clientID: '',
    division_id: '',
    fees: [],
    signer: undefined,
    w_outsource: ''
  })
  const [pane, handlePane] = useState(false);
  const [paneTitle, handleTitle] = useState('');
  const [isLoadingOrder, setLoadingOrder] = useState(false)
  const { id } = props
  const setClient = useSetRecoilState(selectedClient)
  const setDivision = useSetRecoilState(selectedDivision)
  const feesRef = useRef(null)

  const handlePaneOpen = (title: any) => {
    handlePane(true);
    handleTitle(title);
  }

  const getOrderDetails = useRecoilCallback(({ snapshot }) => async ({ stopLoading }) => {
    const res: any = await snapshot.getPromise(getOrderById({ id }));
    if (res.success) {
      setOrder({
        ...res.result,
        w_Appointment_Time: moment(res?.result?.w_Appointment_Time, 'hh:mm A'),
        additionalBorrowersModel: res?.result?.additionalBorrowersModel ? res.result.additionalBorrowersModel : []
      })
      setClient(res?.result?.clientID)
      setDivision(res?.result?.division_id)
      stopLoading()
    } else {
      enqueueSnackbar(
        res?.result?.message || 'Failed to load order details',
        {
          variant: 'error'
        }
      );
    }
  })

  
  useEffect(() => {
    setLoadingOrder(true)
    getOrderDetails({
      stopLoading: () => {
        setLoadingOrder(false)
      }
    })
    // eslint-disable-next-line
  }, [])

  return (
    isLoadingOrder ? <PageLoad></PageLoad> :
    <Formik
      initialValues={{
        w_Loan_Number: [order.w_Loan_Number],
        location_one: order
      }}
      validationSchema={validationSchemaWithoutSplit}
      onSubmit={()=> {}}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue
      }: any) => {
        return (
          <Form onSubmit={handleSubmit}>
            <OrdersForm
              feesRef={feesRef}
              isViewOnlyMode={true}
              signer={order.signer}
              isEditMode={false}
              location='location_one'
              values={values}
              errors={errors}
              touched={touched}
              isSubmitting={isSubmitting}
              handleChange={handleChange}
              handleBlur={handleBlur}
              handleSubmit={handleSubmit}
              setFieldValue={setFieldValue}
              pane={pane}
              paneTitle={paneTitle}
              handlePane={handlePane}
              handlePaneOpen={handlePaneOpen}
              orderFees={order.fees.length > 0 ? order.fees : null}
            />
          </Form>
        );
      }}
    </Formik>
  )
}

export { ViewOrderPopup }