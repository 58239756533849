import React, { useState } from 'react';
import { useRecoilCallback, useSetRecoilState } from 'recoil';
import { Box, Chip, TextField } from '@material-ui/core';
import { Edit, XSquare, CheckSquare } from 'react-feather';

import { setGoal } from '../../Recoil/Selectors/Statistics';
import { forceUpdateGoal } from '../../Recoil/Atoms/Stats';

import { Toast } from '../Toasts';

import { useStyles } from './Styles/GoalCount';

const GoalCount = (props: any) => {
  const classes = useStyles();
  const [edit, setEdit] = useState(false);
  const { count } = props;
  const [goalValue, setGoalValue] = useState(0);
  const [alert, setAlert] = useState(null);
  const todoUpdates = useSetRecoilState(forceUpdateGoal);
  const forceUpdate = () => todoUpdates((n) => n + 1);

  const handleEdit = () => {
    setEdit(!edit)
    setGoalValue(count)
  };

  const handleChange = (e) => {
    setGoalValue(e.target.value)
  };

  const setToast = () => {
    setAlert(null)
  };

  const handleUpdate = useRecoilCallback(({snapshot}) => async () => {
    setAlert(null);
    const res: any = await snapshot.getPromise(setGoal({target: goalValue}));
    if (res.success) {
      setAlert({
        status: 'success',
        message: 'Goal Updated'
      });
      handleEdit()
    }
    else {
      setAlert({
        status: 'error',
        message: 'Update Failed'
      });
    }
    forceUpdate()
  });

  return (
    <Box margin='0px 5px'>
      { !edit ?
        <Box margin='8px 0 4px 0' height='40px'>
          <Chip
            label={count}
            onDelete={handleEdit}
            deleteIcon={<Edit />}
          />
          { alert &&
            <Toast message = {alert.message} type = {alert.status} open = {!(alert === null)} setToast={setToast} />
          }
        </Box> :
        <Box display='flex' flexDirection='row' alignItems='center' padding='0px'>
          <TextField id='standard-basic' variant='outlined' margin='dense' value={goalValue} onChange={handleChange} />
          <XSquare onClick={handleEdit} className={classes.goalButton} size={25} color='red' />
          <CheckSquare onClick={handleUpdate} className={classes.goalButton} size={25} color='green' />
        </Box>
      }
    </Box>
  );
}

export { GoalCount };
