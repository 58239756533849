import { atom } from 'recoil';

import { getUserInfo } from '../../Common/getUserInfo';

const twoFactorLogin = atom({
  key: 'twoFactorLogin',
  default: 0
});

const userInfo = atom({
  key: 'userInfo',
  default: getUserInfo()
});

const refetchToken = atom({
  key: 'refetchToken',
  default: 0
});

const refetchProfile = atom({
  key: 'refetchProfile',
  default: 0
});

const forceLogin = atom({
  key: 'forceLogin',
  default: 0
});

const forceUpdateCallAlerts = atom({
  key: 'forceUpdateCallAlerts',
  default: 0
});

export { twoFactorLogin, userInfo, refetchToken, refetchProfile, forceLogin, forceUpdateCallAlerts };
