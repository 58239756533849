import React from 'react';
import { Grid, TextField } from '@material-ui/core';

const LastName = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  disabled
}) => {
  return (
    <Grid item xs={12} md={6}>
      <TextField
        label='Last Name'
        name='Signer_Last'
        variant='outlined'
        size='small'
        fullWidth
        value={values.Signer_Last}
        onChange={ e => { 
          e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.substr(1).toLowerCase();
          handleChange(e)}}
        onBlur={handleBlur}
        helperText={touched.Signer_Last && errors.Signer_Last}
        error={Boolean(touched.Signer_Last && errors.Signer_Last)}
        disabled={disabled}
      />
    </Grid>
  );
};

export { LastName };
