import React from 'react';
import { Typography } from '@material-ui/core';
import ReactSlidingPane from 'react-sliding-pane';

import { useStyles } from './Styles';

const SlidingPane = (props: any) => {
  const classes = useStyles();
  const { title, open, handlePane, children, width = '70%' } = props;

  return (
    <ReactSlidingPane
      overlayClassName={classes.overlay}
      title={<Typography>{title}</Typography>}
      isOpen={open}
      onRequestClose={() => handlePane(false)}
      from='right'
      width={width}
    >
      {children}
    </ReactSlidingPane>
  );
}

export { SlidingPane };
