import React from 'react';
import { useRecoilState } from 'recoil';
import { FormControl, MenuItem, InputLabel, Select } from '@material-ui/core';

import { currentOrder } from '../../../../Recoil/Atoms/Orders';

const statuses = [{
  value: 'C',
  label: 'Confirmed'
}, {
  value: 'NR',
  label: 'No Answer To Confirmation'
}, {
  value: 'D',
  label: 'Denied'
}, {
  value: 'RSS',
  label: 'Reminder SMS Send'
}, {
  value: 'CSS',
  label: 'Confirmation SMS Sent'
}, {
  value: 'RNR',
  label: 'No Answer To Reminder'
}];

export const ChangeCallStatus = () => {
  const [order, setOrder] = useRecoilState(currentOrder);

  const updateStatus = (e) => {
    const { value } = e.target
    setOrder({ ...order, confirmation_call_status: value });
  }

  return (
    <FormControl
      fullWidth
      margin='dense'
      variant='outlined'
    >
      <InputLabel>Status</InputLabel>
      <Select label='Clients' value={order?.confirmation_call_status} onChange={updateStatus}>
        {statuses.map(s => <MenuItem key={s.value} value={s.value}>{s.label}</MenuItem>)}
      </Select>
    </FormControl>
  );
};
