import React from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { getAuditTrailByOrderId } from '../../../Recoil/Selectors/Orders';
import { TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { useStyles } from '../Styles/OrdersList';

const AuditTrail = (props) => {
  const classes = useStyles();
  const { Id } = props?.values?.location_one;
  const auditTrails = useRecoilValueLoadable(getAuditTrailByOrderId(Id));
  const auditTrailsList = auditTrails?.contents?.result;
  // console.log(auditTrailsList)
  return (
    <>
      <TableContainer>
        <table
          className={classes.table}
          aria-labelledby='tableTitle'
          aria-label='enhanced table'
        >
          <TableHead>
            <TableRow>
              <TableCell>Index</TableCell>
              <TableCell>User's Name</TableCell>
              <TableCell>Modified Values</TableCell>
              <TableCell>Old Values</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              auditTrailsList?.length > 0 ?
                auditTrailsList?.map((trail, index) => {
                  return (
                    <TableRow key={index}>
                      <>
                        <TableCell>
                          {index + 1}
                        </TableCell>
                        <TableCell>
                          {`${trail?.userInfo?.firstName} ${trail?.userInfo?.lastName}`}
                        </TableCell>
                        <TableCell>
                          {
                            Object.keys(trail?.newAttributes).map((key) => {
                              return (
                                <React.Fragment key={key}>
                                <TableRow>
                                  <TableCell>{key}</TableCell>
                                  <TableCell>{key == 'createdAt' ? JSON.stringify(trail?.newAttributes[key]) : trail?.newAttributes[key]}</TableCell>
                                </TableRow>
                              </React.Fragment>
                              )
                            })
                          }
                        </TableCell>
                        <TableCell>
                          {
                            Object.keys(trail?.oldAttributes).map((key) => (
                              <React.Fragment key={key}>
                                <TableRow>
                                  <TableCell>{key}</TableCell>
                                  <TableCell>{key == 'createdAt' ? JSON.stringify(trail?.newAttributes[key]) : trail?.newAttributes[key]}</TableCell>
                                </TableRow>
                              </React.Fragment>
                            ))
                          }
                        </TableCell>
                      </>
                    </TableRow>
                  )
                })
                : <TableRow><TableCell colSpan={4}>No Audit Trail Logs Found</TableCell></TableRow>
            }
          </TableBody>
        </table>
      </TableContainer>
    </>
  );
}

export { AuditTrail };
