import React from 'react';
import { Grid, TextField } from '@material-ui/core';

const Divisions = ({ values, errors, touched, handleChange, handleBlur }) => {
  return (
    <Grid item xs={12}>
      <TextField
        label='Division'
        name='divisionName'
        variant='outlined'
        size='small'
        fullWidth
        value={values.divisionName}
        onChange={ e => { 
          e.target.value = e.target.value.toLowerCase()
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
          handleChange(e)}}
        onBlur={handleBlur}
        helperText={touched.divisionName && errors.divisionName}
        error={Boolean(touched.divisionName && errors.divisionName)}
      />
    </Grid>
  );
}

export { Divisions };
