import React from 'react';
import { TableHead, TableRow, TableCell, TableSortLabel } from '@material-ui/core';

import { useStyles } from '../Styles/style';
import { marginStyles } from 'src/Common/Styles/Margins';
import clsx from 'clsx';

type Order = any;

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
};

interface HeadCell {
  id: string;
  label: string;
};

const headCells: HeadCell[] = [
    {
      id: 'Id',
      label: ' ',
    },
    {
      id: 'f_loan_number',
      label: 'Loan/Order #',
    },
    {
      id: 'billRefNumber',
      label: 'Invoice #',
    },
    {
      id: 'f_borrower_first_name',
      label: 'Borrower',
    },
    {
      id: 'f_signer_name',
      label: 'Signer',
    },
    {
      id: 'f_signer_fees',
      label: 'Signer Fee',
    },
    {
      id: 'f_appointment_date',
      label: 'Appointment Date',
    },
    {
      id: 'invoice_submitted_datetime',
      label: 'Inv. Submitted On',
    },
    {
      id: 'f_status_web',
      label: 'Status',
    },
    {
      id: 'payment_status',
      label: 'Payment Status',
    },
    {
      id: 'actions',
      label: 'Action',
    }
  ];

const PendingVendorInvoicesTableHead = (props: EnhancedTableProps) => {
  const classes = useStyles();
  const marginClasses = marginStyles();

  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow className={classes.tableHead}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='left'
            sortDirection={orderBy === headCell.id ? order : false}
            className={clsx(classes.cellPadding,classes.tableHeadCell)}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              style={{margin:0}}
              className={marginClasses.m0}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export { PendingVendorInvoicesTableHead };
