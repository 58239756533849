import React from 'react';
import { TableHead, TableRow, TableCell, TableSortLabel } from '@material-ui/core';

import { useStyles } from './Styles/OrdersTableHead';
import { marginStyles } from 'src/Common/Styles/Margins';

type Order = any;

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
};

interface HeadCell {
  id: string;
  label: string;
};

const headCells: HeadCell[] = [
  { id: 'w_row_number', label: 'Index' },
  { id: 'w_Loan_Number', label: 'Loan/Order #' },
  { id: 'w_Borrower_Last_Name', label: 'Last Name' },
  { id: 'w_Appointment_Date', label: 'Appointment Date' },
  { id: 'w_Appointment_Time', label: 'Appointment Time' },
  { id: 'f_status_web', label: 'Status' },
  { id: 'scan_backs', label: 'Scan Backs'},
  { id: 'w_docs_received', label: 'Docs Received'},
  { id: 'w_Return_Tracking', label: 'Tracking #' },
  { id: 'Id', label: 'Order ID' },
  { id: 'documents', label: 'Documents' },
  { id: 'actions', label: 'Actions' }
];

const OrdersTablesHead = (props: EnhancedTableProps) => {
  const classes = useStyles();
  const marginClasses = marginStyles();

  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='left'
            sortDirection={orderBy === headCell.id ? order : false}
            className={classes.cellPadding}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              style={{margin:0}}
              className={marginClasses.m0}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export { OrdersTablesHead };
