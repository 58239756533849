import React, { useState } from 'react';
import { useRecoilCallback, useSetRecoilState } from 'recoil'

import { Box, Tooltip, IconButton, SvgIcon } from '@material-ui/core';
import { Edit, LogIn } from 'react-feather';

import { resendMasterLoginRequest } from './../../Recoil/Atoms/Contacts'
import { masterLoginRequest } from './../../Recoil/Selectors/Contacts'
import { Toast } from './../Toasts'

import { Table } from '../Table';

const ContactsData = (props: any) => {
  const { rows, loading, total, pageInfo = {}, setPageInfo = () => { }, adminContacts = false } = props;
  const { orderBy, orderType } = pageInfo

  const [toast, setToast] = useState({ message: '', open: false, type: 'success' })
  const resendLoginEmail = useSetRecoilState(resendMasterLoginRequest)

  const sendEmailLinkToLogin = useRecoilCallback(({ snapshot }) => async (Id: any) => {
    resendLoginEmail(n => n + 1)
    const res = await snapshot.getPromise(masterLoginRequest({ contact_id: Id }))
    if (res.success) {
      const tempLink = document.createElement('a');
      tempLink.href = res?.result;
      tempLink.setAttribute('target', '_blank');
      tempLink.click();
    } else {
      setToast({ open: true, message: res?.result?.error || 'Login Failed!', type: 'error' })
    }
  })

  const actionsFormatter = (e) => {
    return (
      <Box display='flex'>
        <Tooltip title='Edit'>
          <IconButton color='inherit' onClick={() => props.handleEdit(e.row)}>
            <SvgIcon fontSize='small' color='primary'>
              <Edit />
            </SvgIcon>
          </IconButton>
        </Tooltip>
      </Box>
    );
  }

  const adminActionsFormatter = (e) => {
    return (
      <Box display='flex'>
        <Tooltip title='Login'>
          <IconButton color='inherit' onClick={() => sendEmailLinkToLogin(e.row.Id)}>
            <SvgIcon fontSize='small' color='primary'>
              <LogIn />
            </SvgIcon>
          </IconButton>
        </Tooltip>
      </Box>
    );
  }

  const columns = [{
    field: 'id',
    headerName: 'ID'
  }, {
    field: 'Contact_First_Name',
    headerName: 'First Name'
  }, {
    field: 'Contact_Last_Name',
    headerName: 'Last Name'
  }, {
    field: 'Contact_Work_Phone',
    headerName: 'Work Phone'
  }, {
    field: 'Contact_Email',
    headerName: 'Email'
  }, {
    field: 'status',
    headerName: 'Status'
  }, {
    field: 'actions',
    headerName: 'Actions',
    renderCell: adminContacts ? adminActionsFormatter : actionsFormatter
  }];

  const handlePage = (e: unknown, newPage: number) => {
    setPageInfo({
      ...pageInfo,
      page: newPage
    });
  };

  const handleRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPageInfo({
      ...pageInfo,
      limit: parseInt(e.target.value, 10)
    });
  };

  const handleSort = (e: any) => {

    if (e.sortModel &&e.sortModel[0].field) {
      const { field, sort } = e.sortModel[0]
      setPageInfo({
        ...pageInfo,
        orderBy: field,
        orderType: sort,
      });
    }
  };
  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        rows={rows}
        total={total}
        sortModel={
          [{ field: orderBy, sort: orderType }]
        }
        onSortingChange={handleSort}
        pageInfo={pageInfo}
        handlePage={handlePage}
        handleRowsPerPage={handleRowsPerPage}
      />

      <Toast message={toast.message} type={toast.type} open={toast.open} setToast={setToast} />
    </>
  );
};

export { ContactsData };
