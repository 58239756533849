import React from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

import { useStyles } from '../Styles/Fields';

const ContactPrivilege = ({ values, handleChange }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} md={6}>
      <FormControl className={classes.removeSelectMargin} margin='dense' variant='outlined'>
        <InputLabel>Privilege</InputLabel>
        <Select
          label='Privilege'
          name='privilege'
          fullWidth
          value={values.privilege === null ? '' : values.privilege}
          onChange={handleChange}
        >
          <MenuItem value='All'>All Orders</MenuItem>
          <MenuItem value='Self'>Self Orders</MenuItem>
          <MenuItem value='Division Base'>Division Base</MenuItem>
          <MenuItem value='Team Lead'>Team Lead</MenuItem>
        </Select>
      </FormControl>
    </Grid>
  );
}

export { ContactPrivilege };
