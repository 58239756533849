import React from 'react';
import { Typography, Box, Divider } from '@material-ui/core';

import { Page } from '../../Components/Page';
import {
  useRecoilValueLoadable,
  useRecoilState
} from 'recoil';

import { useStyles } from '.././Styles/Page';
import { UnbilledOrdersData } from 'src/Components/Accounting/UnbilledOrder/UnbilledOrdersData';
import { UnbilledOrdersActions } from 'src/Components/Accounting/UnbilledOrder/UnbilledOrdersActions';
import { getUnbilledOrders } from 'src/Recoil/Selectors/Accounting';
import { UnbilledOrdersPagination } from '../../Recoil/Atoms/Accounting';

export const UnbilledOrders = () => {
  const classes = useStyles();

  const [pageInfo, setPageInfo] = useRecoilState(UnbilledOrdersPagination);
  const allUnbilledOrders: any = useRecoilValueLoadable(getUnbilledOrders);
  const unbilledOrdersData = allUnbilledOrders?.contents?.result?.rows || [];
  const total = allUnbilledOrders?.contents?.result?.count || 0;

  return (
    <Page className={classes.paper} title='Unbilled Orders'>
      <Typography variant='h3'>Unbilled Orders</Typography>
      <Box my={2}>
        <Divider variant='fullWidth' />
      </Box>
      <UnbilledOrdersActions />
      <UnbilledOrdersData
        unbilledOrder={unbilledOrdersData}
        loading={allUnbilledOrders?.state === 'loading'}
        total={total}
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
      />
    </Page>
  );
};
