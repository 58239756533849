import React from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import clsx from 'clsx';

import { marginStyles } from '../../../Common/Styles/Margins';

const Method = ({ values, handleChange }) => {
  const marginClasses = marginStyles();

  return (
    <Grid item xs={12} md={6}>
      <FormControl className={clsx(marginClasses.mb0, marginClasses.mt0)} margin='dense' variant='outlined'>
        <InputLabel>Method</InputLabel>
        <Select
          label='Method'
          name='default_docs_method'
          fullWidth
          value={values.default_docs_method}
          onChange={handleChange}
        >
          <MenuItem value='CA'>QS Web Upload</MenuItem>
          <MenuItem value='PDF'>Emailed PDF</MenuItem>
          <MenuItem value='ONM'>Overnight</MenuItem>
          <MenuItem value='SL'>Secure Link</MenuItem>
        </Select>
      </FormControl>
    </Grid>
  );
}

export { Method };
