import moment from 'moment';
import { atom } from 'recoil';

const volumeReport = atom({
    key: 'volumeReport',
    default: {
      page: 1,
      limit: 20,
      day: moment().format('MM/DD/YYYY')
    }
  });

const nightlyReportPagination = atom({
  key: 'nightlyReportPagination',
  default: {
    page: 1,
    limit: 20,
    day: moment().format('MM/DD/YYYY')
  }
});

const matchedReportsPagination = atom({
  key: 'matchedReportsPagination',
  default: {
    start: moment().format('MM/DD/YYYY'), 
    end: moment().format('MM/DD/YYYY'), 
    matched_by: -1, 
    limit: 20, 
    page: 1
  }
})

const matchedReportsPaginationPdf = atom({
  key: 'matchedReportsPaginationPdf',
  default: {
    start: moment().format('MM/DD/YYYY'), 
    end: moment().format('MM/DD/YYYY'), 
    matched_by: -1, 
    limit: 20, 
    page: 1
  }
})

const rangeReportsPagination = atom({
  key: 'rangeReportsPagination',
  default: {
    start: moment().format('MM/DD/YYYY'), 
    end: moment().format('MM/DD/YYYY'), 
    matched_by: -1, 
    limit: 20, 
    page: 1,
    clientID:"",
    divisionID:"",
    w_Signing_State:''
  }
})

const rangeReportsPaginationPdf = atom({
  key: 'rangeReportsPaginationPdf',
  default: {
    start: moment().format('MM/DD/YYYY'), 
    end: moment().format('MM/DD/YYYY'), 
    matched_by: -1, 
    limit: 20, 
    page: 1,
    clientID:"",
    divisionID:"",
    w_Signing_State:''
  }
})

const rangeReportsPaginationCsv = atom({
  key: 'rangeReportsPaginationCsv',
  default: {
    start: moment().format('MM/DD/YYYY'),
    end: moment().format('MM/DD/YYYY'), 
    matched_by: -1, 
    limit: 20, 
    page: 1
  }
})

const resendNightlyReport = atom({
  key: 'resendNightlyReport',
  default: 0
})


const processorsReportPagination = atom({
  key: 'processorsReportPagination',
  default: {
    page: 1,
    limit: 20,
    start: moment().format('MM/DD/YYYY'),
    end: moment().format('MM/DD/YYYY')
  }
})

const refetchProcessorReportCSV = atom({
  key: 'refetchProcessorReportCSV',
  default: 0
})

const refetchVolumeReportPdf = atom({
  key: 'refetchVolumeReportPdf',
  default: 0
})

const resendVolumeReportPdf = atom({
  key: 'resendVolumeReportPdf',
  default: 0
})

export { 
  volumeReport, 
  nightlyReportPagination, 
  matchedReportsPagination, 
  resendNightlyReport, 
  matchedReportsPaginationPdf,
  rangeReportsPagination,
  rangeReportsPaginationPdf,
  rangeReportsPaginationCsv,
  processorsReportPagination,
  refetchProcessorReportCSV,
  refetchVolumeReportPdf,
  resendVolumeReportPdf
}
