import React, { useState } from 'react'
import { useSetRecoilState, useRecoilValue, useRecoilValueLoadable, useRecoilCallback } from 'recoil'
import {
  Button,
  Checkbox,
  Box,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  DialogContentText,
  TextField,
  Tooltip,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab';
import clsx from 'clsx';
import moment from 'moment';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';

import { GeneralModal } from '../Modal'

import { currentOrder, forceUpdateFiles, forceResendDocumentReadyEmail, forceMoveAll } from '../../Recoil/Atoms/Orders'
import { FetchFileList, mergeDocs, DeleteFile, FetchFile, mergeDocumentsMailToSigner, moveDocs } from '../../Recoil/Selectors/Orders'

import { useStyles } from './Styles/MergeDocs'
import { displayStyles } from '../../Common/Styles/Display'
import { paddingStyles } from '../../Common/Styles/Paddings';


const MergeDocs = (props) => {
  const classes = useStyles()
  const paddingClasses = paddingStyles()
  const [selectedDocs, setSelectedDocs] = useState([])
  const [signerConfirmation, setSignerConfirmation] = useState(false)
  const [openConfirmAlert, setOpenAlert] = useState(false)
  const [alert, setAlert] = useState(null);
  const [loadingFile, setLoadingFile] = useState(null);
  const [isMerging, setIsMerging] = useState(null);
  const [isMoving, setIsMoving] = useState(null);
  const [isDeleting, setIsDeleting] = useState(null);
  const setMoveAll = useSetRecoilState(forceMoveAll)

  const setForceResendEmail = useSetRecoilState(forceResendDocumentReadyEmail)
  const completedStatuses: any = ['Signed', 'Completed', 'Cancelled']


  const [sortingIndexes, setSortingIndexes] = useState({});

  const displayClasses = displayStyles()
  const todoUpdates = useSetRecoilState(forceUpdateFiles);
  const selectedOrder: any = useRecoilValue(currentOrder);
  const forceUpdate = () => todoUpdates((n) => n + 1);

  const uploadedFiles: any = useRecoilValueLoadable(FetchFileList(selectedOrder));
  const files = uploadedFiles?.contents?.result?.data || [];
  let totalPageCount = 0
  let totalMergedPageCount = 0
  uploadedFiles?.contents?.result?.data.forEach(pdf => {
    if (pdf?.pages !== 'PDF LOCKED') {
      totalPageCount += parseInt(pdf.pages, 10)
    }
  })

  const mergedFilesData: any = useRecoilValueLoadable(FetchFileList({ Id: `${selectedOrder.Id}/signers` }));
  const mergedFiles = mergedFilesData?.contents?.result?.data || []
  mergedFilesData?.contents?.result?.data.forEach(pdf => {
    if (pdf?.pages !== 'PDF LOCKED') {
      totalMergedPageCount += parseInt(pdf.pages, 10)
    }
  })

  const onCheckBoxChange = (checked: Boolean, name: String, id: Number | String) => {
    if (checked) {
      setSelectedDocs([...selectedDocs, { name, id }])
    } else {
      setSelectedDocs(selectedDocs.filter(p => p.name !== name))
    }
  }

  const onAllSelect = (e) => {
    if (e.target.checked) {
      setSelectedDocs(files.map(f => { return { name: f.name, id: f.documentID } }))
    } else {
      setSelectedDocs([])
    }
  }

  const getSortedList = () => {
    let sortedDocs = []
    selectedDocs.map(doc => {
      sortedDocs.push({
        name: doc.name,
        sortIndex: sortingIndexes[doc.name],
        id: doc.id
      })
      return doc
    })
    const undefindSort = sortedDocs.filter(file => !file.sortIndex || file.sortIndex === '')

    sortedDocs = sortedDocs.filter(file => file.sortIndex)
      .sort((a, b) => (a.sortIndex > b.sortIndex) ? 1 : ((b.sortIndex > a.sortIndex) ? -1 : 0))
      .concat(undefindSort).map((doc, i) => { return { name: doc.name, index: i, id: doc.id } })
    return sortedDocs
  }

  const move = useRecoilCallback(({ snapshot }) => async () => {
    setIsMoving(true)
    setMoveAll((n: any) => n + 1)
    const res: any = await snapshot.getPromise(moveDocs({
      destination: selectedOrder.Id,
    }));
    setIsMoving(false)
    if (res.success && !res.result.Error) {
      setSelectedDocs([])
      setSignerConfirmation(false)
      setIsMerging(false)
      forceUpdate();
      setAlert({
        status: 'success',
        message: 'Files moved successfully'
      })
      snapshot.getPromise(mergeDocumentsMailToSigner({
        to: props?.signer?.Signer_Email,
        signer_name: `${props.signer.Signer_First} ${props.signer.Signer_Last}`,
        f_loan_number: props.f_loan_number,
        f_borrower_first_name: props.f_borrower_first_name,
        f_borrower_last_name: props.f_borrower_last_name,
        f_appointment_date: props.f_appointment_date,
        f_appointment_time: props.f_appointment_time,
        signing_address: `${props.w_Signing_Street_Address}, ${props.w_Signing_City}, ${props.w_Signing_State}, ${props.w_Signing_Zip}`,
        w_Signing_Phone_Number: props.w_Signing_Phone_Number
      }))
    }
    else {
      setIsMerging(false)
      setAlert({
        status: 'error',
        message: res?.result?.Error || 'Error occurred'
      });
    }
  })

  const merge = useRecoilCallback(({ snapshot }) => async () => {
    setAlert(null);
    setOpenAlert(false)
    setIsMerging(true)
    const res: any = await snapshot.getPromise(mergeDocs({
      files: getSortedList(),
      destination: selectedOrder.Id,
      signer_id: selectedOrder.f_signer_id,
      confirmation: signerConfirmation
    }));
    setForceResendEmail((n: any) => n + 1)

    if (res.success && !res.result.Error) {
      snapshot.getPromise(mergeDocumentsMailToSigner({
        to: props?.signer?.Signer_Email,
        signer_name: `${props.signer.Signer_First} ${props.signer.Signer_Last}`,
        f_loan_number: props.f_loan_number,
        f_borrower_first_name: props.f_borrower_first_name,
        f_borrower_last_name: props.f_borrower_last_name,
        f_appointment_date: props.f_appointment_date,
        f_appointment_time: props.f_appointment_time,
        signing_address: `${props.w_Signing_Street_Address}, ${props.w_Signing_City}, ${props.w_Signing_State}, ${props.w_Signing_Zip}`,
        w_Signing_Phone_Number: props.w_Signing_Phone_Number
      }))

      setSelectedDocs([])
      setSignerConfirmation(false)
      setIsMerging(false)
      forceUpdate();
      setAlert({
        status: 'success',
        message: 'Files merged successfully'
      })
    }
    else {
      setIsMerging(false)
      setAlert({
        status: 'error',
        message: res?.result?.Error || 'Error occurred'
      });
    }
  })

  const Delete = useRecoilCallback(({ snapshot }) => async ({ filename, deleteAll }) => {
    setIsDeleting(deleteAll ? 'all' : filename)
    setAlert(null);
    if (deleteAll) {
      const filesToDelete = mergedFiles.map(async (file) => (new Promise(async (resolve, reject) => {
        const response: any = await snapshot.getPromise(DeleteFile({
          filename: file.name,
          folder: `borrower/${selectedOrder.Id}/signers`,
        }));
        if (response.success) {
          resolve(true)
        } else {
          reject(false)
        }
      })))
      Promise.all(filesToDelete).then(values => {
        forceUpdate();
        if (values.filter(v => !v)?.length) {
          setIsDeleting(false)
          enqueueSnackbar(
            'Error occurred while deleting multiple files',
            {
              variant: 'error'
            }
          );
        } else {
          setIsDeleting(false)
          enqueueSnackbar(
            'Files deleted successfully',
            {
              variant: 'success'
            }
          );
        }

      }).catch(err => {
        setIsDeleting(false)
        enqueueSnackbar(
          'Error occurred while deleting multiple files',
          {
            variant: 'error'
          }
        );
      })
    } else {
      const res: any = await snapshot.getPromise(DeleteFile({
        filename,
        folder: `borrower/${selectedOrder.Id}/signers`,
      }));
      if (res.success) {
        setIsDeleting(false)
        forceUpdate();
        enqueueSnackbar(
          res?.result?.message || 'File Deleted!',
          {
            variant: 'success'
          }
        );
      }
      else {
        setIsDeleting(false)
        setAlert({
          status: 'error',
          message: res?.result?.data?.Error || 'Error occurred'
        });
      }
    }

  })
  const onDeleteAll = async () => {
    Delete({ filename: '', deleteAll: true })
  }
  const confirmMerge = () => {
    if (signerConfirmation) {
      merge()
    } else {
      setOpenAlert(true)
    }
  }

  const downloadDocuments = useRecoilCallback(({ snapshot }) => async (pathname: any) => {
    setLoadingFile(pathname)
    const folder = pathname?.substring(0, pathname?.lastIndexOf('/'));
    const filename = pathname?.substring(pathname?.lastIndexOf('/') + 1, pathname?.length);

    const res: any = await snapshot.getPromise(FetchFile({
      folder,
      filename
    }));
    if (res.success) {
      const fileURL = URL.createObjectURL(res.result);
      const tempLink = document.createElement('a');
      tempLink.href = fileURL;
      tempLink.setAttribute('download', `${filename}`);
      tempLink.click();
      setLoadingFile(null)
    }
    else {
      setLoadingFile(null)
      setAlert({
        status: 'error',
        message: res?.result?.data?.Error || 'Error occurred while loading file'
      });
    }
  })

  return (
    <SnackbarProvider>
      <>
        <GeneralModal open={openConfirmAlert} openModal={setOpenAlert} submitLabel='Ok' handleSubmit={merge} >
          <DialogContentText>Are you sure you want to exclude the signer confirmation</DialogContentText>
        </GeneralModal>
        <Grid container spacing={2}>
          {alert &&
            <Box className={displayClasses.w100} my={3}>
              <Alert severity={alert.status}>{alert.message}</Alert>
            </Box>
          }
          <Grid item xs={6} container direction='column' className={classes.section}>
            <Typography variant='h5'>
              Documents - Total Pages {totalPageCount}
            </Typography>
            <List className={classes.list}>
              <ListItem>
                <Button
                  disabled={isMoving || completedStatuses.includes(selectedOrder?.f_status_web) || props.isViewOnlyMode}
                  onClick={move}
                  variant='contained'
                  color='primary'
                >
                  {isMoving && <><CircularProgress size={25}></CircularProgress> &nbsp;</>}
                  Move All
                </Button>
              </ListItem>
              <ListItem>
                <Grid container>
                  <Grid item container xs={9}>
                    <Checkbox
                      checked={selectedDocs.length && selectedDocs.length === files.length}
                      onChange={onAllSelect}
                    />

                    <ListItemText
                      className={paddingClasses.pt1}
                      primary={
                        selectedDocs.length === files.length ? 'Deselect All' : 'Select All'
                      }
                      color='primary'
                    >
                    </ListItemText>
                  </Grid>
                  <Grid item justifyContent='flex-end'>
                    <Button
                      disabled={!selectedDocs.length || isMerging || completedStatuses.includes(selectedOrder?.f_status_web) || props.isViewOnlyMode}
                      onClick={confirmMerge}
                      variant='contained'
                      color='primary'
                    >
                      {isMerging && <><CircularProgress size={25}></CircularProgress> &nbsp;</>}
                      Merge
                    </Button>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem>
                <Checkbox
                  checked={signerConfirmation}
                  onChange={e => setSignerConfirmation(e.target.checked)}
                />
                <ListItemText primary='Signer Confirmation' color='primary'></ListItemText>
              </ListItem>
              {uploadedFiles.state === 'loading' && (
                <ListItem className={paddingClasses.pl1}>
                  <CircularProgress />
                </ListItem>
              )}
              {uploadedFiles.state !== 'loading' && files?.length === 0 && (
                <ListItem className={paddingClasses.pl1}>
                  No documents to show
                </ListItem>
              )}
              {files.map(doc => (
                <ListItem>
                  <Checkbox
                    checked={selectedDocs.filter(selectedDoc => selectedDoc.name === doc.name).length > 0}
                    onChange={e => onCheckBoxChange(e.target.checked, doc.name, doc.documentID)} />
                  <ListItemIcon>
                    <TextField onChange={e => setSortingIndexes({ ...sortingIndexes, [doc.name]: e.target.value })} label='sort no.' className={classes.input} type='number' variant='outlined' size='small' />
                  </ListItemIcon>
                  <ListItemText
                    onClick={e => downloadDocuments(doc.path)}
                    className={clsx(paddingClasses.pl1, loadingFile === doc.path ? '' : classes.listItemText)}
                  >
                    <Tooltip title={doc.name}>
                      <Typography noWrap={true} display='block'>
                        {doc.name}
                      </Typography>
                    </Tooltip>
                  </ListItemText>
                  {loadingFile === doc.path && (<><CircularProgress size={20} /> &nbsp; &nbsp;</>)}
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={6} container direction='column'>
            <Typography variant='h5'>
              Merged Documents - Total Pages {totalMergedPageCount}
            </Typography>
            <List>
              {mergedFilesData.state !== 'loading' && mergedFiles?.length === 0 && (
                <ListItem className={paddingClasses.pl1}>
                  No documents to show
                </ListItem >
              )}
              {mergedFilesData.state === 'loading' && (
                <ListItem className={paddingClasses.pl1}>
                  <CircularProgress />
                </ListItem>
              )}
              {mergedFiles.length ? (
                <>
                  <ListItem >
                    <Grid container justifyContent='flex-end'>
                      <Button disabled={isDeleting === 'all' || props.isViewOnlyMode} size='small' variant='contained' onClick={onDeleteAll}>
                        {isDeleting === 'all' && (<><CircularProgress size={20} /> &nbsp; &nbsp;</>)}
                        Delete All
                      </Button>
                    </Grid>
                  </ListItem>


                  {mergedFiles.map(doc => (
                    <ListItem>
                      <ListItemText
                        onClick={e => downloadDocuments(doc.path)}
                        className={clsx(paddingClasses.pl1, loadingFile === doc.path ? '' : classes.listItemText)}
                      >
                        <Typography>{doc.name}</Typography>
                        <Typography variant='caption'>
                          {`Merged By: ${doc.createdBy.split(' ')[0][0]}${doc.createdBy.split(' ')[1][0]} | Merged On: ${moment(doc.created).format('MM/DD/YYYY hh:mm A')} | ${doc?.viewed?.length > 0 ? `Viewed By: ${doc?.viewed[0]?.signer?.Signer_First} ${doc?.viewed[0]?.signer?.Signer_Last} | Viewed On: ${moment(doc?.viewed[0]?.date_time).format('MM/DD/YYYY hh:mm A')}` : 'Viewed: No'}`}
                        </Typography>
                      </ListItemText>
                      {loadingFile === doc.path && (<><CircularProgress size={20} /> &nbsp; &nbsp;</>)}
                      <Button
                        disabled={isDeleting === doc.name || props.isViewOnlyMode}
                        size='small'
                        onClick={e => Delete({ filename: doc.name, deleteAll: false })}
                        variant='contained'
                      >
                        {isDeleting === doc.name && (<><CircularProgress size={20} /> &nbsp; &nbsp;</>)}  Delete
                      </Button>
                    </ListItem>
                  ))}</>
              ) : null}
            </List>
          </Grid>
        </Grid>
      </>
    </SnackbarProvider>

  )
}

export default (MergeDocs)

