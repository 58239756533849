import React from 'react';
import { Grid, Typography, FormControlLabel, Radio } from '@material-ui/core';

const ScanBacks = ({ isViewOnlyMode, location, values, handleChange }) => {
  return (
    <>
      <Grid item md={2}>
        <Typography variant='body2'>Scan Backs</Typography>
      </Grid>
      <Grid item md={2}>
        <FormControlLabel
          label='Yes'
          control={
            <Radio
              disabled={isViewOnlyMode}
              name={`${location}.fax_back`}
              value='Y'
              checked={values.fax_back === 'Y'}
              onChange={handleChange}
            />
          }
        />
      </Grid>
      <Grid item md={2}>
        <FormControlLabel
          label='No'
          control={
            <Radio
              disabled={isViewOnlyMode}
              name={`${location}.fax_back`}
              value='N'
              checked={values.fax_back === 'N'}
              onChange={handleChange}
            />
          }
        />
      </Grid>
    </>
  );
}

export { ScanBacks };
