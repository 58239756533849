import React,{useState} from 'react';
import { useRecoilState } from 'recoil';
import { Box, IconButton, Tooltip, Typography } from '@material-ui/core';
import { Eye } from 'react-feather';

import { DailyPayBillPagination } from '../../../Recoil/Atoms/Accounting'

import { Table } from '../../Table';
import { SignerInfo } from '../SignerInfo';
import { useStyles } from '../Styles/style';

const DailyPayBillData = ({
    rows,
    total,
    loading,
}) => {
    const classes = useStyles();

    const [pageInfo, setPageInfo] = useRecoilState(DailyPayBillPagination);
    const [signerInfoModal, handleSignerInfoModal] = useState(null);

    const actionsFormatter = params => {
        return (
            <Box display='flex'>
                <Tooltip title='View'>
                    <IconButton size='small' color='primary' onClick={() => { }}>
                        <Eye width='25' height='25' />
                    </IconButton>
                </Tooltip>
            </Box>
        );
    }

    const columns = [{
        field: 'w_Loan_Number',
        headerName: 'Loan/Order #',
        headerClassName: 'datagrid-header',
        cellClassName: 'datagrid-cell-custom',
        disableColumnMenu: true,
    }, {
        field: 'ref_no',
        headerName: 'Ref #',
        headerClassName: 'datagrid-header',
        cellClassName: 'datagrid-cell-custom',
        disableColumnMenu: true,
    }, {
        field: 'contact',
        headerName: 'Contact',
        headerClassName: 'datagrid-header',
        cellClassName: 'datagrid-cell-custom',
        disableColumnMenu: true,
        renderCell: ({ row }) => (
            <Tooltip title={`${row?.contact?.Contact_First_Name} ${row?.contact?.Contact_Last_Name}` || ''}>
              <Typography>{`${row?.contact?.Contact_First_Name} ${row?.contact?.Contact_Last_Name}`  || ''}</Typography>
            </Tooltip>
          )
    }, {
        field: 'signer',
        headerName: 'Signer',
        headerClassName: 'datagrid-header',
        cellClassName: 'datagrid-cell-custom',
        disableColumnMenu: true,
        renderCell: ({ row }) => (
            <Tooltip title={`${row?.signer?.Signer_First} ${row?.signer?.Signer_Last}` || ''}>
              <Typography
                className={classes.cursorPointer}
                color='primary'
                variant='body1'
                variantMapping={{ body1: 'p' }}
                key={row.f_signer_name}
                onClick={() => handleSignerInfoModal(
                  { ...row?.signer, w_Loan_Number: row?.f_loan_number }
                    || { w_Loan_Number: row?.w_Loan_Number })
                }
              >{`${row?.signer?.Signer_First} ${row?.signer?.Signer_Last}`  || ''}</Typography>
            </Tooltip>
          )
    }, {
        field: 'w_Appointment_Date',
        headerName: 'Appointment Date',
        headerClassName: 'datagrid-header',
        cellClassName: 'datagrid-cell-custom',
        disableColumnMenu: true,
    },{
        field: 'qb_status_date_time',
        headerName: 'Bill Created',
        headerClassName: 'datagrid-header',
        cellClassName: 'datagrid-cell-custom',
        disableColumnMenu: true,
    }, {
        field: 'f_status_web',
        headerName: 'Status',
        headerClassName: 'datagrid-header',
        cellClassName: 'datagrid-cell-custom',
        disableColumnMenu: true,
    }, {
        field: 'payment_status',
        headerName: 'Payment Status',
        headerClassName: 'datagrid-header',
        cellClassName: 'datagrid-cell-custom',
        disableColumnMenu: true,
    }, {
        field: 'action',
        headerName: 'Action',
        sortable: false,
        disableColumnMenu: true,
        renderCell: actionsFormatter,
        headerClassName: 'datagrid-header',
        cellClassName: 'datagrid-cell-custom',
    }];

    const handlePage = (e: unknown, newPage: number) => {
        setPageInfo({
            ...pageInfo,
            page: newPage
        });
    };

    const handleRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPageInfo({
            ...pageInfo,
            limit: parseInt(e.target.value, 10)
        });
    };


    return (
    <>
        <Table
            useCustomStyles={false}
            customRowHeight={85}
            loading={loading}
            columns={columns}
            rows={rows}
            total={total}
            pageInfo={pageInfo}
            handlePage={handlePage}
            handleRowsPerPage={handleRowsPerPage}
        />
        <SignerInfo
        signerInfoModal={signerInfoModal}
        handleSignerInfoModal={handleSignerInfoModal}/>
    </>
    );
}

export { DailyPayBillData };
