import { atom } from 'recoil';

const roleResourceGroups = atom({
    key: 'roleGroupResources',
    default: [
      {
        id: 1,
        name: 'Employee'
      },
      {
        id: 2,
        name: 'Admin'
      },
      {
        id: 3,
        name: 'Management'
      }
    ]
  });

const rolepagination = atom({
    key: 'rolepagination',
    default: {
      page: 1,
      limit: 20,
      orderBy: 'name',
      orderType: 'ASC',
      search: ''
    }
  });

  const refreshRoles = atom({
    key: 'refreshRoles',
    default: 0
  });

  export { roleResourceGroups, rolepagination,refreshRoles };