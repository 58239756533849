import React from 'react';
import { Typography } from '@material-ui/core';

import { Page } from '../../Components/Page';
import { SignerCommunications as CommunicationData } from '../../Components/Signers/SignersCommunication';

import { useStyles } from '.././Styles/Page';

export const SignerCommunications = () => {
    const classes = useStyles();
    return (
        <Page className={classes.paper} title='Signers Communications'>
            <Typography variant='h3'>Signer Communications</Typography>
            <CommunicationData />
        </Page>
    );
}
