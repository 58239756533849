import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    select: {
      width: 150
    },
    cursorPointer: {
      cursor: 'pointer'
    },
    rgtMargin: {
      marginRight: '10px',
    },
    flexColumn: {
      display: 'flex',
      flexDirection: 'column'
    }
  })
);

export { useStyles };
