import React from 'react'
import { Box, Typography, Grid, CircularProgress, SvgIcon, Paper } from '@material-ui/core';
import { Trash } from 'react-feather';
import moment from 'moment';

import { useStyles } from './Style/Style';

const FileList = ({ uploadedFiles, total, files, getFile, removeFile, title = 'List of Files', scanback = false, nofiles = 'NO FILE HAS BEEN UPLOADED YET' }) => {
  const classes = useStyles();
  return (
      <Grid direction='column' container classes={{ root: classes.fileListWrapper }}>
        <Paper className={classes.paper}>
        { uploadedFiles.state === 'loading' ?
          <Box my={2} display='flex' justifyContent='center'>
            <CircularProgress />
          </Box> :
          total === 0 && uploadedFiles.state !== 'loading' ?
            <Typography variant='h4' align='center'>{nofiles.toUpperCase()}</Typography> :
            <>
              <Typography variant='h4'>{title}</Typography>
              { files.map(file => {
                const time = file.created ? moment(new Date(file.created)).format('MM/DD/YYYY hh:mm A') : '';
                return (
                  <Box
                    className={classes.fileContainer}
                    key={file.created}
                    display='grid'
                    gridAutoFlow='column'
                    gridTemplateColumns={'1fr 1fr 1fr'}
                    px={2}
                    py={1}
                    mt={2}
                  >
                    <Typography
                      className={classes.fileLabel}
                      color='primary'
                      variant='body2'
                      onClick={() => getFile(file.name)}
                    >
                      {file.name}
                    </Typography>
                    <Typography variant='caption'>Uploaded By: { scanback ? 'Signer' : file.createdBy}</Typography>
                    <Typography variant='caption'>Uploaded On: {time}</Typography>
                    {
                      removeFile && 
                      <SvgIcon
                        className={classes.deleteIcon}
                        fontSize='small'
                        color='primary'
                        onClick={() => removeFile(file.name)}
                      >
                        <Trash />
                      </SvgIcon>
                    }
                  </Box>
                )
              })}
            </>
        }
        </Paper>
      </Grid>
  )
}

export { FileList };
