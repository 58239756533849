import React from 'react';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { Box, Tooltip, IconButton, SvgIcon } from '@material-ui/core';
import { Edit, Columns, User } from 'react-feather';

import { pagination } from '../../Recoil/Atoms/Clients';

import { Table } from '../Table';

const ClientsData = (props) => {
  const [pageInfo, setPageInfo] = useRecoilState(pagination);
  const { orderType, orderBy } = pageInfo
  const actionsFormatter = (params) => {
    return (
      <Box display='flex'>
        <Tooltip title='Edit'>
          <IconButton color='inherit' onClick={() => props.handleEdit(params.row)}>
            <SvgIcon fontSize='small' color='primary'>
              <Edit />
            </SvgIcon>
          </IconButton>
        </Tooltip>
        <Tooltip title='Divisions'>
          <IconButton
            color='inherit'
            component={Link}
            to={`/client/${params.row.id}/${params.row.clientName}/divisions`}
          >
            <SvgIcon fontSize='small' color='primary'>
              <Columns />
            </SvgIcon>
          </IconButton>
        </Tooltip>
        <Tooltip title='Contacts'>
          <IconButton
            color='inherit'
            component={Link}
            to={`/client/${params.row.id}/contacts`}
          >
            <SvgIcon fontSize='small' color='primary'>
              <User />
            </SvgIcon>
          </IconButton>
        </Tooltip>
      </Box>
    );
  }

  const columns = [{
    field: 'clientID',
    headerName: 'Client ID'
  }, {
    field: 'clientName',
    headerName: 'Name'
  }, {
    field: 'address',
    headerName: 'Address',
    renderCell: ({ row }) => <div>
      {row?.address}, {row?.city}, {row?.state}, {row?.zip}
    </div>
  }, {
    field: 'status',
    headerName: 'Status'
  }, {
    field: 'actions',
    headerName: 'Actions',
    renderCell: actionsFormatter
  }];

  const handlePage = (e: unknown, newPage: number) => {
    setPageInfo({
      ...pageInfo,
      page: newPage
    });
  };

  const handleRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPageInfo({
      ...pageInfo,
      limit: parseInt(e.target.value, 10)
    });
  };
  const onSortingChange = (e) => {
    if (e.sortModel &&e.sortModel[0].field) {
      const { field, sort } = e.sortModel[0]
      setPageInfo({
        ...pageInfo,
        orderBy: field,
        orderType: sort,
      });
    }
  }
  return (
    <Table
      loading={props.loading}
      columns={columns}
      rows={props.data}
      total={props.total}
      pageInfo={{ page: pageInfo.page, limit: pageInfo.limit }}
      handlePage={handlePage}
      handleRowsPerPage={handleRowsPerPage}
      sortModel={[{
        sort: orderType, field: orderBy
      }]}
      onSortingChange={onSortingChange}
    />
  );
};

export { ClientsData };
