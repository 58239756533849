import React from 'react';
import { Grid, TextField} from '@material-ui/core';

const Fields = ({ values, errors, touched, handleChange, handleBlur }) => {
  return (
    <Grid container spacing={2} alignItems='center'>
      <Grid item xs={12}>
        <TextField
          label='New Password'
          name='new_password'
          variant='outlined'
          type='password'
          size='small'
          fullWidth
          value={values.new_password}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.new_password && errors.new_password}
          error={Boolean(touched.new_password && errors.new_password)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label='Confirm Password'
          name='confirm_password'
          variant='outlined'
          type='password'
          size='small'
          fullWidth
          value={values.confirm_password}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.confirm_password && errors.confirm_password}
          error={Boolean(touched.confirm_password && errors.confirm_password)}
          />
      </Grid>
    </Grid>
  );
}

export { Fields };
