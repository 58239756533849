import React from 'react';
import { Grid } from '@material-ui/core';

import { OutlinedSection } from '../Section';

import { Divisions } from './Fields/Divisions';
import { BillingSpecificEmail } from './Fields/BillingSpecificEmail';
import { NotificationSpecificEmail } from './Fields/NotificationSpecificEmail';
import { Status } from './Fields/Status';
import { BillingCycle } from './Fields/BillingCycle';
import { BillingEmail } from './Fields/BillingEmail';
import { NotificationEmail } from './Fields/NotificationEmail';
import { Method } from './Fields/Method';
import { DefaultDocs } from './Fields/DefaultDocs';
import { ClientName } from './Fields/ClientName';

import { Fee } from './Fields/Fee';
import { RelatedContacts } from './Fields/RelatedContacts';
import { AttorneyStates } from './Fields/AttorneyStates';

import { displayStyles } from '../../Common/Styles/Display';
import { GeneralInstructions } from './Fields/GeneralInstructions';

const DivisionForm = (props) => {
  const displayClasses = displayStyles();
  const { values, errors, touched, handleChange, handleBlur, setFieldValue, clientID, isEditMode, clientName } = props;
  
  return (
    <Grid className={displayClasses.gridRoot} container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems='center'>
          <ClientName clientName={clientName} />
          <Divisions
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          {isEditMode && (
            <Status
              values={values}
              handleChange={handleChange}
            />
          )}

          <BillingCycle
            values={values}
            handleChange={handleChange}
          />
          <BillingEmail
            values={values}
            handleChange={handleChange}
          />
          {
            values?.billing_email_type === 'SE' &&
            <BillingSpecificEmail
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          }
          <NotificationEmail
            values={values}
            handleChange={handleChange}
          />
          {
            values?.notif_email_type === 'SE' &&
            <NotificationSpecificEmail
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          }
          <Method
            values={values}
            handleChange={handleChange}
          />
          <DefaultDocs
            values={values}
            handleChange={handleChange}
          />
        </Grid>
        <br></br>
        <OutlinedSection title='Division General Instructions'>
          <GeneralInstructions
            values={values}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            clientID={clientID}
          >
          </GeneralInstructions>
        </OutlinedSection>
        <OutlinedSection title='Fees'>
          <Grid container spacing={1}>
            <Fee
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
            />
          </Grid>
        </OutlinedSection>
        <OutlinedSection title='Manager'>
          <Grid container spacing={2} alignItems='center'>
            <RelatedContacts
              values={values}
              errors={errors}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              clientContacts={props.clientContacts}
            />
          </Grid>
        </OutlinedSection>
        <OutlinedSection title='Attorney States'>
          <Grid container spacing={2} alignItems='center'>
            <AttorneyStates
              values={values}
              errors={errors}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              states={props.states}
            />
          </Grid>
        </OutlinedSection>
      </Grid>
    </Grid>
  );
}

export { DivisionForm };
