import React from 'react';
import type { FC } from 'react';
import { Box, Card, CardContent, Container, Divider, Typography, SvgIcon } from '@material-ui/core';
import { Lock } from 'react-feather';

import { Page } from '../Components/Page';
import { LoginForm } from '../Components/Login/LoginForm';

import { useStyles } from './Styles/Login';

interface HandleSubmit {
  handleSubmit: (params) => Promise<void>
};

const Login: FC<HandleSubmit> = (props) => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title='Login'>
      <Container className={classes.cardContainer} maxWidth='sm'>
        <Card>
          <CardContent className={classes.cardContent}>
            <div className={classes.currentMethodIcon}>
              <img alt='Auth method' src='/static/Logo.png' />
            </div>
            <Box alignItems='center' display='flex' justifyContent='space-between'>
              <div>
                <Typography color='textPrimary' gutterBottom variant='h2'>
                  Sign in
                </Typography>
                <Typography variant='body2' color='textSecondary'>
                  Enter your registered email address
                </Typography>
              </div>
            </Box>
            <Box mt={3}>
              <LoginForm handleSubmit={props.handleSubmit} />
            </Box>
            <Box my={3}>
              <Divider />
            </Box>
            <Typography variant='body2' color='textSecondary' align='justify'>
              <SvgIcon className={classes.bannerChip} fontSize='small' color='primary'>
                <Lock />
              </SvgIcon>
              PacDocSign uses 128-bit Secure Sockets Layer (SSL) technology. This means that when you are on our secured
              website the data transferred between PacDocSign and you is encrypted and cannot be viewed by any
              other party. The security of your Borrower personal information is of the utmost importance to PacDocSign.
            </Typography>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export { Login };
