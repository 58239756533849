import React from 'react';

import { Table } from '../Table';

const AuditTrailData = () => {
  const columns = [{
    field: 'order_id',
    headerName: 'Order ID'
  }, {
    field: 'table',
    headerName: 'DB Table'
  }, {
    field: 'new_data',
    headerName: 'New Data'
  }, {
    field: 'date',
    headerName: 'Date/Time'
  }, {
    field: 'created_by',
    headerName: 'By Who'
  }, {
    field: 'user_type',
    headerName: 'User Type'
  }, {
    field: 'user_id',
    headerName: 'User ID'
  }, {
    field: 'query_type',
    headerName: 'Query Type'
  }];

  const rows = [
    {
      id: 1,
      order_id: '1',
      table: 'Borrower',
      new_data: 'No',
      date: '01/01/2021',
      created_by: 'John',
      user_type: 'admin',
      user_id: '1',
      query_type: ''
    }
  ];

  return (
    <Table
      columns={columns}
      rows={rows}
    />
  );
};

export { AuditTrailData };
