import { atom } from 'recoil';

const clientInvoices = atom({
  key: 'clientInvoices',
  default: []
});

const clientInvoicesPagination = atom({
  key: 'clientInvoicesPagination',
  default: {
    page: 1,
    limit: 20,
    orderBy: '',
    orderType: '',
  }
});

const clientInvoicesFilters = atom({
  key: 'clientInvoicesFilters',
  default: {
    billing_cycle: null,
  }
});

const refetchClientInvoices = atom({
  key: 'refetchClientInvoices',
  default: 0
});

export {
  clientInvoices,
  clientInvoicesPagination,
  refetchClientInvoices,
  clientInvoicesFilters
};
