import React from 'react';
import { useRecoilValueLoadable } from 'recoil'
import clsx from 'clsx';
import { Formik, Form } from 'formik';
import { Box, Grid, MenuItem, Select, Typography, FormControl, TextField, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';

import { createBillPopup } from './../../../Recoil/Selectors/Orders'
import { GeneralModal } from '../../Modal';
import { marginStyles } from '../../../Common/Styles/Margins';
import { PageLoad } from 'src/Components/LoadingScreen/PageLoad';

import { useStyles } from '../Styles/style';


const CreateBillModel = (props: any) => {
    const {
        createOrderBillCurrentRow,
        handleCreateOrderBillCurrentRow,
        saveBill,
        check
    } = props
    const classes = useStyles();
    const marginClasses = marginStyles();

    const popupRequest: any = useRecoilValueLoadable(createBillPopup)
    const popup = popupRequest?.contents?.result || {}
    const vendor = popup?.vendor?.find((vendor) => vendor.ListID === popup?.signer?.QB_RefListID) || {}
    const initialValues = popupRequest?.contents?.result ? {
        ...popup,
        Id: popup?.Id,
        vendorAddress: `${vendor?.VendorAddress_Addr1}, \n${vendor?.VendorAddress_Addr2}, ${vendor?.VendorAddress_City}, ${vendor?.VendorAddress_State}, ${vendor?.VendorAddress_PostalCode}`,
        account: '4E0000-1013036602',
        vendorId: popup?.vendor?.find((vendor) => vendor.ListID === popup?.signer?.QB_RefListID)?.ListID || {},
        terms: '',
        RefNumber: popup?.billRefNumber || `${String(popup?.w_Borrower_Last_Name?.substring(0, 20 - String(popup?.Id)?.length)).trim()}${popup?.Id}`,
        billDate: moment(popup?.w_Appointment_Date).format('yyyy-MM-DD'),
        dueDate: moment(popup?.w_Appointment_Date).add(21, 'days').format('yyyy-MM-DD'),
        amount: popup?.total,
        memo: 'FULL PAYMENT FOR THE SERVICES RENDERED',
        expenseAccount: '400000-1010093162',
        expenseCost: popup?.total,
        expenseCustomer: popup?.client?.customerListID || '',
        expenseMemo: '',
        expenseClass: ''
    } : {}

    return (
        <GeneralModal
            open={createOrderBillCurrentRow?.id ? true : false}
            title='PacDocSign : Create Bill'
            loading={check}
            openModal={() => handleCreateOrderBillCurrentRow({})}
            noSubmit={true}
            noFooter={false}
            maxWidth='md'
            submitLabel='Create'
        // handleSubmit={saveBill}
        >
            {popupRequest?.state === 'loading' ?
                <PageLoad />
                :
                popupRequest?.contents?.success ?
                    <Formik
                        initialValues={initialValues}
                        onSubmit={saveBill}
                    >
                        {({
                            values,
                            handleChange
                        }: any) => {
                            return (
                                <>
                                    <Form>
                                        <Box display='flex' p={2}>
                                            <Grid container spacing={1}>

                                                <Grid item xs={6}>
                                                    <Box display='flex' className={clsx(marginClasses.mt3, marginClasses.mb1)}>
                                                        <Grid item xs={4}>
                                                            <Typography color='textSecondary' variant='h5' className={marginClasses.mr2}>Loan#</Typography>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <Typography color='textSecondary'>{values?.w_Loan_Number}</Typography>
                                                        </Grid>
                                                    </Box>
                                                    <Box display='flex' className={clsx(marginClasses.mt3, marginClasses.mb1)}>
                                                        <Grid item xs={4}>
                                                            <Typography color='textSecondary' variant='h5' className={marginClasses.mr2}>Signer</Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Typography color='textSecondary'>{`${values?.signer?.Signer_First || ''} ${values?.signer?.Signer_Last || ''}`}</Typography>
                                                        </Grid>
                                                    </Box>
                                                    <Box display='flex' className={clsx(marginClasses.mt3, marginClasses.mb1)}>
                                                        <Grid item xs={4}>
                                                            <Typography color='textSecondary' variant='h5' className={marginClasses.mr1}>Signer Address</Typography>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <Typography color='textSecondary'>
                                                                {`${values?.signer?.address || ''}, ${values?.signer?.city || ''}, ${values?.signer?.state || ''}, ${values?.signer?.zip || ''}`}
                                                            </Typography>
                                                        </Grid>
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <Box display='flex' className={clsx(marginClasses.mt3, marginClasses.mb1)}>
                                                        <Grid item xs={3}>
                                                            <Typography color='textSecondary' variant='h5' className={marginClasses.mr2}>Client</Typography>
                                                        </Grid>
                                                        <Grid item xs={9}>
                                                            <Typography color='textSecondary'>{values?.client?.clientName || ''}</Typography>
                                                        </Grid>
                                                    </Box>
                                                    <Box display='flex' className={clsx(marginClasses.mt3, marginClasses.mb1)}>
                                                        <Grid item xs={3}>
                                                            <Typography color='textSecondary' variant='h5' className={marginClasses.mr2}>Division</Typography>
                                                        </Grid>
                                                        <Grid item xs={9}>
                                                            <Typography color='textSecondary'>{popup?.orderDivision?.divisionName}</Typography>
                                                        </Grid>
                                                    </Box>
                                                    <Box display='flex' className={clsx(marginClasses.mt3, marginClasses.mb1)}>
                                                        <Grid item xs={3}>
                                                            <Typography color='textSecondary' variant='h5' className={marginClasses.mr2}>Contact</Typography>
                                                        </Grid>
                                                        <Grid item xs={9}>
                                                            <Typography color='textSecondary'>{`${values?.contact?.Contact_First_Name || ''} ${values?.contact?.Contact_Last_Name || ''}`}</Typography>
                                                        </Grid>
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Typography color='textSecondary' variant='h5' className={marginClasses.mt1}>Billing Note</Typography>
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <Box display='flex' className={clsx(marginClasses.mt3, marginClasses.mb1)}>
                                                        <Grid item xs={3}>
                                                            <Typography color='textSecondary'
                                                                variant='h5'
                                                                className={clsx(marginClasses.mt2, marginClasses.mr2)}
                                                            >
                                                                <b>Accounts </b>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <FormControl
                                                                className={clsx(
                                                                    marginClasses.mt0,
                                                                    marginClasses.mb0,
                                                                    marginClasses.mr1,
                                                                    classes.select
                                                                )}
                                                                margin='dense'
                                                                variant='outlined'
                                                                fullWidth
                                                            >
                                                                <Select name='accountRef'
                                                                    defaultValue={'4E0000-1013036602'}
                                                                    onChange={handleChange}
                                                                >
                                                                    {values?.accounts && values?.accounts?.map((account) =>
                                                                        <MenuItem value={account?.ListID}>{account?.FullName}</MenuItem>
                                                                    )}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                    </Box>
                                                    <Box display='flex' className={clsx(marginClasses.mt3, marginClasses.mb1)}>
                                                        <Grid item xs={3}>
                                                            <Typography color='textSecondary'
                                                                variant='h5'
                                                                className={clsx(marginClasses.mt2, marginClasses.mr2)}
                                                            >
                                                                <b>Vendor</b>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <FormControl
                                                                className={clsx(
                                                                    marginClasses.mt0,
                                                                    marginClasses.mb0,
                                                                    marginClasses.mr1,
                                                                    classes.select
                                                                )}
                                                                margin='dense'
                                                                variant='outlined'
                                                                fullWidth
                                                            >
                                                                <Select
                                                                    defaultValue={values?.signer?.QB_RefListID}
                                                                    name='vendorId'
                                                                    onChange={handleChange}
                                                                >
                                                                    {values?.vendor && values?.vendor?.map(vendor =>
                                                                        <MenuItem value={vendor?.ListID}>{vendor?.Name}</MenuItem>
                                                                    )}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                    </Box>
                                                    <Box display='flex' className={clsx(marginClasses.mt3, marginClasses.mb1)}>
                                                        <Grid item xs={3}>
                                                            <Typography color='textSecondary'
                                                                variant='h5'
                                                                className={clsx(marginClasses.mt2, marginClasses.mr2)}
                                                            >
                                                                <b>Address</b>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                placeholder='Address'
                                                                multiline
                                                                variant='outlined'
                                                                size='small'
                                                                fullWidth
                                                                rows={5}
                                                                disabled
                                                                value={values?.vendorAddress}
                                                                name='vendorAddress'
                                                            />
                                                        </Grid>
                                                    </Box>
                                                    <Box display='flex' className={clsx(marginClasses.mt3, marginClasses.mb1)}>
                                                        <Grid item xs={3}>
                                                            <Typography color='textSecondary'
                                                                variant='h5'
                                                                className={clsx(marginClasses.mt2, marginClasses.mr2)}
                                                            >
                                                                <b>Bill Due</b>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                id='date'
                                                                type='date'
                                                                className={clsx(
                                                                    marginClasses.mt0,
                                                                    marginClasses.mb0,
                                                                    marginClasses.mr1,
                                                                    classes.select
                                                                )}
                                                                variant='outlined'
                                                                size='small'
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    shrink: true
                                                                }}
                                                                defaultValue={values?.dueDate}
                                                                name='dueDate'
                                                                onChange={handleChange}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                    <Box display='flex' className={clsx(marginClasses.mt3, marginClasses.mb1)}>
                                                        <Grid item xs={3}>
                                                            <Typography color='textSecondary'
                                                                variant='h5'
                                                                className={clsx(marginClasses.mt2, marginClasses.mr2)}
                                                            >
                                                                <b>Memo</b>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                placeholder='Memo'
                                                                variant='outlined'
                                                                size='small'
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    shrink: true
                                                                }}
                                                                defaultValue={'FULL PAYMENT FOR THE SERVICES RENDERED'}
                                                                name='memo'
                                                                onChange={handleChange}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <Box display='flex' className={clsx(marginClasses.mt3, marginClasses.mb1)}>
                                                        <Grid item xs={3}>
                                                            <Typography color='textSecondary'
                                                                variant='h5'
                                                                className={clsx(marginClasses.mt2, marginClasses.mr2)}
                                                            >
                                                                <b>Ref. Number </b>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                placeholder='Ref. Number'
                                                                variant='outlined'
                                                                size='small'
                                                                fullWidth
                                                                name='billRefNumber'
                                                                disabled
                                                                value={values?.billRefNumber || `${String(values?.w_Borrower_Last_Name?.substring(0, 20 - String(values?.Id)?.length)).trim()}${values?.Id}`}
                                                                onChange={handleChange}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                    <Box display='flex' className={clsx(marginClasses.mt3, marginClasses.mb1)}>
                                                        <Grid item xs={3}>
                                                            <Typography color='textSecondary'
                                                                variant='h5'
                                                                className={clsx(marginClasses.mt2, marginClasses.mr2)}
                                                            >
                                                                <b>Date</b>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                id='date'
                                                                type='date'
                                                                className={clsx(
                                                                    marginClasses.mt0,
                                                                    marginClasses.mb0,
                                                                    marginClasses.mr1,
                                                                    classes.select
                                                                )}
                                                                variant='outlined'
                                                                size='small'
                                                                fullWidth
                                                                value={values?.billDate}
                                                                name='billDate'
                                                                onChange={handleChange}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                    <Box display='flex' className={clsx(marginClasses.mt3, marginClasses.mb1)}>
                                                        <Grid item xs={3}>
                                                            <Typography color='textSecondary'
                                                                variant='h5'
                                                                className={clsx(marginClasses.mt2, marginClasses.mr2)}
                                                            >
                                                                <b>Amount Due</b>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                placeholder='Amount Due'
                                                                variant='outlined'
                                                                size='small'
                                                                fullWidth
                                                                value={values?.expenseCost}
                                                                name='expenseCost'
                                                                onChange={handleChange}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                    <Box display='flex' className={clsx(marginClasses.mt3, marginClasses.mb1)}>
                                                        <Grid item xs={3}>
                                                            <Typography color='textSecondary'
                                                                variant='h5'
                                                                className={clsx(marginClasses.mt2, marginClasses.mr2)}
                                                            >
                                                                <b>Terms</b>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <FormControl
                                                                className={clsx(
                                                                    marginClasses.mt0,
                                                                    marginClasses.mb0,
                                                                    marginClasses.mr1,
                                                                    classes.select
                                                                )}
                                                                margin='dense'
                                                                variant='outlined'
                                                                fullWidth
                                                            >
                                                                <Select name={'terms'} onChange={handleChange} defaultValue='40000-1009993724'>
                                                                    {popup?.terms && popup?.terms.map(term =>
                                                                        <MenuItem value={term?.ListID || ''}>{term?.FullName || ''}</MenuItem>
                                                                    )}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Box display='flex' justifyContent='space-between' className={clsx(marginClasses.mt3, marginClasses.mb1)}>
                                                        <Grid item xs={2}>
                                                            <Typography color='textSecondary'
                                                                variant='h5'
                                                                className={clsx(marginClasses.mt2)}
                                                            >
                                                                <b>Line Expense</b>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <FormControl
                                                                className={clsx(
                                                                    marginClasses.mt0,
                                                                    marginClasses.mb0,
                                                                    marginClasses.mr1,
                                                                )}
                                                                margin='dense'
                                                                variant='outlined'
                                                                fullWidth
                                                            >
                                                                <Select name='expenseAccount' onChange={handleChange} defaultValue={values?.expenseAccount}>
                                                                    {values?.accounts && values?.accounts.map(account =>
                                                                        <MenuItem value={account?.ListID || ''}>{account?.FullName || ''}</MenuItem>
                                                                    )}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <TextField
                                                                placeholder=''
                                                                variant='outlined'
                                                                size='small'
                                                                InputLabelProps={{
                                                                    shrink: true
                                                                }}
                                                                value={values?.amount}
                                                                name='amount'
                                                                onChange={handleChange}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <TextField
                                                                placeholder=''
                                                                variant='outlined'
                                                                size='small'
                                                                InputLabelProps={{
                                                                    shrink: true
                                                                }}
                                                                value={`${values?.w_Borrower_Last_Name}, ${values?.w_Borrower_First_Name}`}
                                                                name='billRefNumber2'
                                                                onChange={handleChange}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <FormControl
                                                                className={clsx(
                                                                    marginClasses.mt0,
                                                                    marginClasses.mb0,
                                                                    marginClasses.mr1,
                                                                )}
                                                                margin='dense'
                                                                variant='outlined'
                                                                fullWidth
                                                            >
                                                                <Select
                                                                    defaultValue={values?.client?.clientName}
                                                                    name='clientName2'
                                                                    disabled
                                                                >
                                                                    <MenuItem value={values?.client?.clientName}>{values?.client?.clientName}</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <FormControl
                                                                className={clsx(
                                                                    marginClasses.mt0,
                                                                    marginClasses.mb0,
                                                                    marginClasses.mr1,
                                                                )}
                                                                margin='dense'
                                                                variant='outlined'
                                                                fullWidth
                                                            >
                                                                <Select disabled value={`${values?.matchedBy?.firstName[0] || ''}${values?.matchedBy?.lastName[0] || ''}`}>
                                                                    <MenuItem value={`${values?.matchedBy?.firstName[0] || ''}${values?.matchedBy?.lastName[0] || ''}`}>
                                                                        {`${values?.matchedBy?.firstName[0] || ''}${values?.matchedBy?.lastName[0] || ''}`}
                                                                    </MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Form>
                                    <Grid container xs={12}>
                                        <Grid item xs={12} md={3} className={clsx(marginClasses.ma5)}>
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                onClick={() => saveBill(values)}
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>

                                </>
                            )
                        }}
                    </Formik>
                    :
                    <Alert severity='error'>{popupRequest?.contents?.result?.data?.message}</Alert>
            }
        </GeneralModal>

    )
};

export { CreateBillModel };
