import React from 'react';
import { useRecoilCallback } from 'recoil';
import { Typography, Box, Divider } from '@material-ui/core';

import { Page } from '../../Components/Page';
import { FormikComponent } from '../../Components/Formik';
import { SettingFields } from '../../Components/Admin/SettingFields';

import { initialValues, validationSchema } from '../../Common/Rules/AdminSettings';

import { useStyles } from '.././Styles/Page';

export const Settings = () => {
  const classes = useStyles();

  const onSubmit = useRecoilCallback(({ snapshot }) => async (values: any, { setSubmitting }) => {
    setSubmitting(false);
  });

  return (
    <Page className={classes.paper} title='Settings'>
      <Typography variant='h3'>Settings</Typography>
      <Box my={2}>
        <Divider variant='fullWidth' />
      </Box>
      <FormikComponent
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        <SettingFields />
      </FormikComponent>
    </Page>
  );
}
