import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { createMemoryHistory } from 'history';
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';

import RenderRoutes from './Routes';

import GlobalStyles from './ThemeComponents/components/GlobalStyles';
// import SettingsNotification from './ThemeComponents/components/SettingsNotification';
import useSettings from './ThemeComponents/hooks/useSettings';
import { createTheme } from './ThemeComponents/theme';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createMemoryHistory();

const App = () => {
  const { settings } = useSettings();

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider dense maxSnack={3}>
            <Router location={history.location} navigator={history}>
              <GlobalStyles />
              {/* <SettingsNotification /> */}
              <RenderRoutes />
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
};

export { App };
