import { selectorFamily, selector } from 'recoil';

import { verifyAuthToken } from './Auth'
import { pagination, refreshDivisions, loadDivision } from '../Atoms/Divisions';
import { selectedClient } from '../Atoms/Clients';
import { orderFilters, orderRangeDetailPagination } from '../Atoms/Orders';
import { rangeReportsPagination } from '../Atoms/Reports';


import { api } from '../../Services/api';

const getDivisions = selector({
  key: 'getDivisions',
  get: async ({ get }) => {
    try {
      const { client }: any = get(orderFilters)

      if (!client) {
        return {
          result: [],
          success: true
        }
      }

      const params = { filter: `clientID:${client}` }
      await verifyAuthToken()
      const result: any = await api.GET('client/division', { params });

      return {
        result,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
})


const getOrderRangeDetailDivisions = selector({
  key: 'getOrderRangeDetailDivisions',
  get: async ({ get }) => {
    try {
      const { client }: any = get(orderRangeDetailPagination)

      if (!client) {
        return {
          result: [],
          success: true
        }
      }

      const params = { filter: `clientID:${client}` }
      await verifyAuthToken()
      const result: any = await api.GET('client/division', { params });

      return {
        result,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
})



const getDivisionsByClientForRangeReport = selector({
  key: 'getDivisionsByClientForRangeReport',
  get: async ({ get }) => {
    try {
      const { clientID }: any = get(rangeReportsPagination)

      if (!clientID) {
        return {
          result: [],
          success: true
        }
      }

      const params = { filter: `clientID:${clientID}` }
      await verifyAuthToken()
      const result: any = await api.GET('client/division', { params });

      return {
        result,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
})


const getAllDivisions = selector({
  key: 'getAllDivisions',
  get: async ({ get }) => {
    try {
      await verifyAuthToken()
      const result: any = await api.GET('client/division');
      return {
        result,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
})

const getDivision = selectorFamily({
  key: 'getDivision',
  get: (params) => async ({ get }) => {
    const id = get(loadDivision);
    if (id !== -1) {
      try {
        await verifyAuthToken()
        const res: any = await api.GET(`client/division/${id}`);
        return {
          result: res,
          success: true
        };
      } catch (err) {
        return {
          result: err,
          success: false
        };
      }
    }
  }
});

const getDivisionsByClient = selectorFamily({
  key: 'getDivisionsByClient',
  get: (id: number) => async ({ get }) => {
    const { limit, page } = get(pagination);
    get(refreshDivisions);

    try {
      await verifyAuthToken()
      const res: any = await api.GET(`client/division/client/${id}?limit=${limit}&page=${page}`);
      const result = res.rows.map(r => {
        return {
          ...r,
          id: r.divisionID,
          divisionStatus: r.status === 'A' ? 'Active' : 'Inactive'
        }
      });
      return {
        result: {
          rows: result,
          count: res.count
        },
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const createDivision = selectorFamily({
  key: 'createDivision',
  get: (params: any) => async () => {
    try {
      await verifyAuthToken()
      const res: any = await api.POST('client/division', params);

      return {
        result: res,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const updateDivision = selectorFamily({
  key: 'updateDivision',
  get: (params: any) => async () => {
    try {
      await verifyAuthToken()
      const res: any = await api.PUT('client/division', params);

      return {
        result: res,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const getContactsByClient = selectorFamily({
  key: 'getContactsByClient',
  get: (id: number) => async () => {
    try {
      await verifyAuthToken()
      const res: any = await api.GET(`client/contacts/${id}`);

      return {
        result: res,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const getStates = selector({
  key: 'getStates',
  get: async () => {
    try {
      await verifyAuthToken()
      const res: any = await api.GET('client/states/');

      return {
        result: res,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});


const getAllDivisionByClient = selector({
  key: 'getAllDivisionByClient',
  get: async ({ get }) => {
    const id = get(selectedClient);
    if (id) {
      try {
        await verifyAuthToken()
        const res: any = await api.GET(`client/division?filter=clientID:${id}`);
        return {
          result: res.rows,
          success: true
        };
      } catch (err) {
        return {
          result: [],
          success: false
        };
      }
    }
  }
})

export {
  getDivision,
  getDivisions,
  getDivisionsByClient,
  createDivision,
  updateDivision,
  getContactsByClient,
  getStates,
  getAllDivisionByClient,
  getAllDivisions,
  getDivisionsByClientForRangeReport,
  getOrderRangeDetailDivisions
};
