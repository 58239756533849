import React from 'react';
import { Grid, TextField } from '@material-ui/core';

const UserPhoneNo = ({ values, errors, touched, handleChange, handleBlur }) => {
  return (
    <Grid container xs={12}>
      <TextField
        label='Phone No'
        name='phone_no'
        variant='outlined'
        size='small'
        fullWidth
        value={values.phone_no}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.phone_no && errors.phone_no}
        error={Boolean(touched.phone_no && errors.phone_no)}
      />
    </Grid>
  );
};

export { UserPhoneNo };
