import React from 'react';
import { Typography, Box, Divider } from '@material-ui/core';

import {
  useRecoilValueLoadable,
  useRecoilState
} from 'recoil';

import { Page } from '../../Components/Page';
import { UnbilledInvoicesData } from 'src/Components/Accounting/UnbilledInvoicesData';
import { UnbilledInvoicesActions } from 'src/Components/Accounting/UnbilledInvoicesActions';
import { getUnbilledInvoices } from 'src/Recoil/Selectors/Accounting';
import { unbilledInvoicesPagination } from '../../Recoil/Atoms/Accounting';


import { useStyles } from '.././Styles/Page';

export const UnbilledInvoices = () => {
  const classes = useStyles();

  const [pageInfo, setPageInfo] = useRecoilState(unbilledInvoicesPagination);
  const allUnbilledInvoices: any = useRecoilValueLoadable(getUnbilledInvoices);
  const unbilledInvoicesData = allUnbilledInvoices?.contents?.result?.rows || [];
  const total = allUnbilledInvoices?.contents?.result?.count || 0;

  return (
    <Page className={classes.paper} title='Unbilled Invoices'>
      <Typography variant='h3'>Unbilled Invoices</Typography>
      <Box my={2}>
        <Divider variant='fullWidth' />
      </Box>
      <UnbilledInvoicesActions />
      <UnbilledInvoicesData
         unbilledInvoices={unbilledInvoicesData}
         loading={total === 0 && allUnbilledInvoices?.state === 'loading'}
         total={total}
         pageInfo={pageInfo}
         setPageInfo={setPageInfo}
      />
    </Page>
  );
};
