import React from 'react';
import { Grid, TextField } from '@material-ui/core';

const ManagerName = ({ values, errors, touched, handleChange, handleBlur }) => {
  return (
    <Grid item xs={12} md={12}>
      <TextField
        label='Name'
        name='officeManagerName'
        variant='outlined'
        size='small'
        fullWidth
        value={values.officeManagerName}
        onChange={ e => { 
          e.target.value = e.target.value.toLowerCase()
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
          handleChange(e)}}
        onBlur={handleBlur}
        helperText={touched.officeManagerName && errors.officeManagerName}
        error={Boolean(touched.officeManagerName && errors.officeManagerName)}
      />
    </Grid>
  );
}

export { ManagerName };
