import React, { useState } from 'react';
import { Tooltip, Typography, Box, IconButton, SvgIcon } from '@material-ui/core'
import { Eye } from 'react-feather'

import { Table } from '../Table';
import { Toast } from '../../Components/Toasts';
import { NewOrder } from './../../Pages/Orders/AddEditViewOrder'
import { SlidingPane } from './../SlidingPane'

const OrderRangeData = ({
  rows,
  total,
  loading,
  pageInfo,
  setPageInfo
}) => {
  const { orderBy, orderType } = pageInfo;
  const [toast, setToast] = useState({ message: '', type: 'success', open: false });
  const [currentOrderId, setCurrentOrderId] = useState(null);
  const [paneOpen, setPaneOpen] = useState(false);

  const handlePaneOpen = (orderId) => {
    setCurrentOrderId(orderId);
    setPaneOpen(true);
  };

  const handlePaneClose = () => {
    setPaneOpen(false);
    setCurrentOrderId(null); // Reset current order ID when closing the pane
  };

  const columns = [
    {
      field: 'w_Loan_Number',
      headerName: 'Loan/Order #',
      headerClassName: 'datagrid-header',
      cellClassName: 'datagrid-cell-custom',
    },
    {
      field: 'billRefNumber',
      headerName: 'Invoice #',
      headerClassName: 'datagrid-header',
      cellClassName: 'datagrid-cell-custom',
      renderCell: ({ row }) => (
        <Tooltip title={row?.billRefNumber || ''}>
          <Typography>{`INV${row?.Id}`}</Typography>
        </Tooltip>
      )
    },
    {
      field: 'w_Borrower_Last_Name',
      headerName: 'Last Name',
      headerClassName: 'datagrid-header',
      cellClassName: 'datagrid-cell-custom',
      renderCell: ({ row }) => (
        <Typography>{row?.f_borrower_first_name || ''} {' '} {row?.f_borrower_last_name || ''}</Typography>
      )
    },
    {
      field: 'client_name',
      headerName: 'Client',
      headerClassName: 'datagrid-header',
      cellClassName: 'datagrid-cell-custom',
      renderCell: ({ row }) => (
        <Tooltip title={row?.client?.clientName || ''}>
          <Typography>{row?.client?.clientName || ''}</Typography>
        </Tooltip>
      )
    },
    {
      field: 'division',
      headerName: 'Division',
      headerClassName: 'datagrid-header',
      cellClassName: 'datagrid-cell-custom',
      renderCell: ({ row }) => (
        <Tooltip title={row?.division?.divisionName || ''}>
          <Typography>{row?.division?.divisionName || ''}</Typography>
        </Tooltip>
      )
    },
    {
      field: 'contact',
      headerName: 'Contact',
      headerClassName: 'datagrid-header',
      cellClassName: 'datagrid-cell-custom',
      renderCell: ({ row }) => (
        <Tooltip title={`${row?.contact?.Contact_First_Name} ${row?.contact?.Contact_Last_Name}`}>
          <Typography>{`${row?.contact?.Contact_First_Name} ${row?.contact?.Contact_Last_Name}` }</Typography>
        </Tooltip>
      )
    },
    {
      field: 'w_Appointment_Date',
      headerName: 'Appointment Date',
      headerClassName: 'datagrid-header',
      cellClassName: 'datagrid-cell-custom'
    },
    {
      field: 'f_status_web',
      headerName: 'Status',
      headerClassName: 'datagrid-header',
      cellClassName: 'datagrid-cell-custom'
    },
    {
      field: 'payment_status',
      headerName: 'Payment Status',
      headerClassName: 'datagrid-header',
      cellClassName: 'datagrid-cell-custom'
    },
    {
      field: 'action',
      headerName: 'Action',
      headerClassName: 'datagrid-header',
      cellClassName: 'datagrid-cell-custom',
      renderCell: ({ row }) => {
        return (
          <Box display='flex' flexDirection='column'>
            <Tooltip title='View'>
              <IconButton
              color='inherit'
              onClick={()=> handlePaneOpen(row?.Id) }
              >
                <SvgIcon fontSize='small' color='primary'>
                  <Eye />
                </SvgIcon>
              </IconButton>
            </Tooltip>
          </Box>
        )
      }
    }
  ];

  const handlePage = (e: unknown, newPage: number) => {
    setPageInfo({
      ...pageInfo,
      page: newPage
    });
  };

  const handleRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPageInfo({
      ...pageInfo,
      limit: parseInt(e.target.value, 10)
    });
  };

  const handleSort = (e: any) => {
    // if (e.sortModel &&e.sortModel[0].field) {
    //   const { field, sort } = e.sortModel[0]

    //   setPageInfo({
    //     ...pageInfo,
    //     orderBy: field,
    //     orderType: sort,
    //   });
    // }
  };

  return (
    <>
      <SlidingPane
        title={'View Order'}
        open={paneOpen}
        handlePane={handlePaneClose}
        width='95%'
      >
        {paneOpen && (
          <NewOrder
            key={currentOrderId || 'new'}
            id={currentOrderId}
            open={paneOpen}
            justView={true}
          />
        )}
      </SlidingPane>
      
      <Table
        customRowHeight={85}
        sortModel={
          [{ field: orderBy, sort: orderType }]
        }
        useCustomStyles={false}
        loading={loading}
        columns={columns}
        rows={rows}
        total={total}
        pageInfo={pageInfo}
        handlePage={handlePage}
        handleRowsPerPage={handleRowsPerPage}
        onSortingChange={handleSort}
        rowsPerPageOptions={[10,50,80]}
      />
    <Toast message={toast.message} type={toast.type} open={toast.open} setToast={setToast} />
    </>
)};

export { OrderRangeData };
