import React from 'react';
import { useRecoilState } from 'recoil';
import {
  Box,
  Button,
  TextField,
  IconButton,
  InputAdornment,
  SvgIcon
} from '@material-ui/core';
import { Search, Plus } from 'react-feather';
import clsx from 'clsx';

import { rolepagination } from './../../Recoil/Atoms/Role';

import { marginStyles } from '../../Common/Styles/Margins';
import { displayStyles } from '../../Common/Styles/Display';

const RoleActions = ({ handleModal }) => {
  const marginClasses = marginStyles();
  const displayClasses = displayStyles();

  const [pageInfo, setPagination] = useRecoilState(rolepagination);

  return (
    <Box my={2} display='flex' justifyContent='flex-end'>
      <TextField
        className={clsx(marginClasses.mt0, marginClasses.mb0)}
        type='search'
        variant='outlined'
        label='Search Roles'
        margin='dense'
        value={pageInfo.search}
        onChange={e =>
          setPagination({ ...pageInfo, search: e.target.value, page: 1 })
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton>
                <SvgIcon fontSize='small' color='action'>
                  <Search />
                </SvgIcon>
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      <Button
        className={marginClasses.ml2}
        color='primary'
        size='small'
        variant='contained'
        onClick={() => handleModal(true)}
      >
        <Box className={displayClasses.whiteColor} display='flex'>
          <SvgIcon className={marginClasses.mr1} fontSize='small'>
            <Plus />
          </SvgIcon>
          New Role
        </Box>
      </Button>
    </Box>
  );
};

export { RoleActions };
