import React, { useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  Box,
  Tooltip,
  Typography,
  TablePagination,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress
} from '@material-ui/core';
import moment from 'moment-timezone';

import { OrdersTableActions } from './OrdersTableActions';
import { FileUploadModal } from '../FileUpload/FileUploadModal';

import { OrdersTablesHead } from './OrdersTableHead';
import { SignerInfo } from './SignerInfo';
import { TrackingStatus } from './TrackingStatus';
import { UnassignOrderModal } from './UnassignOrderModal';

import { currentOrder, ordersPagination } from '../../Recoil/Atoms/Orders';

import { useStyles } from './Styles/OrdersList';

const OrdersData = ({
  rows,
  total,
  loading,
  handleCancelOrder,
  handleChangeStatus,
  handleLockOrder,
  handleChangeCallStatus,
  handleRequestDoc,
  setRefetchOrders
}) => {
  const classes = useStyles();

  const [fileModal, handleFileModal] = useState(false);
  const [reassignModal, handleReassignOrder] = useState(null);
  const [signerInfoModal, handleSignerInfoModal] = useState(null);
  const [trackIngStatusModal, handleTrackIngStatus] = useState(null);
  const completedStatuses: any = ['Signed', 'Completed', 'Cancelled']
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("w_Loan_Number");

  const [pageInfo, setPageInfo] = useRecoilState(ordersPagination);
  const setCurrentOrder = useSetRecoilState(currentOrder);


  const documentActionFormatter = (row) => {
    return (
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='center'
        alignItems='center'
        width='100%'
      >
        <Tooltip title={ `${row?.documentCount || 0} View/Upload Documents` }>
          <Typography
            color='primary'
            variant='body2'
            className={`${classes.cursorPointer}`}
            onClick={e => {
              setCurrentOrder({
                ...row,
                w_Appointment_Time: moment(row.w_Appointment_Time, 'hh:mm A')
              });
              handleFileModal(true);
            }}
          >
            {`${row?.documentCount || 0} View/Upload`}
          </Typography>
        </Tooltip>
      </Box>
    );
  };

  const handlePage = (e: unknown, newPage: number) => {
    setPageInfo({
      ...pageInfo,
      page: newPage
    });
  };

  const handleRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPageInfo({
      ...pageInfo,
      limit: parseInt(e.target.value, 10)
    });
  };

  const handleSort = (e:any , property:any) => {
    if(property !== "w_row_number"){
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setPageInfo({
          ...pageInfo,
          orderBy: property,
          orderType: isAsc ? "desc" : "asc"
        });
    }
  };

  return (
    <>
      <UnassignOrderModal
        reassignModal={reassignModal}
        handleReassignOrder={handleReassignOrder}
      />
      <SignerInfo
        signerInfoModal={signerInfoModal}
        handleSignerInfoModal={handleSignerInfoModal}
      />
      {trackIngStatusModal && (
        <TrackingStatus
          setRefetchOrders={setRefetchOrders}
          order={trackIngStatusModal}
          handleTrackIngStatus={handleTrackIngStatus}
        />
      )}
      { loading ?
        <Box width='100%' padding='10px 0' display='flex' justifyContent='center'>
          <CircularProgress />
        </Box> :
        <>
          <TableContainer hidden={loading}>
            <Table
              className={classes.table}
              aria-labelledby='tableTitle'
              size='medium'
              aria-label='enhanced table'
            >
              <OrdersTablesHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleSort}
              />
              <TableBody>
                {rows.map((row) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.Id}>
                      <TableCell className={classes.cellPadding}>{row.w_row_number}</TableCell>
                      <TableCell className={classes.cellPadding}>{row.w_Loan_Number}</TableCell>
                      <TableCell className={classes.cellPadding}>{row.w_Borrower_Last_Name}</TableCell>
                      <TableCell className={classes.cellPadding}>{row.w_Appointment_Date}</TableCell>
                      <TableCell className={classes.cellPadding}>
                        <Typography variant='body2'>
                          { row.w_Appointment_Time_type === 'OT' ?
                            'Open Time' :
                            row.w_Appointment_Time
                          }
                        </Typography>
                        <Typography variant='body2'>
                          { row.w_Appointment_Time_type === 'OT' ?
                            'Open Time' :
                            `${moment(row.w_appt_time_adj_local_formatted, 'HH:mm:ss').format('hh:mm A')} PST`
                          }
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.cellPadding}>
                        { row.f_status_web }
                        { row.f_status_web === 'In Process' &&
                          row.old_signer &&
                          <Typography
                            color='primary'
                            variant='body2'
                            className={classes.cursorPointer}
                            onClick={() => handleTrackIngStatus(row)}
                          >
                            Tracking Status
                          </Typography>
                        }
                        { row?.signer &&
                          <>
                            {!completedStatuses.includes(row?.f_status_web) &&
                              <Typography
                                color='primary'
                                variant='body2'
                                className={classes.cursorPointer}
                                onClick={() => handleReassignOrder(row)}
                              >
                                Reassign
                              </Typography>
                            }
                            <Typography
                              color='primary'
                              variant='body2'
                              className={classes.cursorPointer}
                              onClick={() => handleSignerInfoModal(
                                { ...row?.signer, w_Loan_Number: row?.w_Loan_Number }
                                || { w_Loan_Number: row?.w_Loan_Number })
                              }
                            >
                              Signer Info
                            </Typography>
                          </>
                        }
                      </TableCell>
                      <TableCell className={classes.cellPadding}>
                        {row.scan_backs}
                      </TableCell>
                      <TableCell className={classes.cellPadding}>
                        {row.w_docs_received}
                      </TableCell>
                      <TableCell className={classes.cellPadding}>
                      <a href={`https://www.fedex.com/fedextrack/?tracknumbers=${row?.w_Return_Tracking}&cntry_code=us`} target='fedexWindow' >{row?.w_Return_Tracking}</a>
                        {row?.S2C_Tracking_Status === 'SC' ?
                          <Tooltip arrow title={row?.S2C_Tracking_Status}>
                            <Typography
                              color='primary'
                              variant='body2'
                              className={classes.cursorPointer}
                              onClick={e => handleTrackIngStatus(row)}
                            >
                              {row?.S2C_Tracking_Status}
                            </Typography>
                          </Tooltip> : row?.S2C_Tracking_Status}
                        <Typography
                          color='primary'
                          variant='body2'
                          className={classes.cursorPointer}
                          onClick={e => handleTrackIngStatus(row)}
                        >
                          Tracking Status
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.cellPadding}>
                        {row.Id}
                      </TableCell>
                      <TableCell className={classes.cellPadding}>
                        {documentActionFormatter(row)}
                      </TableCell>
                      <TableCell className={classes.cellPadding}>
                        <OrdersTableActions
                          key={row.Id}
                          row={row}
                          handleCancelOrder={handleCancelOrder}
                          handleChangeStatus={handleChangeStatus}
                          handleLockOrder={handleLockOrder}
                          handleChangeCallStatus={handleChangeCallStatus}
                          handleRequestDoc={handleRequestDoc}
                          handleNotes={null}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            hidden={loading}
            rowsPerPageOptions={[100, 200, 300]}
            component='div'
            count={total}
            rowsPerPage={pageInfo.limit}
            page={total > 0 ? pageInfo.page - 1 : 0}
            onPageChange={handlePage}
            onChangeRowsPerPage={handleRowsPerPage}
          />
        </>
      }
      {fileModal && (
        <FileUploadModal isUpdateOrder={true} open={fileModal} handleFileModal={handleFileModal} />
      )}
    </>
  );
};

export { OrdersData };
