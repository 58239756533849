import React from 'react';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  InputLabel,
  Select,
  Chip,
  IconButton,
  SvgIcon
} from '@material-ui/core';
import { XCircle } from 'react-feather';
import moment from 'moment';
import clsx from 'clsx';

import { OrdersSearch } from './OrdersSearch';
import { OrdersDateRange } from './OrdersDateRange';

import { getClientNames } from '../../Recoil/Selectors/Clients';
import {
  getContactsNames,
  getAllContactsNames
} from '../../Recoil/Selectors/Contacts';
import {
  getDivisions,
  getAllDivisions
} from '../../Recoil/Selectors/Divisions';
import { orderFilters, ordersPagination } from '../../Recoil/Atoms/Orders';

import { sortASC } from '../../Common/Utilities/helper';

import { useStyles } from './Styles/OrdersList';
import { marginStyles } from '../../Common/Styles/Margins';

import 'bootstrap-daterangepicker/daterangepicker.css';

const OrdersActions = () => {
  const classes = useStyles();
  const marginClasses = marginStyles();

  const [pageInfo, setPageInfo]: any = useRecoilState(ordersPagination);

  const [filter, setFilter] = useRecoilState(orderFilters);
  const clientsData: any = useRecoilValueLoadable(getClientNames);
  const clientsNames = clientsData?.contents?.result || [];
  const clientsNamesList = sortASC(clientsNames, 'clientName').map((client) => (
    <MenuItem key={client.clientID} value={client.clientID}>
      {client.clientName}
    </MenuItem>
  ));

  const allDivisionsRequest: any = useRecoilValueLoadable(getAllDivisions);
  const allDivisionNames = allDivisionsRequest?.contents?.result?.rows || [];

  const divisionsData: any = useRecoilValueLoadable(getDivisions);
  const divisionsNames = divisionsData?.contents?.result?.rows || [];
  const divisionsNamesList = sortASC(
    filter.client ? divisionsNames : allDivisionNames,
    'divisionName'
  ).map((division) => (
    <MenuItem key={division.divisionID} value={division.divisionID}>
      {division.divisionName}
    </MenuItem>
  ));

  const allContactRequest: any = useRecoilValueLoadable(getAllContactsNames);
  const allContactNames = allContactRequest?.contents?.result?.rows || [];

  const contactsData: any = useRecoilValueLoadable(getContactsNames);
  const contactsNames = contactsData?.contents?.result?.rows || [];
  const contactsNamesList = sortASC(
    filter.division ? contactsNames : allContactNames,
    'Contact_First_Name'
  ).map((contact) => (
    <MenuItem key={contact.Id} value={contact.Id}>
      {`${contact.Contact_First_Name} ${contact.Contact_Last_Name}`}
    </MenuItem>
  ));

  const filterKeys = {
    client: 'clientID',
    contact: 'Contact_ID',
    division: 'division_id'
  };

  const setPageInfoFilter = (type: string, value: any) => {
    setPageInfo({
      ...pageInfo,
      limit: 300,
      filter: value ? `${filterKeys[type]}:${value}` : undefined
    });
  };

  const setFilterIds = (type: string, value: any) => {
    const newFilter = Object.assign({}, filter, { [type]: value });

    if (type === 'client') {
      delete newFilter.contact;
      delete newFilter.division;
    }
    if (type === 'division') {
      delete newFilter.contact;
    }
    if (!value) {
      delete newFilter.contact;
      delete newFilter.division;
      delete newFilter.contact;
    }
    setFilter(newFilter);
  };

  const handleFilter = (type: string, value: any) => {
    setPageInfoFilter(type, value);
    setFilterIds(type, value);
  };

  const handleSearch = (searchType, searchValue) => {
    let value = searchValue;
    if (searchType === 'orderDate') {
      value = moment(searchValue).format('YYYY-MM-DD');
    }
    const newPageInfo = { ...pageInfo, page: 1, limit: 300, searchType, value };

    if (!searchValue) {
      delete newPageInfo.searchType;
      delete newPageInfo.searchValue;
    }

    setPageInfo(newPageInfo);
  };

  const handleDates = (start, end) => {
    const startDate = moment(start).format('YYYY-MM-DD');
    const endDate = moment(end).format('YYYY-MM-DD');

    setPageInfo({ ...pageInfo, startDate, endDate, limit: 300 });
  };

  const clearDates = () => {
    const newPageInfo = Object.assign({}, pageInfo);

    delete newPageInfo.startDate;
    delete newPageInfo.endDate;

    setPageInfo(newPageInfo);
  };

  const handleStatus = (status: string) => {
    setPageInfo({ ...pageInfo, status, limit: 300 });
  };
  const clearFilters = () => {
    const newFilter = Object.assign({});
    setFilter(newFilter);
    setPageInfo({
      limit: 300,
      ...pageInfo,
      filter: ''
    });
  };

  const isSearched =
    (filter &&
      Object.keys(filter).filter((key) => (filter[key] ? true : false))
        .length) ||
    pageInfo.filter;
  return (
    <>
      <Box my={2} display='flex'>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item>
            <FormControl
              className={clsx(
                marginClasses.mt0,
                marginClasses.mb0,
                marginClasses.mr1,
                classes.select
              )}
              margin='dense'
              variant='outlined'
            >
              <InputLabel>Clients</InputLabel>
              <Select
                value={filter.client}
                label='Clients'
                defaultValue=''
                onChange={(e) => {
                  handleFilter('client', e.target.value);
                }}
              >
                <MenuItem value={'All Clients'} key={'All Clients'}>
                  All Clients
                </MenuItem>
                {clientsNamesList}
              </Select>
            </FormControl>
            <FormControl
              className={clsx(
                marginClasses.mt0,
                marginClasses.mb0,
                marginClasses.mr1,
                classes.select
              )}
              margin='dense'
              variant='outlined'
            >
              <InputLabel>Divisions</InputLabel>
              <Select
                label='Divisions'
                defaultValue=''
                value={filter.division}
                onChange={(e) => handleFilter('division', e.target.value)}
              >
                {divisionsNamesList}
              </Select>
            </FormControl>
            <FormControl
              className={clsx(
                marginClasses.mt0,
                marginClasses.mb0,
                marginClasses.mr1,
                classes.select
              )}
              margin='dense'
              variant='outlined'
            >
              <InputLabel>Contacts</InputLabel>
              <Select
                value={filter.contact}
                label='Contacts'
                defaultValue=''
                onChange={(e) => handleFilter('contact', e.target.value)}
              >
                {contactsNamesList}
              </Select>
            </FormControl>
            <IconButton disabled={!isSearched} onClick={clearFilters}>
              <SvgIcon
                fontSize='small'
                color={isSearched ? 'error' : 'disabled'}
              >
                <XCircle />
              </SvgIcon>
            </IconButton>
          </Grid>
          <Grid item>
            <OrdersSearch handleSearch={handleSearch} />
          </Grid>
        </Grid>
      </Box>
      <Box my={2} display='flex' width={310}>
        <OrdersDateRange handleDates={handleDates} clearDates={clearDates} />
      </Box>
      <Box my={2} display='flex'>
        <Chip
          label='All'
          classes={{ root: classes.rgtMargin }}
          color={
            pageInfo.status === '' || !pageInfo.status ? 'primary' : 'default'
          }
          onClick={(e) => handleStatus('')}
        />
        <Chip
          label='Active'
          classes={{ root: classes.rgtMargin }}
          color={pageInfo.status === 'active' ? 'primary' : 'default'}
          onClick={(e) => handleStatus('active')}
        />
        <Chip
          label='Cancelled'
          classes={{ root: classes.rgtMargin }}
          color={pageInfo.status === 'cancelled' ? 'primary' : 'default'}
          onClick={(e) => handleStatus('cancelled')}
        />
      </Box>
    </>
  );
};

export { OrdersActions };
