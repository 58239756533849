import React from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';

import { useStyles } from '../Styles/Fields';

const SplitClosing = ({ split, handleSplit }) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      className={classes.splitCheckbox}
      label='Multiple Locations'
      control={
        <Checkbox
          name='split'
          checked={split}
          onChange={(e) => handleSplit(e.target.checked)}
        />
      }
    />
  );
}

export { SplitClosing };
