import React from 'react';
import { Grid, FormControlLabel, Checkbox, FormHelperText } from '@material-ui/core';
import dayjs from 'dayjs';
import TimePicker from 'rc-time-picker'; 
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { useStyles } from '../Styles/Fields';

import '../../../Common/Styles/style.css';

const AppointmentDateTime = ({ isViewOnlyMode, location, values, errors, touched, handleBlur, setFieldValue }) => {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} sm={5}>
        <DatePicker
          disabled={isViewOnlyMode}
          className={classes.mt10}
          label='Appointment Date'
          name={`${location}.w_Appointment_Date`}
          format='MM/DD/YYYY'
          disablePast={true}
          value={dayjs(values.w_Appointment_Date)}
          onChange={(e : any) => {
            setFieldValue(`${location}.w_Appointment_Date`, `${e?.format('MM/DD/YYYY')}`)
          }}
        />
        {touched[location] && errors[location] && errors[location].w_Appointment_Date &&
          <FormHelperText error>
            {errors[location].w_Appointment_Date}
          </FormHelperText>
        }
      </Grid>
      <Grid item xs={12} sm={2}>
        <FormControlLabel
          className={classes.mt10}
          label='Open Time'
          control={
            <Checkbox
              disabled={isViewOnlyMode}
              color='primary'
              name={`${location}.w_Appointment_Time_type`}
              checked={values.w_Appointment_Time_type === 'OT'}
              onChange={(e) => setFieldValue(`${location}.w_Appointment_Time_type`, e.target.checked ? 'OT' : 'SC')}
            />
          }
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <TimePicker
          name={`${location}.w_Appointment_Time`}
          value={
            values.w_Appointment_Time_type === 'OT' ?
              moment('12:00 AM', 'hh:mm A') :
              values.w_Appointment_Time === '' ?
                values.w_Appointment_Time :
                moment(values.w_Appointment_Time, 'hh:mm A')
          }
          onChange={(e: any) => setFieldValue(
            `${location}.w_Appointment_Time`, e ? moment(e, 'hh:mm A') : moment('12:00 AM', 'hh:mm A')
          )}

          showSecond={false}
          use12Hours={true}
          allowEmpty={true}
          minuteStep={15}
          placeholder='Appointment Time'
          disabled={values.w_Appointment_Time_type === 'OT'}
        />
        {errors[location] && errors[location].w_Appointment_Time &&
          <FormHelperText error>
            {errors[location].w_Appointment_Time}
          </FormHelperText>
        }
      </Grid>
    </>
  );
}

export { AppointmentDateTime };
