import React from 'react';
import { Grid, TextareaAutosize } from '@material-ui/core';

import { displayStyles } from '../../../Common/Styles/Display';

const SignerNotes = ({ values, handleChange }) => {
  const displayClasses = displayStyles();
  return (
    <Grid item xs={12}>
      <TextareaAutosize
        className={displayClasses.textArea}
        placeholder='Signer Notes'
        name='operation_notes'
        rowsMin={3}
        value={values.operation_notes}
        onChange={handleChange}
      />
    </Grid>
  );
};
export { SignerNotes };
