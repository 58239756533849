import React from 'react';
import type { FC } from 'react';
import { Link } from 'react-router-dom';
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  SvgIcon,
  useMediaQuery,
} from '@material-ui/core';
import clsx from 'clsx';
import { Menu } from 'react-feather';

import Logo from 'src/ThemeComponents/components/Logo';
import { useTheme } from '@material-ui/core/styles';
import { Account } from './Account';
import { Settings } from './Settings';
import { Notifications } from './Notifications';
import { Inbox } from './Inbox';
import { Orders } from './Orders';
import { Appointments } from './Appointments';
import { Unassigned } from './Unassigned';
import { Matched } from './Matched';
import { MTDSigned } from './MTDSigned';
import { CallAlerts } from './CallAlerts';

import { useStyles } from './Styles';

interface TopBarProps {
  className?: string;
  onMobileNavOpen?: (open) => void;
}

const TopBar: FC<TopBarProps> = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        {isMdDown && (
          <IconButton color='inherit' onClick={() => onMobileNavOpen(true)}>
            <SvgIcon fontSize='small'>
              <Menu />
            </SvgIcon>
          </IconButton>
        )}
        {!isMdDown && (
          <Link to='/dashboard'>
            <Logo height='50px' link='/static/White-Logo.png' />
          </Link>
        )}
        <Box ml={2} flexGrow={1} />
        <Link to='/dashboard'>
          <Orders />
        </Link>
        <Link to='/dashboard'>
          <Appointments />
        </Link>
        <Link to='/dashboard'>
          <Unassigned />
        </Link>
        <Link to='/dashboard'>
          <Matched />
        </Link>
        <Link to='/dashboard'>
          <MTDSigned />
        </Link>
        <Inbox />
        <Notifications />
        <Settings />
        <Account />
      </Toolbar>
      <CallAlerts />
    </AppBar>
  );
};

export default TopBar;
