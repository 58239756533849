import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    select: {
      width: 150
    },
    btnNoWrap:{
      whiteSpace: "nowrap",
      minWidth: "max-content"
    }
  })
);

export { useStyles };
