import React from 'react';
import { Grid, TextField } from '@material-ui/core';

const UserFirstName = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur
}) => {
  return (
    <Grid container xs={12}>
      <TextField
        label='First Name'
        name='firstName'
        variant='outlined'
        size='small'
        fullWidth
        value={values.firstName}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.firstName && errors.firstName}
        error={Boolean(touched.firstName && errors.firstName)}
      />
    </Grid>
  );
};

export { UserFirstName };
