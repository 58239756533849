import React, { Fragment } from 'react';
import { Grid, Typography, FormControlLabel, Radio } from '@material-ui/core';


const DivisionRadios = ({ divisions, setPrivilege }) => {
  return (
    divisions.map((division, i) => {
      return (
        <Fragment key={division.divisionID}>
          <Grid item md={6}>
            <Typography variant='body2'>{division.divisionName}</Typography>
          </Grid>
          <Grid item md={3}>
            <FormControlLabel
              label='Self'
              control={
                <Radio
                  name={`division-${division.divisionID}`}
                  value='Self'
                  checked={division.privilege === 'Self'}
                  onChange={(e) => setPrivilege(e, i)}
                />
              }
            />
          </Grid>
          <Grid item md={3}>
            <FormControlLabel
              label='All'
              control={
                <Radio
                  name={`division-${division.divisionID}`}
                  value='All'
                  checked={division.privilege === 'All'}
                  onChange={(e) => setPrivilege(e, i)}
                />
              }
            />
          </Grid>
        </Fragment>
      )
    })
  );
}

export { DivisionRadios };
