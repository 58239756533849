import React from 'react';
import { Typography, Box, Divider } from '@material-ui/core';

import { Page } from '../../Components/Page';
import { RangeData } from '../../Components/Reports/RangeData';
import { RangeReportActions } from '../../Components/Reports/RangeReportActions';
import { useRecoilValueLoadable } from "recoil"
import { getRangeReport } from "../../Recoil/Selectors/Reports"


import { useStyles } from '.././Styles/Page';

export const Range = () => {
  const classes = useStyles();
  
  const range: any = useRecoilValueLoadable(getRangeReport)
  
  const rangeReport = range?.contents?.result?.rows || []
  const total = range?.contents?.result?.count || []


  return (
    <Page className={classes.paper} title='Range Report'>
      <Typography variant='h3'>Range Report</Typography>
      <Box my={2}>
        <Divider variant='fullWidth' />
      </Box>
      <RangeReportActions buttonDisable={!rangeReport.length}/>
      <RangeData data={rangeReport} total={total} loading={range.state === 'loading'}  />
    </Page>
  );
}
