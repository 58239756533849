import React, { useState, useEffect } from 'react';
import { useRecoilState, useSetRecoilState, useRecoilCallback } from 'recoil';

import { FormikComponent } from '../../Components/Formik';
import { ContactForm } from '../../Components/Client/ContactForm';

import { userInfo } from '../../Recoil/Atoms/Login';
import { refreshContacts, selectedClient } from '../../Recoil/Atoms/Contacts';
import { saveContact } from '../../Recoil/Selectors/Contacts';
import { addClientToContact } from '../../Recoil/Selectors/Clients';
import { getDivisionsByClient } from '../../Recoil/Selectors/Divisions';

import { initialValues, validationSchema } from '../../Common/Rules/ContactForm';

export const NewContact = (props) => {
  const { contact, openModal, setToast } = props;
  const [user] = useRecoilState(userInfo);
  const [client, selectClient] = useRecoilState(selectedClient);

  const [divisions, setDivisions] = useState([]);

  const callRefresh = useSetRecoilState(refreshContacts);

  const refresh = () => callRefresh((n) => n + 1);

  const createPayload = (values) => {
    const selectedDivisions = divisions.filter((d) => d.privilege);
    const params = {
      ...values,
      Company_ID: client,
      divisions: selectedDivisions.map((d) => ({
        createdBy: user.id,
        divisionID: d.divisionID,
        status: 'A',
        privilege: d.privilege
      })) || []
    };

    return params;
  }

  const onSubmit = useRecoilCallback(({ snapshot }) => async (values: any, { setSubmitting }) => {
    let res: any = {};

    const params = createPayload(values);
    params.isEditing = props.isEditing
    res = await snapshot.getPromise(saveContact(params));
    
    if (res.success) {
      refresh();
      openModal(false);
      setToast({
        message: `Contact ${props.isEditing ? 'updated' : 'created'} successfully!`,
        type: 'success',
        open: true
      });
    } else {
      setToast({
        message: res?.result?.data?.message,
        type: 'error',
        open: true
      });
    }

    setSubmitting(false);
  });

  const getDivisions = useRecoilCallback(({ snapshot }) => async (id: any) => {
    selectClient(id);
    const res: any = await snapshot.getPromise(getDivisionsByClient(id));
    if (res.success) {
      let data= res?.result?.rows.map(division=>{
          let privilege = []
          if(contact.ContactDivisionsModel)
          {
            privilege = contact.ContactDivisionsModel.filter(privilegeDivision=>division.divisionID === privilegeDivision.divisionID)
          }
          if(privilege.length)
            {
              return {...division,privilege:privilege[0].privilege}
            }
            else
            {
              return division
            }
        })

      setDivisions(data);
    }
  });
  useEffect(() => {
    if (props.isEditing) {
      getDivisions(contact.Company_ID)
    }
    // eslint-disable-next-line
  }, [])
  const addClient = useRecoilCallback(({ snapshot }) => async (clientID: any, contactID: any) => {
    await snapshot.getPromise(addClientToContact({ clientID, contactID }));
  });

  return (
    <FormikComponent
      onSubmit={onSubmit}
      initialValues={contact.Id ? contact : initialValues}
      validationSchema={validationSchema}
    >
      <ContactForm
        {...props}
        divisions={divisions}
        client={client}
        selectClient={selectClient}
        setDivisions={setDivisions}
        getDivisions={getDivisions}
        addClient={addClient}
      />
    </FormikComponent>
  );
}
