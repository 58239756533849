import * as Yup from 'yup';

export const initialValues = {
  password: '',
  submit: null
};

export const passwordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
});
