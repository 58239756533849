import React, { useState } from 'react';
import { useRecoilValueLoadable,useSetRecoilState } from 'recoil'

import {
  Grid,
  FormControl,
  FormHelperText,
  TextField,
  CircularProgress
} from '@material-ui/core';
import {  Autocomplete } from '@material-ui/lab';
import { Search } from 'react-feather'

import { customerListPagination } from '../../../Recoil/Atoms/Clients'
import { fetchCustomerList } from '../../../Recoil/Selectors/Clients'

import { useStyles } from '../Styles/Fields';

const ClientQbListID = ({ values, errors, setFieldValue, touched }) => {
  
    const classes = useStyles();

    const [search, setSearch] = useState(values?.customerName || '')
    const setCustomerListPagination = useSetRecoilState(customerListPagination)

    const customerListRequest: any = useRecoilValueLoadable(fetchCustomerList)
    const customerList = customerListRequest?.contents?.result || []

    return (
        <Grid item xs={12} md={6}>
            <FormControl
                variant='outlined'
                size='small'
                classes={{ root: classes.selectWidth }}
                error={errors.customerListID && touched.customerListID}
            >
            <Autocomplete
                id='customerListID'
                getOptionLabel={(option: any) => option?.Name}
                options={customerList}
                clearOnBlur={false}
                loading={Boolean(customerListRequest?.state === 'loading')}
                defaultValue={{Name: values?.customerName, ListID: values?.customerListID}}
                onChange={(event, newValue) => {
                    setFieldValue('customerListID', newValue?.ListID)
                }}
                size='small'
                renderInput={(params) => (
                    <TextField
                        label='Quickbook ID' 
                        variant='outlined' 
                        value={search}
                        onChange={(e: any) => setSearch(e.target.value)}
                        {...params}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                            <React.Fragment>
                                {(customerListRequest?.state === 'loading') ? <CircularProgress color="inherit" size={20} /> : <Search className={classes.cursorPointer} onClick={ () => setCustomerListPagination({ search, limit: 500 }) } size={20} /> }
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                            ),
                        }}
                    />
                )}
            />

            {errors.customerListID && touched.customerListID && (
            <FormHelperText error>
                { errors.customerListID}
            </FormHelperText>

            )}
            </FormControl>
        </Grid>
    );
};

export { ClientQbListID };
