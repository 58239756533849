import React from 'react'
import { useRecoilValue, useRecoilValueLoadable } from 'recoil'
import { Typography, Grid } from '@material-ui/core';

import { Table } from '../Table';

import { selectedSignerErrors } from 'src/Recoil/Atoms/Signers';
import { getSignerErros } from 'src/Recoil/Selectors/Signer';



const columns = [{
  field: 'borrower_f_loan_number',
  headerName: 'Loan No.',
  width: 125,
  disableColumnMenu: true,
  align: 'left',
  headerAlign: 'left',
}, {
  field: 'clientName',
  headerName: 'Client Name',
  width: 125,
  disableColumnMenu: true,
  align: 'left',
  headerAlign: 'left',
}, {
  field: 'divisionName',
  headerName: 'Division Name',
  width: 125,
  disableColumnMenu: true,
  align: 'left',
  headerAlign: 'left',
}, {
  field: 'mistake_date',
  headerName: 'Mistake date',
  width: 125,
  disableColumnMenu: true,
  align: 'left',
  headerAlign: 'left',
},
]

export function SignerErrors() {
  const signerData: any = useRecoilValueLoadable(getSignerErros)
  const signersErrors = signerData?.contents?.result?.rows || [];
  const signer = useRecoilValue(selectedSignerErrors)
  return (
    <div>
      <Grid container>
        <Grid container item>
          <Typography variant='h5' display='inline'>
            <Typography color='textSecondary' display='inline'>Signer ID : </Typography>{signer.signer_id}
          </Typography>
        </Grid>
        <Grid container item>
          <Typography variant='h5' display='inline'>
            <Typography color='textSecondary' display='inline'>Signer Name : </Typography>
            {signer.Signer_First}
          </Typography>
        </Grid>
      </Grid>
      <Table loading={signerData?.state === 'loading'}
        pagination={false} uniqueKey='signer_error_id' columns={columns} rows={signersErrors}>
      </Table>
    </div>
  )
}
