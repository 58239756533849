import React from 'react';
import { IconButton, SvgIcon, Tooltip, Badge } from '@material-ui/core';

import { useStyles } from './Styles/Icon';

const Icon = (props: any) => {
  const classes = useStyles();
  return (
    <Tooltip title={props.title}>
      <Badge
      overlap="rectangular"
        classes={{ badge: classes.badge }}
        color='error'
        badgeContent={props.count}
        invisible={false}
        max={100000}
        className={classes.root}
      >
        <IconButton>
          <SvgIcon className={classes.iconColor}>
            {props.children}
          </SvgIcon>
        </IconButton>
      </Badge>
    </Tooltip>
  );
};

export { Icon };
