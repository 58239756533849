import React, { useState, useEffect } from 'react';
import {
  useRecoilValueLoadable,
  useRecoilState,
  useRecoilCallback,
  useSetRecoilState
} from 'recoil';
import { Typography, Box, Divider } from '@material-ui/core';

import { Toast } from '../../Components/Toasts';
import { Page } from '../../Components/Page';

import { PendingVendorInvoicesData } from 'src/Components/Accounting/PendingVendorInvoices/PendingVendorInvoicesData';
import { OrdersDialog } from '../../Components/Orders/OrdersDialog';
import { PendingVendorInvoicesActions } from 'src/Components/Accounting/PendingVendorInvoices/PendingVendorInvoicesActions';

import { lockOrder } from '../../Recoil/Selectors/Orders';
import { dialog as dialogState } from '../../Recoil/Atoms/Modals';
import { getPendingVendorInvoice } from 'src/Recoil/Selectors/Accounting';

import { pendingVendorInvoicesPagination, /* refetchPendingVendorInvoice */ } from '../../Recoil/Atoms/Accounting';
import { forceLockOrder } from '../../Recoil/Atoms/Orders';

import { useStyles } from '.././Styles/Page';

export const PendingVendorInvoices = () => {
  const classes = useStyles();

  const [pageInfo, setPageInfo] = useRecoilState(pendingVendorInvoicesPagination);
  const allPendingInvoices: any = useRecoilValueLoadable(getPendingVendorInvoice);
  const pendingInvoicesData = allPendingInvoices?.contents?.result?.rows || [];
  
  // const setRefetchInvoices = useSetRecoilState(refetchPendingVendorInvoice);
  const total = allPendingInvoices?.contents?.result?.count || 0;
  const [toast, setToast] = useState({ message: '', type: 'success', open: false });
  
  const [dialog, setDialog] = useRecoilState(dialogState)
  const [selectedOrderIds, setSelectedOrderIds] = useState([])
  const lock = useSetRecoilState(forceLockOrder)

  useEffect(() => {
    setSelectedOrderIds([])
  }, [setSelectedOrderIds])

  const handleLockInvoice = useRecoilCallback(({ snapshot }) => async () => {
    setDialog({ ...dialog, loading: true })
    const { result, success } = await snapshot.getPromise(lockOrder);
    const message = result.record.order_locked === 'N' ? 'Unlocked' : 'Locked'

    if (!success) {
      setToast({ message: result.errors, type: 'warning', open: true });
    }
    
    if (success) {
      setToast({ message: `Order ${message} Successfully`, type: 'success', open: true });
    }

    lock((n) => ++n)
    setDialog({ ...dialog, loading: false, open: false })
  });

  const selectId = (id) => {

    const ids = [...selectedOrderIds]
    const index = ids.indexOf(id)

    if (index > -1) {
      ids.splice(index, 1)
    } else {
      ids.push(id)
    }

    setSelectedOrderIds(ids)
  }

  return (
    <Page className={classes.paper} title='Pending Vendor Invoices'>
      <Typography variant='h3'>Pending Vendor Invoices</Typography>
      <Box my={2}>
        <Divider variant='fullWidth' />
      </Box>
      <PendingVendorInvoicesActions selectedOrderIds={selectedOrderIds} setSelectedOrderIds={setSelectedOrderIds} />
      <PendingVendorInvoicesData
        handleLockInvoice={handleLockInvoice}
        pendingInvoice={pendingInvoicesData}
        loading={total === 0 && allPendingInvoices?.state === 'loading'}
        total={total}
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        selectId={selectId}
        selectedOrderIds={selectedOrderIds}
      />
       <OrdersDialog />
      <Toast message={toast.message} type={toast.type} open={toast.open} setToast={setToast} />
    </Page>
  );
};
