import React from 'react';
import { Box } from '@material-ui/core';
import { CircularProgressbar } from 'react-circular-progressbar';

import { useStyles } from './Styles/style';

import 'react-circular-progressbar/dist/styles.css';

const DoughnutChart = ({ data }: any) => {
  const classes = useStyles();
  return (
    <Box minHeight={400} display='flex' flexDirection='column' alignItems='center'>
        <CircularProgressbar value={data} text={`${data}%`} className={classes.CircularProgressbar} />
    </Box>
  );
};

export { DoughnutChart };
