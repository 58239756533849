import React from 'react';
import { useRecoilState } from 'recoil';
import {
  Box,
  Tooltip,
  IconButton,
  SvgIcon,
  Typography
} from '@material-ui/core';
import { Edit } from 'react-feather';

import { userpagination } from '../../Recoil/Atoms/User';

import { Table } from '../Table';

const UserData = props => {
  const [pageInfo, setPageInfo] = useRecoilState(userpagination);
  const { orderType, orderBy } = pageInfo

  const actionsFormatter = params => {
    return (
      <Box display='flex'>
        <Tooltip title='Edit'>
          <IconButton
            color='inherit'
            onClick={() => props.handleEdit(params.row)}
          >
            <SvgIcon fontSize='small' color='primary'>
              <Edit />
            </SvgIcon>
          </IconButton>
        </Tooltip>
      </Box>
    );
  };

  const columns = [


    {
      field: 'firstName',
      headerName: 'User',
      cellClassName: '',
      renderCell: ({ row }) => (
        <div style={{ display: 'block' }}>
          <Typography variant='body1' variantMapping={{ body1: 'p' }}>
                      {row.firstName+" "+row.lastName}
          </Typography>
        </div>
      )
    },

    {
      field: 'email',
      headerName: 'Email',
    width  :800
    },
    {
      field: 'role',
      headerName: 'Role',
      renderCell: ({ row }) => (
        <div style={{ display: 'block' }}>
          <Typography variant='body1' variantMapping={{ body1: 'p' }}>
                      {row.role.name}
          </Typography>
        </div>
      )

    },
    {
      field: 'phone_no',
      headerName: 'Phone No'
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: actionsFormatter
    }
  ];
  const onSortingChange = (e) => {
    if (e.sortModel &&e.sortModel[0].field) {
      const { field, sort } = e.sortModel[0]
      setPageInfo({
        ...pageInfo,
        orderBy: field,
        orderType: sort,
      });
    }
  }
  const handlePage = (e: unknown, newPage: number) => {
    setPageInfo({
      ...pageInfo,
      page: newPage
    });
  };

  const handleRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPageInfo({
      ...pageInfo,
      limit: parseInt(e.target.value, 10)
    });
  };

  return (
    <Table
      loading={props.loading}
      columns={columns}
      rows={props.data}
      total={props.total}
      pageInfo={{ page: pageInfo.page, limit: pageInfo.limit }}
      handlePage={handlePage}
      handleRowsPerPage={handleRowsPerPage}
      sortModel={[{
        sort: orderType, field: orderBy
      }]}
      onSortingChange={onSortingChange}
    />
  );
};

export { UserData };
