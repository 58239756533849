import React from 'react';
import {
  Grid,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText
} from '@material-ui/core';

const FidelityApproved = ({ values, handleChange, errors, touched }) => {
  return (
    <Grid item xs={12} sm={12} md={12}>
      <Typography variant='body2'>Fidelity Approved?</Typography>
      <FormControl component='fieldset'>
        <RadioGroup
          row
          aria-label='fidelity_approved'
          name='fidelity_approved'
          value={values.fidelity_approved}
          onChange={handleChange}
        >
          <FormControlLabel value='Y' control={<Radio />} label='Yes' />
          <FormControlLabel value='N' control={<Radio />} label='No' />
        </RadioGroup>
        {errors.fidelity_approved && touched.fidelity_approved && (
          <FormHelperText error>
            { errors.fidelity_approved}
          </FormHelperText>

        )}
      </FormControl>
    </Grid>
  );
};

export { FidelityApproved };
