import React, { cloneElement } from 'react';
import { Box, Button, CircularProgress } from '@material-ui/core';
import { Formik, Form } from 'formik';

import { marginStyles } from '../../Common/Styles/Margins';

const FormikComponent = ({
  children,
  initialValues,
  validationSchema,
  onSubmit,
  enableReinitialize,
  onInvalid,
  onCancel,
  cancelable
}: any) => {
  const marginClasses = marginStyles();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize={enableReinitialize}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue
      }: any) => {
        return (
          <Form
            onSubmit={e => {
              handleSubmit(e);
              if (errors && Object.keys(errors).length && onInvalid) {
                onInvalid(errors);
              }
            }}
          >
            {cloneElement(children, {
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue
            })}

            <Box mt={3} display='flex' justifyContent='flex-end' >
              {cancelable && (
                <Button
                  size='large'
                  disabled={isSubmitting}
                  className={marginClasses.mr2}
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              )}
              <Button
                variant='contained'
                size='large'
                color='primary'
                type='submit'
                disabled={isSubmitting}
              >
                {isSubmitting && (
                  <CircularProgress
                    className={marginClasses.mr2}
                    color='inherit'
                    size={25}
                  />
                )}
                Submit
              </Button>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export { FormikComponent };
