import type { Theme } from '../../../ThemeComponents/theme';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  topBorder: {
    backgroundColor: theme.palette.primary.main
  },
  contentArea: {
    backgroundColor: theme.palette.background.paper
  }
}));
