import React, { useEffect } from 'react';
import { Box, Typography, Grid } from '@material-ui/core';
import { useRecoilState, useRecoilValueLoadable, useRecoilValue } from 'recoil'

import { DoughnutChart } from '../Charts/Doughnut';
import { GoalCount } from './GoalCount'

import { displayStyles } from '../../Common/Styles/Display';

import { getGoal } from '../../Recoil/Selectors/Statistics';
import { goal, matchedOrders } from '../../Recoil/Atoms/Stats';

const Goal = () => {
  const displayClasses = displayStyles();

  const matchedOrdersCount = useRecoilValue(matchedOrders);
  const [goalCount, setGoalCount] = useRecoilState(goal);
  const goalState: any = useRecoilValueLoadable(getGoal);
  useEffect(()=>{
    setGoalCount(goalState?.contents?.result || 0);
  },[goalState,getGoal])

  const calculateGoal = () => {
    if (matchedOrdersCount > goalCount || goalCount === 0)
      return 100
    return (matchedOrdersCount / goalCount) * 100
  }

  return (
    <Box className={displayClasses.themeBG} p={2}>
      <Grid container justifyContent='space-between' alignItems='center'>
        <Typography variant='h5'>GOAL</Typography>
        <GoalCount count={goalCount}/>
      </Grid>
      <DoughnutChart data={calculateGoal().toFixed(2)} />
    </Box>
  );
}

export { Goal };
