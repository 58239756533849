import React from 'react';
import { Grid, TextField } from '@material-ui/core';

const ClientWebAddress= ({ values, errors, touched, handleChange, handleBlur }) => {
  return (
    <Grid item xs={12}>
      <TextField
        label='Web Address'
        name='webaddress'
        variant='outlined'
        size='small'
        fullWidth
        value={values.webaddress}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.webaddress && errors.webaddress}
        error={Boolean(touched.webaddress && errors.webaddress)}
      />
    </Grid>
  );
}

export { ClientWebAddress };
