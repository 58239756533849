import React from 'react';
import { useRecoilValueLoadable } from 'recoil'
import { Typography, Box, Divider } from '@material-ui/core';

import { getMatchedReport } from './../../Recoil/Selectors/Reports'

import { Page } from '../../Components/Page';
import { MatchersData } from '../../Components/Reports/MatchersData';
import { MatchersReportActions } from '../../Components/Reports/MatchersReportActions';

import { useStyles } from '.././Styles/Page';

export const Matchers = () => {
  const classes = useStyles();

  const matched: any = useRecoilValueLoadable(getMatchedReport)
  const matchedReport = matched?.contents?.result?.rows || []
  const total = matched?.contents?.result?.count || []

  return (
    <Page className={classes.paper} title='Matchers Report'>
      <Typography variant='h3'>Matchers Report</Typography>
      <Box my={2}>
        <Divider variant='fullWidth' />
      </Box>
      <MatchersReportActions buttonDisable={!matchedReport.length}/>
      <MatchersData data={matchedReport} total={total} loading={matched.state === 'loading'} />
    </Page>
  );
}
