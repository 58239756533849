import React, { useState } from 'react';
import {
  Grid,
  Box,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextareaAutosize,
  SvgIcon
} from '@material-ui/core';
import { Save, Trash } from 'react-feather';
import clsx from 'clsx';

import { Table } from '../../Table';

import { useStyles } from '../Styles/FeeAndInstructions';
import { useStyles as fieldStyles } from '../Styles/Fields';
import { displayStyles } from '../../../Common/Styles/Display';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil'
import { getSignerErros } from 'src/Recoil/Selectors/Signer';
import { selectedSignerErrors } from 'src/Recoil/Atoms/Signers';

const Errors = () => {
  const classes = useStyles();
  const fieldClasses = fieldStyles();
  const displayClasses = displayStyles();
  const [newError, handleNewError] = useState(false);


  const errorsData: any = useRecoilValueLoadable(getSignerErros)
  const signersErrors = errorsData?.contents?.result?.rows || [];
  const signer = useRecoilValue(selectedSignerErrors)
  console.error(signer);

  const columns = [{
    field: 'borrower_f_loan_number',
    headerName: 'Loan #'
  }, {
    field: 'borrower_last_name',
    headerName: 'Client'
  }, {
    field: 'mistake_type',
    headerName: 'Type'
  }, {
    field: 'mistake_date',
    headerName: 'Date'
  }, {
    field: 'mistake_description',
    headerName: 'Description'
  }];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6} md={3}>
          <Typography variant='body1'>Signing Agent Name</Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant='body2'>{'{Name}'}</Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant='body1'>Order ID</Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant='body2'>{'{ID}'}</Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant='body1'>Borrower Last Name</Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant='body2'>{'{Last Name}'}</Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant='body1'>Client Name</Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant='body2'>{'{Client}'}</Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant='body1'>Loan Number</Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant='body2'>{'{Loan}'}</Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant='body1'>Division Name</Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant='body2'>{'{Division}'}</Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant='body1'>Scheduler Initial</Typography>
        </Grid>
        <Grid item xs={6} md={9}>
          <Typography variant='body2'>{'{Scheduler}'}</Typography>
        </Grid>
      </Grid>
      <div className={classes.root}>
        <Box px={1} my={2} display='flex' alignItems='center' justifyContent='flex-end'>
          {!newError ?
            <Button color='primary' variant='contained' onClick={() => handleNewError(true)}>New Error</Button> :
            <Grid container spacing={2} alignItems='center'>
              <Grid item xs={12} md={5}>
                <FormControl className={fieldClasses.removeSelectMargin} margin='dense' variant='outlined'>
                  <InputLabel>Mistake Type</InputLabel>
                  <Select
                    label='Mistake Type'
                    name='mistake_type'
                    fullWidth
                    MenuProps={{
                      style: { zIndex: 1302 }
                    }}
                  >
                    <MenuItem value='1'>No Show</MenuItem>
                    <MenuItem value='2'>Late More Than 15 Minutes</MenuItem>
                    <MenuItem value='3'>Documents Cut Off</MenuItem>
                    <MenuItem value='4'>Missing Signature/Initial</MenuItem>
                    <MenuItem value='5'>Missing Stamp</MenuItem>
                    <MenuItem value='6'>Missing Date/Wrong Date</MenuItem>
                    <MenuItem value='7'>Right To Cancel Mistake</MenuItem>
                    <MenuItem value='8'>Did Not Sign Like Signature Line</MenuItem>
                    <MenuItem value='9'>Did Not Supply Copy To Borrower</MenuItem>
                    <MenuItem value='10'>Used Trustee Verbiage</MenuItem>
                    <MenuItem value='11'>Failed To Fax Back</MenuItem>
                    <MenuItem value='12'>Did Not Sign Notary Public After Name</MenuItem>
                    <MenuItem value='13'>Late Canceled Signing</MenuItem>
                    <MenuItem value='14'>Did not document 2 forms of Identification</MenuItem>
                    <MenuItem value='15'>Missing name on acknowledgment</MenuItem>
                    <MenuItem value='16'>Missing documents</MenuItem>
                    <MenuItem value='17'>Others</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextareaAutosize
                  className={fieldClasses.textArea}
                  placeholder='Error Description'
                  name='note'
                  rowsMin={2}
                />
              </Grid>
              <Grid className={clsx(displayClasses.flexRow, displayClasses.justifyBetween)} item xs={3} md={1}>
                <SvgIcon fontSize='small' color='action'>
                  <Save />
                </SvgIcon>
                <SvgIcon fontSize='small' color='action' onClick={() => handleNewError(false)}>
                  <Trash />
                </SvgIcon>
              </Grid>
            </Grid>
          }
        </Box>
        <Table uniqueKey='signer_error_id' columns={columns} rows={signersErrors} pagination={false} />
      </div>
    </>
  );
}
export { Errors };
