import React, { Fragment } from 'react';
import { Grid, Button, SvgIcon, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@material-ui/core';
import { FieldArray } from 'formik';
import { Plus, XCircle } from 'react-feather';
import clsx from 'clsx';

import { displayStyles } from '../../../Common/Styles/Display';
import { marginStyles } from '../../../Common/Styles/Margins';

const AttorneyStates = ({ values, errors, handleChange, setFieldValue, states }) => {
  const displayClasses = displayStyles();
  const marginClasses = marginStyles();
  return (
    <>
      <Grid className={displayClasses.flexRowCE} item xs={12}>
        <Button
          color='primary'
          variant='outlined'
          onClick={
            () => setFieldValue(`states[${values.states.length}]`, {
              state_id: ''
            })
          }
        >
          <SvgIcon fontSize='small'><Plus /></SvgIcon> Add
        </Button>
      </Grid>
      <FieldArray
        name='states'
        render={(helper) => (
          values.states.map((val, i) =>
            <Fragment key={`states-${i}`}>
              <Grid item xs={i > 0 ? 10 : 12} md={i > 0 ? 11 : 12}>
                <FormControl className={clsx(marginClasses.mb0, marginClasses.mt0)} margin='dense' variant='outlined' fullWidth>
                  <InputLabel>State</InputLabel>
                  <Select
                    label='State'
                    name={`states[${i}].state_id`}
                    fullWidth
                    value={val.state_id}
                    onChange={handleChange}
                    error={Boolean(errors.states && errors.states[i] && errors.states[i].state_id)}
                  >
                    { states.map((st) => {
                      return (
                        <MenuItem key={st.id} value={st.id}>{st.State}</MenuItem>
                      );
                    })}
                  </Select>
                  { errors.states && errors.states[i] && errors.states[i].state_id &&
                    <FormHelperText error>
                      {errors.states[i].state_id}
                    </FormHelperText>
                  }
                </FormControl>
              </Grid>
              { i > 0 &&
                <Grid item xs={2} md={1}>
                  <SvgIcon
                    fontSize='small'
                    color='primary'
                    onClick={() => helper.remove(i)}
                  >
                    <XCircle />
                  </SvgIcon>
                </Grid>
              }
            </Fragment>
          )
        )}
      />
    </>
  );
}

export { AttorneyStates };
