import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Theme } from 'src/ThemeComponents/theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    table: {
      minWidth: 750,
    },
    actions: {
      display: 'flex',
      flexDirection: 'column'
    },
    actionLabels: {
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    cursorPointer: {
      cursor: 'pointer'
    },
    select: {
      width: 150
    },
    rgtMargin: {
      marginRight: '10px',
    },
    flexColumn: {
      display: 'flex',
      flexDirection: 'column'
    },
    cellPadding:{
      padding:"15px 3px",
    }
  })
);

export { useStyles };
