import React from 'react';
import { Grid } from '@material-ui/core';

import { OutlinedSection } from '../Section';

import { AccessGroupName } from './AccessGroupFields/AccessGroupName';
import { AccessGroupResources } from './AccessGroupFields/AccessGroupResources';

const AccessGroupForm = props => {
  const { values, errors, touched, handleChange, handleBlur } = props;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={12}>
        <AccessGroupName
          values={values}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <OutlinedSection title='Resources'>
          <AccessGroupResources
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        </OutlinedSection>
      </Grid>
    </Grid>
  );
};

export { AccessGroupForm };
