import React from 'react';
import { useRecoilState } from 'recoil' 

import { nightlyReportPagination } from './../../Recoil/Atoms/Reports'

import { Table } from '../Table';

const NightlyData = (props: any) => {

  const [reportPagination, setPagination] = useRecoilState(nightlyReportPagination)
  const { data, loading, total } = props


  const columns = [{
    field: 'clientName',
    headerName: 'Client'
  }, {
    field: 'divisionName',
    headerName: 'Division'
  }, {
    field: 'contactName',
    headerName: 'Contact',
  }, {
    field: 'loan',
    headerName: 'Total Orders'
  }, {
    field: 'app',
    headerName: 'Total Appointments'
  }];

  const handlePage = (e: unknown, newPage: number) => {
    setPagination({
      ...reportPagination,
      page: newPage
    });
  };

  const handleRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPagination({
      ...reportPagination,
      limit: parseInt(e.target.value, 10)
    });
  };

  return (
    <Table
    loading={loading}
    columns={columns}
    rows={data}
    total={total}
    pageInfo={{ page: reportPagination.page, limit: reportPagination.limit }}
    handlePage={handlePage}
    handleRowsPerPage={handleRowsPerPage}
    />
  );
};

export { NightlyData };
