import React from 'react';
import { FormControl, MenuItem, InputLabel, Select, TextField, } from '@material-ui/core';

import clsx from 'clsx';
import map from 'lodash/map';

import { marginStyles } from '../../../Common/Styles/Margins';

import { statuses } from '../../../Common/Statuses';
import { sortASC } from '../../../Common/Utilities/helper';

import { useStyles } from '../Styles/ClientInvoicesActions';

const SearchInput = ({ type, value, onChange }) => {
  const classes = useStyles();
  const marginClasses = marginStyles();

  const searchInputs = {
    loanNumber: {
      type: 'text',
      label: 'Number'
    },
    invoiceRef: {
      type: 'text',
      label: 'Number'
    },
    borrowerName: {
      type: 'text',
      label: 'Name'
    },
    client: {
      type: 'text',
      label: 'Name'
    },
    division: {
      type: 'text',
      label: 'Name'
    },
    apptDate: {
      type: 'text',
      label: 'MM/DD/YYYY'
    },
    status: {
      type: 'select',
      label: 'Status',
      options: map(
        sortASC(statuses, 'label'), ({ value, label }) => <MenuItem key={value} value={value}>{label}</MenuItem>
      )
    },
    paymentStatus: {
      type: 'select',
      label: 'Status',
      options: map(
        sortASC(statuses, 'label'), ({ value, label }) => <MenuItem key={value} value={value}>{label}</MenuItem>
      )
    },
    credentialsComplete: {
      type: 'text',
      label: 'Yes/No'
    }
  }

  const inputType = searchInputs[type]?.type || 'text'
  const inputLabel = searchInputs[type]?.label || 'Input'
  const selectOptions = searchInputs[type]?.options ||
    <MenuItem key='no-data' disabled value='no-data'>No data</MenuItem>

  const select = (
    <FormControl className={clsx(marginClasses.my0, classes.select)} margin='dense' variant='outlined'>
      <InputLabel>{inputLabel}</InputLabel>
      <Select label={inputLabel} value={value} onChange={onChange}>
        {selectOptions}
      </Select>
    </FormControl>
  )

  const input = (
    <FormControl className={clsx(marginClasses.my0)} margin='dense' variant='outlined'>
      <TextField
        className={clsx(marginClasses.my0)}
        type={inputType}
        variant='outlined'
        label={inputLabel}
        margin='dense'
        value={value}
        onChange={onChange}
      />
    </FormControl>
  )

  return (
    inputType === 'select' ? select : input
  );
};

export { SearchInput };
