import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import InputMask from 'react-input-mask';

const ManagerCell = ({ values, errors, touched, handleChange, handleBlur }) => {
  return (
    <Grid item xs={12} md={6}>
      <InputMask
        mask='(999) 999-9999'
        maskChar=''
        value={values.officeManagerCell}
        onChange={handleChange}
        onBlur={handleBlur}
      >
        {() =>
          <TextField
            label='Mobile'
            name='officeManagerCell'
            variant='outlined'
            size='small'
            fullWidth
            helperText={touched.officeManagerCell && errors.officeManagerCell}
            error={Boolean(touched.officeManagerCell && errors.officeManagerCell)}
          />
        }
      </InputMask>
    </Grid>
  );
}

export { ManagerCell };
