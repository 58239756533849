import React from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { Typography, Box, Divider } from '@material-ui/core';

import { Page } from '../../Components/Page';
import { DocumentActivityActions } from '../../Components/Admin/DocumentActivityActions';
import { DocumentActivityData } from '../../Components/Admin/DocumentActivityData';
import { getDocumentActivity } from '../../Recoil/Selectors/DocumentActivity';

import { useStyles } from '.././Styles/Page';

export const DocumentActivity = () => {
  const classes = useStyles();

  const documentActivityData: any = useRecoilValueLoadable(getDocumentActivity);

  const data = documentActivityData?.contents?.result?.rows || [];
  const total = documentActivityData?.contents?.result?.count || 0;

  return (
    <Page className={classes.paper} title='Document Activity'>
      <Typography variant='h3'>Document Activity</Typography>
      <Box my={2}>
        <Divider variant='fullWidth' />
      </Box>
      <DocumentActivityActions />
      <DocumentActivityData
        loading={documentActivityData.state === 'loading'}
        data={data}
        total={total}
      />
    </Page>
  );
};
