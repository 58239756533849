import React, { useState } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { TextField } from '@material-ui/core';
import moment from "moment";

const ClientInvoicesDateRange = ({ handleDates, clearDates }) => {
  const [label, setLabel] = useState('Select dates')

  const handleApply = (event, picker) => {
    const { startDate, endDate } = picker
    setLabel(`${startDate.format('MM/DD/YYYY')} - ${endDate.format('MM/DD/YYYY')}`)
    handleDates(startDate, endDate)
  };
  const handleCancel = () => {
    setLabel('Select dates')
    clearDates()
  };

  const settings = {
    locale: {
      cancelLabel: 'Clear',
    },
    autoUpdateInput: false,
    ranges: {
      Today: [moment().startOf('day').toDate(), moment().endOf('day').toDate()],
      Yesterday: [
        moment().subtract(1, 'days').toDate(),
        moment().subtract(1, 'days').toDate(),
      ],
      'Last 7 Days': [
        moment().subtract(6, 'days').toDate(),
        moment().toDate(),
      ],
      'Last 30 Days': [
        moment().subtract(29, 'days').toDate(),
        moment().toDate(),
      ],
      'This Month': [
        moment().startOf('month').toDate(),
        moment().endOf('month').toDate(),
      ],
      'Last Month': [
        moment().subtract(1, 'month').startOf('month').toDate(),
        moment().subtract(1, 'month').endOf('month').toDate(),
      ],
    }
  }

  return (
    <DateRangePicker
      initialSettings={settings}
      onApply={handleApply}
      onCancel={handleCancel}
    >
      <TextField
        label="Appointment Date Range"
        InputProps={{
          readOnly: true,
        }}
        value={label}
        variant="outlined"
        size="small"
        fullWidth={true}
      />
    </DateRangePicker>
  );
};

export { ClientInvoicesDateRange };
