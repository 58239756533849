import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    fileList: {
        padding: '5px',
        margin: '5px 0px'
    }
  })
);

export { useStyles };
