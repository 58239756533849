import React from 'react';
import { useRecoilValueLoadable, useSetRecoilState, useRecoilValue, useRecoilCallback } from 'recoil'

import { Typography, Box, Divider, Button } from '@material-ui/core';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';

import { Page } from '../../Components/Page';
import { ProcessorsReportSearch } from './../../Components/Reports/ProcessorsReportSearch'
import { ProcessorsReportData } from './../../Components/Reports/ProcessorsReportData'
import { getProcessorsReport, getProcessorsReportCSV } from './../../Recoil/Selectors/Reports'

import { refetchProcessorReportCSV, processorsReportPagination } from './../../Recoil/Atoms/Reports'

import { useStyles } from '.././Styles/Page';

export const ProcessorsReport = ((props) => {

  const classes = useStyles();
  const reportData: any = useRecoilValueLoadable(getProcessorsReport)
  const processorsReport = reportData?.contents?.result?.rows || []
  const total = reportData?.contents?.result?.count || 0
  const fetchCSV = useSetRecoilState(refetchProcessorReportCSV)
  const processorPagination = useRecoilValue(processorsReportPagination)

  const exportCsv = useRecoilCallback(({ snapshot }) => async () => {
    fetchCSV(n => ++n)
    const res = await snapshot.getPromise(getProcessorsReportCSV(processorPagination));

    if (res.success) {
      const fetchedFile = window.URL.createObjectURL(res.result);
      const tempLink = document.createElement('a');
      tempLink.href = fetchedFile;
      tempLink.setAttribute('download', `Processor Report_${Date.now()}.csv`);
      tempLink.click();
    }
    else {
      enqueueSnackbar(
        res?.result?.message || 'Failed To Fetch CSV',
        {
          variant: 'error'
        }
      );
    }

  });


  return (
    <SnackbarProvider >
      <Page className={classes.paper} title='Nightly Report Email'>
        <Typography variant='h3'>Processors Report</Typography>
        <Box my={2}>
          <Divider variant='fullWidth' />
        </Box>
        <Box my={3} display='flex'>
          <ProcessorsReportSearch />
          <Button variant='contained' className={classes.mside} color='primary' onClick={exportCsv}>Export CSV</Button>
        </Box>
        <ProcessorsReportData data={processorsReport} loading={reportData.state === 'loading'} total={total} />
      </Page>
    </SnackbarProvider>

  );
})
