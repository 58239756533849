import React, { useState } from 'react';
import {
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment-timezone'

import { Search,Mail } from 'react-feather';

import { useStyles } from './Styles/Fields';

const SearchForm = props => {
const {defaultValues}=props
  const classes = useStyles();
  const [clientID, setClient] = useState(defaultValues.clientID);
  const [start, setFromDate] = useState(defaultValues.start);
  const [end, setToDate] = useState(defaultValues.end);

  const {clients=[]} =props

  const onSubmit = e => {
    e.preventDefault()
    props.onSearch({
      clientID,start,end
    });
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <Grid
          container
          lg={12}
          sm={12}
          classes={{ root: classes.searchContainer }}
        >
          <Grid container lg={3} sm={12}>
            <FormControl
              variant='outlined'
              size='small'
              classes={{ root: classes.searchFields }}
            >
              <InputLabel>Client</InputLabel>
              <Select
                fullWidth
                value={clientID}
                label='Select'
                onChange={e => setClient(String(e.target.value))}
              >
                {clients.map(cl => {
                  return <MenuItem value={cl.clientID}>{cl.clientName}</MenuItem>;
                })}
              </Select>
            </FormControl>
          
          </Grid>
          <Grid container  lg={3} sm={12}>
            <FormControl
              variant='outlined'
              size='small'
              classes={{ root: classes.searchFields }}
            >
              <KeyboardDatePicker
                size='small'
                className={classes.datePicker}
                label="From Date"
                format="MM/DD/YYYY"
                name="start"
                inputVariant="outlined"
                value={start}
                onChange={(date) => setFromDate(moment(date).format('MM/DD/YYYY'))}
              />
            </FormControl>
        
          </Grid>
          <Grid container  lg={4} sm={12}>
          <FormControl
              variant='outlined'
              size='small'
              classes={{ root: classes.searchFields }}
            >
              <KeyboardDatePicker
                size='small'
                label="To Date"
                format="MM/DD/YYYY"
                name="end"
                inputVariant="outlined"
                value={end}
                onChange={(date) => setToDate(moment(date).format('MM/DD/YYYY'))}
              /></FormControl>
              <Button
              onClick={onSubmit}
              variant='contained'
              color='primary'
              type='submit'
              classes={{ root: classes.searchButtons }}
              endIcon={<Search size={18} />}
            >
              Search
            </Button>
          </Grid>
          <Grid
            container
            direction='row'
            alignItems='center'
            justifyContent='flex-end'
            lg={2}
            sm={12}
          >
            <Button
              onClick={props?.openMessageModal}
              variant='contained'
              color='primary'
              classes={{ root: classes.searchButtons }}
              endIcon={<Mail size={18} />}
            >
              Message
            </Button>
          </Grid>

          <Button
              onClick={props?.onBulkMessageModal}
              variant='contained'
              color='primary'
              classes={{ root: classes.searchButtons }}
              endIcon={<Mail size={18} />}
            >
              Message All Signers
            </Button>
        </Grid>
      </form>
    </>
  );
};

export { SearchForm };
