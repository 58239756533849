import type { Theme } from '../../ThemeComponents/theme';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: 'calc(100vh - 94px)',
    backgroundColor: theme.palette.background.paper,
    margin: 15,
    padding: 15,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 5
  },
  noBgPage: {
    minHeight: 'calc(100vh - 94px)',
    margin: 15
  },
  paper: {
    minHeight: 'calc(100vh - 94px)',
    backgroundColor: theme.palette.background.paper,
    margin: 15,
    padding: 15,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 5
  },
  viewModeClass: {
    '& .Mui-disabled': {
      color: 'black'
    }
  },
  pointer: {
    cursor: 'pointer'
  },
  mside: {
    margin: '0px 5px'
  }
}));
