import React from 'react';

import { Table } from '../Table';

const AccessLogsData = () => {
  const columns = [{
    field: 'first_name',
    headerName: 'Full Name'
  }, {
    field: 'user_type',
    headerName: 'User Type'
  }, {
    field: 'ipaddress',
    headerName: 'IP Address'
  }, {
    field: 'logged_in',
    headerName: 'Logged In'
  }, {
    field: 'logged_out',
    headerName: 'Logged Out'
  }];

  const rows = [
    { id: 1, first_name: 'John', user_type: 'Jon', ipaddress: '127.0.0.1', logged_in: '', logged_out: '' }
  ];

  return (
    <Table
      columns={columns}
      rows={rows}
    />
  );
};

export { AccessLogsData };
