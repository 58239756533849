import React, { useState } from 'react';
import { useSetRecoilState, useRecoilCallback } from 'recoil';
import { Box, Button, CircularProgress, FormControlLabel, Switch } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Formik, Form } from 'formik';

import { storage } from '../../Services/storage';
import { Toast } from '../Toasts';
import { Fields } from './Fields';
import { PageLoad } from '../../Components/LoadingScreen/PageLoad';

import { updateProfile, updateCallAlerts } from './../../Recoil/Selectors/Auth';
import { refetchProfile, forceUpdateCallAlerts } from './../../Recoil/Atoms/Login';

import { marginStyles } from '../../Common/Styles/Margins';
import { ProfileUpdateSchema } from './../../Common/Rules/Login'

const ProfileForm = (props: any) => {
  const marginClasses = marginStyles();
  const [alert, setAlert] = useState(null);
  const todoUpdates = useSetRecoilState(refetchProfile);
  const forceUpdate = () => todoUpdates((n) => n + 1);
  const updateCallNotifications = useSetRecoilState(forceUpdateCallAlerts);
  const [updating, setUpdating] = useState(false);

  const [toast, setToast] = useState({
    message: '',
    type: 'success',
    open: false
  });

  const { loading, user } = props

  const receiveCalls = storage.get('receiveCalls') || user.receivingCalls

  const onSubmit = useRecoilCallback(({ snapshot }) => async (values: any, { setSubmitting }) => {
    setSubmitting(true);
    const res = await snapshot.getPromise(updateProfile(values));
    setSubmitting(false);
    if (res.success) {
      forceUpdate()
      setToast({
        message: 'Profile Updated Successfully!',
        type: 'success',
        open: true
      });
    } else {
      setAlert({
        status: 'error',
        message: res.result.data.message
      });
    }
  });

  const callAlerts = useRecoilCallback(({ snapshot }) => async (params) => {
    updateCallNotifications(n => n + 1)
    setUpdating(true)

    await snapshot.getPromise(updateCallAlerts(params))

    setUpdating(false)
  })

  return (
    loading ?
      <PageLoad /> :
      <>
        <Toast message={toast.message} type={toast.type} open={toast.open} setToast={setToast} />
        <Formik
          initialValues={user}
          validationSchema={ProfileUpdateSchema}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit
          }: any) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Fields
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                {
                  <FormControlLabel
                    onClick={() => callAlerts(receiveCalls === '1' ? '0' : '1')}
                    control={
                      <Switch
                        checked={receiveCalls === '1'}
                        onChange={() => { }}
                        name='receivingCalls'
                        color='primary'
                      disabled={updating}
                      />
                    }
                    label='Receive Signer Call Alerts'
                  />
                }
                {alert &&
                  <Box mt={3}>
                    <Alert severity={alert.status}>{alert.message}</Alert>
                  </Box>
                }
                <Box mt={3} display='flex' justifyContent='flex-end'>
                  <Button
                    variant='contained'
                    size='large'
                    color='primary'
                    type='submit'
                    disabled={isSubmitting}
                  >
                    {isSubmitting && <CircularProgress className={marginClasses.mr2} color='inherit' size={25} />}
                    Save
                  </Button>
                </Box>
              </Form>
            )
          }}
        </Formik>
      </>
  );
};

export { ProfileForm };
