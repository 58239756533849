import React from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

import { useStyles } from '../Styles/Fields';

const ContactStatus = ({ values, handleChange }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} md={6}>
      <FormControl className={classes.removeSelectMargin} margin='dense' variant='outlined'>
        <InputLabel>Status</InputLabel>
        <Select
          label='Status'
          name='status'
          fullWidth
          value={values.status}
          onChange={handleChange}
        >
          <MenuItem value='A'>Active</MenuItem>
          <MenuItem value='I'>Inactive</MenuItem>
        </Select>
      </FormControl>
    </Grid>
  );
}

export { ContactStatus };
