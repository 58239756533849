import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilCallback, useSetRecoilState, useRecoilValueLoadable } from 'recoil';
import { Box, Divider, IconButton, Grid, Typography, Tooltip, DialogContentText } from '@material-ui/core';
import { SnackbarProvider ,enqueueSnackbar } from 'notistack';
import { Edit, RefreshCw, Clock, Trash2, Slash, Link } from 'react-feather';

import { Table } from '../Table';
import Label from 'src/ThemeComponents/components/Label';
import { GeneralModal } from '../Modal';
import { SignerErrors } from './SignerErrors';
import MergeSigner from './MergeSigner'
import { SearchForm } from './SearchForm'

import { refetchSigners, pagination } from '../../Recoil/Atoms/Signers';
import { deleteSigner, deactiveSigner, getSigners, mergeSignerProfiles, signerPasswordReset } from '../../Recoil/Selectors/Signer';

import { useStyles } from './Styles/Fields'


const defaultSigner = {
  signer_id: '',
  signing: 0,
  errors: 0,
  Signer_First: '',
  Signer_Last: '',
}

const SignersList = (props) => {
  const navigate = useNavigate();
  const classes = useStyles()
  const [resetSignerPassword, handleSignerPassword] = useState(null);
  const [openDeleteModalId, setOpenDeleteModal] = useState(0)
  const [openDeactiveModalId, setOpenDeactiveModal] = useState(0)
  const [isErrorsModalOpen, setErrorsModal] = useState(false)
  const [loading, setLoading] = useState(false);
  const [selectedSignerToMerge, setSelectedSigner] = useState(defaultSigner)
  const [toSigner, setToSigner] = useState(defaultSigner)

  const [pageInfo, setPageinfo] = useRecoilState(pagination);

  const callRefresh = useSetRecoilState(refetchSigners);

  const signerData: any = useRecoilValueLoadable(getSigners);
  const signers = signerData?.contents?.result?.rows || [];
  const total = signerData?.contents?.result?.count || 0;

  const refresh = () => callRefresh((n) => n + 1);

  const signerPasswordResetRequest = useRecoilCallback(({ snapshot }) => async () => {
    setLoading(true)
    const { success } = await snapshot.getPromise(
      signerPasswordReset({ Signer_Email: resetSignerPassword.Signer_Email })
    );
    if (success) {
      handleSignerPassword(null)
      enqueueSnackbar(
        "A link to reset password is sent to the signer's email",
        { variant: 'success' })
    } else {
      enqueueSnackbar(
        'Failed to send email',
        { variant: 'error' })
    }
    setLoading(false)
  });

  const onSearch = (data) => {
    setPageinfo({
      ...pageInfo,
      page: 1,
      ...data,
    })
  }

  const onDeleteSigner = useRecoilCallback(({ snapshot }) => async (id) => {
    const response = await snapshot.getPromise(deleteSigner({ id }));
    if (response.success) {
      refresh()
    } else {
      enqueueSnackbar(
        response.result.message || 'Error occurred while deleting record',
        {
          variant: 'error'
        }
      )
      console.error(response);
    }
  });

  const onDeactiveSigner = useRecoilCallback(({ snapshot }) => async (id) => {
    const response = await snapshot.getPromise(deactiveSigner({
      id
    }));
    if (response.success) {
      refresh()
    } else {
      enqueueSnackbar(
        response.result.message || 'Error occurred while deactivating signer',
        {
          variant: 'error'
        }
      )
    }
  });

  const mergeSigner = useRecoilCallback(({ snapshot }) => async () => {
    if (!selectedSignerToMerge.signer_id || !toSigner.signer_id) {
      enqueueSnackbar(
        'Please select signer to merge',
        {
          variant: 'error'
        }
      )
    } else {
      const response = await snapshot.getPromise(mergeSignerProfiles({
        fromId: selectedSignerToMerge.signer_id,
        toId: toSigner.signer_id
      }));
      if (response.success) {
        enqueueSnackbar(
          response.result.message || 'Signer merged successfully',
          {
            variant: 'success'
          }
        )
        setSelectedSigner(defaultSigner)
        setToSigner(defaultSigner)
        refresh()
      } else {
        enqueueSnackbar(
          response.result.message || 'Error occurred while merge signer',
          {
            variant: 'error'
          }
        )
      }
    }
  });

  const handleClose = () => {
    setOpenDeleteModal(0);
    setOpenDeactiveModal(0);
  }

  const handleAgreeToDelete = () => {
    handleClose()
    onDeleteSigner(openDeleteModalId)
  }

  const handleAgreeToDeActive = () => {
    handleClose()
    onDeactiveSigner(openDeactiveModalId)
  }

  // const onSignerErrorsOpen = (signer) => {
  //   setErrorsModal(true)
  //   setSigner(signer)
  // }

  const handleRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPageinfo({
      ...pageInfo,
      limit: parseInt(e.target.value, 10)
    });
  };

  const columns = [
    {
      field: 'Badge',
      renderCell: (row) => (
        <Box display='flex' width='100%' className={classes.actionBox} >

          {row.row.nsa_member_number && row.row.nsa_member_number !== "" &&
            <Box>
              <Tooltip title='National Notary Association | NNA'>
                <img alt='NNA' src='/static/images/nna_badge.png'></img>
              </Tooltip>
            </Box>}
          {row.row.pacdoc_member && row.row.pacdoc_member === "Y" &&
            <Box ml={2}>
              <Tooltip title='PACDOC Sign'>
                <img alt='PACDOC Sign' height={22} width={22} src='https://crm.pacdocsign.com//images/favicon_1.ico'></img>
              </Tooltip>
            </Box>
          }

        </Box>
      ),
    }, {
      field: 'Role',
      headerName: 'Type',
      disableColumnMenu: true,
      align: 'left',
      headerAlign: 'left',
      width: 100,
      headerClassName: 'datagrid-header',
      cellClassName: 'datagrid-cell',
      renderCell: (data) => (
        <Box>{data?.row?.Role.toLowerCase() === 'child' ? 'Sub User' : 'Main User'}</Box>
      )
    }, {
      field: 'signer_id',
      headerName: 'Signer ID',
      disableColumnMenu: true,
      align: 'left',
      headerAlign: 'left',
      width: 100,
      headerClassName: 'datagrid-header',
      cellClassName: 'datagrid-cell',
    }, {
      field: 'Signer_First',
      headerName: 'First Name',
      disableColumnMenu: true,
      align: 'left',
      headerAlign: 'left',
      width: 100,
      headerClassName: 'datagrid-header',
      cellClassName: 'datagrid-cell',
    }, {
      field: 'Signer_Last',
      headerName: 'Last Name',
      disableColumnMenu: true,
      align: 'left',
      headerAlign: 'left',
      flex: 1,
      headerClassName: 'datagrid-header',
      cellClassName: 'datagrid-cell',
    }, {
      field: 'mobile_phone',
      headerName: 'Mobile',
      disableColumnMenu: true,
      align: 'left',
      headerAlign: 'left',
      flex: 0.8,
      headerClassName: 'datagrid-header',
      cellClassName: 'datagrid-cell',
    }, {
      field: 'home_phone',
      headerName: 'Home Phone',
      disableColumnMenu: true,
      align: 'left',
      headerAlign: 'left',
      flex: 0.8,
      headerClassName: 'datagrid-header',
      cellClassName: 'datagrid-cell',
    }, {
      field: 'username',
      headerName: 'Email',
      disableColumnMenu: true,
      align: 'left',
      headerAlign: 'left',
      flex: 1,
      headerClassName: 'datagrid-header',
      cellClassName: 'datagrid-cell',
    }, {
      field: 'city',
      headerName: 'Address',
      disableColumnMenu: true,
      align: 'left',
      headerAlign: 'left',
      width: 120,
      headerClassName: 'datagrid-header',
      cellClassName: 'datagrid-cell',
      renderCell: ({ row }) =>
        <div>{row?.city ? `${row?.city}, ` : ''}
          {row?.state ? `${row?.state}, ` : ''}
          {row?.zip ? `${row?.zip}` : ''} </div>
    }, {
      disableColumnMenu: true,
      field: 'Signer_Status',
      headerName: 'Status',
      align: 'left',
      headerAlign: 'left',
      width: 80,
      renderCell: (e) => (
        <Label color={e.value && e.value.toLowerCase() === 'active' ? 'success' : 'secondary'}>{e.value}</Label>
      ),
      headerClassName: 'datagrid-header',
      cellClassName: 'datagrid-cell',
    }, {

      field: 'action',
      headerName: 'Actions',
      width: 165,
      align: 'left',
      headerAlign: 'left',
      sortable: false,
      disableColumnMenu: true,
      headerClassName: 'datagrid-header',
      cellClassName: 'datagrid-cell',
      renderCell: (details) => (
        <Box display='flex' width='100%' className={classes.actionBox} >
          <Tooltip title='Edit'>
            <IconButton
              size='small'
              color='primary'
              onClick={() => navigate(`/signers/edit/${details?.row?.signer_id}`)}
            >
              <Edit width='25' height='25' />
            </IconButton>
          </Tooltip>
          <Tooltip title='Reset Password'>
            <IconButton size='small' color='primary'
              onClick={e => handleSignerPassword(details?.row)}
            >
              <RefreshCw width='25' height='25' >
              </RefreshCw>
            </IconButton></Tooltip>
          <Tooltip title='Signer History'>
            <IconButton
              size='small'
              color='primary'
              onClick={() => navigate(`/signers/history/${details?.row?.signer_id}`)}
            >
              <Clock width='25' height='25' />
            </IconButton>
          </Tooltip>
          <Tooltip title='Deactive'>
            <IconButton
              size='small'
              color='primary'
              onClick={() => setOpenDeactiveModal(details?.row?.signer_id)}
            >
              <Slash width='25' height='25' />
            </IconButton>
          </Tooltip>
          { }
          <Tooltip title='Delete'>
            <IconButton
              disabled={details?.row?.rows.length !== 0}
              size='small'
              color='primary'
              onClick={() => setOpenDeleteModal(details?.row?.signer_id)}
            >
              <Trash2 width='25' height='25' />
            </IconButton>
          </Tooltip>
          <Tooltip title='Merge'>
            <IconButton
              size='small'
              color='primary'
              onClick={() => setSelectedSigner(details?.row)}
            >
              <Link to="#" width='25' height='25' />
            </IconButton>
          </Tooltip>
        </Box>
      )
    }];

  const onSortingChange = (e) => {
    if (e.sortModel && e?.sortModel[0]?.field) {
      setPageinfo({
        ...pageInfo, orderBy: e.sortModel[0].field, orderType: e.sortModel[0].sort
      })
    }
  }

  const Message = () => {
    return (
      <SnackbarProvider>
        <Grid alignContent='center' alignItems='center'>
          <Typography>
            {openDeleteModalId ? ' Are you sure you want to remove this signer from system?' : 'Are you sure you want to deactivate this signer?'}
          </Typography>
        </Grid>
      </SnackbarProvider>

    )
  }

  return (
    <>
      {/* Confirmation Dialog */}
      <GeneralModal
        open={openDeleteModalId || openDeactiveModalId}
        title={openDeleteModalId ? 'Delete Signer' : 'Deactivate Signer'}
        openModal={handleClose}
        handleSubmit={openDeleteModalId ? handleAgreeToDelete : handleAgreeToDeActive}
        submitLabel={openDeleteModalId ? 'Delete' : 'Deactive'}
      >
        <Message />
      </GeneralModal>

      {/* Signer Errors Dialog */}
      <GeneralModal
        open={isErrorsModalOpen}
        title='Signer Error'
        openModal={setErrorsModal}
        noSubmit
      >
        <SignerErrors></SignerErrors>
      </GeneralModal>

      {/* Signer Merge Dialog */}
      <GeneralModal
        open={selectedSignerToMerge.signer_id}
        title={`Merge Profile - Signer ID: ${selectedSignerToMerge.signer_id}`}
        openModal={e => { setSelectedSigner(defaultSigner); setToSigner(defaultSigner) }}
        handleSubmit={mergeSigner}
        submitLabel='Merge'
      >
        <DialogContentText>
          <MergeSigner
            toSigner={toSigner}
            setToSigner={setToSigner}
            selectedSigner={selectedSignerToMerge}
          ></MergeSigner>
        </DialogContentText>
      </GeneralModal>

      {/* Reset Password Modal */}
      <GeneralModal
        title='Reset Signer Password'
        open={resetSignerPassword}
        openModal={handleSignerPassword}
        submitLabel='Yes'
        loading={loading}
        handleSubmit={async e => { signerPasswordResetRequest() }}
      >
        <DialogContentText>
          Are you sure you want to send an email to the signer to reset the password?
        </DialogContentText>
      </GeneralModal>

      <SearchForm onSearch={onSearch} defaultValues={pageInfo} />
      <Box my={2}>
        <Divider variant='fullWidth' />
      </Box>
      <Table
        customRowHeight={80}
        useCustomStyles={false}
        loading={signerData?.state === 'loading'}
        handlePage={(e, p) => {
          setPageinfo({
            ...pageInfo, page: p
          })
        }}
        sortModel={[{
          field: pageInfo.orderBy,
          sort: pageInfo.orderType
        }]}
        onSortingChange={onSortingChange}
        handleRowsPerPage={handleRowsPerPage}
        pageInfo={{ page: pageInfo.page, limit: pageInfo.limit }}
        total={total}
        columns={columns}
        rows={signers}
        uniqueKey='signer_id'
      />
    </>
  );
};

export const SignersData = (SignersList);
