import * as Yup from 'yup';

const initialValues = {
  accessGroupName: '',
  accessGroupResources:[]
};

const validationSchema = Yup.object().shape({
  accessGroupName: Yup.string().required('Client Name is Required.'),
  accessGroupResources:Yup.array().nullable()
  });


export {
  initialValues,
  validationSchema
};
