import React, { Suspense, useEffect } from 'react';
import { useRecoilValue, useRecoilCallback } from 'recoil';
import { Navigate, Routes, Route, useLocation } from 'react-router-dom';
import cookie from 'react-cookies';

import { PageLoad } from '../Components/LoadingScreen/PageLoad';

import { Dashboard } from '../Pages/Dashboard';

import { Settings } from '../Pages/Admin/Settings';
import { AccessLogs } from '../Pages/Admin/AccessLogs';
import { AuditTrail } from '../Pages/Admin/AuditTrail';
import { DocumentActivity } from '../Pages/Admin/DocumentActivity';
import { User } from '../Pages/Admin/User';
import { Role } from '../Pages/Admin/Role';
import { AccessGroup } from '../Pages/Admin/AccessGroup';

import { Clients } from '../Pages/Client/Clients';
import { Contacts } from '../Pages/Client/Contacts';

import { ClientContacts } from '../Pages/Contacts';

import { Divisions } from '../Pages/Divisions';

import { Orders } from '../Pages/Orders';
import { MonitoringOrders } from '../Pages/Orders/MonitoringOrders';
import { NewOrder } from '../Pages/Orders/AddEditViewOrder';
import { UnassignedOrders } from '../Pages/Orders/unassignedOrders';
import { AssignSigner } from '../Pages/Orders/assignSigner';
import { AssignAttempts } from '../Pages/Orders/AssignAttempts';

import { Signers } from '../Pages/Signers';
import { MyProfile } from '../Pages/MyProfile';
import { PPNSigners } from '../Pages/Signers/PPNSigners';
import { NewSigner } from '../Pages/Signers/NewSigner';
import { EditSigner } from '../Pages/Signers/EditSigner';
import { SignerCommunications } from '../Pages/Signers/SignerCommunication';
import { SignerHistoryPage } from '../Pages/Signers/SignerHistory';

import { Volume } from '../Pages/Reports/Volume';
import { Range } from '../Pages/Reports/Range';
import { Nightly } from '../Pages/Reports/Nightly';
import { Matchers } from '../Pages/Reports/Matchers';
import { ProcessorsReport } from '../Pages/Reports/ProcessorsReport'

import { monitorTime } from './../Recoil/Selectors/Orders';
import { currentTimer } from './../Recoil/Atoms/Orders';
import { OrderRangeDetails } from 'src/Pages/Accounting/OrderRangeDetails';
import { PendingVendorInvoices } from 'src/Pages/Accounting/PendingVendorInvoices';
import { UnbilledInvoices } from 'src/Pages/Accounting/UnbilledInvoices';
import { UnbilledOrders } from 'src/Pages/Accounting/UnbilledOrders';
import { DailyVendorBill } from 'src/Pages/Accounting/DailyVendorBill';

import { ClientInvoices } from '../Pages/Accounting/ClientInvoices';
import { DailyPayBill } from '../Pages/Accounting/DailyPayBill'
import { SignerLogin } from '../Pages/Signers/SignerLogin';

const BasePage = () => {
  const timer = useRecoilValue(currentTimer);
  const location = useLocation();

  const setTimer = useRecoilCallback(({ snapshot }) => async (params: any) => {
    const currentTime = cookie.load('timer');
    await snapshot.getPromise(
      monitorTime({ Id: currentTime, url: params.url })
    );
  });

  useEffect(() => {
    const path = location?.pathname?.split('/')[2];
    const currentTime = cookie.load('timer');
    if (path === 'assignsigner') {
      if (currentTime && currentTime !== timer) {
        setTimer({ url: 'end' });
        cookie.remove('timer');
      }
      if (timer !== -1) {
        cookie.save('timer', timer);
        setTimer({ url: 'start' });
      }
    } else if (path !== 'assignsigner' && currentTime) {
      setTimer({ url: 'end' });
      cookie.remove('timer');
    }
    window.addEventListener('beforeunload', ev => {
      setTimer({ url: 'end' });
      cookie.remove('timer');
    });
  }, [location, timer, setTimer]);
  return (
    <Suspense fallback={<PageLoad />}>
      <Routes>
      <Route path='/' element={<Navigate to="/dashboard" />} />
    <Route path='/login' element={<Navigate to="/dashboard" />} />
    <Route path='/admin' element={<Navigate to="/admin/settings" />} />

        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/my-profile' element={<MyProfile />} />

        <Route path='/admin/settings' element={<Settings />} />
        <Route path='/admin/access-logs' element={<AccessLogs />} />
        <Route path='/admin/audit-trail' element={<AuditTrail />} />
        <Route path='/admin/document-activity' element={<DocumentActivity />} />
        <Route path='/admin/users' element={<User />} />
        <Route path='/admin/role' element={<Role />} />
        <Route path='/admin/access-group' element={<AccessGroup />} />

        <Route path='/client/clients' element={<Clients />} />
        <Route path='/client/contacts' element={<Contacts />} />
        <Route path='/client/:id/:name/divisions' element={<Divisions />} />
        <Route path='/client/:id/contacts' element={<ClientContacts />} />
        <Route path='/client/contacts/master-login' element={<Contacts />} />

        <Route path='/orders' element={<Orders />} />
        <Route path='/orders-monitoring' element={<MonitoringOrders />} />
        <Route path='/orders/new' element={<NewOrder />} />
        <Route path='/orders/edit/:id' element={<NewOrder />} />
        <Route path='/orders/view/:id' element={<NewOrder />} />
        <Route path='/orders/duplicate/:order_id' element={<NewOrder />} />
        <Route path='/orders/unassigned' element={<UnassignedOrders />} />
        <Route

          path='/orders/assignsigner/:orderId'
          element={<AssignSigner />}
        />
        <Route

          path='/orders/AssignAttempts/:created_by/:borrower_id'
          element={<AssignAttempts />}
        />

        <Route path='/signers' element={<Signers />} />
        <Route path='/ppn-signers' element={<PPNSigners />} />
        <Route path='/signer-login' element={<SignerLogin />} />
        <Route path='/signers/new' element={<NewSigner />} />
        <Route path='/signers/edit/:id' element={<EditSigner />} />
        <Route

          path='/signers/communications'
          element={<SignerCommunications />}
        />
        <Route

          path='/signers/history/:id'
          element={<SignerHistoryPage />}
        />

        <Route path='/reports/volume' element={<Volume />} />
        <Route path='/reports/range' element={<Range />} />
        <Route path='/reports/nightly' element={<Nightly />} />
        <Route path='/reports/matchers' element={<Matchers />} />
        <Route path='/reports/processors-report' element={<ProcessorsReport />} />

        <Route
          path='/accounting/order-range-details'
          element={<OrderRangeDetails />}
        />
        <Route
          path='/accounting/pending-vendor-invoices'
          element={<PendingVendorInvoices />}
        />
        <Route
          path='/accounting/unbilled-invoices'
          element={<UnbilledInvoices />}
        />
        <Route path='/accounting/unbilled-orders' element={<UnbilledOrders />} />
        <Route path='/accounting/daily-vendor-bill' element={<DailyVendorBill />} />

        <Route path={'/accounting/client-invoices'} element={<ClientInvoices />} />
        <Route path={'/accounting/daily-pay-bill'} element={<DailyPayBill />} />

        <Route path='*' element={<Navigate to='/404' />} />
      </Routes>

    </Suspense>
  );
};

export { BasePage };
