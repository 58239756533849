import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { Typography } from '@material-ui/core';

import { Table } from '../Table';
import { OrdersTableActions } from './OrdersTableActions';
import { SignerInfo } from './SignerInfo';

import { monitoringOrderPagination } from '../../Recoil/Atoms/Orders';

import { getColorFromStatus } from '../../Common/Rules/ColorLegends';
import ColorLegend from '../ColorLegends/ColorLegend';

import { useStyles } from './Styles/OrdersList';

const MonitoringOrdersData = ({
  rows,
  total,
  loading,
  handleCancelOrder,
  handleChangeStatus,
  handleLockOrder,
  handleChangeCallStatus,
  handleRequestDoc,
  handleNotes
}) => {
  const [pageInfo, setPageInfo] = useRecoilState(monitoringOrderPagination);
  const [signerInfoModal, handleSignerInfoModal] = useState(null);
  const { orderBy, orderType } = pageInfo;
  const classes = useStyles()
  const columns = [{
    field: 'w_Loan_Number',
    headerName: 'Loan/Order #',
    headerClassName: 'datagrid-header',
    cellClassName: 'datagrid-cell',
    disableColumnMenu: true,
    width: 120
  }, {
    field: 'signer',
    sortable: false,
    headerName: 'Signer',
    headerClassName: 'datagrid-header',
    cellClassName: 'datagrid-cell',
    disableColumnMenu: true,
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    renderCell: ({ row }) =>
      <Typography
        color='primary'
        variant='body2'
        className={classes.cursorPointer}
        onClick={e => handleSignerInfoModal(
          { ...row?.signer, w_Loan_Number: row?.w_Loan_Number }
          || { w_Loan_Number: row?.w_Loan_Number })
        }
      >
        {row?.signer?.Signer_First}  {row?.signer?.Signer_Last}
      </Typography>

  }, {
    field: 'w_Company_Name',
    headerName: 'Client',
    headerClassName: 'datagrid-header',
    cellClassName: 'datagrid-cell',
    disableColumnMenu: true,
    flex: 1,
    align: 'center',
    headerAlign: 'center',
  }, {
    field: 'name',
    headerName: 'Borrower',
    headerClassName: 'datagrid-header',
    cellClassName: 'datagrid-cell',
    disableColumnMenu: true,
    flex: 1,
    sortable: false,
  }, {
    field: 'w_Signing_State',
    headerName: 'State',
    headerClassName: 'datagrid-header',
    cellClassName: 'datagrid-cell',
    disableColumnMenu: true,
    flex: 1
  }, {
    field: 'w_Appointment_Time',
    headerName: 'Local Time',
    headerClassName: 'datagrid-header',
    cellClassName: 'datagrid-cell',
    disableColumnMenu: true,
    flex: 1,
    align: 'center',
    headerAlign: 'center',
  }, {
    field: 'pst_time',
    headerName: 'PST Time',
    headerClassName: 'datagrid-header',
    cellClassName: 'datagrid-cell',
    disableColumnMenu: true,
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    sortable: false,
  }, {
    field: 'w_docs_received',
    headerName: 'Docs',
    headerClassName: 'datagrid-header',
    cellClassName: 'datagrid-cell',
    disableColumnMenu: true,
    flex: 1,
    align: 'center',
    headerAlign: 'center',
  }, {
    field: 'f_status_web',
    headerName: 'Status',
    headerClassName: 'datagrid-header',
    cellClassName: 'datagrid-cell',
    disableColumnMenu: true,
    flex: 1,
    align: 'center',
    headerAlign: 'center',
  }, {
    field: 'confirmation_call_status',
    headerName: 'Call Status',
    headerClassName: 'datagrid-header',
    cellClassName: 'datagrid-cell',
    disableColumnMenu: true,
    align: 'center',
    headerAlign: 'center',
    flex: 1
  }, {
    field: 'action',
    headerName: 'Action',
    headerClassName: 'datagrid-header',
    cellClassName: 'datagrid-cell',
    sortable: false,
    disableColumnMenu: true,
    width: 200,
    align: 'left',
    headerAlign: 'left',
    renderCell: ({ row }) =>
      <OrdersTableActions
        key={row.Id}
        row={row}
        handleCancelOrder={handleCancelOrder}
        handleChangeStatus={handleChangeStatus}
        handleLockOrder={handleLockOrder}
        handleChangeCallStatus={handleChangeCallStatus}
        handleRequestDoc={handleRequestDoc}
        handleNotes={handleNotes}
      />
  }];

  const handlePage = (e: unknown, newPage: number) => {
    setPageInfo({
      ...pageInfo,
      page: newPage
    });
  };

  const handleRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPageInfo({
      ...pageInfo,
      limit: parseInt(e.target.value, 10)
    });
  };

  const handleSort = (e: any) => {
    if (e.sortModel &&e.sortModel[0].field) {
      const { field, sort } = e.sortModel[0]
      setPageInfo({
        ...pageInfo,
        orderBy: field,
        orderType: sort,
      });
    }
  };

  return (
    <>
      <SignerInfo
        signerInfoModal={signerInfoModal}
        handleSignerInfoModal={handleSignerInfoModal}
      ></SignerInfo>
      <Table
        useCustomStyles={false}
        headerHeight={35}
        rowHeight={25}
        loading={loading}
        columns={columns}
        rows={rows}
        total={total}
        pageInfo={pageInfo}
        handlePage={handlePage}
        getRowBackGroundColor={(row, colorOptions) => getColorFromStatus(row.confirmation_call_status)}
        handleRowsPerPage={handleRowsPerPage}
        onSortingChange={handleSort}
        sortModel={[{
          field: orderBy,
          sort: orderType,
        }]}
      />
      <ColorLegend />
    </>
  );
};

export { MonitoringOrdersData };
