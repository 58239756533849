import React from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import clsx from 'clsx';

import { marginStyles } from '../../../Common/Styles/Margins';

const NotificationEmail = ({ values, handleChange }) => {
  const marginClasses = marginStyles();

  return (
    <Grid item xs={12} md={6}>
      <FormControl className={clsx(marginClasses.mb0, marginClasses.mt0)} margin='dense' variant='outlined'>
        <InputLabel>Notification Email</InputLabel>
        <Select
          label='Notification Email'
          name='notif_email_type'
          fullWidth
          value={values.notif_email_type}
          onChange={handleChange}
        >
          <MenuItem value='INDVC'>Individual Contact</MenuItem>
          <MenuItem value='ADVC'>All Division Contacts</MenuItem>
          <MenuItem value='SE'>Specific Email</MenuItem>
        </Select>
      </FormControl>
    </Grid>
  );
}

export { NotificationEmail };
