import { Grid, TextField, SvgIcon, Button } from '@material-ui/core'
import React from 'react'
import { Plus, XCircle } from 'react-feather';

export function GeneralInstructions(props) {
  const { values, setFieldValue, clientID } = props
  const defaultInstruction = { client_id: clientID, lender_id: values?.divisionID, instructions: '' }
  const generalinstructions = values?.generalinstructions?.length ? values?.generalinstructions : [defaultInstruction]

  const onChange = (value, idx) => {
    setFieldValue(`generalinstructions[${idx}]`, { ...generalinstructions[idx], instructions: value })
  }
  const onNewInstruction = () => {
    if (generalinstructions.length && !generalinstructions.filter(i => !i.instructions).length) {
      setFieldValue(`generalinstructions[${generalinstructions.length}]`, { ...defaultInstruction })
    }
  }
  const onDeleteInstruction = index => {
    setFieldValue('generalinstructions', generalinstructions.filter((i, idx) => idx !== index))
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={6} container spacing={2} >
        {generalinstructions.map((i, idx) => (
          <Grid key={idx} item container justifyContent='space-between'>
            <Grid item xs={10}>
              <TextField
                fullWidth
                size='small'
                label='description'
                variant='outlined'
                onChange={e => onChange(e.target.value, idx)}
                value={i?.instructions}
              >
              </TextField>
            </Grid>
            {idx !== 0 ? (
              <Button
                onClick={() => onDeleteInstruction(idx)}
              >
                <SvgIcon
                  fontSize='small'
                  color='primary'
                >
                  <XCircle />
                </SvgIcon>
              </Button>) :
              <Button
                color='primary'
                variant='outlined'
                onClick={() => onNewInstruction()}>
                <SvgIcon
                  fontSize='small'
                  color='primary'
                >
                  <Plus />
                </SvgIcon>
              </Button>}
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}
