import React from 'react';
import { Grid, TextareaAutosize } from '@material-ui/core';

import { useStyles } from '../Styles/Fields';

const SpecialInstruction = ({ isViewOnlyMode, location, values, handleChange }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <TextareaAutosize
        disabled={isViewOnlyMode}
        className={classes.textArea}
        placeholder='Special Instructions'
        name={`${location}.w_Additional_Information`}
        minRows={3}
        value={values.w_Additional_Information}
        onChange={handleChange}
      />
    </Grid>
  );
}

export { SpecialInstruction };
