import { selector} from 'recoil';

import { verifyAuthToken } from './Auth'
import { documentActivitypagination , refreshDocumentActivity} from '../Atoms/DocumentActivity';

import { api } from '../../Services/api';

const getDocumentActivity = selector({
    key: 'getDocumentActivity',
    get: async ({ get }) => {
      get(refreshDocumentActivity);
      const params = get(documentActivitypagination);

      try {
        await verifyAuthToken()
        const res: any = await api.GET('auth/employee/getAllDocumentActivity', {
          params
        });
        const result = res.rows.map(r => {
          return {
            ...r,
            id: r.orderID,
          }
        });
        return {
          result: {
            rows: result,
            count: res.count
          },
          success: true
        };
      } catch (err) {
        return {
          result: err,
          success: false
        };
      }
    }
  });

  export {
    getDocumentActivity,
  }; 