import React from 'react';
import { Grid, Typography, FormControlLabel, Radio } from '@material-ui/core';
import { useStyles } from '../Styles/Fields';

const Outsource = ({ isViewOnlyMode, location, values, handleChange }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item md={2}>
        <Typography className={classes.p10} variant='body2'>Outsource</Typography>
      </Grid>
      <Grid item md={2}>
        <FormControlLabel
          label='Yes'
          control={
            <Radio
              disabled={isViewOnlyMode}
              name={`${location}.w_outsource`}
              value='Y'
              checked={values.w_outsource === 'Y'}
              onChange={handleChange}
            />
          }
        />
      </Grid>
      <Grid item md={2}>
        <FormControlLabel
          label='No'
          control={
            <Radio
              disabled={isViewOnlyMode}
              name={`${location}.w_outsource`}
              value='N'
              checked={values.w_outsource === 'N'}
              onChange={handleChange}
            />
          }
        />
      </Grid>
    </Grid>
  );
}

export { Outsource };
