import * as Yup from 'yup';

const initialValues = {
  clientName: '',
  clientStatus: 'A',
  clientPhone: '',
  clientEmail: '',
  address: '',
  city: '',
  state: 'CA',
  zip: '',
  clientLocalTime: 'Pacific Standard Time (PST)',
  officeManagerName: '',
  officeManagerPhone: '',
  officeManagerExt: '',
  officeManagerCell: '',
  officeManagerEmail: '',
  shippingInfo: 'DHL',
  shippingAccount: '',
  webaddress: ''
};

const validationSchema = Yup.object().shape({
  clientName: Yup
    .string().required('Client Name is Required.'),
  clientStatus: Yup
    .string().required('Client Status is Required.'),
  clientPhone: Yup
    .string().required('Client Phone Number is Required.'),
  // clientEmail: Yup
  //   .string().email('Email Address is Invalid.').required('Client Email Address is Required.'),
  address: Yup
    .string().required('Client Address is Required.'),
  city: Yup
    .string().required('Client City is Required.'),
  state: Yup
    .string().required('Client State is Required.'),
  zip: Yup
    .string().required('Client Zipcode is Required.'),
  clientLocalTime: Yup
    .string().required('Select a Timezone.'),
  officeManagerName: Yup
    .string().required('Office Manager Name is Required.'),
  officeManagerPhone: Yup
    .string().required('Office Manager Phone Number is Required.'),
  officeManagerExt: Yup
    .string().nullable(),
  officeManagerEmail: Yup
    .string().email('Email Address is Invalid.').required('Office Manager Email Address is Required.'),
  shippingInfo: Yup
    .string().required('Please Select a Courier.'),
  webaddress:
    Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        'Enter correct url!'
      )
      .notRequired(),

});

export {
  initialValues,
  validationSchema
};
