import React, { useState } from 'react';
import {
  Grid,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Link,
  FormHelperText
} from '@material-ui/core';

import { GeneralModal } from '../../Modal/';

const NNACertifiedNotary = ({ values, handleChange, errors, touched }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  const onSelectYes = () => {
    setMessage(
      'Please Upload Your NSA Certification Under The Credentials Tab.'
    );
    handleClickOpen();
  };

  const onSelectNo = () => {
    setMessage(
      'Effective December 1st 2013, background check from a 3rd party source will be mandatory in addition to the state background check. National Notary Association offers a package which includes this Background Check as well as a NSA certification.'
    );
    handleClickOpen();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, action) => {
    setOpen(false);
  };

  return (
    <>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant='body2'>
          Are you an NNA Certified Notary Signing Agent
          {
            <Link href='#' onClick={e => e.preventDefault()}>
              {' '}
              (NSA)
            </Link>
          }
          ?
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <FormControl component='fieldset'>
          <RadioGroup
            row
            aria-label='certified_signing_agent'
            name='certified_signing_agent'
            value={values.certified_signing_agent}
            onChange={handleChange}
          >
            <FormControlLabel
              value='Y'
              onClick={onSelectYes}
              control={<Radio />}
              label='Yes'
            />
            <FormControlLabel
              value='N'
              onClick={onSelectNo}
              control={<Radio />}
              label='No'
            />
          </RadioGroup>
          {errors.certified_signing_agent && touched.certified_signing_agent && (
            <FormHelperText error>
              { errors.certified_signing_agent}
            </FormHelperText>

          )}
        </FormControl>
      </Grid>
      <GeneralModal
        open={open}
        title='PacDocSign'
        openModal={handleClose}
        noSubmit={true}
      >
        <Typography>{message}</Typography>
      </GeneralModal>
    </>
  );
};

export { NNACertifiedNotary };
