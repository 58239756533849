import React from 'react';
import { useSetRecoilState, useRecoilCallback } from 'recoil';

import { FormikComponent } from '../../Components/Formik';
import { RoleForm } from '../../Components/Admin/RoleForm';

import { refreshRoles } from '../../Recoil/Atoms/Role';
import { saveRole, updateRole } from '../../Recoil/Selectors/Role';

import { initialValues, validationSchema } from '../../Common/Rules/RoleForm';

export const NewRole = props => {
  const callRefresh = useSetRecoilState(refreshRoles);
  const refresh = () => callRefresh(n => n + 1);

  const onSubmit = useRecoilCallback(
    ({ snapshot }) => async (values: any, { setSubmitting }) => {
      let res: any = {};
      let message = '';
      if (values.id) {
        res = await snapshot.getPromise(updateRole(values));
        message = 'Role has been updated';
      } else {
        res = await snapshot.getPromise(saveRole(values));
        message = 'Role has been created';
      }
      setSubmitting(false);
      if (res.success) {
        refresh();
        props.openModal(false);
        props.setToast({
          message,
          type: 'success',
          open: true
        });
      }
    }
  );

  return (
    <FormikComponent
      onSubmit={onSubmit}
      initialValues={
        props.selectedRole.id ? props.selectedRole : initialValues
      }
      validationSchema={validationSchema}
      enableReinitialize={props.selectedRole.id ? true : false}
    >
      <RoleForm />
    </FormikComponent>
  );
};
