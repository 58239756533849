import React from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
// const GoogleApiWrapper = require('react-google-maps').GoogleApiWrapper;
// const Map = require('react-google-maps').Map;
// const Marker = require('react-google-maps').Marker;


import { useStyles } from './Styles'

function MapContainer(props) {
  const classes = useStyles();
  const mapStyles = {
    width: '100%',
    height: '400px'
  };
  const { orderLocation, signerLocation, distance, setDrivingTime = () => { }, orderAddress = '', signerAddress = '', drivingTime = {} } = props;
  const directionsService = new props.google.maps.DirectionsService();
  directionsService.route(
    {
      destination: orderAddress,
      origin: signerAddress,
      travelMode: google.maps.TravelMode.DRIVING
    },
    (result, status) => {
      if (status === google.maps.DirectionsStatus.OK && !drivingTime?.duration) {
        setDrivingTime({
          duration: result?.routes[0]?.legs[0]?.duration,
          distance: result?.routes[0]?.legs[0]?.distance
        });
      }
    }
  );
  return (
    <div className={classes.root}>
      <Map
        google={props.google}
        zoom={distance > 50 ? 5 : 10}
        style={mapStyles}
        initialCenter={orderLocation}
      />
      <Marker position={signerLocation} title='Signer' />
      <Marker position={orderLocation} title='Order' />
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
})(MapContainer);
