import React, { useState } from 'react'
import { useRecoilCallback } from 'recoil'
import { Typography, Grid, TextField } from '@material-ui/core'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'

import { GeneralModal } from '../Modal'
import { updateOrder } from '../../Recoil/Selectors/Orders'

export const TrackingStatus = ((props: any) => {
  const { handleTrackIngStatus, order, setRefetchOrders } = props
  const [trackingNumber, setTrackingNumber] = useState(order?.w_Return_Tracking)
  const [status, setStatus] = useState(order?.S2C_Tracking_Status || '')


  const [comment, setComment] = useState(order?.S2C_Tracking_Note)

  const [loading, setLoaing] = useState(false)




  const onSubmit = useRecoilCallback(({ snapshot }) => async () => {
    if (!trackingNumber) {
      enqueueSnackbar(
        'Please fill all the fields',
        {
          variant: 'error'
        }
      );
      return
    }

    setLoaing(true)
    const res: any = await snapshot.getPromise(updateOrder({
      w_Loan_Number: [],
      location_one: {
        S2C_Tracking_Status: status,
        w_Return_Tracking: trackingNumber,
        Id: order?.Id,
        S2C_Tracking_Note: comment
      }
    }));
    if (res.success) {
      setComment('')
      setStatus('')
      handleTrackIngStatus(false)
      setRefetchOrders(n => n + 1)
      setLoaing(false)
      enqueueSnackbar(
        'Order details updated',
        {
          variant: 'success'
        }
      );
    }
    else {
      handleTrackIngStatus(false)
      setLoaing(false)
      enqueueSnackbar(
        res?.result?.message || 'Error occurred',
        {
          variant: 'error'
        }
      );
    }
  })
  return (
    <SnackbarProvider>
      <GeneralModal
        loading={loading}
        maxWidth='sm'
        open={order}
        title='Signer To Client Tracking Status'
        openModal={e => handleTrackIngStatus(null)}
        submitLabel='Submit'
        handleSubmit={onSubmit}
      >
        <Grid container xs={12} spacing={3}>
          <Grid container item justifyContent='space-between'>
            <Typography>
              <b>
                Loan Number
              </b>
            </Typography>
            <Typography>
              {order?.w_Loan_Number}
            </Typography>
          </Grid>
          <Grid container item justifyContent='space-between'>
            <TextField
              label='Tracking #'
              size='small'
              variant='outlined'
              fullWidth
              value={trackingNumber}
              onChange={e => setTrackingNumber(e.target.value)}
            >
            </TextField>
          </Grid>
        </Grid>

      </GeneralModal>
    </SnackbarProvider>

  )
})
