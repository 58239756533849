import React from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

import { useStyles } from '../Styles/Fields';

const LocalTime = ({ values, handleChange }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} md={6}>
      <FormControl className={classes.removeSelectMargin} margin='dense' variant='outlined' fullWidth>
        <InputLabel>Timezone</InputLabel>
        <Select
          label='Timezone'
          name='clientLocalTime'
          fullWidth
          value={values.clientLocalTime}
          onChange={handleChange}
        >
          <MenuItem value='Pacific Standard Time (PST)'>Pacific Standard Time (PST) UTC-8</MenuItem>
          <MenuItem value='Mountain Standard Time (MST)'>Mountain Standard Time (MST) UTC-7</MenuItem>
          <MenuItem value='Central Standard Time (CST)'>Central Standard Time (CST) UTC-6</MenuItem>
          <MenuItem value='Eastern Standard Time (EST)'>Eastern Standard Time (EST) UTC-5</MenuItem>
          <MenuItem value='Hawaii-Aleutian Standard Time (HST)'>Hawaii-Aleutian Standard Time (HST) UTC-10</MenuItem>
        </Select>
      </FormControl>
    </Grid>
  );
}

export { LocalTime };
