import React from 'react';
import { useRecoilState } from 'recoil'

import { processorsReportPagination } from './../../Recoil/Atoms/Reports'

import { Table } from '../Table';

const ProcessorsReportData = (props: any) => {

  const { data, loading, total } = props
  const [page, setPage] = useRecoilState(processorsReportPagination)

  const columns = [{
    field: 'Contact_First_Name',
    headerName: 'First Name'
    }, {
    field: 'Contact_Last_Name',
    headerName: 'Last Name'
    }, {
    field: 'Contact_Email',
    headerName: 'Email'
    }, {
    field: 'Contact_Cell',
    headerName: 'Phone Number'
    }, {
    field: 'Contact_Work_Phone',
    headerName: 'Work Phone Number'
    }];

  const handlePage = (e: unknown, newPage: number) => {
    setPage({
      ...page,
      page: newPage
    });
  };

  const handleRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPage({
      ...page,
      limit: parseInt(e.target.value, 10)
    });
  };

  return (
    <Table
      loading={loading}
      columns={columns}
      rows={data}
      total={total}
      handlePage={handlePage}
      handleRowsPerPage={handleRowsPerPage}
    />
  );
};

export { ProcessorsReportData };
