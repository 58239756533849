import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import InputMask from 'react-input-mask';

const EmergencyContactPhone = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  disabled
}) => {
  return (
    <Grid item xs={12} md={6} lg={6}>
      {disabled ? (
        <TextField
          label='Phone Number'
          name='emergency_phone_no'
          variant='outlined'
          size='small'
          fullWidth
          value={values.emergency_phone_no}
          helperText={touched.emergency_phone_no && errors.emergency_phone_no}
          error={Boolean(
            touched.emergency_phone_no && errors.emergency_phone_no
          )}
          disabled={disabled}
        />
      ) : (
        <InputMask
          mask='(999) 999-9999'
          maskChar=''
          value={values.emergency_phone_no}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          {() => (
            <TextField
              label='Phone Number'
              name='emergency_phone_no'
              value={values.emergency_phone_no}
              variant='outlined'
              size='small'
              fullWidth
              helperText={
                touched.emergency_phone_no && errors.emergency_phone_no
              }
              error={Boolean(
                touched.emergency_phone_no && errors.emergency_phone_no
              )}
            />
          )}
        </InputMask>
      )}
    </Grid>
  );
};

export { EmergencyContactPhone };
