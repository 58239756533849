import React from 'react';
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText
} from '@material-ui/core';

import { useStyles } from '../Styles/Fields';

const SignerAccountType = ({
                             values,
                             errors,
                             setFieldValue,
                             handleChange,
                             disabled,
                             touched
                           }) => {

  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <FormControl
          variant='outlined'
          size='small'
          classes={{ root: classes.selectWidth }}
          error={Boolean(touched.state && errors.state)}
        >
          <InputLabel>Account Type</InputLabel>
          <Select
            error={Boolean(touched.state && errors.state)}
            label='Signer_Account_Type'
            name='Signer_Account_Type'
            fullWidth
            value={values.Signer_Account_Type || ''}
            onChange={handleChange}
            placeholder='Signer_Account_Type'
            disabled={disabled}
          >

            <MenuItem key={'borrower-company'} value={'COMPANY'}>
              Company
            </MenuItem>
            <MenuItem key={'borrower-individual'} value={'INDIVIDUAL'}>
              Individual
            </MenuItem>
          </Select>
          {touched.state && errors.state &&
              <FormHelperText>
                {errors.Signer_Account_Type}
              </FormHelperText>
          }
        </FormControl>
      </Grid>
    </>
  );
};

export { SignerAccountType };
