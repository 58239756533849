import * as Yup from 'yup';

const initialValues = {
  divisionName: '',
  billing_cycle: 'Monthly',
  billing_email_type: 'INDVC',
  billing_email: '',
  notif_email_type: 'INDVC',
  notif_email: '',
  status: 'A',
  default_docs_received: 'N',
  default_docs_method: 'CA',
  fees: [{
    default: 'N',
    default_dayofmonth_start: '',
    default_dayofmonth_end: '',
    fee_name: 'Standard Signing /E-Docs',
    fee_amount: '',
    attorney_fee: '',
    bill_amount: '',
    attorney_bill: ''
  }],
  contacts: [{
    contactID: '',
    privilege: 'Self',
    status: 'A'
  }],
  states: [{
    state_id: ''
  }],
};

const validationSchema = Yup.object().shape({
  divisionName: Yup
    .string().required('Division Name is Required.'),
  billing_cycle: Yup
    .string().required('Select a Billing Cycle'),
  billing_email_type: Yup
    .string().required('Select a Billing Email Type'),
  notif_email_type: Yup
    .string().required('Select an Email Notification Type'),
  default_docs_method: Yup
    .string().required('Select a Default Document Method'),
  status: Yup
    .string().required('Select a Status'),
  default_docs_received: Yup
    .string().required('Division Name is Required.'),
  fees: Yup
    .array()
    .of(
      Yup.object({
        default: Yup.string().optional(),
        default_dayofmonth_start: Yup.string().optional(),
        default_dayofmonth_end: Yup.string().optional(),
        fee_name: Yup.string().required('Please Select a Service Type'),
        fee_amount: Yup.string().required('Please Enter Notary Fee'),
        attorney_fee: Yup.string().required('Please Enter Attorney Fee'),
        bill_amount: Yup.string().required('Please Enter Client Notary Fee'),
        attorney_bill: Yup.string().required('Please Enter Client Attorney Fee'),
      })
    ),
  contacts: Yup
    .array()
    .of(
      Yup.object({
        contactID: Yup.string().notRequired(),
        privilege: Yup.string().notRequired()
      })
    ),
  states: Yup
    .array()
    .of(
      Yup.object({
        state_id: Yup.string().notRequired()
      })
    ).notRequired()
});

export {
  initialValues,
  validationSchema
};
