import React, { useState } from 'react';
import { useRecoilState } from 'recoil';

import { Box, IconButton, Tooltip } from '@material-ui/core';
import { FileText, Eye } from 'react-feather';

import { NewOrder } from '../../../Pages/Orders/AddEditViewOrder';
import { SlidingPane } from '../../SlidingPane';
import { getCreateBillPopup } from './../../../Recoil/Atoms/Orders';

const UnbilledOrderDataActions = ({ row, handleCreateOrderBillCurrentRow }) => {
  const [currentOrderId, setCurrentOrderId] = useState(null);
  const [paneOpen, setPaneOpen] = useState(false);

  const handlePaneOpen = (orderId) => {
    setCurrentOrderId(orderId);
    setPaneOpen(true);
  };

  const handlePaneClose = () => {
    setPaneOpen(false);
    setCurrentOrderId(null); // Reset current order ID when closing the pane
  };
  const [billPopup, setBillPopup] = useRecoilState(getCreateBillPopup);

  const openPopup = () => {
    setBillPopup({
      orderId: row?.Id,
      refetch: billPopup?.refetch + 1
    });
    handleCreateOrderBillCurrentRow(row);
  };

  return (
    <Box display='flex' width='100%'>
      <Tooltip title='View'>
        <IconButton
          size='small'
          color='primary'
          onClick={() => {
            handlePaneOpen(row?.Id);
          }}
        >
          <Eye width='25' height='25' />
        </IconButton>
      </Tooltip>

      <Tooltip title='Create Bill'>
        <IconButton size='small' color='primary' onClick={() => openPopup()}>
          <FileText width='25' height='25' />
        </IconButton>
      </Tooltip>
      <SlidingPane
        title={'View Invoice'}
        open={paneOpen}
        handlePane={handlePaneClose}
        width='95%'
      >
        {paneOpen && (
          <NewOrder
            key={currentOrderId || 'new'}
            id={currentOrderId}
            open={paneOpen}
            justView={true}
          />
        )}
      </SlidingPane>
    </Box>
  );
};

export { UnbilledOrderDataActions };
