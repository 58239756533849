import React from 'react'
import {
    Box,
    Typography,
    List,
    ListItem,
    Grid,
    Select,
    MenuItem,
    FormControl,
    InputLabel
} from '@material-ui/core'
import moment from 'moment'

import { storage } from '../../Services/storage';
import { useStyles } from './Styles/Fields';


export const BillingInfo = (props: any) => {

    const { values, handleChange, location } = props
    const classes = useStyles();

    const role = storage.get('userRole') || 'unknown'

    const BillingFields = [
        {
            value: 'payment_status', label: 'Payment Status',
            render: (
                <FormControl
                    className={classes.removeSelectMargin}
                    fullWidth
                    margin='dense'
                    variant='outlined'
                    disabled={role.toLowerCase() !== 'accountant'}
                >
                    <InputLabel>Payment Status</InputLabel>
                    <Select
                        label='Payment Status'
                        name={`${location}.payment_status`}
                        fullWidth
                        value={values.payment_status}
                        variant='outlined'
                        onChange={handleChange}
                    >
                        <MenuItem value='Invoice Disputed'>Invoice Disputed</MenuItem>
                        <MenuItem value='Invoice Submitted'>Invoice Submitted</MenuItem>
                        <MenuItem value='Paid'>Paid</MenuItem>
                        <MenuItem value='Pending'>Pending</MenuItem>
                    </Select>

                </FormControl>
            )
        },
        { value: 'invoice_send_status', label: 'Invoice Status', render: <Typography>{values?.invoice_send_status === 0 ? 'Not Sent' : 'Sent'}</Typography> },
        { value: 'payment_check_no', label: 'Check #' },
        {
            value: 'payment_check_date', label: 'Check Date',
            render: <Typography>{values.payment_check_date ? moment(values?.payment_check_date).format('MM/DD/YYYY hh:mm A') : ''}</Typography>
        },
    ]

    return (
        <Box style={{ width: '100%' }}>
            <List component='nav'>
                {BillingFields.map(field => (
                    <ListItem button>
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography variant='body1'>{field?.label}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                {field.render ? field.render : <Typography variant='body1'>{values[field['value']]}</Typography>}
                            </Grid>
                        </Grid>
                    </ListItem>
                ))}
            </List>
        </Box>
    )
}