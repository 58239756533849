import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    height: '400px',
    width: '100%',
    ' & > div': {
      position: 'relative !important'
    }
  }

}))
