import React from 'react';
import { Grid, TextField } from '@material-ui/core';

const UserPassword = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur
}) => {
  return (
    <Grid container xs={12}>
      <TextField
        label='Password'
        name='password'
        variant='outlined'
        size='small'
        type="password"
        fullWidth
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.password && errors.password}
        error={Boolean(touched.password && errors.password)}
      />
    </Grid>
  );
};

export { UserPassword };
