import React from 'react';
import { useRecoilCallback, useSetRecoilState } from 'recoil';
import { Box, Divider, IconButton, SvgIcon, Tooltip } from '@material-ui/core';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';

import { Table } from '../Table';
import Label from 'src/ThemeComponents/components/Label';

import { LogIn } from 'react-feather';
import { forceSignerLogin } from './../../Recoil/Atoms/Signers'
import { masterLoginRequest } from '../../Recoil/Selectors/Signer';

const SignerLoginData = (props) => {

    const { setPageinfo, pageInfo, handleRowsPerPage, loading, total, rows } = props
    const loginRequest = useSetRecoilState(forceSignerLogin)

    const sendEmailLinkToLogin = useRecoilCallback(({ snapshot }) => async (Id: any) => {
        loginRequest(n => n + 1)
        const res = await snapshot.getPromise(masterLoginRequest({ signer_id: Id }))
        if (res.success) {
            const tempLink = document.createElement('a');
            tempLink.href = res?.result;
            tempLink.setAttribute('target', '_blank');
            tempLink.click();
        } else {
            enqueueSnackbar(res?.result?.error || 'Login Failed!', { variant: 'error' });
        }
    })

    const columns = [
        {
            field: 'signer_id',
            headerName: 'Signer ID',
            disableColumnMenu: true,
            align: 'left',
            headerAlign: 'left',
            headerClassName: 'datagrid-header',
            cellClassName: 'datagrid-cell',
        }, {
            field: 'Signer_First',
            headerName: 'First Name',
            disableColumnMenu: true,
            align: 'left',
            headerAlign: 'left',
            headerClassName: 'datagrid-header',
            cellClassName: 'datagrid-cell',
        }, {
            field: 'Signer_Last',
            headerName: 'Last Name',
            disableColumnMenu: true,
            align: 'left',
            headerAlign: 'left',
            flex: 1,
            headerClassName: 'datagrid-header',
            cellClassName: 'datagrid-cell',
        }, {
            field: 'Signer_Email',
            headerName: 'Email',
            disableColumnMenu: true,
            align: 'left',
            headerAlign: 'left',
            flex: 1,
            headerClassName: 'datagrid-header',
            cellClassName: 'datagrid-cell',
        }, {
            disableColumnMenu: true,
            field: 'Signer_Status',
            headerName: 'Status',
            align: 'left',
            headerAlign: 'left',
            width: 80,
            renderCell: (e) => (
                <Label color={e.value && e.value.toLowerCase() === 'active' ? 'success' : 'secondary'}>{e.value}</Label>
            ),
            headerClassName: 'datagrid-header',
            cellClassName: 'datagrid-cell',
        }, {

            field: 'action',
            headerName: 'Actions',
            width: 165,
            align: 'left',
            headerAlign: 'left',
            sortable: false,
            disableColumnMenu: true,
            headerClassName: 'datagrid-header',
            cellClassName: 'datagrid-cell',
            renderCell: (details) => (
                <Box display='flex'>
                    <Tooltip title='Login'>
                        <IconButton color='inherit' onClick={() => sendEmailLinkToLogin(details.row.signer_id)}>
                            <SvgIcon fontSize='small' color='primary'>
                                <LogIn />
                            </SvgIcon>
                        </IconButton>
                    </Tooltip>
                </Box>
            )
        }];

    const onSortingChange = (e) => {
        if (e.sortModel && e?.sortModel[0]?.field) {
            setPageinfo({
                ...pageInfo, orderBy: e.sortModel[0].field, orderType: e.sortModel[0].sort
            })
        }
    }

    return (
        <SnackbarProvider >
            <>
                <Box my={2}>
                    <Divider variant='fullWidth' />
                </Box>
                <Table
                    customRowHeight={80}
                    useCustomStyles={true}
                    loading={loading}
                    handlePage={(e, p) => {
                        setPageinfo({
                            ...pageInfo, page: p
                        })
                    }}
                    sortModel={[{
                        field: pageInfo.orderBy,
                        sort: pageInfo.orderType
                    }]}
                    onSortingChange={onSortingChange}
                    handleRowsPerPage={handleRowsPerPage}
                    pageInfo={{ page: pageInfo.page, limit: pageInfo.limit }}
                    total={total}
                    columns={columns}
                    rows={rows}
                    uniqueKey='signer_id'
                />
            </>
        </SnackbarProvider>

    );
};

export const SignersLoginData = (SignerLoginData);
