import React from 'react';
import { Grid, TextField } from '@material-ui/core';

const ManagerEmail = ({ values, errors, touched, handleChange, handleBlur }) => {
  return (
    <Grid item xs={12} md={6}>
      <TextField
        label='Email'
        name='officeManagerEmail'
        variant='outlined'
        size='small'
        fullWidth
        value={values.officeManagerEmail}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.officeManagerEmail && errors.officeManagerEmail}
        error={Boolean(touched.officeManagerEmail && errors.officeManagerEmail)}
      />
    </Grid>
  );
}

export { ManagerEmail };
