import React, { useState } from 'react';
import {
  Grid,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core';

import { GeneralModal } from '../../Modal/';

const PreferredNotary = ({ values, handleChange }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  const onSelectYes = () => {
    setMessage(
      'Please Upload Your NSA Certification Under The Credentials Tab.'
    );
    handleClickOpen();
  };

  const onSelectNo = () => {
    setMessage(
      'Effective December 1st 2013, background check from a 3rd party source will be mandatory in addition to the state background check. National Notary Association offers a package which includes this Background Check as well as a NSA certification.'
    );
    handleClickOpen();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant='body2'>
          PacDocSign Preferred Notary (PPN)
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <FormControl component='fieldset'>
          <RadioGroup
            row
            aria-label='signer_preffered_ind'
            name='signer_preffered_ind'
            value={values.signer_preffered_ind}
            onChange={handleChange}
          >
            <FormControlLabel
              value='E'
              control={<Radio size='small' />}
              label='Elite'
            />
            <FormControlLabel
              value='Y'
              onClick={onSelectYes}
              control={<Radio size='small' />}
              label='Yes'
            />
            <FormControlLabel
              value='N'
              onClick={onSelectNo}
              control={<Radio size='small' />}
              label='No'
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <GeneralModal
        open={open}
        title='PacDocSign'
        openModal={handleClose}
        noSubmit={true}
      >
        <Typography>{message}</Typography>
      </GeneralModal>
    </>
  );
};

export { PreferredNotary };
