import React, { useState } from 'react';
import { useRecoilState } from 'recoil'
import {
    Box,
    Grid,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    TextField,
    Button
} from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment'

import { DailyPayBillPagination } from './../../../Recoil/Atoms/Accounting'

import { marginStyles } from '../../../Common/Styles/Margins';

const DailyPayBillAction = () => {

    const marginClasses = marginStyles();
    const [filter, setFiler] = useState('loan')
    const [value, setValue] = useState('')
    const [date, setDate] = useState(moment())
    const [search, setSearch] = useRecoilState(DailyPayBillPagination)

    return (
        <Box my={2} display='flex'>
            <Grid
                container
                direction='row'
                justifyContent='flex-start'
                alignItems='center'
            >
                <Grid item>
                    <FormControl
                        margin='dense'
                        variant='outlined'
                        className={clsx(
                          marginClasses.mt0,
                          marginClasses.mb0,
                          marginClasses.mr2
                        )}
                    >
                        <InputLabel>Select</InputLabel>
                        <Select
                            value={filter}
                            label='Select'
                            variant='outlined'
                            onChange={(e: any) => setFiler(e.target.value)}
                        >
                            <MenuItem value={'loan'}>Loan/Order#</MenuItem>
                            <MenuItem value={'signer'}>Signer Name</MenuItem>
                            <MenuItem value={'id'}>Order Id#</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item> 
                    <TextField
                        label='Search'
                        name='value'
                        variant='outlined'
                        size='small'
                        value={value}
                        onChange={ (e: any) => setValue(e.target.value) }
                        className={clsx(
                            marginClasses.mr2
                        )}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        id='date'
                        type='date'
                        variant='outlined'
                        onChange={ (e: any) => setDate(moment(e.target.value)) }
                        defaultValue={date}
                        size='small'
                        className={clsx(
                            marginClasses.mr2
                        )}
                    />
                </Grid>

                <Grid item>
                    <Button
                        color='primary'
                        variant='contained'
                        size='medium'
                        fullWidth
                        onClick = { () => setSearch({ ...search, filter, date: date.format('MM/DD/YYYY'), value }) }
                    >
                        Search
                    </Button>
                </Grid>

            </Grid>
        </Box>
    )
}

export { DailyPayBillAction };
