import { selector, selectorFamily } from 'recoil';

import { verifyAuthToken } from './Auth'
import { userpagination, refreshUsers } from '../Atoms/User';

import { api } from '../../Services/api';

const getUsers = selector({
    key: 'getUsers',
    get: async ({get}) => {
        get(refreshUsers);
        const params = get(userpagination);

        try {
            await verifyAuthToken()
            const res: any = await api.GET('auth/employee/get/employees', {
                params
            });

            return {
                result: {
                    rows: res.rows,
                    count: res.count
                },
                success: true
            };
        } catch (err) {
            return {
                result: err,
                success: false
            };
        }
    }
});

const saveUser = selectorFamily({
    key: 'saveUser',
    get: (params: any) => async ({get}) => {
        if (params) {
            try {
                await verifyAuthToken()
                const res: any = await api.POST('auth/employee/create-employee', params);
                return {
                    result: res,
                    success: true
                };
            } catch (err) {
                return {
                    result: err,
                    success: false
                };
            }
        }
    }
});


const updateUser = selectorFamily({
    key: 'updateUser',
    get: (params: any) => async ({get}) => {
        if (params) {
            try {
                delete params.role
                delete params.team_lead
                await verifyAuthToken()
                const res: any = await api.PUT('/auth/employee/user/update', params);
                if (res[0] === 0) {
                    return {
                        result: {
                            error: 'Unable to Update the Record!'
                        },
                        success: false
                    }
                }

                return {
                    result: res,
                    success: true
                };
            } catch (err) {
                return {
                    result: err,
                    success: false
                };
            }
        }
    }
});

const getAllSignerUsers = selectorFamily({
    key: 'getAllSignerUsers',
    get: (signerId) => async ({get}) => {
        try {
            await verifyAuthToken()
            const res: any = await api.GET(`signer/user/${signerId.toString()}`);
            return {
                result: res,
                success: true
            }
        } catch (error) {
            return {
                result: [],
                success: false
            }
        }
    }
});

export {
    getUsers,
    saveUser,
    updateUser,
    getAllSignerUsers
};
