import React from 'react';
import {
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText
} from '@material-ui/core';
import { SignerTypes } from '../../../Common/Rules/SignerForm';

const SignerType = ({ values, handleChange, errors, touched }) => {
  return (
    <Grid item sm={8} xs={8} md={6} lg={6}>
      <Typography variant='body2'>Signer Type</Typography>
      <RadioGroup
        row
        aria-label='signer_type'
        name='signer_type'
        value={values.signer_type}
        onChange={handleChange}
      >
        {SignerTypes.map((t) => (
          <FormControlLabel value={t} control={<Radio />} label={t} />
        ))}
      </RadioGroup>
      {errors.signer_type && touched.signer_type &&
        <FormHelperText error={errors.signer_type}>
          {errors.signer_type}
        </FormHelperText>
      }
    </Grid>
  );
};

export { SignerType };
