import React, { useState } from 'react';
import { useRecoilCallback } from 'recoil'
import moment from 'moment'

import { Grid, Button, Typography, CardMedia } from '@material-ui/core';

import { Toast } from '../../../Components/Toasts';
import { GeneralModal } from './../../Modal/index';
import { Table } from '../../Table';

import { sendClientConfirmation, viewClientConfirmation } from '../../../Recoil/Selectors/Clients';

import { useStyles } from '../Styles/Fields'

const ClientConfirmation = (params: any) => {
  const classes = useStyles()
  const [toast, setToast] = useState({ message: '', type: 'success', open: false });
  const [submitting, setSubmitting] = useState(false)
  const [file, setFile] = useState({ open: false, fileURL: undefined })
  const [confirmationListModal, setConfirmationListModal] = useState(false);
  const { values } = params


  const columns = [{
    field: 'Confirmation Sent At',
    headerName: 'Confirmation Sent At',
    renderCell: (data) => (
      <Typography>{moment(data?.row?.dateTime).format("MM/DD/YYYY HH:mm A")}</Typography>
    )
  }, {
    field: 'Sent By',
    headerName: 'Sent By',
    renderCell: (data) => (
      <Typography>{data?.row?.employee?.firstName} {data?.row?.employee?.lastName}</Typography>
    )
  }];

  const sendConfirmation = useRecoilCallback(({ snapshot }) => async () => {
    setSubmitting(true)
    const { result, success } = await snapshot.getPromise(sendClientConfirmation({ Id: values.Id, diff: new Date() }));

    if (success) {
      setToast({ message: result.data || 'Client Confirmation Sent', type: 'success', open: true });
    } else {
      setToast({ message: 'Failed to send confirmation', type: 'error', open: true });
    }
    setSubmitting(false)
  });

  const viewConfirmation = useRecoilCallback(({ snapshot }) => async () => {
    setSubmitting(true)
    const { result, success } = await snapshot.getPromise(viewClientConfirmation({ Id: values.Id, diff: new Date() }));

    if (success) {
      const fileURL = URL.createObjectURL(result);
      setFile({ open: true, fileURL })
    } else {
      setToast({ message: 'Failed to fetch confirmation', type: 'error', open: true });
    }
    setSubmitting(false)
  });
  const toggleConfirmationListModal = () => {
    setConfirmationListModal(!confirmationListModal);
  };

  return (
    <>
      <Grid item xs={3}>
        <Typography variant='body2' className={classes.fieldHeader} >Client Confirmation</Typography>
      </Grid>
      <Grid item xs={9}>
        {values.clientConfirmationList && values.clientConfirmationList.length ? values.clientConfirmationList.slice(0, 3).map((confirmation, index) =>
          <Typography
            variant='body2'
            key={index}
          >
            Confirmed @
            {moment(confirmation?.dateTime).format("MM/DD/YYYY HH:mm A")} {" By "}
            {confirmation?.employee?.firstName} {confirmation?.employee?.lastName}
          </Typography>)
          : <Typography variant='body2'>
            No
          </Typography>}
        {values.clientConfirmationList && values.clientConfirmationList.length && values.clientConfirmationList.length > 4 ?
          <Button
            color='primary'
            onClick={toggleConfirmationListModal}
            variant='outlined'
          >
            Show More
          </Button> : ""}

      </Grid>
      <Grid item xs={12}>
        <Button
          disabled={Boolean(submitting || !values?.signer)}
          color='primary'
          onClick={sendConfirmation}
          variant='outlined'
        >
          Send Client Confirmation
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          disabled={submitting || !values?.signer}
          color='primary'
          onClick={viewConfirmation}
          variant='outlined'>
          View Client Confirmation
        </Button>
      </Grid>
      <Toast message={toast.message} type={toast.type} open={toast.open} setToast={setToast} />

      <GeneralModal
        open={confirmationListModal}
        title='Client Confirmations'
        loading={false}
        openModal={setConfirmationListModal}
        noSubmit={false}
        noFooter={true}
        maxWidth='sm'
      >
        <Table
          columns={columns}
          rows={values.clientConfirmationList}
        />

      </GeneralModal>

      <GeneralModal
        title='Confirmation'
        submitLabel='OK'
        noSubmit
        openModal={() => setFile({ open: false, fileURL: undefined })}
        open={file.open}
        maxWidth='xl'
      >
        <CardMedia
          component='iframe'
          src={file.fileURL}
          title='Confirmation'
          className={classes.windowHeight}
        />
      </GeneralModal>
    </>
  );
}

export { ClientConfirmation };
