import React from 'react';
import { Grid } from '@material-ui/core';

import { UserFirstName } from './UserFields/UserFirstName';
import { UserLastName } from './UserFields/UserLastName';
import { UserEmail } from './UserFields/UserEmail';
import { UserPassword } from './UserFields/UserPassword';
import { UserRole } from './UserFields/UserRole';
import { UserPhoneNo } from './UserFields/UserPhoneNo';

const UserForm = props => {
  const { values, errors, touched, handleChange, handleBlur } = props;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={12}>
        <UserFirstName
          values={values}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <UserLastName
          values={values}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <UserEmail
          values={values}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
      </Grid>
      
      <Grid item xs={12} md={12}>
        <UserPhoneNo
          values={values}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
      </Grid>
      {!values.id &&   
      <Grid item xs={12} md={12}>
        <UserPassword
          values={values}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
      </Grid>
      }
      <Grid item xs={12} md={12}>
        <UserRole values={values} handleChange={handleChange} />
      </Grid>
    </Grid>
  );
};

export { UserForm };