import { atom } from 'recoil';
// import moment from 'moment'

const orders = atom({
  key: 'orders',
  default: []
});

const currentOrder = atom({
  key: 'currentOrder',
  default: {
    Id: null,
    f_status_web: '',
    order_locked: '',
    confirmation_call_status: '',
    f_signer_id: ''
  }
});

const ordersPagination = atom({
  key: 'ordersPagination',
  default: {
    page: 1,
    limit: 20,
    orderBy: '',
    orderType: '',
  }
});

const monitoringOrderPagination = atom({
  key: 'monitoringOrderPagination',
  default: {
    page: 1,
    limit: 20,
    orderBy: '',
    orderType: '',
  }
});

const orderFilters = atom({
  key: 'orderFilters',
  default: {
    client: null,
    division: null,
    contact: null,
  }
});

const orderRangeDetailPagination = atom({
  key: 'orderRangeDetailPagination',
  default: {
    client: null,
    division: null,
    contact: null,
    orderBy: 'Id',
    orderType: 'DESC',
    start: null, // moment().format('MM/DD/YYYY'),
    end: null, // moment().format('MM/DD/YYYY'),
    filter: 'w_sql_appointment_date',
    limit: 20,
    page: 1
  }
});

const refetchOrders = atom({
  key: 'refetchOrders',
  default: 0
});

const refetchMonitoringOrders = atom({
  key: 'refetchMonitoringOrders',
  default: 0
});

const refetchNearBySigners = atom({
  key: 'refetchNearBySigners',
  default: 0
});

const forceUpdateFiles = atom({
  key: 'forceUpdateFiles',
  default: 0
});

const forceDownload = atom({
  key: 'forceDownload',
  default: 0
});

const forceChangeAccountingStatus = atom({
  key: 'forceChangeAccountingStatus',
  default: 0
});

const searchedOrders = atom({
  key: 'searchedOrders',
  default: {
    value: '',
    field: ''
  }
});

const forceCreateOrder = atom({
  key: 'forceCreateOrder',
  default: {
    value: 0
  }
});

const feeDetails = atom({
  key: 'fees',
  default: {
    fees: [],
    isLoaded: false,
  }
});

const unassignedOrdersPagination = atom({
  key: 'unassignedOrdersPagination',
  default: {
    page: 1,
    limit: 50,
    orderBy: '',
    orderType: '',
    search: '',
    orderId: ''
  }
});

const assignSignersPagination = atom({
  key: 'assignSignersPagination',
  default: {
    page: 1,
    limit: 20,
    distance: '50',
    orderId: '',
    signerType: 'signer_w_history',
    searchWith: '',
    orderBy: 'distance',
    orderType: 'ASC',
    search: '',
    cached: true
  }
});

const assignSignerOrderId = atom({
  key: 'assignSignerOrderId',
  default: {
    orderId: '',
  }
});

const refetchNotes = atom({
  key: 'refetchNotes',
  default: 0
});

const notesParams = atom({
  key: 'notesParams',
  default: {
    Id: ''
  }
});

const uploadedBy = atom({
  key: 'uploadedBy',
  default: {
    id: 0,
    type: ''
  }
});

const forceFetchFees = atom({
  key: 'forceFetchFees',
  default: 0
});

const currentTimer = atom({
  key: 'currentTimer',
  default: -1
});

const forceUnassignOrder = atom({
  key: 'forceUnassignOrder',
  default: 0
});

const refreshOrders = atom({
  key: 'refreshOrders',
  default: 0
});
const assignAttemptsOrderId = atom({
  key: 'assignAttemptsOrderId',
  default: {
    borrower_id: -1,
    created_by: -1
  }
});

const refetchAssignAttempts = atom({
  key: 'refetchAssignAttempts',
  default: 0
});

const forceReassign = atom({
  key: 'forceReassign',
  default: 0
});

const forceSendResult = atom({
  key: 'forceSendResult',
  default: 0
});

const forceMoveAll = atom({
  key: 'forceMoveAll',
  default: 0
});

const forceResendDocumentReadyEmail = atom({
  key: 'forceResendDocumentReadyEmail',
  default: 0
});

const forceClearNerbySignerCache = atom({
  key: '',
  default: 0
});

const forceLockOrder = atom({
  key: 'forceLockOrder',
  default: 0
});

const signerCallAlerts = atom({
  key: 'signerCallAlerts',
  default: []
});

const getCreateBillPopup = atom({
  key: 'getCreateBillPopup',
  default: {
    orderId: undefined,
    refetch: 0
  }
});

export {
  orders,
  currentOrder,
  ordersPagination,
  monitoringOrderPagination,
  refetchOrders,
  refetchMonitoringOrders,
  forceUpdateFiles,
  searchedOrders,
  orderFilters,
  forceCreateOrder,
  feeDetails,
  unassignedOrdersPagination,
  assignSignersPagination,
  refetchNearBySigners,
  notesParams,
  refetchNotes,
  uploadedBy,
  forceFetchFees,
  currentTimer,
  forceUnassignOrder,
  refreshOrders,
  assignAttemptsOrderId,
  refetchAssignAttempts,
  forceReassign,
  forceSendResult,
  forceDownload,
  forceChangeAccountingStatus,
  orderRangeDetailPagination,
  forceMoveAll,
  forceResendDocumentReadyEmail,
  assignSignerOrderId,
  forceClearNerbySignerCache,
  forceLockOrder,
  signerCallAlerts,
  getCreateBillPopup
};

