import type { Theme } from './../../../ThemeComponents/theme';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  box: {
    backgroundColor: theme.palette.background.paper,
    minHeight: 120,
    paddingLeft: 20
  },
  iconContainer: {
    background: theme.palette.background.dark,
    height: 120,
    width: 120,
    padding: 30
  }
}));
