import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { storage } from './storage';
import { config, actionCodeSettings } from '../Static/Firebase/Config';

firebase.initializeApp(config);
const AUTH = firebase.auth();

const sendSignInLinkToEmail = (email) => {
  const promise = async (resolve, reject) => {
    try {
      await AUTH.sendSignInLinkToEmail(email, actionCodeSettings);
      storage.set('userEmail', email);
      resolve();
    } catch(err) {
      reject(err.message);
    }
  };

  return new Promise(promise);
};

const isSignInWithEmailLink = async () => {
  const isSignin = await AUTH.isSignInWithEmailLink(window.location.href);
  if (isSignin) {
    let email = storage.get('userEmail');
    if (!email) {
      email = window.prompt('Please provide your email for confirmation');
    }
    const promise = async (resolve, reject) => {
      try {
        const res = await AUTH.signInWithEmailLink(email, window.location.href);
        storage.set('userInfo', res.user);
        resolve({
          success: true
        });
      } catch(err) {
        reject(err.message);
      }
    };

    return new Promise(promise);
  }

  return false;
};

const signOut = () => {
  const promise = async (resolve, reject) => {
    try {
      await AUTH.signOut();
      resolve();
    } catch(err) {
      reject(err.message);
    }
  };

  return new Promise(promise);
};

export { sendSignInLinkToEmail, isSignInWithEmailLink, signOut };
