import * as Yup from 'yup';

import { PasswordRegex } from '../Regex/Password';

export const initialValues = {
  new_password: '',
  confirm_password: ''
};

export const validationSchema = Yup.object().shape({
  new_password: Yup
    .string()
    .required('Password is required')
    .matches(
      PasswordRegex,
      'Must contain 8 characters, one uppercase, one lowercase, one number and one special case character'
    ),
  confirm_password: Yup
    .string()
    .required('Enter Password Again')
    .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
    .matches(
      PasswordRegex,
      'Must contain 8 characters, one uppercase, one lowercase, one number and one special case character'
    )
});
