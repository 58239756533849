import React, { useState } from 'react';
import { useSetRecoilState, useRecoilCallback } from 'recoil';
import {
  Grid,
  Button,
  SvgIcon,
  DialogContentText,
  CircularProgress,
  CardMedia
} from '@material-ui/core';
import { File, UploadCloud, Mail } from 'react-feather';
import moment from 'moment';

import MergeDocs from '../MergeDocs';
import { Toast } from '../../Toasts';
import { GeneralModal } from '../../Modal';
import { FileUploadModal } from '../../FileUpload/FileUploadModal';

import { requestDocument } from '../../../Recoil/Selectors/Clients';
import { currentOrder } from '../../../Recoil/Atoms/Orders';

import { marginStyles } from '../../../Common/Styles/Margins';
import { useStyles } from './../Styles/Fields'

const DocumentOptions = ({ isViewOnlyMode, values, signer }) => {
  const marginClasses = marginStyles();
  const classes = useStyles()
  const [fileModal, handleFileModal] = useState(false);
  const [isUploadModalOpen, setUploadModal] = useState(false);
  const [load, setLoad] = useState(false);
  const [file, setFile] = useState({ open: false, fileURL: undefined })
  const [toast, setToast] = useState({
    message: '',
    type: 'success',
    open: false
  });

  const setCurrentOrder = useSetRecoilState(currentOrder);

  const handleRequest = useRecoilCallback(({ snapshot }) => async () => {
    setLoad(true);
    const res: any = await snapshot.getPromise(requestDocument({
      Contact_ID: values.Contact_ID,
      f_loan_number: values.f_loan_number,
      f_borrower_last_name: values.f_borrower_last_name,
      f_appointment_date: values.f_appointment_date,
      f_appointment_time: values.f_appointment_time
    }));
    setLoad(false);
    if (res.success) {
      setToast({
        message: 'Email Sent Successfully!',
        type: 'success',
        open: true
      });
    } else {
      setToast({
        message: 'An Error Occured!',
        type: 'error',
        open: true
      });
    }
  });

  return (
    <>
      {fileModal &&
        <FileUploadModal open={fileModal} handleFileModal={handleFileModal} isViewOnlyMode={isViewOnlyMode} />
      }
      <GeneralModal
        open={isUploadModalOpen}
        cancelTitle='OK'
        openModal={setUploadModal}
        noSubmit
        title={`UPLOAD DOCS - SIGNER ID: ${signer?.signer_id || ''}`}
      >
        <DialogContentText>
          <MergeDocs {...values} isViewOnlyMode={isViewOnlyMode} />
        </DialogContentText>
      </GeneralModal>
      <Grid item xs={6} md={4}>
        <Button
          fullWidth
          color='primary'
          variant='outlined'
          onClick={(e) => {
            setCurrentOrder({
              ...values,
              w_Appointment_Time: moment(values.w_Appointment_Time, 'hh:mm A')
            });
            handleFileModal(true);
          }}
        >
          <SvgIcon className={marginClasses.mr2} fontSize='small'>
            <File />
          </SvgIcon>
          View Docs
        </Button>
      </Grid>
      <Grid item xs={6} md={4}>
        <Button
          fullWidth
          color='primary'
          variant='outlined'
          disabled={!signer?.signer_id}
          onClick={(e) => {
            if (signer?.signer_id) {
              setCurrentOrder({
                ...values,
                w_Appointment_Time: moment(values.w_Appointment_Time, 'hh:mm A')
              })
              setUploadModal(true);
            }
          }}
        >
          <SvgIcon className={marginClasses.mr2} fontSize='small'>
            <UploadCloud />
          </SvgIcon>
          Upload Docs
        </Button>
      </Grid>
      <Grid item xs={6} md={4}>
        <Button
          disabled={isViewOnlyMode}
          fullWidth
          color='primary'
          variant='outlined'
          onClick={!isViewOnlyMode ? handleRequest : undefined}
        >
          {load && (
            <CircularProgress
              className={marginClasses.mr2}
              color='inherit'
              size={25}
            />
          )}
          <SvgIcon className={marginClasses.mr2} fontSize='small'>
            <Mail />
          </SvgIcon>
          Request Docs
        </Button>
      </Grid>
      <GeneralModal
        title='Confirmation'
        submitLabel='OK'
        noSubmit
        openModal={() => setFile({ open: false, fileURL: undefined })}
        open={file.open}
        maxWidth='xl'
      >
        <CardMedia
          component='iframe'
          src={file.fileURL}
          title='Confirmation'
          className={classes.windowHeight}
        />
      </GeneralModal>
      <Toast message={toast.message} type={toast.type} open={toast.open} setToast={setToast} />
    </>
  );
}

export { DocumentOptions };
