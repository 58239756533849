import { atom } from 'recoil';

const documentActivitypagination = atom({
    key: 'documentActivitypagination',
    default: {
      page: 1,
      limit: 20,
      orderBy: 'da_orderID',
      orderType: 'ASC',
      search: ''
    }
  });

  const refreshDocumentActivity = atom({
    key: 'refreshDocumentActivity',
    default: 0
  });

  export {documentActivitypagination,refreshDocumentActivity}
  