import { makeStyles } from '@material-ui/core';
import { colorLegends } from 'src/Common/Rules/ColorLegends';

import type { Theme } from '../../../ThemeComponents/theme';

const colors: any = {}
colorLegends.map(c => colors[c.colorName] = c.color)
export const useStyles = makeStyles((theme: Theme) => ({
  columnWidths: {
    width: '100% !important',
    maxWidth: 'none !important',
    minWidth: 'auto !important',
  },
  generalGridClasses: {
    '& .MuiDataGrid-colCellWrapper': {
      '& .MuiDataGrid-colCell': {
        maxWidth: 'none !important'
      },
    },
    '& .MuiDataGrid-columnHeader':{
      maxWidth: 'none !important'
    },
    '& .rendering-zone, .MuiDataGrid-renderingZone': {
      width: '100% !important'
    },
    '& .MuiDataGrid-viewport': {
      maxHeight: 'unset !important',
      minHeight: 'unset !important'
    },
    '& .MuiDataGrid-row': {
      width: '100%',
      minHeight: 'unset !important',
      '& .MuiDataGrid-cell': {
        maxWidth: 'none !important',
        wordBreak: 'break-word'
      }
    },
    '& .MuiDataGrid-menuIcon': {
      display: 'none'
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none'
    },
    '& .MuiDataGrid-root .MuiDataGrid-window': {
      overflow: 'hidden !important'
    },
    // Row Background Color
    '& [data-id *="row-bg"]': {
      '&[data-id *="row-bg-mauve"]': {
        background: colors.mauve
      },
      '&[data-id *="row-bg-azure"]': {
        background: colors.azure
      },
      '&[data-id *="row-bg-green"]': {
        background: colors.green
      },
      '&[data-id *="row-bg-yellow"]': {
        background: colors.yellow
      },
      '&[data-id *="row-bg-brass"]': {
        background: colors.brass
      },
      '&[data-id *="row-bg-coral"]': {
        background: colors.coral
      },
      '&[data-id *="row-bg-salmon"]': {
        background: colors.salmon
      },
      '&[data-id *="row-bg-pink"]': {
        background: colors.pink
      },
      '&[data-id *="row-bg-riptide"]': {
        background: colors.riptide
      },
      '&[data-id *="row-bg-madang"]': {
        background: colors.madang
      },
      '&[data-id *="row-bg-red"]': {
        background: colors.red
      }
    }
  },
  paper: {
    width: '100%',
    flexGrow: 1,
    '& > div': {
      height: 'auto !important',
      width: 'auto !important'
    },
    '& .MuiDataGrid-root': {
      overflow: 'auto',
      fontSize: '0.9rem',
      '& .MuiDataGrid-columnsContainer': {
        justifyContent: 'center'
      },
      '& .MuiDataGrid-colCell:focus': {
        outline: 'none'
      },
      '& .MuiDataGrid-iconButtonContainer .MuiButtonBase-root .MuiIconButton-label': {
        color: 'white',
        top: ' -3px',
        position: 'relative',
      },
      '& .MuiDataGrid-cell:focus': {
        outline: 'none'
      }
    },
    '& .flexWrap > div': {
      flexWrap: 'wrap'
    },
    '& .datagrid-cell': {
      borderRight: 'none',
      fontSize: '0.9rem',
      wordWrap: 'break-word',
      overflowWrap: 'break-word',
      whiteSpace: 'unset',
      width: '100%',
      lineHeight: '18px !important',
      maxHeight: 'unset !important',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left'
    },
    '& .datagrid-cell-custom': {
      borderRight: 'none',
      fontSize: '0.9rem',
      wordWrap: 'break-word',
      overflowWrap: 'break-word',
      whiteSpace: 'unset',
      width: '100%',
      lineHeight: '18px !important',
      maxHeight: 'unset !important',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
      marginLeft: '6px !important',
    },
    '& .datagrid-header': {
      padding: '0px 10px',
      fontSize: '0.9rem',
      wordWrap: 'break-word',
      overflowWrap: 'break-word',
      whiteSpace: 'unset',
      width: '100% !important',
      lineHeight: '18px !important',
      maxHeight: 'unset !important',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
      '& .MuiDataGrid-colCell-draggable': {
        display: 'unset',
      },
      '& .MuiDataGrid-colCellTitle': {
        whiteSpace: 'normal',
        textOverflow: 'inherit'
      },
      '& .MuiDataGrid-colCellTitleContainer': {
        whiteSpace: 'none'
      },
    },
    '& .MuiDataGrid-columnsContainer': {
      background: theme.palette.primary.main,
      color: theme.palette.common.white
    },
  },
  datagrid: {
    border: 'none',
    '& .MuiDataGrid-cell.MuiDataGrid-withBorder': {
      borderRight: '0px'
    },
    '& .MuiDataGrid-overlay': {
      position: 'relative',
      top: '0px !important',
      height: 56
    }
  },
  datagridFluidHeight: {
    height: 'auto !important',
    '& .MuiDataGrid-mainGridContainer': {
      display: 'table'
    },
    '& .MuiDataGrid-window': {
      position: 'relative',
      top: '0px !important'
    },
    '& .MuiDataGrid-cell.MuiDataGrid-withBorder': {
      lineHeight: 'normal'
    },
    '& .MuiDataGrid-viewport': {
      maxHeight: 'none !important'
    },
    '& .MuiDataGrid-columnsContainer': {
      zIndex: 1,
      position: 'relative',
      '& .MuiButtonBase-root': {
        color: 'rgba(0, 0, 0, 0.26)',
        cursor: 'default',
        pointerEvents: 'none'
      }
    },
    '& .MuiDataGrid-row': {
      maxHeight: 'none !important',
      width: '100%',
      '& .MuiDataGrid-cell': {
        maxHeight: 'none !important',
        lineHeight: 'normal !important',
        whiteSpace: 'normal',
        alignItems: 'center',
        display: 'flex',
        color:'#000000',
        '& .MuiSvgIcon-fontSizeSmall':{
          fontSize: '1.75rem'
        }
      }
    }
  },
  datagridFluidWidth: {
    width: 'auto !important',
    '& .MuiDataGrid-viewport': {
      minWidth: '0 !important',
      maxWidth: 'none !important'
    },
    '& .MuiDataGrid-colCellWrapper': {
      '& .MuiDataGrid-colCell': {
        maxWidth: 'none !important'
      },
    },
    '& .MuiDataGrid-row': {
      width: 'auto',
      '& .MuiDataGrid-cell': {
        maxWidth: 'none !important',
        wordBreak: 'break-word'
      }
    }
  },
  pagination: {
    '& .MuiTablePagination-toolbar': {
      flexFlow: 'wrap',
      // alignItems: 'baseline',
      paddingLeft: theme?.spacing(2),
      paddingTop:  theme?.spacing(2)
    },
    '& .MuiTablePagination-toolbar > p:nth-child(2)': {
      display: 'flex',
      flexGrow: 1
    }
  },
  paginationTotalRows: {
    flexGrow: 1
  },
  editIconButton: {
    color: theme?.palette?.primary?.main
  }
}));

