import React, { useState } from 'react';
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Grid,
  Typography, IconButton, SvgIcon, ButtonGroup
} from '@material-ui/core';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { useRecoilState } from 'recoil'
import clsx from 'clsx';
import moment from 'moment'
import { Search, XCircle } from 'react-feather';

import { unbilledInvoicesPagination } from './../../Recoil/Atoms/Accounting'

import { marginStyles } from '../../Common/Styles/Margins';
import { useStyles } from './Styles/style';
import { paddingStyles } from '../../Common/Styles/Paddings';

const UnbilledInvoicesActions = () => {
  const classes = useStyles();
  const marginClasses = marginStyles();
  const [reset, setReset] = useState(false);
  const { p2 } = paddingStyles();

  const [unbilledPagination, setUnbilledPagination] = useRecoilState(unbilledInvoicesPagination)
  const [options, setOptions] = useState({
    filterBy: unbilledPagination.filterBy || '',
    filterValue: unbilledPagination.filterValue || '',
    billing_cycle: unbilledPagination.billing_cycle || '',
    clientName: unbilledPagination.clientName || '',
    startDate: unbilledPagination.startDate || '',
    endDate: unbilledPagination.endDate || ''
  })

  const search = () => {
    setUnbilledPagination({
      ...unbilledPagination,
      filterBy: options.filterBy || '',
      filterValue: options.filterValue || '',
      billing_cycle: options.billing_cycle || '',
      clientName: options.clientName || '',
      startDate: options.startDate || '',
      endDate: options.endDate || ''
    })
    setReset(true)
  };

  const clear = () => {
    setOptions({
      ...options,
      filterBy: 'loanNumber',
      filterValue: '',
      billing_cycle: 'Daily',
      clientName: '',
      startDate: '',
      endDate: ''
    })
    setUnbilledPagination({
      ...unbilledPagination,
      clientName: '',
      billing_cycle: 'Daily',
      filterBy: '',
      filterValue: '',
      startDate: '',
      endDate: ''
    })
    setLabel('Select dates')
    setReset(false)
  };

  const settings = {
    locale: {
      cancelLabel: 'Clear',
    },
    autoUpdateInput: false,
    ranges: {
      Today: [moment().startOf('day').toDate(), moment().endOf('day').toDate()],
      Yesterday: [
        moment().subtract(1, 'days').toDate(),
        moment().subtract(1, 'days').toDate(),
      ],
      'Last 7 Days': [
        moment().subtract(6, 'days').toDate(),
        moment().toDate(),
      ],
      'Last 30 Days': [
        moment().subtract(29, 'days').toDate(),
        moment().toDate(),
      ],
      'This Month': [
        moment().startOf('month').toDate(),
        moment().endOf('month').toDate(),
      ],
      'Last Month': [
        moment().subtract(1, 'month').startOf('month').toDate(),
        moment().subtract(1, 'month').endOf('month').toDate(),
      ],
    }
  }

  const [label, setLabel] = useState('Select dates')

  const handleApply = (event, picker) => {
    const { startDate, endDate }: any = picker
    setLabel(`${startDate.format('MM/DD/YYYY')} - ${endDate.format('MM/DD/YYYY')}`)
    setUnbilledPagination({
      ...unbilledPagination,
      startDate: startDate.format('MM/DD/YYYY'),
      endDate: endDate.format('MM/DD/YYYY')
    })
  };

  const handleCancel = () => {
    setLabel('Select dates')
    setUnbilledPagination({ ...unbilledPagination, startDate: '', endDate: '' })
  };

  return (
    <Box my={2}>
      <Grid container>
        <Grid item lg={5} md={5} sm={6} xs={12}>
          <Box display='flex'>
            <Typography
              variant='body2'
              color='textSecondary'
              className={clsx(marginClasses.mt2, marginClasses.mr2)}
            >
              <b>Billing Cycle: </b>
            </Typography>
            <FormControl
              className={clsx(
                marginClasses.mt0,
                marginClasses.mb0,
                marginClasses.mr1,
                classes.select
              )}
              margin='dense'
              variant='outlined'
              fullWidth
            >
              <Select
                value={options?.billing_cycle}
                name='billing_cycle'
                onChange={(e: any) => {
                  setOptions({ ...options, billing_cycle: e.target.value })
                }}
              >
                <MenuItem value={'Daily'}>Daily</MenuItem>
                <MenuItem value={'Weekly'}>Weekly</MenuItem>
                <MenuItem value={'Monthly'}>Monthly</MenuItem>
                <MenuItem value={'Yearly'}>Yearly</MenuItem>
              </Select>
            </FormControl>
            <Typography
              variant='body2'
              color='textSecondary'
              className={clsx(marginClasses.mt2, marginClasses.ml3)}
            >
              <b>Date: {unbilledPagination?.startDate || ''} - {unbilledPagination?.endDate || ''}</b>
            </Typography>
          </Box>
        </Grid>
        <Grid item lg={7} md={7} sm={12} xs={12}>
          <Grid container spacing={1}>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <TextField
                label='Client Name'
                name='clientName'
                variant='outlined'
                size='small'
                fullWidth
                value={options?.clientName}
                onChange={(e: any) => {
                  setOptions({ ...options, clientName: e.target.value })
                }}
              />
            </Grid>
            <Grid item lg={5} md={5} sm={6} xs={12}>
              <DateRangePicker
                initialSettings={settings}
                onApply={handleApply}
                onCancel={handleCancel}
              >
                <TextField
                  label="Date Range"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={label}
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                />
              </DateRangePicker>
            </Grid>
            <Grid item lg={2} md={2} sm={6} xs={12}>
              <FormControl className={marginClasses.my0} margin='dense' variant='outlined'>
                <ButtonGroup>
                  <IconButton className={p2} onClick={() => search()}>
                    <SvgIcon fontSize='small' color='primary'>
                      <Search />
                    </SvgIcon>
                  </IconButton>
                  <IconButton disabled={!reset} className={p2} onClick={() => clear()}>
                    <SvgIcon fontSize='small' color={reset ? 'error' : 'disabled'}>
                      <XCircle />
                    </SvgIcon>
                  </IconButton>
                </ButtonGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>{' '}
        <Grid container spacing={1}>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <br />
            <Box display='flex' className='mt4'>
              <Typography
                variant='body2'
                color='textSecondary'
                className={clsx(marginClasses.mt2, marginClasses.mr2)}
              >
                <b>Select</b>
              </Typography>
              <FormControl
                className={clsx(
                  marginClasses.mt0,
                  marginClasses.mb0,
                  marginClasses.mr1,
                  classes.select
                )}
                margin='dense'
                variant='outlined'
                fullWidth
              >
                <Select
                  value={options?.filterBy}
                  name='filterBy'
                  onChange={(e: any) => {
                    setOptions({ ...options, filterBy: e.target.value })
                  }}
                >
                  <MenuItem value={'w_Loan_Number'}>Loan #</MenuItem>
                  <MenuItem value={'Id'}>Order #</MenuItem>
                  <MenuItem value={'billRefNumber'}>Reference #</MenuItem>
                  <MenuItem value={'borrower'}>Borrower</MenuItem>
                  <MenuItem value={'signer'}>Signer</MenuItem>
                  <MenuItem value={'f_status_web'}>Status</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <br />
            <Box display='flex'>
              <Typography
                variant='body2'
                color='textSecondary'
                className={clsx(marginClasses.mt2, marginClasses.mr2)}
              >
                <b>Search</b>
              </Typography>
              <TextField
                type='text'
                className={clsx(
                  marginClasses.mt0,
                  marginClasses.mb0,
                  marginClasses.mr1,
                  classes.select
                )}
                variant='outlined'
                size='small'
                placeholder='Search'
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                value={options?.filterValue}
                name='filterValue'
                onChange={(e: any) => {
                  setOptions({ ...options, filterValue: e.target.value })
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export { UnbilledInvoicesActions };
