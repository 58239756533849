import React from 'react';
import { useRecoilState } from 'recoil';
import { Box, Grid } from '@material-ui/core';
import moment from 'moment';

import { DocumentActivitySearch } from './DocumentActivitySearch';
import { DocumentActivityDateRange } from './DocumentActivityDateRange';

import { documentActivitypagination } from '../../Recoil/Atoms/DocumentActivity';

const DocumentActivityActions = () => {
  const [pageInfo, setPageInfo]: any = useRecoilState(
    documentActivitypagination
  );

  const handleSearch = (searchType, searchValue) => {
    let value = searchValue;
    const newPageInfo = { ...pageInfo, page: 1, limit: 20, searchType, value };

    if (!searchValue) {
      delete newPageInfo.searchType;
      delete newPageInfo.searchValue;
    }

    setPageInfo(newPageInfo);
  };

  const handleDates = (start, end) => {
    const startDate = moment(start).format('YYYY-MM-DD');
    const endDate = moment(end).format('YYYY-MM-DD');

    setPageInfo({ ...pageInfo, startDate, endDate });
  };

  const clearDates = () => {
    const newPageInfo = Object.assign({}, pageInfo);

    delete newPageInfo.startDate;
    delete newPageInfo.endDate;

    setPageInfo(newPageInfo);
  };

  return (
    <Box my={2} display='flex'>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <Grid item>
          <DocumentActivityDateRange
            handleDates={handleDates}
            clearDates={clearDates}
          />
        </Grid>
        <Grid item>
          <DocumentActivitySearch handleSearch={handleSearch} />
        </Grid>
      </Grid>
    </Box>
  );
};

export { DocumentActivityActions };
