import { selector, selectorFamily } from 'recoil';

import { verifyAuthToken } from './Auth'
import { rolepagination , refreshRoles} from '../Atoms/Role';

import { api } from '../../Services/api';

const getRoles = selector({
    key: 'getRoles',
    get: async ({ get }) => {
      get(refreshRoles);
      const params = get(rolepagination);

      try {
        await verifyAuthToken()
        const res: any = await api.GET('auth/roles/get', {params});
        
        res.rows.map((resourceId:any)=>{
         return resourceId.resourceList= resourceId.resourceGroup.map(values=>values.id)
         })

        return {
          result: {
            rows: res,
          },
          success: true
        };
      } catch (err) {
        return {
          result: err,
          success: false
        };
      }
    }
  });


  const getResource = selector({
    key: 'getResource',
    get: async ({ get }) => {
      get(refreshRoles);
      try {
        await verifyAuthToken()
        const res: any = await api.GET('auth/roles/getResources');
        return {
          result: {
            rows: res,
          },
          success: true
        };
      } catch (err) {
        return {
          result: err,
          success: false
        };
      }
    }
  });

  const saveRole = selectorFamily({
    key: 'saveRole',
    get: (params: any) => async ({ get }) => {
    get(refreshRoles);
      if (params) {
        try {
          await verifyAuthToken()
          const res: any = await api.POST('auth/roles/create', params);
          return {
            result: res,
            success: true
          };
        } catch (err) {
          return {
            result: err,
            success: false
          };
        }
      }
    }
  });


  const updateRole = selectorFamily({
    key: 'updateRole',
    get: (params: any) => async ({ get }) => {
      get(refreshRoles);
      if (params) {
        try {
          delete params.createdAt;
          delete params.updatedAt;
          await verifyAuthToken()
          const res: any = await api.PUT('auth/roles/update', params);

          if (res[0] === 0) {
            return {
              result: {
                error: 'Unable to Update the Record!'
              },
              success: false
            }
          }

          return {
            result: res,
            success: true
          };
        } catch (err) {
          return {
            result: err,
            success: false
          };
        }
      }
    }
  });

  const deleteRole = selectorFamily({
    key: 'deleteRole',
    get: (params: any) => async ({ get }) => {
      get(refreshRoles);
      if (params) {
        try {
          delete params.createdAt;
          delete params.updatedAt;
          await verifyAuthToken()
          const res: any = await api.PUT('auth/roles/update',params);

          if (res[0] === 0) {
            return {
              result: {
                error: 'Unable to Delete the Record!'
              },
              success: false
            }
          }

          return {
            result: res,
            success: true
          };
        } catch (err) {
          return {
            result: err,
            success: false
          };
        }
      }
    }
  });

  export {
    getRoles,
    saveRole,
    updateRole,
    deleteRole,
    getResource
  }; 
  