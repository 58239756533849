import { atom } from 'recoil';

const contacts = atom({
  key: 'contacts',
  default: []
});

const loadContact = atom({
  key: 'loadContact',
  default: -1
})

const pagination = atom({
  key: 'contactsPagination',
  default: {
    page: 1,
    limit: 20,
    orderBy: 'Contact_Last_Name',
    orderType: 'ASC'
  }
});

const refreshContacts = atom({
  key: 'refreshContacts',
  default: 0
});

const search = atom({
  key: 'search',
  default: ''
});

const selectedClient = atom({
  key: 'selectedClientContact',
  default: ''
});
const clientContactpagination = atom({
  key: 'clientContactpagination',
  default: {
    page: 1,
    limit: 20,
    orderBy: 'Contact_Last_Name',
    orderType: 'ASC',
    search: ''
  }
});
const refreshClientContacts = atom({
  key: 'refreshClientContacts',
  default: 0
});

const selectedContact = atom({
  key: 'selectedContact',
  default: 0
});

const resendMasterLoginRequest = atom({
  key: 'resendMasterLoginRequest',
  default: 0
});

export {
  contacts, loadContact, pagination, refreshContacts, search, selectedClient, clientContactpagination,
  refreshClientContacts, selectedContact, resendMasterLoginRequest
};
