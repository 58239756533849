import React from 'react'
import { Typography, Box, Divider } from '@material-ui/core';
import {
    useRecoilValueLoadable,
    useSetRecoilState,
    useRecoilState
} from 'recoil';

import { Page } from '../../Components/Page';

import { useStyles } from '.././Styles/Page';

import { DailyVendorBillActions } from 'src/Components/Accounting/DailyVendorBillActions';
import { DailyVendorBillData } from 'src/Components/Accounting/DailyVendorBillData';

import { refetchDailyVendorBill, DailyVendorBillPagination } from '../../Recoil/Atoms/Accounting';
import { getDailyVendorBill } from 'src/Recoil/Selectors/Accounting';


export const DailyVendorBill = () => {
    const classes = useStyles();

    const [pageInfo, setPageInfo]: any = useRecoilState(DailyVendorBillPagination);
    const setRefetchDailyVendorBill = useSetRecoilState(refetchDailyVendorBill);

    const allClientInvoices: any = useRecoilValueLoadable(getDailyVendorBill)
    const clientInvoicesData = allClientInvoices?.contents?.result?.rows || [];
    const total = allClientInvoices?.contents?.result?.count || 0;

    return (
        <Page className={classes.paper} title='Daily Vendor Bill'>
            <Typography variant='h3'>Daily Vendor Bill</Typography>
            <Box my={2}>
                <Divider variant='fullWidth' />
            </Box>
            <DailyVendorBillActions />
            <DailyVendorBillData
                setRefetchDailyVendorBill={setRefetchDailyVendorBill}
                rows={clientInvoicesData}
                total={total}
                pageInfo={pageInfo}
                setPageInfo={setPageInfo}
                loading={allClientInvoices?.state === 'loading'} />
        </Page>
    )
}