import React from 'react'
import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Select,
    MenuItem
} from '@material-ui/core'

import { useStyles } from './../Styles/PendingVendorInvoicesStyles'

const PayMultipleBillsTable = (props) => {

    const classes = useStyles()

    const { payMultipleBills, setAccountPayable, account } = props

    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Ref.Number</TableCell>
                            <TableCell>Bill Date</TableCell>
                            <TableCell>Bill Due</TableCell>
                            <TableCell>AmountDue</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            payMultipleBills?.bills?.map(bill => {
                                return (
                                    <TableRow>
                                        <TableCell>{bill?.refNumber}</TableCell>
                                        <TableCell>{bill?.billDate}</TableCell>
                                        <TableCell>{bill?.billDue}</TableCell>
                                        <TableCell>$ {parseFloat(bill?.amount).toFixed(2)}</TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <TableContainer component={Paper} className={classes.mrTp}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Vendor</TableCell>
                            <TableCell>AP Account</TableCell>
                            <TableCell>Account</TableCell>
                            <TableCell>Sub Total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>{payMultipleBills?.bills[0].vendorName}</TableCell>
                            <TableCell>{'Account Payable'}</TableCell>
                            <TableCell>
                                <Select
                                    labelId="Select-Amount"
                                    id="Select-Amount"
                                    fullWidth
                                    value={account}
                                    onChange={(e: any) => setAccountPayable(e.target.value)}
                                >
                                    {payMultipleBills?.accounts.map((account: any) => {
                                        return <MenuItem value={account.ListID}>{account.FullName}</MenuItem>
                                    })}
                                </Select>
                            </TableCell>
                            <TableCell>$ {parseFloat(payMultipleBills?.amountDue).toFixed(2)}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>

    )
}

export { PayMultipleBillsTable }