import React from 'react';
import {
  Grid,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';

import { useRecoilValue } from 'recoil';

import { getResource } from 'src/Recoil/Selectors/Role';


const RoleResourceGroups = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur
}) => {
  const roleData: any = useRecoilValue(getResource);
  const resources =roleData.result.rows



  return (
    <Grid container xs={12}>
      <FormControl
        component='fieldset'
        error={Boolean(touched.resourceList && errors.resourceList)}
      >
        <FormGroup>
          {resources.map((resource, idx) => {
            return (
              <FormControlLabel
                key={idx}
                control={
                  <Checkbox
                  defaultChecked={values.id ? values.resourceList.filter(resourceId=>resourceId===resource.id).length : false}
                    onChange={handleChange}
                    name='resourceList'
                    value={resource.id}
                  />
                }
                label={resource.name}
              />
            );
          })}
        </FormGroup>
      </FormControl>
    </Grid>
  );
};

export { RoleResourceGroups };
