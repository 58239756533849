import React from 'react';
import { Box, TextField, IconButton, InputAdornment, SvgIcon } from '@material-ui/core';
import { Search } from 'react-feather';
import clsx from 'clsx';

import { marginStyles } from '../../Common/Styles/Margins';

const FilteredOrdersActions = () => {
  const marginClasses = marginStyles();

  return (
    <Box my={2} display='flex' justifyContent='flex-end'>
      <TextField
        className={clsx(marginClasses.mt0, marginClasses.mb0)}
        type='search'
        variant='outlined'
        label='Search Orders'
        margin='dense'
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton>
                <SvgIcon fontSize='small' color='action'>
                  <Search />
                </SvgIcon>
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    </Box>
  );
};

export { FilteredOrdersActions };

