import * as Yup from 'yup';

const initialValues = {
  Contact_First_Name: '',
  Contact_Last_Name: '',
  Contact_Email: '',
  confirmation_email: '',
  Contact_Work_Phone: '',
  Contact_Work_Ext: '',
  Contact_Cell: '',
  Contact_Show_Signer_Email: 'Y',
  Contact_Show_Signer_Tel: 'Y',
  Contact_Signer_Report: 'N',
  Contact_Team_Manager: '',
  status: 'A',
  privilege: 'All'
};

const validationSchema = Yup.object().shape({
  Contact_First_Name: Yup
    .string().required('First Name is Required.'),
  Contact_Last_Name: Yup
    .string().required('Last Name is Required.'),
  Contact_Email: Yup
    .string().email('Email Address is Invalid.').required('Contact Email Address is Required.'),
  confirmation_email: Yup
    .string()
    .email('Email Address is Invalid.')
    .required('Contact Email Address is Required.')
    .oneOf([Yup.ref('Contact_Email'), null], 'Email must match'),
  Contact_Work_Phone: Yup
    .string().required('Work Phone is Required.'),
  Contact_Work_Ext: Yup
    .string(),
  Contact_Cell: Yup
    .string(),
  Contact_Show_Signer_Email: Yup
    .string().required('Select an Option'),
  Contact_Show_Signer_Tel: Yup
    .string().required('Select an Option'),
  Contact_Signer_Report: Yup
    .string().required('Select an Option'),
  Contact_Team_Manager: Yup
    .string().required('Team Manager Name is Required.'),
  status: Yup
    .string(),
  privilege: Yup
    .string()
});

export {
  initialValues,
  validationSchema
};
