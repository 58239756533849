export const statuses = [
  {
    value: 'Assigned',
    label: "Assigned"
  },
  {
    value: 'Borrower No Show',
    label: "Borrower No Show"
  },
  {
    value: 'Borrower Recheduled',
    label: "Borrower Recheduled"
  },
  {
    value: 'Borrower Would Not Sign',
    label: "Borrower Would Not Sign"
  },
  {
    value: 'Bwr No Show',
    label: "Bwr No Show"
  },
  {
    value: 'Cancel / bwr',
    label: "Cancel/bwr"
  },
  {
    value: 'Cancel / lender',
    label: "Cancel/lender"
  },
  {
    value: 'Cancelled',
    label: "Cancelled"
  },
  {
    value: 'Cancelled By Borrower',
    label: "Cancelled By Borrower"
  },
  {
    value: 'Cancelled By Client',
    label: "Cancelled By Client"
  },
  {
    value: 'Client Rescheduled',
    label: "Client Rescheduled"
  },
  {
    value: 'Closed',
    label: "Closed"
  },
  {
    value: 'Double Book',
    label: "Double Book"
  },
  {
    value: 'Duplicate',
    label: "Duplicate"
  },
  {
    value: 'In Process',
    label: "In Process"
  },
  {
    value: 'Invoice Disputed',
    label: "Invoice Disputed"
  },
  {
    value: 'Invoice Submitted',
    label: "Invoice Submitted"
  },
  {
    value: 'Late Cancel',
    label: "Late Cancel"
  },
  {
    value: 'Late Cancellation',
    label: "Late Cancellation"
  },
  {
    value: 'No Docs',
    label: "No Docs"
  },
  {
    value: 'Pending',
    label: "Pending"
  },
  {
    value: 'Processing',
    label: "Processing"
  },
  {
    value: 'Signed',
    label: "Signed"
  },
  {
    value: 'Would Not Sign',
    label: "Would Not Sign"
  },
  {
    value: "Wouldn't Sign",
    label: "Wouldn't Sign"
  },
]
