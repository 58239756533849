import React, { useState } from 'react';
import { Typography, Box, Divider } from '@material-ui/core';

import { useSetRecoilState, useRecoilValueLoadable, useRecoilState } from 'recoil';
import { useLocation } from 'react-router';

import { NewContact } from './NewContact';
import { Page } from '../../Components/Page';
import { GeneralModal } from '../../Components/Modal';
import { ContactActions } from '../../Components/Client/ContactActions';
import { ContactsData } from '../../Components/Client/ContactsData';
import { Toast } from '../../Components/Toasts';
import { PageLoad } from '../../Components/LoadingScreen/PageLoad';

import { loadContact, selectedClient, pagination } from '../../Recoil/Atoms/Contacts';
import { getContact, getContacts } from '../../Recoil/Selectors/Contacts';
import { getClientNames } from '../../Recoil/Selectors/Clients';

import { useStyles } from '.././Styles/Page';

export const Contacts = () => {
  const classes = useStyles();
  const [modal, handleModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [reloadOrders, setReloadOrders] = useState(1);
  const [pageInfo, setPageInfo] = useRecoilState(pagination);
  const location = useLocation()
  const [toast, setToast] = useState({
    message: '',
    type: 'success',
    open: false
  });

  const selectContact = useSetRecoilState(loadContact);
  const selectClient = useSetRecoilState(selectedClient);
  const [adminContacts] = useState(location?.pathname?.includes('/client/contacts/master-login') ? true : false)

  const contacts: any = useRecoilValueLoadable(getContacts);
  const rows = contacts?.contents?.result?.rows || [];
  const total = contacts?.contents?.result?.count || 0;

  const clientNames: any = useRecoilValueLoadable(getClientNames);
  const clients = clientNames?.contents?.result || [];

  const contactData: any = useRecoilValueLoadable(getContact(reloadOrders));
  const contact = contactData?.contents?.result || {};

  const openModal = (open) => {
    selectContact(-1);
    selectClient('');
    handleModal(open);
    setIsEditing(false)
  }

  const handleEdit = (e) => {
    setReloadOrders(reloadOrders + 1)
    selectContact(e.Id);
    selectClient(e.Company_ID);
    handleModal(true);
    setIsEditing(true)
  }

  return (
    <Page className={classes.paper} title='Contacts'>
      <Typography variant='h3'>Contacts</Typography>
      <Box my={2}>
        <Divider variant='fullWidth' />
      </Box>
      <ContactActions handleModal={handleModal} pageInfo={pageInfo}
        setPageInfo={setPageInfo} />
      <ContactsData
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        loading={contacts.state === 'loading'}
        rows={rows} total={total}
        handleEdit={handleEdit}
        adminContacts={adminContacts}
      />
      <GeneralModal
        open={modal}
        title={isEditing ? 'Edit Contact' : 'New Contact'}
        loading={false}
        openModal={openModal}
        noSubmit={true}
      >
        {contactData.state === 'loading' ?
          <PageLoad /> :
          <NewContact
            isEditing={isEditing}
            contact={contact}
            clients={clients}
            setToast={setToast}
            openModal={openModal}
          />
        }
      </GeneralModal>
      <Toast message={toast.message} type={toast.type} open={toast.open} setToast={setToast} />
    </Page>
  );
}
