import React from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@material-ui/core';

import { useStyles } from '../Styles/Fields';

const ClosingType = ({ isViewOnlyMode, location, values, handleChange, errors, touched }) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} md={6}>
      <FormControl
        className={classes.removeSelectMargin}
        fullWidth
        margin='dense'
        variant='outlined'
        error={Boolean((errors[location]) && errors[location].Closing_Type)}>
        <InputLabel>Closing Type</InputLabel>
        <Select
          disabled={isViewOnlyMode}
          label='Closing Type'
          name={`${location}.Closing_Type`}
          error={Boolean((errors[location]) && errors[location].Closing_Type)}
          fullWidth
          value={values.Closing_Type}
          onChange={handleChange}
        >
          <MenuItem value='FC'>Full Closing</MenuItem>
          <MenuItem value='2FC'>2 Loans</MenuItem>
          <MenuItem value='2PC'>2+ Loans</MenuItem>
          <MenuItem value='P'>Partial</MenuItem>
          <MenuItem value='S1'>Split - 1st</MenuItem>
          <MenuItem value='S2'>Split - 2nd</MenuItem>
          <MenuItem value='H'>Heloc</MenuItem>
          <MenuItem value='26'>Single Document</MenuItem>
          <MenuItem value='27'>White Glove</MenuItem>
        </Select>
        {Boolean((errors[location]) && errors[location].Closing_Type) &&
          <FormHelperText>{errors[location].Closing_Type}</FormHelperText>}
      </FormControl>
    </Grid>
  );
}
export { ClosingType };
