import React from 'react';
import { Typography } from '@material-ui/core';

import { Page } from '../../Components/Page';
import { SignersData } from '../../Components/Signers/SignersData';

import { useStyles } from '.././Styles/Page';

export const Signers = () => {
  const classes = useStyles();
  return (
    <Page className={classes.paper} title='Signers'>
      <Typography variant='h3'>Signers</Typography>
      <SignersData />
    </Page>
  );
}
