import React from 'react';
import { Grid, TextField, Button, SvgIcon } from '@material-ui/core';
import { FieldArray } from 'formik';
import { Plus, XCircle } from 'react-feather';

import { useStyles } from '../Styles/Fields';

const LoanNumber = ({ isViewOnlyMode, values, errors, reset, touched, handleChange, handleBlur, setFieldValue, isEditMode, partialSigning }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Grid className={classes.gridRoot} container spacing={2} alignItems='center'>
        <FieldArray
          name='w_Loan_Number'
          render={(helper) => (
           values && values.map((num, i) =>
              <Grid className={classes.pRelative} key={`w_Loan_Number - ${i}`} item xs={12} md={6}>
                <TextField
                  disabled={isViewOnlyMode}
                  label='Loan Number'
                  name={`w_Loan_Number[${i}]`}
                  variant='outlined'
                  size='small'
                  fullWidth
                  defaultValue={reset ? '' : num}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={
                    (touched.w_Loan_Number && touched.w_Loan_Number[i]) &&
                    (errors.w_Loan_Number && errors.w_Loan_Number[i])
                  }
                  error={Boolean(
                    (touched.w_Loan_Number && touched.w_Loan_Number[i]) &&
                    (errors.w_Loan_Number && errors.w_Loan_Number[i])
                  )}
                />
                {i > 0 &&
                  <SvgIcon
                    className={classes.closeButton}
                    fontSize='small'
                    color='primary'
                    onClick={() => helper.remove(i)}
                  >
                    <XCircle />
                  </SvgIcon>
                }
              </Grid>
            )
          )}
        />
        <Grid item xs={12} sm={6} md={4}>
          {
            !isEditMode && !isViewOnlyMode && partialSigning === '1'
              ?
              <Button
                color='primary'
                variant='outlined'
                onClick={() => setFieldValue(`w_Loan_Number[${values.length}]`, '')}
              >
                <SvgIcon fontSize='small'><Plus /></SvgIcon> Add Loan #
          </Button>
              :
              null
          }
        </Grid>
      </Grid>
    </Grid>
  );
}

export { LoanNumber };
