const encodeQueryData = data => {
  const ret = [];
  for (let d in data)
    ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
  return `?${ret.join('&')}`;
};

const sortASC = (array, field) => {
  if (array.length <= 1) {
    return array
  }
  try {
    return array.slice().sort((a, b) => (a?.[field] > b?.[field]) ? 1 : ((b?.[field] > a?.[field]) ? -1 : 0))
  } catch (err) {
    console.error(err);
    return array
  }

}

export { encodeQueryData, sortASC };
