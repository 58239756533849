import React from 'react';
import { Grid } from '@material-ui/core';

import { OutlinedSection } from '../Section';

import { RoleName } from './RoleFields/RoleName';
import { RoleResourceGroups } from './RoleFields/RoleResourceGroups';

const RoleForm = props => {
  const { values, errors, touched, handleChange, handleBlur } = props;
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={12}>
        <RoleName
          values={values}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <OutlinedSection title='Resource Groups'>
          <RoleResourceGroups
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        </OutlinedSection>
      </Grid>
    </Grid>
  );
};

export { RoleForm };
