import React from 'react';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import { useRecoilValue } from 'recoil';

import { getRoles } from 'src/Recoil/Selectors/Role';
const UserRole = ({ values, handleChange }) => {
  const roleData: any = useRecoilValue(getRoles);
  const roles =roleData.result.rows.rows
  return (
    <Grid item xs={12} md={12}>
      <FormControl margin='dense' variant='outlined'>
        <InputLabel>Role</InputLabel>
        <Select
          label='Role'
          name='roleID'
          fullWidth
          value={values.id && !values.roleID && values.role ? values.role.id :values.roleID  }
          onChange={handleChange}
        >
          {roles.map(role => (
            <MenuItem value={role.id}>{role.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
};

export { UserRole };
