import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    gridRoot: {
      flexGrow: 1
    },
    pRelative: {
      position: 'relative'
    },
    closeButton: {
      position: 'absolute',
      top: '-2px',
      right: '-2px',
      cursor: 'pointer',
      background: theme.palette.background.default
    },
    splitCheckbox: {
      marginTop: 10,
      marginBottom: 10
    },
    mt10: {
      marginTop: 10
    },
    p10: {
      paddingTop: 10
    },
    mb20: {
      marginBottom: 20
    },
    addressItem: {
      color: '#000',
      padding: '7px 8px',
      cursor: 'pointer',
      '&:hover': {
        background: '#e7e7e7'
      }
    },
    removeSelectMargin: {
      marginTop: 0,
      marginBottom: 0
    },
    textArea: {
      fontSize: '1.3rem',
      width: '100%',
      padding: '8px 10px',
      marginTop: 4,
      maxHeight: '176px',
      overflow: 'auto !important',
      resize: 'none'
    },
    fieldHeader: {
      fontWeight: 700,
      color: theme.palette.grey[700]
    },
    fullHeight: {
      height: '100%',
    },
    windowHeight: {
      height: '100vh'
    },
    wrapText: {
      overflowWrap: 'anywhere'
    },
    pointer: {
      cursor: 'pointer'
    }
  })
);

export { useStyles };
