import React, { useState } from 'react';
import { useRecoilState, useRecoilCallback } from 'recoil';

import { GeneralModal } from '../Modal';
import { DialogContentText, Grid, TextField } from '@material-ui/core';
import { unassignOrder } from '../../Recoil/Selectors/Orders';
import { forceUnassignOrder } from '../../Recoil/Atoms/Orders';
import { useNavigate } from 'react-router-dom';

export const UnassignOrderModal = (props: any) => {
  const navigate = useNavigate();

  const { reassignModal, handleReassignOrder } = props;
  const [unassignAtom, setUnassign] = useRecoilState(forceUnassignOrder);

  const [reassign_reason, setReassign_reason] = useState('');

  const unassign = useRecoilCallback(({ snapshot }) => async Id => {
    setUnassign(unassignAtom + 1);
    await snapshot.getPromise(unassignOrder({ Id, reassign_reason }));
  });

  return (
    <GeneralModal
      title='Reassign Signer'
      open={reassignModal}
      openModal={handleReassignOrder}
      submitLabel='Yes'
      handleSubmit={async e => {
        await unassign(reassignModal.Id);
        navigate(`/orders/assignsigner/${reassignModal.Id}`);
      }}
    >
      <DialogContentText>
        <Grid container xs={12} spacing={3}>
          <Grid container item>
            Are you sure you want to rematch existing signer?
          </Grid>
          <Grid container item>
            <TextField
              multiline
              rows={3}
              label='Describe the reason to reassign the order'
              size='small'
              variant='outlined'
              fullWidth
              value={reassign_reason}
              onChange={e => setReassign_reason(e.target.value)}
            ></TextField>
          </Grid>
        </Grid>
      </DialogContentText>
    </GeneralModal>
  );
};
