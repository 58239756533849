import React from 'react'

import { Grid, DialogContentText } from '@material-ui/core'

import { colorLegends, colorLegendsUnassignedOrders } from 'src/Common/Rules/ColorLegends'

import { useStyles } from './Styles'

export default function ColorLegend() {
  const classes = useStyles()
  return (
    <>
      {/* {console.log(window.location?.pathname)} */}
      <Grid container spacing={1} className={classes.wrapper}>
        {window?.location?.pathname === '/orders/unassigned' ?
           colorLegendsUnassignedOrders.filter(c => !c.hidden).map(item => (
            <Grid>
              <DialogContentText>
                <span className={`bg-${item.colorName} ${classes.colorlegend}`}></span>
                {item.name};
              </DialogContentText>
            </Grid>
           ))
          :
          colorLegends.filter(c => !c.hidden).map(item => (
          <Grid>
            <DialogContentText>
              <span className={`bg-${item.colorName} ${classes.colorlegend}`}></span>
              {item.name};
            </DialogContentText>
          </Grid>
        ))}
      </Grid>
    </>
  )
}
