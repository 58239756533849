import React from 'react';
import { Typography, Box, Divider } from '@material-ui/core';

import { Page } from '../../Components/Page';
import { AuditTrailData } from '../../Components/Admin/AuditTrialData';

import { useStyles } from '.././Styles/Page';

export const AuditTrail = () => {
  const classes = useStyles();

  return (
    <Page className={classes.paper} title='Audit Trail'>
      <Typography variant='h3'>Audit Trail</Typography>
      <Box my={2}>
        <Divider variant='fullWidth' />
      </Box>
      <AuditTrailData />
    </Page>
  );
}
