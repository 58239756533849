import { atom } from 'recoil';

const divisions = atom({
  key: 'divisions',
  default: []
});

const clientDivisions = atom({
  key: 'clientDivisions',
  default: []
});

const pagination = atom({
  key: 'divisionPagination',
  default: {
    page: 1,
    limit: 20
  }
});

const refreshDivisions = atom({
  key: 'refreshDivisions',
  default: 0
});

const loadDivision = atom({
  key: 'loadDivision',
  default: -1
})

const selectedDivision = atom({
  key: 'selectedDivision',
  default: -1
})


export {
  clientDivisions,
  pagination,
  refreshDivisions,
  loadDivision,
  selectedDivision,
  divisions,
};
