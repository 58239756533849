import { selector, selectorFamily } from 'recoil';

import { verifyAuthToken } from './Auth'
import { accessgrouppagination , refreshAccessGroups} from '../Atoms/AccessGroups';
import { userInfo } from '../Atoms/Login';

import { api } from '../../Services/api';

const getAccessGroups = selector({
    key: 'getAccessGroups',
    get: async ({ get }) => {
      get(refreshAccessGroups);
      const params = get(accessgrouppagination);
  
      try {
        await verifyAuthToken()
        const res: any = await api.GET('auth/employee/getAllAccessGroups', {
          params
        });
        const result = res.rows.map(r => {
          return {
            ...r,
            id: r.accessGroupID,
            status: r.accessGroupStatus === 'A' ? 'Active' : 'Inactive'
          }
        });
        return {
          result: {
            rows: result,
            count: res.count
          },
          success: true
        };
      } catch (err) {
        return {
          result: err,
          success: false
        };
      }
    }
  });

  const saveAccessGroup = selectorFamily({
    key: 'saveAccessGroup',
    get: (params: any) => async ({ get }) => {
      if (params) {
        try {
          await verifyAuthToken()
          const res: any = await api.POST('auth/employee/accessgroup/new', params);
          return {
            result: res,
            success: true
          };
        } catch (err) {
          return {
            result: err,
            success: false
          };
        }
      }
    }
  });
  

  const updateAccessGroup = selectorFamily({
    key: 'updateAccessGroup',
    get: (params: any) => async ({ get }) => {
      const user = get(userInfo);
      if (params) {
        try {
          delete params.createdAt;
          delete params.updatedAt;
          const data = {
            ...params,
            modifiedBy: user.id,
          }
          await verifyAuthToken()
          const res: any = await api.PUT('admin/accessgroup/update', data);
  
          if (res[0] === 0) {
            return {
              result: {
                error: 'Unable to Update the Record!'
              },
              success: false
            }
          }
  
          return {
            result: res,
            success: true
          };
        } catch (err) {
          return {
            result: err,
            success: false
          };
        }
      }
    }
  });

  export {
    getAccessGroups,
    saveAccessGroup,
    updateAccessGroup,
  };