import React from 'react';
import { useRecoilState } from 'recoil'
import { Grid, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

import { uploadedBy } from './../../Recoil/Atoms/Orders'

const UploadedBySelect = (params: any) => {

  const [ uploadedByAtom, setUploadedBy ] = useRecoilState(uploadedBy)
  const { Contact_ID, clientID } = params

  return (
    <Grid item xs={12} md={6}>
      <FormControl margin='dense' variant='outlined' fullWidth>
        <InputLabel>Uploaded By</InputLabel>
        <Select
          value={uploadedByAtom.type}
          label='Uploaded By'
          fullWidth
          onChange={(e) => setUploadedBy({ 
            id: e.target.value === 'client' ? Contact_ID : clientID, 
            type: String(e.target.value) }) }
        >
          <MenuItem value='client'>Client</MenuItem>
          <MenuItem value='internal'>Internal</MenuItem>
        </Select>
      </FormControl>
    </Grid>
  );
}

export { UploadedBySelect };
