import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, IconButton, Typography } from '@material-ui/core';

import { Page } from '../../Components/Page';
import { SignerHistory } from '../../Components/Signers/SignerHistory';

import { useStyles } from '.././Styles/Page';
import { paddingStyles } from 'src/Common/Styles/Paddings';


export const SignerHistoryPage = () => {
  const paddingClasses = paddingStyles()
  const classes = useStyles();
  const navigate = useNavigate()
  return (
    <Page className={classes.paper} title='Signer History'>
      <Grid container justifyContent='space-between' spacing={2}>
        <Grid item>
          <Typography variant='h3'>Signer History</Typography>
        </Grid>
        <Grid item>
          <IconButton className={paddingClasses.p0} onClick={e => navigate('/signers')}>
            <Typography>Back</Typography>
          </IconButton>
        </Grid>
        <Grid xs={12} item>
          <SignerHistory />
        </Grid>
      </Grid>
    </Page>
  );
}
