import { atom } from 'recoil';

interface DialogInterface {
  open: boolean,
  loading: boolean,
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | string,
  type?: 'cancel' | 'duplicate' | 'edit' | string,
  title?: string,
  submitLabel?: string,
  handleSubmit?: () => void
}

export const dialog = atom<DialogInterface>({
  key: 'dialog',
  default: {
    open: false,
    loading: false,
    size: 'sm',
    title: 'Dialog',
    submitLabel: 'Yes',
  },
});
