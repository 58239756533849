import React, { useState } from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { Typography, Box, Divider } from '@material-ui/core';

import { NewAccessGroup } from './NewAccessGroup';
import { Page } from '../../Components/Page';
import { GeneralModal } from '../../Components/Modal';
import { AccessGroupActions } from '../../Components/Admin/AccessGroupActions';
import { AccessGroupData } from '../../Components/Admin/AccessGroupData';
import { Toast } from '../../Components/Toasts';

import { getAccessGroups } from '../../Recoil/Selectors/AccessGroups';

import { useStyles } from '.././Styles/Page';

export const AccessGroup = () => {
  const classes = useStyles();
  const [modal, handleModal] = useState(false);
  const [isEditMode, setEditMode] = useState(false);

  const [selectedAccessGroup, handleSelectedAccessGroup] = useState({});
  const [toast, setToast] = useState({
    message: '',
    type: 'success',
    open: false
  });

  const accessGroupsData: any = useRecoilValueLoadable(getAccessGroups);

  const data = accessGroupsData?.contents?.result?.rows || [];
  const total = accessGroupsData?.contents?.result?.count || 0;

  const openModal = open => {
    handleSelectedAccessGroup({});
    handleModal(open);
    setEditMode(false);
  };

  const handleEdit = params => {
    handleSelectedAccessGroup(params);
    handleModal(true);
    setEditMode(true);
  };

  return (
    <Page className={classes.paper} title='Access Groups'>
      <Typography variant='h3'>Access Groups</Typography>
      <Box my={2}>
        <Divider variant='fullWidth' />
      </Box>
      <AccessGroupActions handleModal={handleModal} />
      <AccessGroupData
        loading={accessGroupsData.state === 'loading'}
        data={data}
        total={total}
        handleEdit={handleEdit}
      />
      <GeneralModal
        open={modal}
        title={isEditMode ? 'Edit Access Group' : 'New Access Group'}
        loading={false}
        openModal={openModal}
        noSubmit={true}
      >
        <NewAccessGroup
          selectedAccessGroup={selectedAccessGroup}
          openModal={openModal}
          setToast={setToast}
        />
      </GeneralModal>
      <Toast
        message={toast.message}
        type={toast.type}
        open={toast.open}
        setToast={setToast}
      />
    </Page>
  );
};
