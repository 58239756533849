import React, { useState } from 'react';
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Grid,
  Typography, IconButton, SvgIcon, ButtonGroup
} from '@material-ui/core';
import clsx from 'clsx';
import { useRecoilState } from 'recoil'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment'
import { Search, XCircle } from 'react-feather';

import { marginStyles } from '../../../Common/Styles/Margins';
import { useStyles } from '../Styles/style';

import { UnbilledOrdersPagination } from '../../../Recoil/Atoms/Accounting'
import { paddingStyles } from '../../../Common/Styles/Paddings';

const UnbilledOrdersActions = () => {
  const classes = useStyles();
  const marginClasses = marginStyles();
  const { p2 } = paddingStyles();

  const [unbilledPagination, setUnbilledPagination] = useRecoilState(UnbilledOrdersPagination)
  const [reset, setReset] = useState(false)
  const [options, setOptions] = useState({
    filterBy: unbilledPagination.filterBy || '',
    filterValue: unbilledPagination.filterValue || '',
    startDate: unbilledPagination.startDate || '',
    endDate: unbilledPagination.endDate || ''
  })

  const settings = {
    locale: {
      cancelLabel: 'Clear',
    },
    autoUpdateInput: false,
    ranges: {
      Today: [moment().startOf('day').toDate(), moment().endOf('day').toDate()],
      Yesterday: [
        moment().subtract(1, 'days').toDate(),
        moment().subtract(1, 'days').toDate(),
      ],
      'Last 7 Days': [
        moment().subtract(6, 'days').toDate(),
        moment().toDate(),
      ],
      'Last 30 Days': [
        moment().subtract(29, 'days').toDate(),
        moment().toDate(),
      ],
      'This Month': [
        moment().startOf('month').toDate(),
        moment().endOf('month').toDate(),
      ],
      'Last Month': [
        moment().subtract(1, 'month').startOf('month').toDate(),
        moment().subtract(1, 'month').endOf('month').toDate(),
      ],
    }
  }

  const [label, setLabel] = useState('Select dates')

  const handleApply = (event, picker) => {
    const { startDate, endDate }: any = picker
    setLabel(`${startDate.format('MM/DD/YYYY')} - ${endDate.format('MM/DD/YYYY')}`)
    setOptions({ 
      ...options, 
      startDate: startDate.format('MM/DD/YYYY'), 
      endDate: endDate.format('MM/DD/YYYY')})
  };

  const handleCancel = () => {
    setLabel('Select dates')
    setOptions({ ...options, startDate: '', endDate: '' })
  };

  const search = () => {
    setUnbilledPagination({
      ...unbilledPagination,
      filterBy: options.filterBy || '',
      filterValue: options.filterValue || '',
      startDate: options.startDate || '',
      endDate: options.endDate || ''
    })
    setReset(true)
  };

  const clear = () => {
    setOptions({
      ...options,
      filterBy: 'loanNumber',
      filterValue: '',
      startDate: '',
      endDate: ''
    })
    setUnbilledPagination({
      ...unbilledPagination,
      filterBy: 'loanNumber',
      filterValue: '',
      startDate: '',
      endDate: ''
    })
    setLabel('Select dates')
    setReset(false)
  };

  return (
    <Box my={2}>
      <Grid container>
        <Grid item lg={11} md={11} sm={12} xs={12}>
          <Grid container spacing={1}>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <Box display='flex'>
                <Typography
                  variant='body2'
                  color='textSecondary'
                  className={clsx(marginClasses.mt2, marginClasses.mr2)}
                >
                  <b>Select</b>
                </Typography>
                <FormControl
                  className={clsx(
                    marginClasses.mt0,
                    marginClasses.mb0,
                    marginClasses.mr1,
                    classes.select
                  )}
                  margin='dense'
                  variant='outlined'
                  fullWidth
                >
                  <Select
                    value={options?.filterBy}
                    name='filterBy'
                    onChange={(e: any)=> { 
                      setOptions({...options, filterBy: e.target.value}) 
                    }}
                  >
                    <MenuItem value={'loanNumber'}>Loan #</MenuItem>
                    <MenuItem value={'orderId'}>Order #</MenuItem>
                    <MenuItem value={'billRefNumber'}>Reference #</MenuItem>
                    <MenuItem value={'borrower'}>Borrower</MenuItem>
                    <MenuItem value={'signer'}>Signer</MenuItem>
                    <MenuItem value={'status'}>Status</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <Box display='flex'>
                <Typography
                  variant='body2'
                  color='textSecondary'
                  className={clsx(marginClasses.mt2, marginClasses.mr2)}
                >
                  <b>Search</b>
                </Typography>
                <TextField
                  type='text'
                  className={clsx(
                    marginClasses.mt0,
                    marginClasses.mb0,
                    marginClasses.mr1,
                    classes.select
                  )}
                  variant='outlined'
                  size='small'
                  placeholder='Search'
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={options?.filterValue}
                  name='filterValue'
                  onChange={(e: any)=> { 
                    setOptions({...options, filterValue: e.target.value}) 
                  }}
                />
              </Box>
            </Grid>
            <Grid item lg={5} md={6} sm={6} xs={12}>
            <DateRangePicker
                initialSettings={settings}
                onApply={handleApply}
                onCancel={handleCancel}
              >
                <TextField
                  label="Date Range"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={label}
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                />
              </DateRangePicker>
            </Grid>
            </Grid>
          </Grid>
        <Grid item lg={1} md={1} sm={12} xs={12}>
          <FormControl className={marginClasses.my0} margin='dense' variant='outlined'>
            <ButtonGroup>
              <IconButton className={p2} onClick={() => search()}>
                <SvgIcon fontSize='small' color='primary'>
                  <Search />
                </SvgIcon>
              </IconButton>
              <IconButton disabled={!reset} className={p2} onClick={() => clear()}>
                <SvgIcon fontSize='small' color={reset ? 'error' : 'disabled'}>
                  <XCircle />
                </SvgIcon>
              </IconButton>
            </ButtonGroup>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export { UnbilledOrdersActions };