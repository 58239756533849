import { selectorFamily, selector } from 'recoil';
import moment from 'moment';

import {
  assignSignersPagination,
  ordersPagination,
  refetchOrders,
  refetchMonitoringOrders,
  unassignedOrdersPagination,
  currentOrder,
  refetchNearBySigners,
  forceCreateOrder,
  forceUpdateFiles,
  refetchNotes,
  forceFetchFees,
  currentTimer,
  forceUnassignOrder,
  refreshOrders,
  assignAttemptsOrderId,
  refetchAssignAttempts,
  forceReassign,
  forceSendResult,
  forceDownload,
  forceChangeAccountingStatus,
  forceMoveAll,
  forceResendDocumentReadyEmail,
  forceClearNerbySignerCache,
  forceLockOrder,
  getCreateBillPopup
} from '../Atoms/Orders';
import { verifyAuthToken } from './Auth'
import { selectedClient } from '../Atoms/Clients';
import { selectedDivision } from '../Atoms/Divisions';
import { api, fileapi } from '../../Services/api';

const createOrder = selectorFamily({
  key: 'createOrder',
  get: (params: any) => async ({ get }) => {
    get(forceCreateOrder)

    if (params) {
      try {
        await verifyAuthToken()
        const res: any = await api.POST('orders/', params);
        return {
          result: res,
          success: true
        };
      } catch (error) {
        return {
          result: error,
          success: false
        };
      }
    }
  }
});

const updateOrder = selectorFamily({
  key: 'updateOrder',
  get: (params: any) => async ({ get }) => {
    if (params) {
      try {
        get(forceCreateOrder)
        await verifyAuthToken()
        const res: any = await api.PUT('orders/', params);
        return {
          result: res,
          success: true
        };
      } catch (error) {
        return {
          result: error,
          success: false
        };
      }
    }
  }
});

const getAllOrders = selector({
  key: 'getAllOrders',
  get: async ({ get }) => {
    get(refetchOrders)

    const params = get(ordersPagination);
    try {
      await verifyAuthToken()
      const res: any = await api.GET('orders/', {
        params: {
          ...params,
          orderBy: params.orderBy || 'w_Appointment_Date',
          orderType: params.orderType || 'DESC'
        }
      });

      res.forEach((r, i) => {
        r.id = r.Id
        r.w_row_number = ++i
      })

      return {
        result: res,
        success: true
      };
    } catch (err) {
      return {
        result: [],
        success: false
      };
    }
  }
});

const getUnassignedOrders = selector({
  key: 'getUnassignedOrders',
  get: async ({ get }) => {
    get(refreshOrders)

    const params = get(unassignedOrdersPagination);

    const withSorting = {
      ...params,
      orderBy: params.orderBy || 'Id',
      orderType: params.orderType || 'DESC',
    }

    try {
      await verifyAuthToken()
      const res: any = await api.GET('orders/getUnassignedOrders/', { params: withSorting })

      res.rows.forEach((r, i) => {
        r.id = r.Id
        r.w_row_number = ++i

      })

      return {
        result: res,
        success: true
      };
    } catch (err) {
      return {
        result: [],
        success: false
      };
    }
  }
});

const getMonitoringOrders = selector({
  key: 'getMonitoringOrders',
  get: async ({ get }) => {
    get(refetchMonitoringOrders);
    const page = get(ordersPagination);
    const d = moment().format('YYYY-MM-DD')
    const params = {
      ...page,
      orderBy: page.orderBy || 'Id',
      orderType: page.orderType || 'DESC',
      status: 'monitoring',
      filter: `w_sql_appointment_date:${d}`
    };
    try {
      await verifyAuthToken()
      const res: any = await api.GET('orders/', { params });

      res.forEach(r => {
        r.id = r.Id;
        r.name = `${r.w_Borrower_First_Name} ${r.w_Borrower_Last_Name}`;
        r.pst_time = r.w_Appointment_Time
      })

      return {
        result: res,
        success: true
      };
    } catch (err) {
      return {
        result: [],
        success: false
      };
    }
  }
});

const cancelOrder = selector({
  key: 'cancelOrder',
  get: async ({ get }) => {
    try {
      const { Id: id } = get(currentOrder)
      await verifyAuthToken()
      const result: any = await api.PUT(`orders/cancel/${id}`);

      return {
        result,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const getDefaultFee = selector({
  key: 'getDefaultFee',
  get: async ({ get }) => {
    const client = get(selectedClient)
    const division = get(selectedDivision)
    get(forceFetchFees)
    if (client && division > -1) {
      try {
        await verifyAuthToken()
        const res: any = await api.GET(`orders/getfee/${client}/${division}`);
        return {
          result: res.rows,
          success: true
        };
      } catch (err) {
        console.error(err);
        return {
          result: err,
          success: false
        };
      }
    }
  }
});

const lockOrder = selector({
  key: 'lockOrder',
  get: async ({ get }) => {
    try {
      get(forceLockOrder)
      const { Id: id, order_locked: currentLock } = get(currentOrder)
      const lock = currentLock === 'N' ? 'y' : 'n'

      await verifyAuthToken()
      const result: any = await api.PUT(`orders/lock/${id}`, { lock });

      return {
        result,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const getNearBySigners = selector({
  key: 'getNearBySigners',
  get: async ({ get }) => {
    get(refetchNearBySigners)
    const params = get(assignSignersPagination);
    const ids = []
    if (params.orderId) {
      try {
        await verifyAuthToken()
        const res: any = await api.GET(`orders/signers/getNearBySigners/${params.orderId}`, { params })

        res.rows.forEach(r => {
          r.id = r.signer_id
          ids.push(r.signer_id)
        })

        const activity: any = await api.POST(`/orders/signers/getSignersActivity`, { ids, borrower_id: params.orderId });

        res.rows.forEach(r => {
          const fetchedActivity = activity.filter((signerActivity) => signerActivity?.signer_id === r?.signer_id)[0]
          r.result = fetchedActivity?.result || ''
          r.requested_fee = fetchedActivity?.requested_fee || 0.00
        })

        return {
          result: res,
          success: true
        };
      } catch (err) {
        return {
          result: err,
          success: false
        };
      }
    }
  }
});

const changeOrderStatus = selector({
  key: 'changeOrderStatus',
  get: async ({ get }) => {
    try {
      const { Id: id, f_status_web } = get(currentOrder)
      await verifyAuthToken()
      const result: any = await api.PUT(`orders/update_status/${id}`, {
        status: f_status_web
      });
      return {
        result,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const assignSigner = selectorFamily({
  key: 'assignSigner',
  get: ({ ...data }: any) => async ({ get }) => {
    try {
      get(forceReassign)
      await verifyAuthToken()
      const res: any = await api.POST('/orders/assign', data);
      return {
        result: res,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const getOrderById = selectorFamily({
  key: 'getOrderById',
  get: (params: any) => async ({ get }) => {
    if (params) {
      try {
        get(forceCreateOrder)
        await verifyAuthToken()
        const res: any = await api.GET(`/orders/getbyid/${params.id}`);
        return {
          result: res,
          success: true
        };
      } catch (error) {
        return {
          result: error,
          success: false
        };
      }
    }
  }
});

const getConfirmationById = selectorFamily({
  key: 'getConfirmationById',
  get: (params: any) => async ({ get }) => {
    if (params) {
      try {
        get(forceCreateOrder)
        await verifyAuthToken()
        const res: any = await api.GET(`/orders/get/confirmations/${params.id}/${params.type}`);
        return {
          result: res,
          success: true
        };
      } catch (error) {
        return {
          result: error,
          success: false
        };
      }
    }
  }
});

const confirmCallStatus = selector({
  key: 'confirmCallStatus',
  get: async ({ get }) => {
    const params = get(currentOrder);
    try {
      await verifyAuthToken()
      const res: any = await api.PUT('/orders/updateCallStatus', params);
      return {
        result: res,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const getOrderNotes = selectorFamily({
  key: 'getOrderNotes',
  get: (id: any) => async ({ get }) => {
    get(refetchNotes);
    try {
      await verifyAuthToken()
      const res: any = await api.GET(`/orders/borrower-notes?borrower_ID=${id}`);

      return {
        result: res.map(r => ({
          ...r,
          name: `${r.employee.firstName} ${r.employee.lastName}`,
          key: r.id
        })),
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const newNote = selectorFamily({
  key: 'newNote',
  get: (params: any) => async () => {
    try {
      await verifyAuthToken()
      const res: any = await api.POST('/orders/borrower-notes', params);
      return {
        result: res,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const updateNote = selectorFamily({
  key: 'updateNote',
  get: (params: any) => async () => {
    try {
      await verifyAuthToken()
      const res: any = await api.PUT('/orders/borrower-notes', params);
      return {
        result: res,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const uploadFile = selectorFamily({
  key: 'uploadFile',
  get: ({ file, destination, type, id }: any) => async ({ get }) => {
    try {
      get(forceUpdateFiles);
      if (file) {
        const formData: any = new FormData();
        formData.append('file', file);
        formData.append('destination', String(destination));
        if (id !== 0) {
          formData.append('type', String(type));
          formData.append('id', Number(id));
          formData.append(' ', ' ');
        }
        await verifyAuthToken()
        const res: any = await fileapi.POST('upload', formData);
        return {
          result: res,
          success: true
        };
      }
    } catch (error) {
      return {
        result: error,
        success: false
      };
    }

    return {
      result: false,
      success: false
    }
  }
});

const FetchFileList = selectorFamily({
  key: 'FetchFileList',
  get: (order: any) => async ({ get }) => {
    if (order.Id) {
      try {
        get(forceUpdateFiles)
        await verifyAuthToken()
        const res: any = await fileapi.POST('list', {
          folder: `borrower/${order.Id}`
        });
        res?.data.forEach((row: any) => { row.id = row.documentID })
        return {
          result: res,
          success: true
        };
      } catch (error) {
        return {
          result: [],
          success: false
        };
      }
    }
  }
});

const fetchScanbacks = selectorFamily({
  key: 'fetchScanbacks',
  get: (order: any) => async ({ get }) => {
    if (order.Id) {
      try {
        get(forceUpdateFiles)
        await verifyAuthToken()
        const res: any = await fileapi.POST('list', {
          folder: `borrower/${order.Id}/signers/scanbacks`
        });
        return {
          result: res,
          success: true
        };
      } catch (error) {
        return {
          result: [],
          success: false
        };
      }
    }
  }
});

const DeleteFile = selectorFamily({
  key: 'DeleteFile',
  get: (params) => async ({ get }) => {
    try {
      get(forceUpdateFiles)
      await verifyAuthToken()
      const res: any = await fileapi.POST('delete', params);
      return {
        result: res,
        success: true
      };
    } catch (error) {
      return {
        result: error,
        success: false
      };
    }
  }
});

const FetchFile = selectorFamily({
  key: 'FetchFile',
  get: (params: any) => async ({ get }) => {
    try {
      get(forceDownload)
      await verifyAuthToken()
      const res: any = await fileapi.DOWNLOAD('getfile', params);
      return {
        result: res,
        success: true
      };
    } catch (error) {
      return {
        result: error,
        success: false
      };
    }
  }
});


const mergeDocs = selectorFamily({
  key: 'mergeDocs',
  get: ({ files, destination, signer_id, confirmation }: any) => async ({ get }) => {
    try {
      get(forceUpdateFiles);
      if (files.length) {
        await verifyAuthToken()
        const res: any = await fileapi.POST('merge', { files, destination, signer_id, confirmation });
        return {
          result: res,
          success: true
        };
      }
    } catch (error) {
      return {
        result: error,
        success: false
      };
    }
    return {
      result: false,
      success: false
    }
  }
});

const saveNotaryFee = selectorFamily({
  key: 'saveNotaryFee',
  get: (params: any) => async ({ get }) => {
    if (params) {
      try {
        get(forceCreateOrder)
        await verifyAuthToken()
        const res: any = await api.POST('/orders/save/', params);
        return {
          result: res,
          success: true
        };
      } catch (error) {
        return {
          result: error,
          success: false
        };
      }
    }
  }
});

const sendCancelResult = selectorFamily({
  key: 'sendCancelResult',
  get: ({ ...data }: any) => async ({ get }) => {
    try {
      get(forceSendResult)
      await verifyAuthToken()
      const res: any = await api.POST('orders/signers/order/result', data);
      return {
        result: res,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});


const monitorTime = selectorFamily({
  key: 'monitorTime',
  get: (params: any) => async ({ get }) => {
    try {
      get(currentTimer)
      await verifyAuthToken()
      const res: any = await api.POST(`orders/activity/${params.url}`, { order_id: params.Id });
      return {
        result: res,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});


const unassignOrder = selectorFamily({
  key: 'unassignOrder',
  get: (params: any) => async ({ get }) => {
    if (params) {
      try {
        get(forceUnassignOrder)
        await verifyAuthToken()
        const res: any = await api.POST('/orders/unassign/order', { ...params });
        return {
          result: res,
          success: true
        };
      } catch (error) {
        return {
          result: error,
          success: false
        };
      }
    }
  }
});

const getAssignAttempts = selector({
  key: 'getAssignAttempts',
  get: async ({ get }) => {
    const selectedAttempt = get(assignAttemptsOrderId)
    get(refetchAssignAttempts)
    if (selectedAttempt) {
      try {
        await verifyAuthToken()
        const result: any = await api.GET(`orders/matchattempt/${selectedAttempt.created_by}/${selectedAttempt.borrower_id}`);
        return {
          result,
          success: true
        };
      } catch (err) {
        return {
          result: [],
          success: false
        };
      }
    }
  }
});

const mergeDocumentsMailToSigner = selectorFamily({
  key: 'mergeDocumentsMailToSigner',
  get: (params) => async ({ get }) => {
    try {
      get(forceResendDocumentReadyEmail)
      await verifyAuthToken()
      const result: any = await api.POST('orders/mergeDocumentsMailToSigner', params);
      return {
        result,
        success: true
      };
    } catch (err) {
      return {
        result: [],
        success: false
      };
    }
  }
});


const moveDocs = selectorFamily({
  key: 'moveDocs',
  get: (params: any) => async ({ get }) => {
    try {
      get(forceMoveAll)
      await verifyAuthToken()
      const res: any = await fileapi.GET('/move', { params });
      return {
        result: res,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const changeAccountingStatus = selectorFamily({
  key: 'changeAccountingStatus',
  get: (borrower_id: any) => async ({ get }) => {
    try {
      get(forceChangeAccountingStatus)
      await verifyAuthToken()
      const res: any = await api.GET(`orders/changeaccountingstatus/${borrower_id}`);
      return {
        result: res,
        success: true
      };
    } catch (error) {
      return {
        result: error,
        success: false
      };
    }
  }
});

const clearNearbySignerCache = selectorFamily({
  key: 'clearNearbySignerCache',
  get: (orderid: any) => async ({ get }) => {
    try {
      get(forceClearNerbySignerCache)
      await verifyAuthToken()
      const res: any = await api.GET(`orders/signers/clear-nearbysignercache/${orderid}`);
      return {
        result: res,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const getAuditTrailByOrderId = selectorFamily({
  key: 'getAuditTrailByOrderId',
  get: (orderid: any) => async ({ get }) => {
    try {
      await verifyAuthToken()
      const res: any = await api.GET(`orders/audit/${orderid}`);
      return {
        result: res,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const createBillPopup = selector({
  key: 'createBillPopup',
  get: async ({ get }) => {
    const createBillParams = get(getCreateBillPopup)
    if (createBillParams?.orderId) {
      try {
        await verifyAuthToken()
        const result: any = await api.GET(`orders/createbill/popup/${createBillParams?.orderId}`);
        return {
          result,
          success: true
        };
      } catch (err) {
        return {
          result: err,
          success: false
        };
      }
    }
  }
});

export {
  createOrder,
  getAllOrders,
  getUnassignedOrders,
  getMonitoringOrders,
  getNearBySigners,
  cancelOrder,
  getDefaultFee,
  lockOrder,
  changeOrderStatus,
  assignSigner,
  updateOrder,
  getOrderById,
  confirmCallStatus,
  getOrderNotes,
  newNote,
  updateNote,
  uploadFile,
  FetchFileList,
  fetchScanbacks,
  DeleteFile,
  FetchFile,
  mergeDocs,
  saveNotaryFee,
  sendCancelResult,
  monitorTime,
  unassignOrder,
  getAssignAttempts,
  mergeDocumentsMailToSigner,
  moveDocs,
  getConfirmationById,
  changeAccountingStatus,
  clearNearbySignerCache,
  getAuditTrailByOrderId,
  createBillPopup
};
