import React from 'react';
import { Grid } from '@material-ui/core';

import { OutlinedSection } from '../Section';

import { ClientsList } from './ContactFields/ClientsList';

import { ContactName } from './ContactFields/ContactName';
import { ContactEmail } from './ContactFields/ContactEmail';
import { ContactPhone } from './ContactFields/ContactPhone';

import { ContactRadios } from './ContactFields/ContactRadios';

import { ContactTeamManager } from './ContactFields/ContactTeamManager';
import { ContactStatus } from './ContactFields/ContactStatus';
import { ContactPrivilege } from './ContactFields/ContactPrivilege';

import { DivisionRadios } from './ContactFields/DivisionRadios';

import { useStyles } from './Styles/Fields';

const ContactForm = (props) => {
  const classes = useStyles();
  const { values, errors, touched, handleChange, handleBlur, isEditMode } = props;

  const setPrivilege = (e, i) => {
    const updatedDiv = [...props.divisions].map((d, index) => {
      if (i === index) {
        return {
          ...d,
          privilege: e.target.value
        };
      }
      return { ...d };
    })
    props.setDivisions(updatedDiv);
  }

  return (
    <Grid className={classes.gridRoot} container spacing={2}>
      <Grid item xs={12} md={6}>
        <OutlinedSection title='Select a Client'>
          <Grid container spacing={2}>
            <ClientsList clients={props.clients} client={props.client} getDivisions={props.getDivisions} />
          </Grid>
        </OutlinedSection>
        <OutlinedSection title='Contact Information'>
          <Grid container spacing={2}>
            <ContactName
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <ContactEmail
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <ContactPhone
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </Grid>
        </OutlinedSection>
      </Grid>
      <Grid item xs={12} md={6}>
        <OutlinedSection title='Contact Settings'>
          <Grid container spacing={2} alignItems='center'>
            <ContactRadios
              values={values}
              handleChange={handleChange}
            />
            <ContactTeamManager
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            {isEditMode && (
              <ContactStatus
                values={values}
                handleChange={handleChange}
              />
            )}
            <ContactPrivilege
              values={values}
              handleChange={handleChange}
            />
          </Grid>
        </OutlinedSection>
        <OutlinedSection title='Divisions'>
          <Grid container spacing={2} alignItems='center'>
            <DivisionRadios divisions={props.divisions} setPrivilege={setPrivilege} />
          </Grid>
        </OutlinedSection>
      </Grid>
    </Grid>
  );
}

export { ContactForm };
