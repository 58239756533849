import { selector, selectorFamily } from 'recoil';

import { verifyAuthToken } from './Auth'
import { api } from '../../Services/api';
import { forceUpdateGoal } from '../../Recoil/Atoms/Stats';

const unassignedOrders = selector({
  key: 'unassignedOrders',
  get: async ({ get }) => {
    try {
      await verifyAuthToken()
      const res: any = await api.GET('orders/fetchUnisngedOrdersCount');
      return {
        result: res.count,
        success: true
      }
    } catch (error) {
      return {
        result: 0,
        success: false
      }
    }
  }
});

const appointments = selector({
  key: 'appointments',
  get: async ({ get }) => {
    try {
      await verifyAuthToken()
      const res: any = await api.GET('orders/getCurrentApptCount');
      return {
        result: res.count,
        success: true
      }
    } catch (error) {
      return {
        result: 0,
        success: false
      }
    }
  }
});

const mtdSigned = selector({
  key: 'mtdSigned',
  get: async ({ get }) => {
    try {
      await verifyAuthToken()
      const res: any = await api.GET('orders/getTotalMTDSignedOrder');
      return {
        result: res.count,
        success: true
      }
    } catch (error) {
      return {
        result: 0,
        success: false
      }
    }
  }
});

const monthOrder = selector({
  key: 'monthOrder',
  get: async ({ get }) => {
    try {
      await verifyAuthToken()
      const res: any = await api.GET('orders/getCurrentOrderCount');
      return {
        result: res.count,
        success: true
      }
    } catch (error) {
      return {
        result: 0,
        success: false
      }
    }
  }
});

const getMatchedOrders = selector({
  key: 'getMatchedOrders',
  get: async ({ get }) => {
    try {
      await verifyAuthToken()
      const res: any = await api.GET('orders/getMatchedOrders');
      return {
        result: res.count,
        success: true
      }
    } catch (error) {
      return {
        result: 0,
        success: false
      }
    }
  }
});

const getGoal = selector({
  key: 'getGoal',
  get: async ({ get }) => {
    try {
      get(forceUpdateGoal)
      await verifyAuthToken()
      const res: any = await api.GET('orders/goal/get');
      return {
        result: res.target,
        success: true
      }
    } catch (error) {
      return {
        result: 0,
        success: false
      }
    }
  }
});

const setGoal = selectorFamily({
  key: 'setGoal',
  get: (params: any) => async ({ get }) => {
    if (params) {
      try {
        get(forceUpdateGoal)
        await verifyAuthToken()
        const res: any = await api.POST('orders/goal/set', params);
        return {
          result: res,
          success: true
        };
      } catch (error) {
        return {
          result: error,
          success: false
        };
      }
    }
  }
});

const signedOrderGraphData = selector({
  key: 'signedOrderGraphData',
  get: async ({ get }) => {
    try {
      await verifyAuthToken()
      const res: any = await api.GET('orders/signedorders/graph');

      return {
        result: res,
        success: true
      }
    } catch (error) {
      return {
        result: 0,
        success: false
      }
    }
  }
});

export {
  unassignedOrders,
  appointments,
  mtdSigned,
  monthOrder,
  getMatchedOrders,
  getGoal,
  setGoal,
  signedOrderGraphData
};
