import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import { TextField, IconButton, InputAdornment, SvgIcon } from '@material-ui/core';
import { Search } from 'react-feather';
import { Page } from '../../Components/Page';
import { useStyles } from '.././Styles/Page';
import { SignersLoginData } from '../../Components/Signers/SignerLoginData';

import { pagination } from '../../Recoil/Atoms/Signers';
import { getSigners } from '../../Recoil/Selectors/Signer';

export const SignerLogin = () => {
    const classes = useStyles();
    const [pageInfo, setPageinfo] = useRecoilState(pagination);
    const signerData: any = useRecoilValueLoadable(getSigners);
    const [search, setSigner] = useState('')

    const signers = signerData?.contents?.result?.rows || [];
    const total = signerData?.contents?.result?.count || 0;

    useEffect(() => {
        setPageinfo({
            limit: 20,
            page: 1,
            count: 0,
            orderBy: 'signer_id',
            orderType: 'DESC',
            firstName: '',
            lastName: '',
            search: '',
            secondaryCategory: ''
        })
    }, [setPageinfo])

    const handleSearch = () => {
        setPageinfo({
            ...pageInfo,
            firstName: search.split(' ')[0] || '',
            lastName: search.split(' ')[1] || '',
            page: 1
        })
    }

    return (
        <Page className={classes.paper} title='Signer Login'>
            <TextField
                type='search'
                variant='outlined'
                label='Search Signer By Name'
                margin='dense'
                value={search}
                onChange={(e) => { setSigner(e.target.value) }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <IconButton onClick={handleSearch}>
                                <SvgIcon fontSize='small' color='action'>
                                    <Search />
                                </SvgIcon>
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
            <SignersLoginData
                pageInfo={pageInfo}
                setPageInfo={setPageinfo}
                loading={signerData.state === 'loading'}
                rows={signers}
                total={total}
            />
        </Page>
    );
}
