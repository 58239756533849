import React, { useState } from 'react';
import { useRecoilState, useRecoilCallback, useSetRecoilState } from 'recoil'

import { Button, Box, CircularProgress } from '@material-ui/core'

import { Toast } from './../Toasts'

import { volumeReport, refetchVolumeReportPdf, resendVolumeReportPdf } from './../../Recoil/Atoms/Reports'
import { getVolumeReportPdf, sendVolumeReportPdf } from './../../Recoil/Selectors/Reports'

import { Table } from '../Table';

const VolumeData = (props: any) => {
  const { rows, loading, total } = props;
  const [volume, setVolume] = useRecoilState(volumeReport)
  const refetchPdf = useSetRecoilState(refetchVolumeReportPdf)
  const resendPdf = useSetRecoilState(resendVolumeReportPdf)
  const [alert, setAlert] = useState(null);
  const [pending, setPending] = useState(false);

  const fetchPdf = useRecoilCallback(({snapshot}) => async () => {
    refetchPdf(n => n + 1)
    setPending(true)
    const res: any = await snapshot.getPromise(
      getVolumeReportPdf({ day: volume.day }))
      if (res.success) {
        const fetchedFile = window.URL.createObjectURL(res.result);
        window.open(fetchedFile)
      }
    setPending(false)
  })

  const sendPdf = useRecoilCallback(({snapshot}) => async () => {
    resendPdf(n => n + 1)
    setPending(true)
    const res: any = await snapshot.getPromise(sendVolumeReportPdf({ day: volume.day }))
      if (res.success) {
        setAlert({
          open: true,
          status: 'success',
          message: res?.result?.message || 'Email Sent Successfully'
        });
      } else {
        setAlert({
          open: true,
          status: 'error',
          message: res?.result?.error || 'Failed To Send Email'
        });
      }
      setPending(false)
  })

  const columns = [
    {
    field: 'clientName',
    headerName: 'Client Name'
  }, {
    field: 'loan',
    headerName: 'Number Of Orders'
  }, {
    field: 'app',
    headerName: 'Number Of Appointments'
  }];

  const handlePage = (e: unknown, newPage: number) => {
    setVolume({
      ...volume,
      page: newPage
    });
  };

  const handleRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVolume({
      ...volume,
      limit: parseInt(e.target.value, 10)
    });
  };

  // const handleSort = (e: any) => {

  //   if (e.sortModel &&e.sortModel[0].field) {
  //     const { field, sort } = e.sortModel[0]
  //     setVolume({
  //       ...volume,
  //       orderBy: field,
  //       orderType: sort,
  //     });
  //   }
  // };
  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        rows={rows}
        total={total}
        // sortModel={
        //   [{ field: orderBy, sort: orderType }]
        // }
        // onSortingChange={handleSort}
        handlePage={handlePage}
        handleRowsPerPage={handleRowsPerPage}
      />
      <Box display='flex' justifyContent='space-between'>
        <Button disabled={pending} variant='contained' color='primary' onClick={fetchPdf}> 
          {pending && <CircularProgress size={12} />} View PDF
        </Button>
        <Button disabled={pending} variant='contained' color='primary' onClick={sendPdf}> 
          {pending && <CircularProgress size={12} />} Send PDF
        </Button>
      </Box>
      {
        alert &&
        <Toast type={alert.status} message={alert.message} open={alert.open} setToast={setAlert} />
      }
    </>
  );
};

export { VolumeData };
