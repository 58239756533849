import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';

import { GeneralModal } from 'src/Components/Modal'
import { Table } from 'src/Components/Table'
import moment from 'moment';

function ViewInvoice(props) {
  const { orderDetails, close, open } = props

  const feesColumns = [
    {
      field: 'fee_name',
      headerName: 'Fee',
      disableColumnMenu: true,

    }, {
      field: 'fee_amount',
      headerName: 'Fee Amount',
      disableColumnMenu: true,

    }
  ]
  const applicableFees = orderDetails?.signer?.signer_type === 'Notary' ? 'fee_amount' : 'attorney_fee'
  const totalFees = orderDetails.fees?.length ?
    orderDetails.fees.map(item => item[applicableFees]).reduce((prev, curr) => prev + curr, 0) : 0

  return (
    <GeneralModal
      title='View Invoice'
      openModal={close}
      open={open}
      noSubmit
    >
      <Grid container spacing={4}>
        <Grid item xs={6} container justifyContent='space-around'>
          <Grid item >
            <b>From</b>
            {orderDetails?.signer ?
              <>
            <Typography>
              <br></br>
              {orderDetails?.signer?.Signer_First} {orderDetails?.signer?.Signer_Last}
            </Typography>

            <Typography>
              {orderDetails?.signer?.address}
            </Typography>

            <Typography>
              {orderDetails?.signer?.city} {orderDetails?.signer?.state} {orderDetails?.signer?.zip}
            </Typography>
            </>
              :
            <>
              <Typography>
                <br></br>
                {orderDetails?.f_signer_name}
              </Typography>

              <Typography>
                {orderDetails?.f_signer_address}
              </Typography>

              <Typography>
                {orderDetails?.f_signer_city} {orderDetails?.f_signer_state} {orderDetails?.f_signer_zip}
              </Typography>
            </>
          }
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <b>To</b>
          <Typography>
            <br></br>
            Pacific Document Signing Inc
          </Typography>

          <Typography>
            10801 National Blvd. Suite #510
          </Typography>
          <Typography>
            Los Angeles, CA   90064
          </Typography>
        </Grid>
        <Grid item container xs={5} justifyContent='space-around'>
          <Grid item container justifyContent='flex-end'>
            <Grid container item xs={8} justifyContent='space-between'>
              <Typography>
                <b>
                  Appointment Date
                </b>
              </Typography>

              <Typography>
                {orderDetails?.f_appointment_date}
              </Typography>
            </Grid>
            <Grid container item xs={8} justifyContent='space-between'>
              <Typography>
                <b>
                  Appointment Time
                </b>
              </Typography>
              <Typography>
                {orderDetails?.f_appointment_time}
              </Typography>
            </Grid>
            <Grid container item xs={8} justifyContent='space-between'>
              <Typography>
                <b>
                  Client Name
                </b>
              </Typography>

              <Typography>
                {orderDetails?.client?.clientName}
              </Typography>
            </Grid>
            <Grid container item xs={8} justifyContent='space-between'>
              <Typography>
                <b>
                  Borrower Last Name
                </b>
              </Typography>

              <Typography>
                {orderDetails?.f_borrower_last_name}
              </Typography>
            </Grid>
            <Grid container item xs={8} justifyContent='space-between'>
              <Typography>
                <b>
                  Borrower City
                </b>
              </Typography>

              <Typography>
                {orderDetails?.f_borrower_city}
              </Typography>
            </Grid>
            <Grid container item xs={8} justifyContent='space-between'>
              <Typography>
                <b>
                  Status
                </b>
              </Typography>

              <Typography>
                {orderDetails?.f_status_web}
              </Typography>
            </Grid>
          </Grid>

        </Grid>
        <Grid item container xs={6} justifyContent='space-around'>
          <Grid item container justifyContent='space-around'>
            <Grid container item xs={8} justifyContent='space-between'>
              <Typography>
                <b>
                  Invoice #
                </b>
              </Typography>

              <Typography>
                INV{orderDetails?.Id}
              </Typography>
            </Grid>
            <Grid container item xs={8} justifyContent='space-between'>
              <Typography>
                <b>
                  Invoice Date
                </b>
              </Typography>

              <Typography>
                { orderDetails?.invoice_send_status === 1 ? 
                moment(orderDetails?.invoice_submitted_datetime).format('MM/DD/YYYY') : 
                moment().format('MM/DD/YYYY')}
              </Typography>
            </Grid>
            <Grid container item xs={8} justifyContent='space-between'>
              <Typography>
                <b>
                  Amount Due
                </b>
              </Typography>

              <Typography>
                ${totalFees || orderDetails?.f_signer_fees} USD
              </Typography>
            </Grid>
          </Grid>

        </Grid>
        <Grid item xs={12} container justifyContent='space-around' >
          <Grid item xs={9}>
            <Table
              columns={feesColumns}
              rows={orderDetails.fees || []}
              pagination={false}
            >
            </Table>
            <Grid container item justifyContent='flex-end'>
              <Grid item xs={12} container justifyContent='flex-end'>
                <Typography><b>Total	${totalFees || orderDetails?.f_signer_fees} USD</b></Typography>
              </Grid>
              <Grid item xs={12} container justifyContent='flex-end'>
                <Typography><b> Amount Due	${totalFees || orderDetails?.f_signer_fees} USD</b></Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12}>
          <Alert icon={false} severity='success'>
            <b> Disclaimer : </b>
             This Invoice is subject to approval by the operation
            and accounting departments of PacDocSign for accuracy.
           </Alert>

        </Grid>
      </Grid>
    </GeneralModal>
  )
}
export { ViewInvoice }
