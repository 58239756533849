import React from 'react';
import { useRecoilState } from 'recoil'
import { KeyboardDatePicker } from '@material-ui/pickers';

import moment from 'moment';
import { volumeReport } from './../../Recoil/Atoms/Reports'

import 'bootstrap-daterangepicker/daterangepicker.css';
import { useStyles } from './Styles/Fields';

const VolumeDateRange = () => {

  const [volume, setVolume] = useRecoilState(volumeReport)

  const classes = useStyles();
  return (
    <KeyboardDatePicker
    size='small'
    className={classes.datePicker}
    label="From Date"
    format="MM/DD/YYYY"
    name="fromDate"
    inputVariant="outlined"
    value={volume.day}
    onChange={(date) => setVolume({ day: moment(date).format('MM/DD/YYYY'), limit: 20, page: 1 })}
  />
  );
};

export { VolumeDateRange };
