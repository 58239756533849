import React from 'react';
import { Grid, TextField } from '@material-ui/core';

const ManagerExt = ({ values, handleChange }) => {
  return (
    <Grid item xs={12} md={4}>
      <TextField
        label='Ext'
        name='officeManagerExt'
        variant='outlined'
        size='small'
        type='number'
        fullWidth
        value={values.officeManagerExt}
        onChange={handleChange}
      />
    </Grid>
  );
}

export { ManagerExt };
