import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState, useSetRecoilState, useRecoilValueLoadable } from 'recoil';
import { Typography, Box, Divider } from '@material-ui/core';

import { NewDivision } from './NewDivision';
import { Page } from '../../Components/Page';
import { GeneralModal } from '../../Components/Modal';
import { DivisionActions } from '../../Components/Divisions/DivisionActions';
import { DivisionsData } from '../../Components/Divisions/DivisionsData';
import { Toast } from '../../Components/Toasts';
import { PageLoad } from '../../Components/LoadingScreen/PageLoad';

import { clientDivisions, loadDivision } from '../../Recoil/Atoms/Divisions';
import {
  getDivision,
  getDivisionsByClient,
  getContactsByClient,
  getStates
} from '../../Recoil/Selectors/Divisions';

import { useStyles } from '../Styles/Page';

export const Divisions = () => {
  const classes = useStyles();
  const params: any = useParams();

  const [modal, handleModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [toast, setToast] = useState({
    message: '',
    type: 'success',
    open: false
  });
  const [reloadDivision, setReloadDivision] = useState(1)
  const [divisions, setDivisions] = useRecoilState(clientDivisions);
  const handleSelectedDivision = useSetRecoilState(loadDivision);


  const divisionsData: any = useRecoilValueLoadable(getDivisionsByClient(params.id));
  const data = divisionsData?.contents?.result?.rows || [];
  const total = divisionsData?.contents?.result?.count || 0;

  const clientContactsList: any = useRecoilValueLoadable(getContactsByClient(params.id));
  const clientContacts = clientContactsList?.contents?.result || [];

  const statesList: any = useRecoilValueLoadable(getStates);
  const states = statesList?.contents?.result || [];

  const divisionData: any = useRecoilValueLoadable(getDivision(reloadDivision));
  const division = divisionData?.contents?.result || {};

  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(divisions)) {
      setDivisions(data);
    }
  }, [divisions, data, setDivisions]);

  const openModal = (open) => {
    handleSelectedDivision(-1);
    handleModal(open);
    setIsEditing(false)
  }

  const handleEdit = (e) => {
    setReloadDivision(reloadDivision + 1)
    handleSelectedDivision(e);
    handleModal(true);
    setIsEditing(true)
  }

  return (
    <Page className={classes.paper} title='Divisions'>
      <Typography variant='h3'>Divisions</Typography>
      <Box my={2}>
        <Divider variant='fullWidth' />
      </Box>
      <DivisionActions handleModal={handleModal} />
      <DivisionsData data={divisions} total={total} handleEdit={handleEdit} />
      <GeneralModal
        open={modal}
        title={isEditing ? 'Edit Division' : 'New Division'}
        loading={false}
        openModal={openModal}
        noSubmit={true}
        maxWidth='xl'
        noFooter={true}
      >
        {divisionData.state === 'loading' ?
          <PageLoad /> :
          <NewDivision
            division={division}
            clientContacts={clientContacts}
            states={states}
            clientID={params.id}
            clientName={params.name}
            setToast={setToast}
            openModal={openModal}
            maxWidth='xl'
          />
        }
      </GeneralModal>
      <Toast message={toast.message} type={toast.type} open={toast.open} setToast={setToast} />
    </Page>
  );
}
