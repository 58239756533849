import React, { useEffect } from 'react';
import {
  useRecoilValueLoadable,
  useSetRecoilState,
  useRecoilState
} from 'recoil';
import { Typography, Box, Divider } from '@material-ui/core';

import { Page } from '../../Components/Page';

import { useStyles } from '../Styles/Page';
import { ClientInvoicesData } from '../../Components/Accounting/ClientInvoicesData';
import { ClientInvoicesActions } from '../../Components/Accounting/ClientInvoicesActions'

import {
  getAllClientInvoices
} from '../../Recoil/Selectors/ClientInvoices';
import { refetchClientInvoices, clientInvoicesPagination } from '../../Recoil/Atoms/ClientInvoices';

export const ClientInvoices = () => {
  const classes = useStyles();

  const [pageInfo, setPageInfo]: any = useRecoilState(clientInvoicesPagination);
  const setRefetchClientInvoices = useSetRecoilState(refetchClientInvoices);

  const allClientInvoices: any = useRecoilValueLoadable(getAllClientInvoices)
  const clientInvoicesData = allClientInvoices?.contents?.result || [];
  const total = allClientInvoices?.contents?.result?.count || 0;

  useEffect(() => {
    return () => {
      setPageInfo({
        ...pageInfo,
        searchType: undefined,
        searchValue: undefined
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page className={classes.paper} title='Client Invoices'>
      <Typography variant='h3'>Client Invoices</Typography>
      <Box my={2}>
        <Divider variant='fullWidth' />
      </Box>
      <ClientInvoicesActions />
      <ClientInvoicesData
        setRefetchClientInvoices={setRefetchClientInvoices}
        rows={clientInvoicesData.length ? clientInvoicesData : []}
        total={total}
        loading={allClientInvoices?.state === 'loading'}
      />
    </Page>
  )
}
