import React, { useState } from 'react';
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  Button,
  TextField,
  Grid,
  Typography,
  IconButton,
  SvgIcon,
  ButtonGroup
} from '@material-ui/core';
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState, useRecoilCallback } from 'recoil'

import clsx from 'clsx';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment'

import { Search, XCircle } from 'react-feather';

import { Toast } from './../../../Components/Toasts';
import { GeneralModal } from '../../Modal';
import { PageLoad } from 'src/Components/LoadingScreen/PageLoad';
import { PayMultipleBillsTable } from './PayMultipleBillsTable'

import { pendingVendorInvoicesPagination, multipleBillsPopup, paySelectedMultipleBills, refetchPendingVendorInvoice } from '../../../Recoil/Atoms/Accounting'
import { payMultipleBillsPopup, paySelectedBills } from './../../../Recoil/Selectors/Accounting'


import { useStyles } from '../Styles/style';
import { paddingStyles } from '../../../Common/Styles/Paddings';
import { marginStyles } from '../../../Common/Styles/Margins';

const PendingVendorInvoicesActions = ({ selectedOrderIds, setSelectedOrderIds }) => {
  const classes = useStyles();
  const marginClasses = marginStyles();
  const { p2 } = paddingStyles();

  const [paySelectedBillsModal, setPaySelectedBillsModal] = useState(false);
  const [pendingVendorPagination, setPendingVendorPagination] = useRecoilState(pendingVendorInvoicesPagination)
  const [label, setLabel] = useState('Select dates')
  const [toast, setToast] = useState({ message: '', type: 'success', open: false });

  const payMultipleBillsPopupRequest: any = useRecoilValueLoadable(payMultipleBillsPopup)
  const payMultipleBills = payMultipleBillsPopupRequest?.contents?.result || {};
  const result = payMultipleBillsPopupRequest?.contents?.success

  const [reset, setReset] = useState(false)
  const setMultipleBillsPopup = useSetRecoilState(multipleBillsPopup)
  const forcePayBill = useSetRecoilState(paySelectedMultipleBills)

  const [account, setAccount] = useState(undefined)
  const refresh = useSetRecoilState(refetchPendingVendorInvoice)

  const togglePaySelectedBillsModal = () => {
    setPaySelectedBillsModal(!paySelectedBillsModal);
    if (!paySelectedBillsModal) {
      setMultipleBillsPopup(values => { return { orderIds: selectedOrderIds, refetch: values?.refetch + 1 } })
    }
  };

  const [options, setOptions] = useState({
    filterBy: pendingVendorPagination.filterBy || '',
    filterValue: pendingVendorPagination.filterValue || '',
    startDate: pendingVendorPagination.startDate || '',
    endDate: pendingVendorPagination.endDate || ''
  })

  const settings = {
    locale: {
      cancelLabel: 'Clear',
    },
    autoUpdateInput: false,
    ranges: {
      Today: [moment().startOf('day').toDate(), moment().endOf('day').toDate()],
      Yesterday: [
        moment().subtract(1, 'days').toDate(),
        moment().subtract(1, 'days').toDate(),
      ],
      'Last 7 Days': [
        moment().subtract(6, 'days').toDate(),
        moment().toDate(),
      ],
      'Last 30 Days': [
        moment().subtract(29, 'days').toDate(),
        moment().toDate(),
      ],
      'This Month': [
        moment().startOf('month').toDate(),
        moment().endOf('month').toDate(),
      ],
      'Last Month': [
        moment().subtract(1, 'month').startOf('month').toDate(),
        moment().subtract(1, 'month').endOf('month').toDate(),
      ],
    }
  }

  const handleApply = (event, picker) => {
    const { startDate, endDate }: any = picker
    setLabel(`${startDate.format('MM/DD/YYYY')} - ${endDate.format('MM/DD/YYYY')}`)
    setOptions({
      ...options,
      startDate: startDate.format('MM/DD/YYYY'),
      endDate: endDate.format('MM/DD/YYYY')
    })
  };

  const handleCancel = () => {
    setLabel('Select dates')
    setOptions({ ...options, startDate: '', endDate: '' })
  };

  const search = () => {
    setPendingVendorPagination({
      ...pendingVendorPagination,
      filterBy: options.filterBy || '',
      filterValue: options.filterValue || '',
      startDate: options.startDate || '',
      endDate: options.endDate || ''
    })
    setReset(true)
  };

  const clear = () => {
    setOptions({
      ...options,
      filterBy: 'loanNumber',
      filterValue: '',
      startDate: '',
      endDate: ''
    })
    setPendingVendorPagination({
      ...pendingVendorPagination,
      filterBy: 'loanNumber',
      filterValue: '',
      startDate: '',
      endDate: ''
    })
    setLabel('Select dates')
    setReset(false)
  };

  const payAllSelectedBills = useRecoilCallback(({ snapshot }) => async (params) => {
    forcePayBill(n => n + 1)
    const res = await snapshot.getPromise(paySelectedBills(params));
    if (res?.success) {
      setToast({
        message: 'Payment Successfull.',
        type: 'success',
        open: true
      })
      setSelectedOrderIds([])
      setPaySelectedBillsModal(false)
      refresh(n => n + 1)
    } else {
      setToast({
        message: 'Payment Failed',
        type: 'warning',
        open: true
      })
    }
  });

  return (
    <Box my={2}>
      <Toast message={toast.message} type={toast.type} open={toast.open} setToast={setToast} />
      <Box>
        <GeneralModal
          open={paySelectedBillsModal}
          title='PacDocSign : Pay Multiple Bills'
          loading={payMultipleBillsPopupRequest.state === 'loading'}
          openModal={setPaySelectedBillsModal}
          submitLabel='Submit'
          handleSubmit={() => payAllSelectedBills({
            bills: payMultipleBills?.bills,
            account: account,
            subtotal: payMultipleBills?.amountDue,
            apaccount: payMultipleBills?.bills[0].apAccountId,
            vendorId: payMultipleBills?.bills[0].vendorId
          })}
          noFooter={false}
          noSubmit={!result}
          maxWidth='md'
        >
          {
            payMultipleBillsPopupRequest.state === 'loading'
              ?
              <PageLoad />
              :
              !result
                ?
                <Typography variant='body1' color='textSecondary'>{payMultipleBills?.data?.message}</Typography>
                :
                <PayMultipleBillsTable
                  payMultipleBills={payMultipleBills}
                  setAccountPayable={setAccount}
                  account={account}
                />
          }
        </GeneralModal>
      </Box>
      <Grid container spacing={1}>
        <Grid item lg={2} md={2} sm={6} xs={12}>
          <Button
            variant='contained'
            className={classes.greenButton}
            fullWidth
            onClick={togglePaySelectedBillsModal}
          >
            Pay Selected Bills
          </Button>
        </Grid>
        <Grid item lg={2} md={2} sm={6} xs={12}>
          <FormControl
            className={clsx(
              marginClasses.mt0,
              marginClasses.mb0,
              marginClasses.mr1
            )}
            margin='dense'
            variant='outlined'
            fullWidth
          >
            <Select
              value={options?.filterBy}
              name='filterBy'
              onChange={(e: any) => {
                setOptions({ ...options, filterBy: e.target.value })
              }}
            >
              <MenuItem value={'loanNumber'}>Loan#</MenuItem>
              <MenuItem value={'orderId'}>Order ID</MenuItem>
              <MenuItem value={'signer'}>Signer Name</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={2} md={2} sm={6} xs={12}>
          <TextField
            id='date'
            type='text'
            className={clsx(
              marginClasses.mt0,
              marginClasses.mb0,
              marginClasses.mr1
            )}
            variant='outlined'
            size='small'
            placeholder='Search'
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            value={options?.filterValue}
            name='filterValue'
            onChange={(e: any) => {
              setOptions({ ...options, filterValue: e.target.value })
            }}
          />
        </Grid>
        <Grid item lg={5} md={6} sm={6} xs={12}>
          <DateRangePicker
            initialSettings={settings}
            onApply={handleApply}
            onCancel={handleCancel}
          >
            <TextField
              label="Date Range"
              InputProps={{
                readOnly: true,
              }}
              value={label}
              variant="outlined"
              size="small"
              fullWidth={true}
            />
          </DateRangePicker>
        </Grid>

        <Grid item lg={1} md={1} sm={12} xs={12}>
          <FormControl className={marginClasses.my0} margin='dense' variant='outlined'>
            <ButtonGroup>
              <IconButton className={p2} onClick={() => search()}>
                <SvgIcon fontSize='small' color='primary'>
                  <Search />
                </SvgIcon>
              </IconButton>
              <IconButton disabled={!reset} className={p2} onClick={() => clear()}>
                <SvgIcon fontSize='small' color={reset ? 'error' : 'disabled'}>
                  <XCircle />
                </SvgIcon>
              </IconButton>
            </ButtonGroup>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export { PendingVendorInvoicesActions };
