import React, { useState } from 'react'
import {
    Box,
    FormControl,
    Select,
    MenuItem,
    TextField,
    Grid,
    Typography, IconButton, SvgIcon, ButtonGroup
} from '@material-ui/core';
import clsx from 'clsx';
import { useRecoilState } from 'recoil';
import { Search, XCircle } from 'react-feather';

import { marginStyles } from '../../Common/Styles/Margins';
import { useStyles } from './Styles/style';
import { DailyVendorBillPagination } from 'src/Recoil/Atoms/Accounting';
import { paddingStyles } from '../../Common/Styles/Paddings';

function DailyVendorBillActions() {
    const classes = useStyles();
    const marginClasses = marginStyles();
    const { p2 } = paddingStyles();

    const [pageInfo, setPageInfo] = useRecoilState(DailyVendorBillPagination)
    const [reset, setReset] = useState(false)

    const [options, setOptions] = useState({
        filter: pageInfo.filter || '',
        orderType: pageInfo.orderType || '',
        value: pageInfo.value || '',
        orderId: pageInfo.orderId || '',
        date: pageInfo.date || ''
    })

    const search = () => {
        setPageInfo({
            ...pageInfo,
            filter: options.filter || '',
            orderType: options.orderType || '',
            value: options.value || '',
            orderId: options.orderId || '',
            date: options.date || ''
        })
        setReset(true)
    };

    const clear = () => {
        setOptions({
          ...options,
          filter: '',
          orderType: '',
          value: '',
          orderId: '',
          date:''
        })
        setPageInfo({
          ...pageInfo,
          filter: '',
          orderType: '',
          value: '',
          orderId: '',
          date:''
        })
        setReset(false)
      };

    return (
        <Box my={2}>
            <Grid container>
                <Grid item lg={2} md={2} sm={6} xs={12}>
                    <Box display='flex'>
                        <Typography
                            variant='body2'
                            color='textSecondary'
                            className={clsx(marginClasses.mt2, marginClasses.mr2)}
                        >
                            <b>Select </b>
                        </Typography>
                        <FormControl
                            className={clsx(
                                marginClasses.mt0,
                                marginClasses.mb0,
                                marginClasses.mr1,
                                classes.select
                            )}
                            margin='dense'
                            variant='outlined'
                            fullWidth
                        >
                            <Select value={options?.filter} onChange={(e: any) => {
                                setOptions({ ...options, filter: e.target.value })
                            }}>
                                <MenuItem value={'loan'}>Loan #</MenuItem>
                                <MenuItem value={'orderid'}>Order #</MenuItem>
                                <MenuItem value={'signer'}>Signer</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box display='flex' justifyContent="center">
                        <Typography
                            variant='body2'
                            color='textSecondary'
                            className={clsx(marginClasses.mt2, marginClasses.mr2)}
                        >
                            <b>Search</b>
                        </Typography>
                        <FormControl>
                            <TextField
                                label='Search'
                                name='Search'
                                variant='outlined'
                                size='small'
                                value={options?.value}
                                onChange={(e: any) => {
                                    setOptions({ ...options, value: e.target.value })
                                }}
                            />
                        </FormControl>

                    </Box>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box display='flex'>
                        <Typography
                            variant='body2'
                            color='textSecondary'
                            className={clsx(marginClasses.mt2, marginClasses.mr2)}
                        >
                            <b>Date</b>
                        </Typography>
                        <TextField
                            id='date'
                            type='date'
                            variant='outlined'
                            defaultValue='2017-05-24'
                            size='small'
                            fullWidth
                            InputLabelProps={{
                                shrink: true
                            }}
                            value={options?.date}
                            onChange={(e: any) => {
                                setOptions({ ...options, date: e.target.value })
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item lg={1} md={1} sm={6} xs={12}>
                    <FormControl className={marginClasses.my0} margin='dense' variant='outlined'>
                        <ButtonGroup>
                            <IconButton className={p2} onClick={() => search()}>
                                <SvgIcon fontSize='small' color='primary'>
                                <Search />
                                </SvgIcon>
                            </IconButton>
                            <IconButton disabled={!reset} className={p2} onClick={() => clear()}>
                                <SvgIcon fontSize='small' color={reset ? 'error' : 'disabled'}>
                                <XCircle />
                                </SvgIcon>
                            </IconButton>
                        </ButtonGroup>
                    </FormControl>
                </Grid>
            </Grid>
        </Box>
    )
}

export  {DailyVendorBillActions}