import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilCallback } from 'recoil';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { resetPasswordRequest } from './../../Recoil/Selectors/Auth';

import { marginStyles } from '../../Common/Styles/Margins';

const RequestResetPasswordForm = (params: any) => {
  const marginClasses = marginStyles();
  const [alert, setAlert] = useState(null);
  const onSubmit = useRecoilCallback(({ snapshot }) => async (values: any) => {
    const { result, success } = await snapshot.getPromise(
      resetPasswordRequest({ email: values.email })
    );
    if (success) {
      setAlert({ message: result.message, status: 'success' });
    } else {
      setAlert({ message: 'Failed to Reset Password', status: 'error' });
    }
  });
  const validation = Yup.object().shape({
    email: Yup.string()
      .email('Invalid Email')
      .required('Please Enter Your Email')
  });

  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={validation}
      onSubmit={onSubmit}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        errors
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid item xs={12}>
            <TextField
              label='Email'
              name='email'
              variant='outlined'
              size='small'
              fullWidth
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              // helperText={touched.email}
              error={Boolean(touched.email && errors.email)}
            />
          </Grid>
          {alert && (
            <Box my={2}>
              <Alert severity={alert.status}>{alert.message}</Alert>
            </Box>
          )}
          <Box display='flex' justifyContent='flex-end' mt={3}>
            <Link to='/login'>
              <Button
                className={marginClasses.mr1}
                color='primary'
                size='small'
              >
                Back to login
              </Button>
            </Link>
            <Button
              type='submit'
              color='primary'
              variant='contained'
              size='small'
              disabled={isSubmitting}
            >
              {isSubmitting && (
                <CircularProgress
                  className={marginClasses.mr2}
                  color='inherit'
                  size={25}
                />
              )}
              Submit
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export { RequestResetPasswordForm };
