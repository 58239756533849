import React from 'react';
import { useSetRecoilState, useRecoilCallback } from 'recoil';

import { FormikComponent } from '../../Components/Formik';
import { ClientForm } from '../../Components/Client/ClientsForm';

import { refreshClients } from '../../Recoil/Atoms/Clients';
import { saveClient, updateClient } from '../../Recoil/Selectors/Clients';

import { initialValues, validationSchema } from '../../Common/Rules/ClientForm';

export const NewClient = (props) => {
  const callRefresh = useSetRecoilState(refreshClients);
  const refresh = () => callRefresh((n) => n + 1);

  const onSubmit = useRecoilCallback(({ snapshot }) => async (values: any, { setSubmitting }) => {
    let res: any = {};
    let message = '';
    if (values.clientID) {
      res = await snapshot.getPromise(
        updateClient(values)
      );
      message = 'Client has been updated';
    } else {
      res = await snapshot.getPromise(
        saveClient(values)
      );
      message = 'Client has been created';
    }
    setSubmitting(false);
    if (res.success) {
      refresh();
      props.openModal(false);
      props.setToast({
        message,
        type: 'success',
        open: true
      });
    }
  });

  return (
    <FormikComponent
      onSubmit={onSubmit}
      initialValues={props.selectedClient.clientID ? props.selectedClient : initialValues}
      validationSchema={validationSchema}
      enableReinitialize={props.selectedClient.clientID ? true : false}
    >
      <ClientForm />
    </FormikComponent>
  );
}
