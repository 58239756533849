import React, { useState, useEffect } from 'react';
import { Typography, Box, Divider, TextField, IconButton, InputAdornment, SvgIcon, DialogContentText } from '@material-ui/core';
import { useRecoilState, useRecoilValueLoadable, useRecoilCallback, useSetRecoilState } from 'recoil';
import { Search } from 'react-feather';
import clsx from 'clsx';


import { Page } from '../../Components/Page';
import { Toast } from '../../Components/Toasts';
import { GeneralModal } from '../../Components/Modal';
import { UnassignedOrdersData } from '../../Components/Orders/UnassignedOrders';
import { orderChannel } from './../../Common/NotificationService'

import { getUnassignedOrders, cancelOrder } from '../../Recoil/Selectors/Orders';
import { unassignedOrdersPagination, refreshOrders } from '../../Recoil/Atoms/Orders';
import { dialog as dialogState } from '../../Recoil/Atoms/Modals'

import { useStyles } from '../Styles/Page';
import { marginStyles } from '../../Common/Styles/Margins';
import { AssignAttempts } from './AssignAttempts';

export const UnassignedOrders = () => {
  const classes = useStyles();
  const marginClasses = marginStyles();
  const [search, setSearch] = useState('')
  const [cancelModelOpen, setCancelModal] = useState(false);
  const [viewAssignAttempts, setAssignAttempts] = useState(null);

  const [pageInfo, setPageInfo] = useRecoilState(unassignedOrdersPagination);
  const [dialog, setDialog] = useRecoilState(dialogState);
  const ordersData: any = useRecoilValueLoadable(getUnassignedOrders);
  const refresh = useSetRecoilState(refreshOrders)
  const [toast, setToast] = useState({ message: '', type: 'success', open: false });
  const orders = ordersData?.contents?.result?.rows || [];
  const total = ordersData?.contents?.result?.count || 0;
  const [unassigned, setUnassigned] = useState([])
  const [totalOrders, setTotalOrders] = useState(0)
  // const [stamp, setStamp] = useState(undefined)


  const onSearchChange = (e) => {
    setPageInfo({
      ...pageInfo,
      page: 1,
      search
    })
  };

  const handleCancelOrder = useRecoilCallback(({ snapshot }) => async () => {
    setCancelModal(false)
    setDialog({ ...dialog, loading: true })
    const { result, success } = await snapshot.getPromise(cancelOrder);
    if (!success) {
      setToast({ message: result.errors || 'Error occurred', type: 'warning', open: true });
    }
    refresh((n) => ++n)
    setDialog({ ...dialog, loading: false, open: false })
  });

  const onInitCancelOrder = () => {
    setCancelModal(true)
  };
  useEffect(() => {
    return () => {
      setPageInfo({
        ...pageInfo,
        search: '',
      })
    }
    // eslint-disable-next-line
  }, [])

  useEffect(()=>{
    if (process.env.REACT_APP_UNASSIGNED_PUSHER === '1') {
      orderChannel.unbind('unassigned-orders')
      orderChannel.bind('unassigned-orders', data => {
        if (ordersData?.state !== 'loading'){
          refresh(n => ++n)
        }
      });
    }
  }, [ordersData, refresh])


  useEffect(() => {
    if (process.env.REACT_APP_UNASSIGNED_PUSHER !== '1') {
      setInterval(() => {
      refresh((n: number) => ++n)
      }, 10000)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (ordersData.state !== 'loading') { setUnassigned(orders); setTotalOrders(total) }
  }, [ordersData, orders, setUnassigned, setTotalOrders, totalOrders, total])

  return (
    viewAssignAttempts ?
      <AssignAttempts
        orderDetails={viewAssignAttempts?.orderDetails}
        rows={viewAssignAttempts?.rows || []}
        orderFees={viewAssignAttempts?.orderFees}
        loanNumber={viewAssignAttempts?.loanNumber}
        apptDateTime={viewAssignAttempts?.apptDateTime}
        onBack={e => setAssignAttempts(null)}
      >
      </AssignAttempts> :
      <Page className={classes.paper} title='Unassigned Orders'>
        <GeneralModal
          open={cancelModelOpen}
          openModal={setCancelModal}
          submitLabel='Yes'
          handleSubmit={handleCancelOrder}
          title='Cancel Order'
        >
          <DialogContentText>
            Are you sure you want to cancel this order?
        </DialogContentText>
        </GeneralModal>
        <Typography variant='h3'>Unassigned Orders</Typography>
        <Box my={2}>
          <Divider variant='fullWidth' />
        </Box>
        <Box my={2} display='flex' justifyContent='flex-end'>
          <form onSubmit={ (e)=> {
            e.preventDefault()
            onSearchChange(e)
          }}>
            <TextField
              onChange={e => setSearch(e.target.value)}
              className={clsx(marginClasses.mt0, marginClasses.mb0)}
              type='search'
              variant='outlined'
              label='Search Orders'
              margin='dense'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={onSearchChange}>
                      <SvgIcon fontSize='small' color='action'>
                        <Search />
                      </SvgIcon>
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </form>
        </Box>

        <UnassignedOrdersData
          setAssignAttempts={setAssignAttempts}
          loading={unassigned.length === 0 && ordersData?.state === 'loading'} // {ordersData?.state === 'loading'}
          rows={unassigned}
          total={totalOrders}
          pageInfo={pageInfo}
          handleCancelOrder={onInitCancelOrder}
          setPageInfo={setPageInfo}
        />
        <Toast message={toast.message} type={toast.type} open={toast.open} setToast={setToast} />
      </Page>
  );
}
