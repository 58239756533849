import React, { useState } from 'react'
import clsx from 'clsx'
import {
  useRecoilCallback,
  useSetRecoilState
} from 'recoil'
import { GeneralModal } from '../../Modal'

import {
  Box,
  TextField,
  Typography,
  Checkbox,
  TablePagination,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
} from '@material-ui/core'

import { PendocVendorInvoiceDataActions } from './PendingVendorInvoiceDataAction'
import { PayBill } from './PayBill'
import { ChangePaymentStatusModel } from './ChangePaymentStatusModel'
import { marginStyles } from '../../../Common/Styles/Margins'
import { useStyles } from '../Styles/style'
import { updatePaymentStatus, updateReferenceNumber } from '../../../Recoil/Selectors/Accounting'
import { payBill } from '../../../Recoil/Selectors/Accounting'
import { refetchPendingVendorInvoice, paySingleBill } from '../../../Recoil/Atoms/Accounting'
import { Toast } from '../../../Components/Toasts'
import { SignerInfo } from '../SignerInfo'
import { PendingVendorInvoicesTableHead } from './PendingVendorInvoicesTableHead'

interface RowData {
  id: string | null
  order_number: string | null
  invoice_number: string | null
  borrower: string | null
  signer: string | null
  signer_fee: number | null
  appointment_date: string | null
  inv_submitted_on: string | null
  status: string | null
  payment_status: string | null
  credential_complete: string | null
  actions: string | null
}
const PendingVendorInvoicesData = ({
  pendingInvoice,
  total,
  loading,
  pageInfo,
  setPageInfo,
  handleLockInvoice,
  selectId,
  selectedOrderIds
}) => {
  const marginClasses = marginStyles()
  const classes = useStyles()

  const [toast, setToast] = useState({ message: '', type: 'success', open: false })
  const [check, setCheck] = useState(false)
  const [close, setClose] = useState(false)

  const [signerInfoModal, handleSignerInfoModal] = useState(null)
  const [order, setOrder] = useState("desc")
  const [orderBy, setOrderBy] = useState("Id")

  const refresh = useSetRecoilState(refetchPendingVendorInvoice)
  const [changeRowReferenceNumber, handleChangeRowReferenceNumber] = useState<RowData | any>()
  const [changeRowPaymentStatus, handleChangeRowPaymentStatus] = useState<RowData | any>()

  const [payBillModal, handlePayBillModal] = useState<RowData | any>()
  const forcePayBill = useSetRecoilState(paySingleBill)

  const handlePage = (e: unknown, newPage: number) => {
    setPageInfo({
      ...pageInfo,
      page: newPage + 1
    })
  }

  const handleRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPageInfo({
      ...pageInfo,
      limit: parseInt(e.target.value, 10)
    })
  }

  const handleSort = (e: any, property: any) => {
    if (property !== "Id") {
      const isAsc = orderBy === property && order === "asc"
      setOrder(isAsc ? "desc" : "asc")
      setOrderBy(property)
      setPageInfo({
        ...pageInfo,
        orderBy: property,
        orderType: isAsc ? "desc" : "asc"
      })
    }
  }

  const rows = pendingInvoice

  const saveReferenceNumber = useRecoilCallback(({ snapshot }) => async () => {
    setCheck(true)
    const res: any = await snapshot.getPromise(updateReferenceNumber({
      id: changeRowReferenceNumber.Id,
      billRefNumber: changeRowReferenceNumber.billRefNumber
    }))
    if (!res?.success) {
      setToast({
        message: res?.result?.error?.message || 'Reference Number not updated',
        type: 'warning',
        open: true
      })
    }
    setCheck(false)
    refresh((n) => ++n)
    setClose(true)
  })

  const submitPayBill = useRecoilCallback(({ snapshot }) => async (params: any) => {
    setCheck(true)
    forcePayBill(n => n + 1)
    const res: any = await snapshot.getPromise(payBill(params))
    if (!res?.success) {
      setToast({
        message: 'Payment Failed',
        type: 'warning',
        open: true
      })
    }
    else {
      setToast({
        message: 'Payment Successfull.',
        type: 'success',
        open: true
      })
      handlePayBillModal({})
    }
    setCheck(false)
    refresh((n) => ++n)
  })

  const savePaymentStatus = useRecoilCallback(({ snapshot }) => async () => {
    setCheck(true)
    const res: any = await snapshot.getPromise(updatePaymentStatus({
      id: changeRowPaymentStatus.Id,
      payment_status: changeRowPaymentStatus.payment_status,
      payment_check_no: changeRowPaymentStatus.payment_check_no,
      payment_check_date: changeRowPaymentStatus.payment_check_date,
      payment_note: changeRowPaymentStatus.payment_note
    }))
    if (!res?.result) {
      setToast({
        message: res?.result?.error?.message || 'Payment Status not updated',
        type: 'warning',
        open: true
      })
    }
    setCheck(false)
    refresh((n) => ++n)
  })

  return (
    <div>
      {close === false &&
        <GeneralModal
          open={changeRowReferenceNumber?.id ? true : false}
          title='PacDocSign : Change Reference Number'
          loading={check}
          openModal={() => handleChangeRowReferenceNumber({})}
          noSubmit={false}
          noFooter={false}
          maxWidth='sm'
          submitLabel='Save'
          handleSubmit={saveReferenceNumber}
        >
          <Box display='flex' p={2}>
            <Typography
              variant='body1'
              color='textSecondary'
              className={clsx(
                marginClasses.mt2,
                marginClasses.mb0,
                marginClasses.mr1
              )}
            >
              <b> Ref#</b>
            </Typography>
            <TextField
              id='standard-basic'
              variant='outlined'
              fullWidth
              size='small'
              value={changeRowReferenceNumber?.billRefNumber}
              onChange={e =>
                handleChangeRowReferenceNumber({
                  ...changeRowReferenceNumber,
                  billRefNumber: e.currentTarget.value
                })
              }
            />
          </Box>
        </GeneralModal>
      }
      <SignerInfo
        signerInfoModal={signerInfoModal}
        handleSignerInfoModal={handleSignerInfoModal}
      />
      <PayBill
        payBillModal={payBillModal}
        handlePayBillModal={handlePayBillModal}
        submitPayBill={submitPayBill}
      />
      <ChangePaymentStatusModel
        changeRowPaymentStatus={changeRowPaymentStatus}
        handleChangeRowPaymentStatus={handleChangeRowPaymentStatus}
        check={check}
        savePaymentStatus={savePaymentStatus}
      />
      {loading ?
        <Box width='100%' padding='10px 0' display='flex' justifyContent='center'>
          <CircularProgress />
        </Box> :
        <>
          <TableContainer hidden={loading}>
            <Table
              className={classes.table}
              aria-labelledby='tableTitle'
              size='medium'
              aria-label='enhanced table'
            >
              <PendingVendorInvoicesTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleSort}
              />
              <TableBody>
                {rows?.map((row) => {
                  let color = ''
                  if (row?.f_status_web === 'Cancelled' || row?.f_status_web === 'Borrower Would Not Sign') {
                    color = 'white'
                  } else if (row?.qb_status === '1') {
                    // has bill
                    color = '#d7d0da'
                  } else if (row?.qb_status === '2') {
                    // has check
                    color = '#5bb75b'
                  }
                  return (
                    <TableRow style={{ backgroundColor: color }} hover tabIndex={-1} key={row.Id}>
                      <TableCell className={classes.cellPadding}>{row?.qb_status === '1'
                        ?
                        <Checkbox
                          checked={selectedOrderIds.includes(row?.Id)}
                          onChange={() => selectId(row?.Id)}
                        />
                        :
                        <></>
                      }
                      </TableCell>
                      <TableCell className={classes.cellPadding}>{row.f_loan_number}</TableCell>
                      <TableCell className={classes.cellPadding}>{row.billRefNumber}</TableCell>
                      <TableCell className={classes.cellPadding}>{row.f_borrower_first_name} {' '} {row.f_borrower_last_name}</TableCell>
                      <TableCell className={classes.cellPadding}>
                        <Typography color='primary'
                          variant='body2'
                          className={classes.cursorPointer}
                          onClick={e => handleSignerInfoModal(
                            { ...row?.signer, F_Loan_Number: row?.f_loan_number })
                          } >
                          {row.f_signer_name}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.cellPadding}>{row.f_signer_fees}</TableCell>
                      <TableCell className={classes.cellPadding}>{row.f_appointment_date}</TableCell>
                      <TableCell className={classes.cellPadding}>
                        {row.invoice_submitted_datetime ?
                        <span>{
                          (new Date(row.invoice_submitted_datetime).getMonth() + 1) + '/' +
                          new Date(row.invoice_submitted_datetime).getDate() + '/' +
                          new Date(row.invoice_submitted_datetime).getFullYear()
                        }</span>
                        :
                        <></>
                      }
                      </TableCell>
                      <TableCell className={classes.cellPadding}>{row.f_status_web}</TableCell>
                      <TableCell className={classes.cellPadding}>{row.payment_status}</TableCell>
                      <TableCell className={classes.cellPadding}>
                        <PendocVendorInvoiceDataActions
                          key={row.Id}
                          row={row}
                          handleLockInvoice={handleLockInvoice}
                          handleChangeRowReferenceNumber={handleChangeRowReferenceNumber}
                          handleChangeRowPaymentStatus={handleChangeRowPaymentStatus}
                          handlePayBillModal={handlePayBillModal}
                        />
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            hidden={loading}
            rowsPerPageOptions={[10, 50, 80, 100, 250]}
            component='div'
            count={total}
            rowsPerPage={pageInfo.limit}
            page={pageInfo ? pageInfo.page - 1 : 0}
            onPageChange={handlePage}
            onChangeRowsPerPage={handleRowsPerPage}
          />
        </>
      }
      <SignerInfo
        signerInfoModal={signerInfoModal}
        handleSignerInfoModal={handleSignerInfoModal} />
      <Toast message={toast.message} type={toast.type} open={toast.open} setToast={setToast} />
    </div>
  )
}

export { PendingVendorInvoicesData }