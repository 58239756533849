import React from 'react';
import { Grid, Typography, FormControlLabel, Radio } from '@material-ui/core';

const DefaultDocs = ({ values, handleChange }) => {
  return (
    <>
      <Grid item md={2}>
        <Typography variant='body2'>Default Docs Upload</Typography>
      </Grid>
      <Grid item md={2}>
        <FormControlLabel
          label='Yes'
          control={
            <Radio
              name='default_docs_received'
              value='Y'
              checked={values.default_docs_received === 'Y'}
              onChange={handleChange}
            />
          }
        />
      </Grid>
      <Grid item md={2}>
        <FormControlLabel
          label='No'
          control={
            <Radio
              name='default_docs_received'
              value='N'
              checked={values.default_docs_received === 'N'}
              onChange={handleChange}
            />
          }
        />
      </Grid>
    </>
  );
}

export { DefaultDocs };
