import React, { useRef, useState } from 'react';
import { Box, IconButton, Popover, SvgIcon, Tooltip, Typography, Badge } from '@material-ui/core';
import { Mail } from 'react-feather';

import { useStyles } from './Styles/Icon';

const Inbox = () => {
  const classes = useStyles();
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title='Inbox'>
        <Badge
        overlap="rectangular"
          color='secondary'
          variant='dot'
          invisible={true}
          classes={{ badge: classes.badge }}
        >
          <IconButton
            color='inherit'
            ref={ref}
            onClick={handleOpen}
          >
            <SvgIcon>
              <Mail />
            </SvgIcon>
          </IconButton>
        </Badge>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Box p={2}>
          <Typography
            variant='h5'
            color='textPrimary'
          >
            Inbox
          </Typography>
        </Box>
        <Box p={2}>
          <Typography
            variant='h6'
            color='textPrimary'
          >
            There are no messages.
          </Typography>
        </Box>
      </Popover>
    </>
  );
};

export { Inbox };
