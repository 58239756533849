import React, { useState } from 'react';
import {
  FormControl, IconButton, SvgIcon, MenuItem, InputLabel, Select, ButtonGroup
} from '@material-ui/core';
import { Search, XCircle } from 'react-feather';

import clsx from 'clsx';
import map from 'lodash/map';

import { SearchInput } from './SearchInput';

import { useStyles } from '../Styles/OrdersList';
import { marginStyles } from '../../../Common/Styles/Margins';
import { paddingStyles } from '../../../Common/Styles/Paddings';

const OrdersSearch = ({ handleSearch }) => {
  const classes = useStyles();
  const marginClasses = marginStyles();
  const { p2 } = paddingStyles();

  const [searchType, setSearchType] = useState('borrowerName')
  const [searchValue, setSearchValue] = useState('')
  const [showClear, setShowClear] = useState(false)

  const searchTypes = {
    borrowerName: 'Borrower Name',
    loanNumber: 'Loan/Order #',
    orderId: 'Order Id#',
    city: 'City',
    state: 'State',
    orderDate: 'Order Date',
    docsReceived: 'Docs Received',
    status: 'Status',
    apptDate: 'Appointment Date'
  }

  const searchOptions = map(searchTypes, (label, type) => <MenuItem key={type} value={type}>{label}</MenuItem>)

  const handleSearchType = (e) => {
    setSearchType(e.target.value)
    setSearchValue('')
  }

  const handleSearchValue = (e) => setSearchValue(e.target.value);

  const handleSearchClick = (e) => {
    e.preventDefault()
    setShowClear(true)
    handleSearch(searchType, searchValue)
  }

  const handleSearchClear = (e) => {
    setShowClear(false)
    setSearchValue('')
    handleSearch()
  }

  return (
    <>
      <FormControl className={clsx(marginClasses.my0, marginClasses.mr1, classes.select)} margin='dense' variant='outlined'>
        <InputLabel>Search</InputLabel>
        <Select label='Search by' value={searchType} onChange={handleSearchType}>
          {searchOptions}
        </Select>
      </FormControl>
      <SearchInput
        type={searchType}
        value={searchValue}
        onChange={handleSearchValue}
        handleSearchClick={handleSearchClick}
      />
      <FormControl className={marginClasses.my0} margin='dense' variant='outlined'>
        <ButtonGroup>
          <IconButton className={p2} onClick={handleSearchClick}>
            <SvgIcon fontSize='small' color='primary'>
              <Search />
            </SvgIcon>
          </IconButton>
          <IconButton disabled={!showClear} className={p2} onClick={handleSearchClear}>
            <SvgIcon fontSize='small' color={showClear ? 'error' : 'disabled'}>
              <XCircle />
            </SvgIcon>
          </IconButton>
        </ButtonGroup>
      </FormControl>
    </>
  );
};

export { OrdersSearch };

