import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  Box,
  Tooltip,
  IconButton,
  SvgIcon,
  Typography
} from '@material-ui/core';
import { Edit } from 'react-feather';

import { accessgrouppagination, accessGroupResources } from '../../Recoil/Atoms/AccessGroups';

import { Table } from '../Table';

const AccessGroupData = props => {
  const [pageInfo, setPageInfo] = useRecoilState(accessgrouppagination);
  const resources = useRecoilValue(accessGroupResources);
  // const { orderType, orderBy } = pageInfo;
  const actionsFormatter = params => {
    return (
      <Box display='flex'>
        <Tooltip title='Edit'>
          <IconButton
            color='inherit'
            onClick={() => props.handleEdit(params.row)}
          >
            <SvgIcon fontSize='small' color='primary'>
              <Edit />
            </SvgIcon>
          </IconButton>
        </Tooltip>
      </Box>
    );
  };

  const columns = [
    {
      field: 'accessGroupName',
      headerName: 'Name',
      headerClassName: 'datagrid-header',
      cellClassName: 'datagrid-cell',
      disableColumnMenu: true
    },
    {
      field: 'accessGroupResources',
      headerName: 'Resources',
      cellClassName: '',
      renderCell: ({ row }) => (
        <div style={{ display: 'block' }}>
          {row.accessGroupResources.map(resource => {
            return (
              <Typography
                variant='body1'
                variantMapping={{ body1: 'p' }}
                key={resource}
              >
                {resources.find(r => resource === r.id).name}
              </Typography>
            );
          })}
        </div>
      )
    },
    {
      field: 'modifiedOn',
      headerName: 'Modified Date/Time'
    },
    {
      field: 'status',
      headerName: 'Status'
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: actionsFormatter
    }
  ];

  const handlePage = (e: unknown, newPage: number) => {
    setPageInfo({
      ...pageInfo,
      page: newPage
    });
  };

  const handleRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPageInfo({
      ...pageInfo,
      limit: parseInt(e.target.value, 10)
    });
  };
  // const onSortingChange = e => {
  //   if (e.sortModel &&e.sortModel[0].field) {
  //     const { field, sort } = e.sortModel[0];
  //     setPageInfo({
  //       ...pageInfo,
  //       orderBy: field,
  //       orderType: sort
  //     });
  //   }
  // };
  return (
    <Table
      loading={props.loading}
      columns={columns}
      rows={props.data}
      total={props.total}
      pageInfo={{ page: pageInfo.page, limit: pageInfo.limit }}
      handlePage={handlePage}
      handleRowsPerPage={handleRowsPerPage}
      // sortModel={[
      //   {
      //     sort: orderType,
      //     field: orderBy
      //   }
      // ]}
      // onSortingChange={onSortingChange}
    />
  );
};

export { AccessGroupData };
