import React from 'react';
import { useRecoilValueLoadable, useRecoilState } from 'recoil'
import { Typography, Box, Divider } from '@material-ui/core';

import { OrderRangeData } from 'src/Components/Accounting/OrderRangeData';
import { OrderRangeActions } from 'src/Components/Accounting/OrderRangeActions';
import { getOrdersRangeDetails } from './../../Recoil/Selectors/Accounting'
import { orderRangeDetailPagination } from './../../Recoil/Atoms/Orders'
import { Page } from '../../Components/Page';

import { useStyles } from '.././Styles/Page';

export const OrderRangeDetails = () => {
  const classes = useStyles();

  const ordersRequest: any = useRecoilValueLoadable(getOrdersRangeDetails)
  const orders = ordersRequest?.contents?.result?.rows || [];
  const total = ordersRequest?.contents?.result?.count || 0;

  const [pageInfo, setPageInfo] = useRecoilState(orderRangeDetailPagination);

  return (
    <Page className={classes.paper} title='Order Range Details'>
      <Typography variant='h3'>Order Range Details</Typography>
      <Box my={2}>
        <Divider variant='fullWidth' />
      </Box>
      <OrderRangeActions />
      <OrderRangeData 
        rows={orders} 
        total={total} 
        loading={ordersRequest?.state === 'loading'} 
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
      />
    </Page>
  );
}
