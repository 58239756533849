import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import InputMask from 'react-input-mask';

const ContactPhone = ({ values, errors, touched, handleChange, handleBlur }) => {
  return (
    <>

      <Grid item xs={12} md={6}>
        <InputMask
          mask='(999) 999-9999'
          maskChar=''
          value={values.Contact_Work_Phone}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          {() =>
            <TextField
              label='Work Phone'
              name='Contact_Work_Phone'
              variant='outlined'
              size='small'
              fullWidth
              helperText={touched.Contact_Work_Phone && errors.Contact_Work_Phone}
              error={Boolean(touched.Contact_Work_Phone && errors.Contact_Work_Phone)}
            />
          }
        </InputMask>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label='Ext'
          name='Contact_Work_Ext'
          variant='outlined'
          size='small'
          fullWidth
          value={values.Contact_Work_Ext}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputMask
          mask='(999) 999-9999'
          maskChar=''
          value={values.Contact_Cell}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          {() =>
            <TextField
              label='Mobile Number'
              name='Contact_Cell'
              variant='outlined'
              size='small'
              fullWidth
              helperText={touched.Contact_Cell && errors.Contact_Cell}
              error={Boolean(touched.Contact_Cell && errors.Contact_Cell)}
            />
          }
        </InputMask>
      </Grid>
    </>
  );
}

export { ContactPhone };
