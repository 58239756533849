import React from 'react';
import { Grid, TextField } from '@material-ui/core';

const ClientName = ({ values, errors, touched, handleChange, handleBlur }) => {
  return (
    <Grid item xs={12}>
      <TextField
        label='Name'
        name='clientName'
        variant='outlined'
        size='small'
        fullWidth
        value={values.clientName}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.clientName && errors.clientName}
        error={Boolean(touched.clientName && errors.clientName)}
      />
    </Grid>
  );
}

export { ClientName };
