import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Tooltip
} from '@material-ui/core';
import { Search, PlusCircle, X } from 'react-feather';

import { useStyles } from './Styles/Fields';

const SearchForm = props => {
  const classes = useStyles();
  const { defaultValues } = props
  const [secondaryCategory, setSecondaryCategory] = useState(defaultValues?.secondaryCategory);
  const [firstName, setFirstName] = useState(defaultValues?.firstName);
  const [lastName, setLastName] = useState(defaultValues?.lastName);
  const [search, setSearch] = useState(defaultValues?.search);

  const isSearchUpdated = defaultValues?.firstName !== firstName ||
    defaultValues?.lastName !== lastName ||
    defaultValues?.search !== search ||
    (defaultValues?.secondaryCategory !== secondaryCategory && search)

  const secondaryCategories = [
    'Signer_Id',
    'Signer_Email',
    'Zip',
    'City',
    'State'
  ];

  const onSubmit = e => {
    e.preventDefault()
    if (isSearchUpdated) {
      props.onSearch({
        firstName,
        lastName,
        search,
        secondaryCategory
      });
    }
  };
  const onClearSearch = () => {
    setFirstName('')
    setLastName('')
    setSearch('')
    setSecondaryCategory('')
    if (defaultValues?.firstName || defaultValues?.lastName || defaultValues?.search) {
      props.onSearch({
        firstName: '',
        lastName: '',
        search: '',
        secondaryCategory: ''
      });
    }
  }

  const isSearchEmpty = !firstName && !lastName && !search

  return (
    <>
      <form onSubmit={onSubmit}>
        <Grid
          container
          lg={12}
          sm={12}
          classes={{ root: classes.searchContainer }}
        >
          <Grid container lg={4} sm={12}>
            <TextField
              size='small'
              variant='outlined'
              label='First Name'
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
              classes={{ root: classes.searchFields }}
            />
            <TextField
              size='small'
              variant='outlined'
              label='Last Name'
              value={lastName}
              onChange={e => setLastName(e.target.value)}
              classes={{ root: classes.searchFields }}
            />
          </Grid>
          <Grid container lg={4} sm={12}>
            <FormControl
              variant='outlined'
              size='small'
              classes={{ root: classes.searchFields }}
            >
              <InputLabel>Select</InputLabel>
              <Select
                fullWidth
                value={secondaryCategory}
                label='Select'
                onChange={e => setSecondaryCategory(String(e.target.value))}
              >
                {secondaryCategories.map(category => {
                  return <MenuItem value={category}>{category}</MenuItem>;
                })}
              </Select>
            </FormControl>
            <TextField
              size='small'
              variant='outlined'
              label='Search'
              value={search}
              onChange={e => setSearch(e.target.value)}
              classes={{ root: classes.searchFields }}
            />
            {!isSearchEmpty && (
              <Tooltip title='Clear Search'>
                <IconButton onClick={onClearSearch}>
                  <X color='red'></X>
                </IconButton>
              </Tooltip>
            )}
          </Grid>
          <Grid
            container
            direction='row'
            alignItems='center'
            justifyContent='flex-end'
            lg={4}
            sm={12}
          >
            <Button
              disabled={!isSearchUpdated}
              onClick={onSubmit}
              variant='contained'
              color='primary'
              type='submit'
              classes={{ root: classes.searchButtons }}
              endIcon={<Search size={18} />}
            >
              Search
            </Button>
            <Link to='/signers/new' className={classes.buttonLink}>
              <Button
                variant='contained'
                color='primary'
                classes={{ root: classes.searchButtons }}
                endIcon={<PlusCircle size={18} />}
              >
                Add New
              </Button>
            </Link>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export { SearchForm };
