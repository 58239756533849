import React, { useState, useEffect } from 'react';
import { useRecoilCallback, useRecoilValueLoadable, useRecoilState, useSetRecoilState } from 'recoil';
import { Typography, Box, Divider } from '@material-ui/core';

import { Page } from '../../Components/Page';
import { SlidingPane } from '../../Components/SlidingPane';
import { MonitoringOrdersData } from '../../Components/Orders/MonitoringOrdersData';
import { OrdersDialog } from '../../Components/Orders/OrdersDialog';

import { Notes } from '../../Components/Orders/FeeAndInstructions/Notes';

import { getMonitoringOrders, confirmCallStatus, changeOrderStatus } from '../../Recoil/Selectors/Orders';
import { dialog as dialogState } from '../../Recoil/Atoms/Modals';
import { refetchMonitoringOrders } from '../../Recoil/Atoms/Orders';
import { notesParams } from '../../Recoil/Atoms/Orders';

import { Toast } from '../../Components/Toasts';

import { useStyles } from '../Styles/Page';

export const MonitoringOrders = () => {
  const classes = useStyles();

  const [openPane, handlePaneOpen] = useState(false);
  const [monitoring, setMonitoring] = useState([])
  const refresh = useSetRecoilState(refetchMonitoringOrders);
  const setNotesParams = useSetRecoilState(notesParams);

  const allOrders: any = useRecoilValueLoadable(getMonitoringOrders);
  const ordersData = allOrders?.contents?.result || [];
  const total = allOrders?.contents?.result?.count || 0;

  const [toast, setToast] = useState({ message: '', type: 'success', open: false });
  const [dialog, setDialog] = useRecoilState(dialogState);

  const handleChangeCallStatus = useRecoilCallback(({ snapshot }) => async () => {
    setDialog({ ...dialog, loading: true })

    const { result, success } = await snapshot.getPromise(confirmCallStatus);

    if (!success) {
      setToast({ message: result.message, type: 'warning', open: true });
    } else {
      setToast({ message: 'Order has been updated', type: 'success', open: true });
    }

    refresh((n) => ++n);
    setDialog({ ...dialog, loading: false, open: false })
  });

  const handleChangeStatus = useRecoilCallback(({ snapshot }) => async () => {
    setDialog({ ...dialog, loading: true })

    const { result, success } = await snapshot.getPromise(changeOrderStatus);
    if (!success) {
      setToast({
        message: result?.error?.message || 'Please select status and try again',
        type: 'warning',
        open: true
      });
    }
    refresh((n) => ++n);
    setDialog({ ...dialog, loading: false, open: false })
  });

  useEffect(() => {
    if (allOrders.state !== 'loading' && monitoring.length === 0) { 
      setMonitoring(ordersData) 
    }
  }, [ordersData, allOrders, monitoring])

  const handleNotes = (open, row) => {
    handlePaneOpen(open);
    setNotesParams({
      Id: open ? row.Id : ''
    })
  }


  useEffect(() => {
    setInterval(() => {
      refresh((n: number) => ++n)
    }, 10000)
    // eslint-disable-next-line
  }, [])



  return (
    <Page className={classes.paper} title='Orders'>
      <Typography variant='h3'>Monitoring Orders</Typography>
      <Box my={2}>
        <Divider variant='fullWidth' />
      </Box>
      <MonitoringOrdersData
        rows={monitoring}
        total={total}
        loading={monitoring.length === 0 && allOrders?.state === 'loading'}
        handleCancelOrder={null}
        handleLockOrder={null}
        handleChangeCallStatus={handleChangeCallStatus}
        handleRequestDoc={null}
        handleNotes={handleNotes}
        handleChangeStatus={handleChangeStatus}
      />
      <OrdersDialog />

      <SlidingPane
        title='Borrower Notes'
        open={openPane}
        handlePane={handleNotes}
      >
        <Notes isViewOnlyMode={false} />
      </SlidingPane>

      <Toast message={toast.message} type={toast.type} open={toast.open} setToast={setToast} />
    </Page>
  );
}
