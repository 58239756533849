import React, { useState } from 'react';
import type { FC } from 'react';
import { Box, Button, CircularProgress, FormHelperText, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Formik } from 'formik';

import { initialValues, validationSchema } from '../../Common/Rules/Login';

interface HandleSubmit {
  handleSubmit: (params) => Promise<void>
};

const LoginForm: FC<HandleSubmit> = (props) => {
  const [alert, setAlert] = useState(null);

  const onSubmit = async (values, { resetForm, setSubmitting }) => {
    await props.handleSubmit({
      email: values.email,
      setSubmitting,
      resetForm,
      setAlert,
      date: new Date()
    });
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        errors
      }) => (
        <form onSubmit={handleSubmit}>
          { alert &&
            <Box mb={3}>
              <Alert severity={alert.status}>{alert.message}</Alert>
            </Box>
          }
          <TextField
            label='Email'
            name='email'
            type='text'
            variant='outlined'
            fullWidth
            onChange={ (e) => { e.target.value = e.target.value.trim(); handleChange(e) } }
            onBlur={handleBlur}
            value={values.email}
            helperText={touched.email as Boolean && errors.email as String }
            error={Boolean(touched.email && errors.email)}
          />

          {errors.submit && (
            <FormHelperText error>
              {errors.submit as String}
            </FormHelperText>
          )}
          <Box mt={3}>
            <Button
              type='submit'
              size='large'
              color='primary'
              variant='contained'
              fullWidth
              disabled={isSubmitting}
            >
              {isSubmitting && (
                <CircularProgress color='inherit' size={25}></CircularProgress>
              )}
              Submit
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export { LoginForm };
