import React from 'react';
import { Grid, TextField } from '@material-ui/core';

const SetsOfDocumentLoan = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  disabled
}) => {
  return (
    <Grid item xs={12} md={12}>
      <TextField
        label='Number of Notarized Documents?'
        name='set_loan_document'
        variant='outlined'
        size='small'
        type='number'
        fullWidth
        value={values.set_loan_document}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.set_loan_document && errors.set_loan_document}
        error={Boolean(touched.set_loan_document && errors.set_loan_document)}
        disabled={disabled}
      />
    </Grid>
  );
};

export { SetsOfDocumentLoan };
