import React, { useState, useEffect, /* useCallback */ } from 'react';
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState, useRecoilCallback } from 'recoil';
import { useParams } from 'react-router';
import { Alert } from '@material-ui/lab';
import { Typography, Box, Divider, TextField, InputLabel, FormControl, Select, MenuItem, Button, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import { Repeat } from 'react-feather'

import { Page } from '../../Components/Page';
import { ViewOrderPopup } from './../../Components/Orders/ViewOrderPopup'
import { GeneralModal } from './../../Components/Modal'
import { SignersData } from '../../Components/Orders/assignSigner';

import { getNearBySigners, clearNearbySignerCache } from '../../Recoil/Selectors/Orders';
import { getAssignSignerOrder } from './../../Recoil/Selectors/Signer'
import { assignSignersPagination, currentTimer, assignSignerOrderId, forceClearNerbySignerCache } from '../../Recoil/Atoms/Orders';

import { useStyles } from '../Styles/Page';
import { marginStyles } from '../../Common/Styles/Margins';
import { useStyles as useOrderActionStyles } from '../../Components/Orders/Styles/OrdersList';

export const AssignSigner = () => {
  const classes = useStyles();
  const marginClasses = marginStyles();
  const OAClasses = useOrderActionStyles()
  
  const [search, setSearch] = useState('')
  const [pageInfo, setPageInfo] = useRecoilState(assignSignersPagination);
  const setOrderId = useSetRecoilState(assignSignerOrderId);

  const [distance, setDistance] = useState(pageInfo.distance)
  const [signerType, setType] = useState(pageInfo.signerType)
  const [searchWith, setSearchWith] = useState(pageInfo.searchWith)
  
  const [order, setOrder] = useState(null)
  const [clearCacheLoading, setClearCacheLoading] = useState(false)
  const forceClearCache = useSetRecoilState(forceClearNerbySignerCache)

  const resetPagination = () => {
    setPageInfo({
      page: 1,
      limit: 20,
      distance: '50',
      orderId: '',
      signerType: 'signer_w_history',
      searchWith: '',
      orderBy: 'distance',
      orderType: 'ASC',
      search: '',
      cached: true
    });
  }

  const setTimer = useSetRecoilState(currentTimer)

  const setStateValue = (fn, val) => { fn(val) }

  const location: any = useParams()
  useEffect(() => {
    if (!pageInfo.orderId || location.orderId !== pageInfo.orderId) {
      setPageInfo({
        ...pageInfo, orderId: location.orderId, distance, signerType, searchWith, search
      })
      setOrderId({ orderId: location.orderId })
    }
    setTimer(location.orderId)
  }, [pageInfo, location, setPageInfo, signerType, distance, searchWith, search, setTimer, setOrderId])

  const onSearch = () => {
    setPageInfo({
      ...pageInfo, orderId: location.orderId, distance, signerType, searchWith, search
    })
  }

  const ordersData: any = useRecoilValueLoadable(getAssignSignerOrder)
  const signersData: any = useRecoilValueLoadable(getNearBySigners)

  const signers = signersData?.contents?.result?.rows || [];
  const total = signersData?.contents?.result?.count || 0;
  const orderDetails = ordersData?.contents?.result || {};


  const distanceOptions = ['50', '100', '150', '200']
  const typeOptions = [
    { id: '0', label: 'All signers' },
    { id: 'fidelity_approved', label: 'Signers Fidelity Approved' },
    { id: 'signer_w_history', label: 'With History' }]
  const searchOptions = [{ id: 'in', label: 'Search Within' }, { id: 'db', label: 'Search Database' }]

  useEffect(() => {
    resetPagination()
    // eslint-disable-next-line
  }, []);

  const flushSignersCache = useRecoilCallback(({ snapshot }) => async (orderid) => {
    
    forceClearCache(n => n + 1)
    setClearCacheLoading(true)
    
    const res = await snapshot.getPromise(clearNearbySignerCache(orderid))

    if (res.success) {
      setPageInfo({ ...pageInfo, page: 1, search: '' })
    }
    
    setClearCacheLoading(false)
  });


  return (
    <Page className={classes.paper} title='Assign Signer'>
      <Typography variant='h3'>Assign Signer</Typography>
      <Box my={2}>
        <Divider variant='fullWidth' />
      </Box>
      <Box my={2} display='flex'>
        <Typography className={marginClasses.mr1} variant='h5'>Loan/Order# </Typography>
        <Typography
          onClick={() => setOrder(orderDetails?.Id)}
          className={`${marginClasses.mr1} ${classes.pointer}`}
        >
          {orderDetails?.w_Loan_Number || ''} |
        </Typography>
        <Typography className={marginClasses.mr1} variant='h5'>Appointment Date/Time</Typography>
        <Typography className={marginClasses.mr1}>
          {`${orderDetails?.w_Appointment_Date || ''} ${orderDetails?.w_Appointment_Time || ''}`}
        </Typography>
      </Box>
      {(orderDetails?.w_Additional_Information && orderDetails?.w_Additional_Information !== '') &&
        <Box mb={3}>
          <Alert icon={false} severity={'error'}>{orderDetails?.w_Additional_Information}</Alert>
        </Box>
      }
      <Box my={2} display='flex' justifyContent='flex'>
        <FormControl
          className={clsx(marginClasses.mt0, marginClasses.mb0, marginClasses.mr1, OAClasses.select)}
          margin='dense'
          variant='outlined'
        >
          <InputLabel>Distance</InputLabel>
          <Select
            label='Clients'
            value={pageInfo?.distance} // {distance}
            onChange={e => {
              setPageInfo({ ...pageInfo, distance: String(e.target.value) });
              setStateValue(setDistance, e.target.value);
            }}
          >
            {distanceOptions.map(d =>
              <MenuItem key={d} value={d}>{d} Miles</MenuItem>
            )}
          </Select>
        </FormControl>
        <FormControl
          className={clsx(marginClasses.mt0, marginClasses.mb0, marginClasses.mr1, OAClasses.select)}
          margin='dense'
          variant='outlined'
        >
          <InputLabel>Type</InputLabel>
          <Select
            value={pageInfo?.signerType}
            label='Type'
            onChange={e => {
              setPageInfo({ ...pageInfo, signerType: String(e.target.value) });
              setStateValue(setType, e.target.value);
            }}
          >
            {typeOptions.map(t =>
              <MenuItem key={t.label} value={t.id}>{t.label}</MenuItem>
            )}
          </Select>
        </FormControl>
        <FormControl
          className={clsx(marginClasses.mt0, marginClasses.mb0, marginClasses.mr1, OAClasses.select)}
          margin='dense'
          variant='outlined'
        >  <InputLabel>Search with</InputLabel>
          <Select
            label='searchWith'
            value={pageInfo?.searchWith} // {searchWith}
            onChange={e => {
              setPageInfo({ ...pageInfo, searchWith: String(e.target.value) });
              setStateValue(setSearchWith, e.target.value);
            }}
          >
            {searchOptions.map(t =>
              <MenuItem key={t.id} value={t.id}>{t.label}</MenuItem>
            )}
          </Select>
        </FormControl>
        <TextField
          value={search}
          onChange={e => setSearch(e.target.value)}
          className={clsx(marginClasses.mt0, marginClasses.mb0)}
          type='search'
          variant='outlined'
          label='Search'
          margin='dense'
        />
        <Button onClick={onSearch} className={marginClasses.ml1} variant='contained' color='primary'>
          Search
        </Button>
        <Tooltip title='Refetch Signers List'>
          <Button onClick={() => flushSignersCache(orderDetails?.Id)} className={marginClasses.ml1} variant='contained' color='primary' disabled={clearCacheLoading}>
            <Repeat />
          </Button>
        </Tooltip>
      </Box>
      <SignersData
        orderDetails={orderDetails}
        rows={signers}
        total={total}
        pageInfo={pageInfo}
        loading={signersData?.state === 'loading'} setPageInfo={setPageInfo}
      />
      <GeneralModal
        title='Order Details'
        maxWidth='xl'
        open={order}
        openModal={e => setOrder(false)}
        noSubmit
      >
        <ViewOrderPopup id={order} />
      </GeneralModal>
    </Page>
  );
}
