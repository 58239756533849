import React from 'react';
import { Box, Button, SvgIcon } from '@material-ui/core';
import { Plus } from 'react-feather';

import { marginStyles } from '../../Common/Styles/Margins';
import { displayStyles } from '../../Common/Styles/Display';

const DivisionActions = ({ handleModal }) => {
  const marginClasses = marginStyles();
  const displayClasses = displayStyles();

  return (
    <Box my={2} display='flex' justifyContent='flex-end'>
      <Button
        className={marginClasses.ml2}
        color='primary'
        variant='contained'
        onClick={() => handleModal(true)}
      >
        <Box className={displayClasses.whiteColor} display='flex' alignItems='center'>
          <SvgIcon className={marginClasses.mr1} fontSize='small'>
            <Plus />
          </SvgIcon>
          New Division
        </Box>
      </Button>
    </Box>
  );
};

export { DivisionActions };
