import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    mrTp: {
      marginTop: '20px'
    },
  })
);

export { useStyles };
