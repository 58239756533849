import React from 'react';
import { Grid, Typography, FormControlLabel, Radio, FormHelperText } from '@material-ui/core';

import { paddingStyles } from '../../../Common/Styles/Paddings';
import { displayStyles } from '../../../Common/Styles/Display';

const PartialSigning = ({ isViewOnlyMode, location, values, errors, handleChange, setFieldValue, loanNumber }) => {
  const paddingClasses = paddingStyles();
  const displayClasses = displayStyles();

  return (
    <>
      <Grid item md={2} className={displayClasses.flexRowC}>
        <Typography variant='body2' className={paddingClasses.pl1}>Partial Signing</Typography>
      </Grid>
      <Grid item md={2}>
        <FormControlLabel
          label='Yes'
          control={
            <Radio
              disabled={isViewOnlyMode}
              name={`${location}.Partial_Signing`}
              value={'1'}
              checked={values.Partial_Signing === '1'}
              onChange={handleChange}
            />
          }
        />
      </Grid>
      <Grid item md={2}>
        <FormControlLabel
          label='No'
          control={
            <Radio
              disabled={isViewOnlyMode}
              name={`${location}.Partial_Signing`}
              value={'0'}
              checked={values.Partial_Signing === '0'}
              onChange={ e => { setFieldValue('w_Loan_Number', [loanNumber[0]]); handleChange(e) } }
            />
          }
        />
      </Grid>
      { errors[location] && errors[location].Partial_Signing &&
        <Grid item xs={12} container justifyContent='flex-end'>
          <Grid item xs={6} >
            <FormHelperText error>
              {errors[location].Partial_Signing}
            </FormHelperText>
          </Grid>
        </Grid>
      }
    </>
  );
}

export { PartialSigning };
