import { atom } from 'recoil';

const refetchPendingVendorInvoice = atom({
  key: 'refetchPendingVendorInvoice',
  default: 0
});

const pendingVendorInvoicesPagination = atom({
  key: 'pendingVendorInvoicesPagination',
  default: {
    page: 1,
    limit: 50,
    orderBy: '',
    orderType: '',
    filterValue: '',
    filterBy: 'loanNumber',
    range: 'f_appointment_date',
    startDate: '',
    endDate: ''
  }
});

const currentReferenceNumber = atom({
  key: 'currentReferenceNumber',
  default: {
    id: null,
    billRefNumber: '',
  }
});

const currentPaymentStatus = atom({
  key: 'currentPaymentStatus',
  default: {
    Id: null,
    payment_status: '',
  }
});

const UnbilledOrdersPagination = atom({
  key: 'UnbilledOrdersPagination',
  default: {
    page: 1,
    limit: 50,
    orderBy: 'w_sql_appointment_date',
    orderType: 'desc',
    filterValue: '',
    filterBy: 'loanNumber',
    range: 'f_appointment_date',
    startDate: '',
    endDate: ''
  }
});

const refetchUnbilledOrders = atom({
  key: 'refetchUnbilledOrders',
  default: 0
});

const unbilledInvoicesPagination = atom({
  key: 'unbilledInvoicesPagination',
  default: {
    page: 1,
    limit: 50,
    orderBy: 'w_sql_appointment_date',
    orderType: 'DESC',
    clientName: '',
    billing_cycle: 'Daily',
    filterBy: 'w_Loan_Number',
    filterValue: '',
    startDate: '',
    endDate: ''
  }
});

const refetchUnbilledInvoices = atom({
  key: 'refetchUnbilledInvoices',
  default: 0
});

const DailyVendorBillPagination = atom({
  key: 'DailyVendorBillPagination',
  default: {
    page: 1,
    limit: 50,
    filter: '',
    orderType: '',
    value: '',
    orderId: '',
    date: ''
  }
});

const refetchDailyVendorBill = atom({
  key: 'refetchDailyVendorBill',
  default: 0
});

const DailyPayBillPagination = atom({
  key: 'DailyPayBillPagination',
  default: {
    page: 1,
    limit: 20,
    orderBy: 'w_sql_appointment_date',
    orderType: 'desc',
    value: '',
    filter: '',
    date: ''
  }
});

const forceCreateBill = atom({
  key: 'forceCreateBill',
  default: 0
});

const changePaymentStatusPopupRefetch = atom({
  key: 'changePaymentStatusPopupRefetch',
  default: {
    Id: undefined,
    refetch: 0
  }
});

const paySingleBillPopup = atom({
  key: 'paySingleBillPopup',
  default: {
    Id: undefined,
    refetch: 0
  }
});

const multipleBillsPopup = atom({
  key: 'multipleBillsPopup',
  default: {
    orderIds: undefined,
    refetch: 0
  }
});

const paySingleBill = atom({
  key: 'paySingleBill',
  default: 0
});

const paySelectedMultipleBills = atom({
  key: 'paySelectedMultipleBills',
  default: 0
});

export {
  refetchPendingVendorInvoice,
  pendingVendorInvoicesPagination,
  DailyVendorBillPagination,
  refetchDailyVendorBill,
  currentReferenceNumber,
  currentPaymentStatus,
  UnbilledOrdersPagination,
  refetchUnbilledOrders,
  unbilledInvoicesPagination,
  refetchUnbilledInvoices,
  DailyPayBillPagination,
  forceCreateBill,
  changePaymentStatusPopupRefetch,
  paySingleBillPopup,
  paySingleBill,
  multipleBillsPopup,
  paySelectedMultipleBills
}