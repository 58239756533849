import React from 'react';
import { Grid, TextField } from '@material-ui/core';

const BillingSpecificEmail = ({ values, errors, touched, handleChange, handleBlur }) => {
  return (
    <Grid item xs={12} md={6}>
      <TextField
        label='Billing Email'
        name='billing_email'
        variant='outlined'
        size='small'
        fullWidth
        value={values.billing_email}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.billing_email && errors.billing_email}
        error={Boolean(touched.billing_email && errors.billing_email)}
      />
    </Grid>
  );
}

export { BillingSpecificEmail };
