import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import InputMask from 'react-input-mask';


const Fields = ({ values, errors, touched, handleChange, handleBlur }) => {
  return (
    <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12} sm={12} md={7}>
            <TextField
              label='First Name'
              name='firstName'
              variant='outlined'
              size='small'
              fullWidth
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.firstName && errors.firstName}
              error={Boolean(touched.firstName && errors.firstName)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={7}>
            <TextField
              label='Last Name'
              name='lastName'
              variant='outlined'
              size='small'
              fullWidth
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.lastName && errors.lastName}
              error={Boolean(touched.lastName && errors.lastName)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={7}>
            <InputMask
                mask='(999)999-9999'
                maskChar=''
                value={values.phone_no}
                onChange={handleChange}
                onBlur={handleBlur}
                    helperText={touched.phone_no && errors.phone_no}
                    error={Boolean(touched.phone_no && errors.phone_no)}
              >
                {() =>
                  <TextField
                    label='Phone Number'
                    name='phone_no'
                    variant='outlined'
                    size='small'
                    fullWidth
                    helperText={touched.phone_no && errors.phone_no}
                    error={Boolean(touched.phone_no && errors.phone_no)}
                  />
                }
              </InputMask>
          </Grid>
          <Grid item xs={12} sm={12} md={7}>
            <TextField
              label='Team Lead'
              name='team_lead'
              variant='outlined'
              size='small'
              fullWidth
              value={values.team_lead}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.team_lead && errors.team_lead}
              error={Boolean(touched.team_lead && errors.team_lead)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={7}>
            <TextField
              label='Email'
              name='email'
              variant='outlined'
              size='small'
              fullWidth
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.email && errors.email}
              error={Boolean(touched.email && errors.email)}
            />
          </Grid>
    </Grid>
  );
}

export { Fields };
