import React, { useState } from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { Typography, Box, Divider } from '@material-ui/core';

import { NewRole } from './NewRole';
import { Page } from '../../Components/Page';
import { GeneralModal } from '../../Components/Modal';
import { RoleActions } from '../../Components/Admin/RoleActions';
import { RoleData } from '../../Components/Admin/RoleData';
import { Toast } from '../../Components/Toasts';

import { getRoles } from '../../Recoil/Selectors/Role';

import { useStyles } from '.././Styles/Page';
import { DeleteRole } from './DeleteRole';

export const Role = () => {
  const classes = useStyles();
  const [modal, handleModal] = useState(false);
  const [deleteModal, handleDeleteModal] = useState(false);
  const [isEditMode, setEditMode] = useState(false);

  const [selectedRole, handleSelectedRole] = useState({});
  const [toast, setToast] = useState({
    message: '',
    type: 'success',
    open: false
  });

  const roleData: any = useRecoilValueLoadable(getRoles);

  const data = roleData?.contents?.result?.rows?.rows || [];
  const total = roleData?.contents?.result?.rows?.count || 0;

  const openModal = open => {
    handleSelectedRole({});
    handleModal(open);
    setEditMode(false);
  };

  const handleEdit = params => {
    handleSelectedRole(params);
    handleModal(true);
    setEditMode(true);
  };

  const openDeleteModal = open => {
    handleSelectedRole({});
    handleDeleteModal(open);
  };

  const handleDelete = params => {
    handleSelectedRole(params);
    handleDeleteModal(true);
  };

  return (
    <Page className={classes.paper} title='Roles'>
      <Typography variant='h3'>Roles</Typography>
      <Box my={2}>
        <Divider variant='fullWidth' />
      </Box>
      <RoleActions handleModal={handleModal} />
      <RoleData
        loading={roleData.state === 'loading'}
        data={data}
        total={total}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
      />
      <GeneralModal
        open={modal}
        title={isEditMode ? 'Edit Role' : 'New Role'}
        loading={false}
        openModal={openModal}
        noSubmit={true}
      >
        <NewRole
          selectedRole={selectedRole}
          openModal={openModal}
          setToast={setToast}
        />
      </GeneralModal>

      <GeneralModal
        open={deleteModal}
        title={'Delete Role'}
        loading={false}
        openModal={openDeleteModal}
        noSubmit={true}
      >
        <DeleteRole
          selectedRole={selectedRole}
          openModal={openDeleteModal}
          setToast={setToast} />
      </GeneralModal>

      <Toast
        message={toast.message}
        type={toast.type}
        open={toast.open}
        setToast={setToast}
      />
    </Page>
  );
};
