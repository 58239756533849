import { atom } from 'recoil';

const clients = atom({
  key: 'clients',
  default: []
});

const clientsNames = atom({
  key: 'clientsNames',
  default: []
});

const pagination = atom({
  key: 'clientsPagination',
  default: {
    page: 1,
    limit: 20,
    orderBy: 'clientName',
    orderType: 'ASC',
    search: ''
  }
});

const refreshClients = atom({
  key: 'refreshClients',
  default: 0
});

const selectedClient = atom({
  key: 'selectedClient',
  default: undefined
});

const searchClient = atom({
  key: 'searchClient',
  default: ''
});

const customerListPagination = atom({
  key: 'customerListPagination',
  default: {
    limit: 500,
    search: ''
  }
});


export { clients, pagination, refreshClients, selectedClient, clientsNames, searchClient, customerListPagination };
