import React from 'react';
import { Button, Grid, LinearProgress, Dialog, DialogActions, DialogContent, Typography, IconButton } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

import { useStyles } from './Styles';
import { X } from 'react-feather';
import { paddingStyles } from 'src/Common/Styles/Paddings';

const GeneralModal = (props) => {
  const { open, title, loading, openModal, noSubmit, maxWidth, noFooter, cancelTitle, fullScreen = false } = props;
  const classes = useStyles();
  const paddingClasses = paddingStyles()
  return (
    <Dialog
      open={open || false}
      maxWidth={maxWidth || 'md'}
      fullWidth={true}
      fullScreen={fullScreen}
    >
      <MuiDialogTitle disableTypography className={classes.dialogTitle}>
        <Grid container justifyContent='space-between'>
          <Typography variant='h4'>{title}</Typography>
          <IconButton onClick={() => openModal(false)} disabled={loading} className={paddingClasses.p0}>
            <X></X>
          </IconButton>
        </Grid>
        {loading && <LinearProgress className={classes.progress}></LinearProgress>}
      </MuiDialogTitle>
      <DialogContent className={classes.dialogContent}>
        {props.children}
      </DialogContent>
      {!noFooter && (
        <DialogActions className={classes.dialogActions}>
          <Button color='secondary' onClick={() => openModal(false)} disabled={loading}>
            {title === 'Cancel Order' ? 'No' : cancelTitle ? cancelTitle : 'Cancel'}
          </Button>
          {!noSubmit &&
            <Button color='primary' variant='contained' type='submit' onClick={props.handleSubmit} disabled={loading}>
              {props.submitLabel}
            </Button>
          }
        </DialogActions>)}
    </Dialog>
  );
}

export { GeneralModal };

