import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilCallback, useRecoilState, useSetRecoilState, useRecoilValueLoadable } from 'recoil';
import { Typography, Box, Divider } from '@material-ui/core';
import { SnackbarProvider, useSnackbar} from 'notistack';
import PropTypes from 'prop-types';

import { Page } from '../../Components/Page';
import { FormikComponent } from '../../Components/Formik';
import { SignerForm } from '../../Components/Signers/SignersForm';
import { PageLoad } from '../../Components/LoadingScreen/PageLoad';
import { SignerCredentials } from './../../Components/SignerCredentials'

import { selectedSigner, refetchSigners, refetchSigner } from '../../Recoil/Atoms/Signers';
import { getSignerById, editSigner } from 'src/Recoil/Selectors/Signer';
import { validationSchema } from '../../Common/Rules/SignerForm'

import { useStyles } from '.././Styles/Page';
import { Grid, Button, Tabs, Tab } from '@material-ui/core';

const Form = (props) => {
  const classes = useStyles();
  const { id } = useParams();
  // const id = props?.match?.params?.id || props?.id;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [signerData, setSignerData] = useRecoilState(selectedSigner);
  const callRefreshSigners = useSetRecoilState(refetchSigners);
  const callRefreshSigner = useSetRecoilState(refetchSigner);

  const signer: any = useRecoilValueLoadable(getSignerById({ signerid: id }));

  const refreshSigners = () => callRefreshSigners((n) => n + 1);
  const refreshSigner = useCallback(() => {
    callRefreshSigner((n) => n + 1);
  }, [callRefreshSigner])

  const onSubmit = useRecoilCallback(
    ({ snapshot }) => async ({ ...values }, { setSubmitting, resetForm }) => {
      const requiredFields = [
        'nsa_member_number',
        'signer_type',
        'company_name',
        'Signer_Email',
        'home_phone',
        'mobile_phone',
        'address',
        'city',
        'state',
        'zip',
        'payment_address',
        'payment_city',
        'payment_state',
        'payment_zip',
        'Signer_First',
        'Signer_Last',
        'Signer_Status',
        'operation_notes',
        'certified_signing_agent',
        'fidelity_approved',
        'other_language',
        'commisioning_states',
        'username',
        'signer_id',
        'send_receive_sms',
        'emergency_first_name',
        'emergency_last_name',
        'emergency_phone_no',
        'pmntAdrsSameAsAdrs',
        'set_loan_document',
        'phone_type',
        'prfCom',
        'latitude',
        'longitude',
        'signer_preffered_ind',
        'QB_RefListID',
        'Signer_Account_Type'
      ];
      const subset = Object.fromEntries(
        Object.entries({
          ...values,
          signer_id: id,
          username: values.Signer_Email,
          other_language: values.other_language.toString(),
          pmntAdrsSameAsAdrs: Boolean(values.pmntAdrsSameAsAdrs),
        }).filter(([key]) => requiredFields.includes(key))
      );

      const response = await snapshot.getPromise(editSigner({ data: subset }));
      setSubmitting(false);
      if (response.success) {
        enqueueSnackbar(
          response?.result?.message || 'Signer updated successfully',
          {
            variant: 'success'
          }
        );
        resetForm();
        refreshSigners();
        navigate('/signers');
      } else {
        enqueueSnackbar(
          response?.result?.data?.message || response?.result?.message || 'Something went wrong',
          {
            variant: 'error'
          }
        );
      }
    }
  );

  useEffect(() => {
    if (signer.state !== 'loading') {
      const response = Object.assign({}, signer.contents?.result);
      const data = {
        ...response,
        other_language: response.other_language?.split(',') || [],
        pmntAdrsSameAsAdrs: Boolean(response.pmntAdrsSameAsAdrs),
        commisioning_states:
          Array.isArray(response.CommisioningStates || '') ?
            response.CommisioningStates?.map(stateDetails => stateDetails.state) : [],
        prfCom: response.prfCom?.length ? response.prfCom.map(c => c.preferred_comm_value) : []
      }
      setSignerData(data);
    }
  }, [signer, setSignerData]);

  useEffect(() => {
    return () => {
      setSignerData({});
    }
  }, [setSignerData]);

  useEffect(() => {
    if (signer.state !== 'loading' && !signerData.hasOwnProperty('signer_id')) {
      refreshSigner();
    }
  }, [signerData, refreshSigner, signer]);

  const onInvalid = () => {
    enqueueSnackbar('Please fill out all the required details', {
      variant: 'error'
    });
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  }


  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
  };

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`
    };
  }

  const [value, setValue] = React.useState(0);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <SnackbarProvider>
      <Page className={classes.paper} title='Edit Signer'>
        <Grid container justifyContent='space-between'>
          <Grid item>
            <Typography variant='h3'>Edit Signer</Typography>
          </Grid>
          <Grid item>
            {props?.match?.params?.id && <Button onClick={e => navigate('/signers')}> Back</Button>}
          </Grid>
        </Grid>
        <Box my={2}>
          <Divider variant='fullWidth' />
        </Box>
        {signerData && signerData.hasOwnProperty('signer_id') ?
          <>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor='primary'
              textColor='primary'
              centered
            >
              <Tab label='Profile' {...a11yProps(0)} />
              <Tab label='Credentials' {...a11yProps(1)} />
            </Tabs>

            <TabPanel value={value} index={0}>
              <FormikComponent
                onInvalid={onInvalid}
                onSubmit={onSubmit}
                initialValues={signerData}
                validationSchema={validationSchema}
              >
                <SignerForm isEditMode={true} />
              </FormikComponent>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <SignerCredentials id={id} />
            </TabPanel>
          </>
          :
          <PageLoad />
        }
      </Page>
    </SnackbarProvider>

  );
}

const EditSigner = Form
export { EditSigner }
