import React, { useState } from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { Typography, Box, Divider } from '@material-ui/core';

import { NewClient } from './NewClient';
import { Page } from '../../Components/Page';
import { GeneralModal } from '../../Components/Modal';
import { ClientActions } from '../../Components/Client/ClientActions';
import { ClientsData } from '../../Components/Client/ClientsData';
import { Toast } from '../../Components/Toasts';

import { getClients } from '../../Recoil/Selectors/Clients';

import { useStyles } from '.././Styles/Page';

export const Clients = () => {
  const classes = useStyles();
  const [modal, handleModal] = useState(false);
  const [isEditMode, setEditMode] = useState(false);

  const [selectedClient, handleSelectedClient] = useState({});
  const [toast, setToast] = useState({
    message: '',
    type: 'success',
    open: false
  });



  const clientsData: any = useRecoilValueLoadable(getClients);
  const data = clientsData?.contents?.result?.rows || [];
  const total = clientsData?.contents?.result?.count || 0;


  const openModal = (open) => {
    handleSelectedClient({});
    handleModal(open);
    setEditMode(false)
  }

  const handleEdit = (params) => {
    handleSelectedClient(params);
    handleModal(true);
    setEditMode(true)
  }

  return (
    <Page className={classes.paper} title='Clients'>
      <Typography variant='h3'>Clients</Typography>
      <Box my={2}>
        <Divider variant='fullWidth' />
      </Box>
      <ClientActions handleModal={handleModal} />
      <ClientsData loading={clientsData.state === 'loading'} data={data} total={total} handleEdit={handleEdit} />
      <GeneralModal
        open={modal}
        title={isEditMode ? 'Edit Client' : 'New Client'}
        loading={false}
        openModal={openModal}
        noSubmit={true}
      >
        <NewClient selectedClient={selectedClient} openModal={openModal} setToast={setToast} />
      </GeneralModal>
      <Toast message={toast.message} type={toast.type} open={toast.open} setToast={setToast} />
    </Page>
  );
}
