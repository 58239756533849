import React, { useState } from 'react';
import { Grid, FormControlLabel, Checkbox, TextField } from '@material-ui/core';
import { languages } from '../../../Common/Rules/SignerForm';
import { GeneralModal } from 'src/Components/Modal';

const Languages = ({ values, setFieldValue }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [otherLanguage, setOtherLanguage] = useState('')
  const [otherLanguageError, setOtherLanguageError] = useState('')

  const selected = values.other_language || []

  const selectLanguage = (language, checked) => {
    if (language === 'Other') {
      if (checked) {
        setIsModalOpen(true)
        return null;
      } else {
        setFieldValue(
          'other_language',
          selected.filter(l => l !== language && languages.includes(l))
        );
      }

    } else {
      if (checked) {
        setFieldValue(
          'other_language',
          !selected.includes(language)
            ? selected.concat(language)
            : selected
        );
      } else {
        setFieldValue(
          'other_language',
          selected.filter(l => l !== language)
        );
      }
    }
  };

  const onSelectNewLanguage = () => {
    if (otherLanguage?.length < 2) {
      setOtherLanguageError('Please enter valid language')
      return
    } else {
      selectLanguage(['Other', otherLanguage], true)
      setOtherLanguage('')
      setIsModalOpen(false)
    }
  }

  return (
    <Grid container spacing={0} md={12}>
      <GeneralModal
        maxWidth='xs'
        open={isModalOpen}
        openModal={setIsModalOpen}
        submitLabel='Ok'
        handleSubmit={onSelectNewLanguage}
        title='Other Language'
      >
        <TextField
          fullWidth
          margin='dense'
          variant='outlined'
          label='Language Name'
          value={otherLanguage}
          error={Boolean(otherLanguageError)}
          helperText={otherLanguageError}
          onChange={e => {
            setOtherLanguageError('');
            setOtherLanguage(e.target.value)
          }}
        >
        </TextField>
      </GeneralModal>
      {languages.map((language, index) => {
        return (
          <Grid item lg={4} sm={4} xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selected?.length && selected.includes(language)}
                  onChange={(e) => {
                    selectLanguage(language, e.target.checked);
                  }}
                  name='other_language'
                  color='primary'
                />
              }
              label={language}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export { Languages };
