import React from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { DialogContentText } from '@material-ui/core';

import { GeneralModal } from '../../Modal';

import { dialog as dialogState } from '../../../Recoil/Atoms/Modals';

import { OrderChangeStatus } from './DialogTypes/OrderChangeStatus';
import { OrderLock } from './DialogTypes/OrderLock';
import { ChangeCallStatus } from './DialogTypes/ChangeCallStatus';

export const OrdersDialog = () => {
  const dialog = useRecoilValue(dialogState)
  const resetDialog = useResetRecoilState(dialogState)

  const { size, open, title, loading, handleSubmit, submitLabel, type } = dialog

  const types = {
    cancel: (
      <DialogContentText id='alert-dialog-description'>
        Are you sure you want to cancel this order?
      </DialogContentText>
    ),
    lock: <OrderLock />,
    change_status: <OrderChangeStatus />,
    change_call_status: <ChangeCallStatus />
  }

  return (
    <GeneralModal
      maxWidth={size}
      open={open}
      title={title}
      loading={loading}
      openModal={resetDialog}
      handleSubmit={handleSubmit || (() => null)}
      submitLabel={submitLabel}
    >
      {types[type] || ''}
    </GeneralModal>
  );
};
