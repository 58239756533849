import React from 'react';
import { useRecoilValueLoadable } from 'recoil'
import { Typography, Box, Divider } from '@material-ui/core';

import { Page } from '../../Components/Page';
import { DailyPayBillAction } from './../../Components/Accounting/DailyPayBills/DailyPayBillAction'
import { DailyPayBillData } from './../../Components/Accounting/DailyPayBills/DailyPayBillData'

import { getDailyPayBill } from './../../Recoil/Selectors/Accounting'

import { useStyles } from '.././Styles/Page';

export const DailyPayBill = () => {
    const classes = useStyles();
    const dailybill: any = useRecoilValueLoadable(getDailyPayBill);
    const dailyBillData = dailybill?.contents?.result?.rows || [];
    const total = dailybill?.contents?.result?.count || 0;

    return (
        <Page className={classes.paper} title='Unbilled Invoices'>
            <Typography variant='h3'>Daily Pay Bill</Typography>
            <Box my={2}>
                <Divider variant='fullWidth' />
            </Box>
            <DailyPayBillAction />
            <DailyPayBillData
                rows={dailyBillData}
                loading={dailybill?.state === 'loading'}
                total={total}
            />
        </Page>
    );
};
