import React, { useState } from 'react';
import { useRecoilValueLoadable, useRecoilState, useRecoilCallback } from 'recoil'
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
  Grid,
} from '@material-ui/core';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment'
import clsx from 'clsx';

import { getClientNames } from '../../Recoil/Selectors/Clients';
import { getOrderRangeDetailContactsNames } from '../../Recoil/Selectors/Contacts';
import { getOrderRangeDetailDivisions } from '../../Recoil/Selectors/Divisions';
import { orderRangeDetailPagination } from '../../Recoil/Atoms/Orders';
import { getOrderRangeReportCsv } from '../../Recoil/Selectors/Accounting';

import { sortASC } from '../../Common/Utilities/helper';
import { marginStyles } from '../../Common/Styles/Margins';

const OrderRangeActions = () => {
  // const classes = useStyles();
  const marginClasses = marginStyles();

  const [filter, setFilter] = useRecoilState(orderRangeDetailPagination);
  const [label, setLabel] = useState('Select dates')
  const [dateRange, setDateRange] = useState({ start: '', end: '', filter: '' })
  const [loading, setLoading] = useState(false)

  const clientsData: any = useRecoilValueLoadable(getClientNames)
  const clientsNames = clientsData?.contents?.result || []
  const clientsNamesList = sortASC(clientsNames, 'clientName').map(client =>
    <MenuItem key={client.clientID} value={client.clientID}>{client.clientName}</MenuItem>
  );

  const divisionsData: any = useRecoilValueLoadable(getOrderRangeDetailDivisions)
  const divisionsNames = divisionsData?.contents?.result?.rows || []
  const divisionsNamesList = sortASC( filter.client ? divisionsNames : [], 'divisionName').map(division =>
    <MenuItem key={division.divisionID} value={division.divisionID}>{division.divisionName}</MenuItem>
  );

  const contactsData: any = useRecoilValueLoadable(getOrderRangeDetailContactsNames)
  const contactsNames = contactsData?.contents?.result?.rows || []
  const contactsNamesList = sortASC((filter.division ? contactsNames : []) , 'Contact_First_Name').map(
    contact =>
      <MenuItem key={contact.Id} value={contact.Id}>
        {`${contact.Contact_First_Name} ${contact.Contact_Last_Name}`}
      </MenuItem>
  )

  const settings = {
    locale: {
      cancelLabel: 'Clear',
    },
    autoUpdateInput: false,
    ranges: {
      Today: [moment().startOf('day').toDate(), moment().endOf('day').toDate()],
      Yesterday: [
        moment().subtract(1, 'days').toDate(),
        moment().subtract(1, 'days').toDate(),
      ],
      'Last 7 Days': [
        moment().subtract(6, 'days').toDate(),
        moment().toDate(),
      ],
      'Last 30 Days': [
        moment().subtract(29, 'days').toDate(),
        moment().toDate(),
      ],
      'This Month': [
        moment().startOf('month').toDate(),
        moment().endOf('month').toDate(),
      ],
      'Last Month': [
        moment().subtract(1, 'month').startOf('month').toDate(),
        moment().subtract(1, 'month').endOf('month').toDate(),
      ],
    }
  }

  const handleApply = (event, picker) => {
    const { startDate, endDate } = picker
    setLabel(`${startDate.format('MM/DD/YYYY')} - ${endDate.format('MM/DD/YYYY')}`)
    setDateRange({ ...dateRange, start: startDate.format('MM/DD/YYYY'), end: endDate.format('MM/DD/YYYY')})
  };

  const handleCancel = () => {
    setLabel('Select dates')
    setDateRange({ ...dateRange, start: null, end: null})
  };

  const getScanbackCsv = useRecoilCallback(({ snapshot }) => async (filename: any) => {
    setLoading(true)
    const res: any = await snapshot.getPromise(
      getOrderRangeReportCsv({
      action: 'view/download', 
    }))
    if (res.success) {
      const fetchedFile = window.URL.createObjectURL(res.result);
      const tempLink = document.createElement('a');
      tempLink.href = fetchedFile;
      tempLink.setAttribute('download', "Orders Range Report.csv");
      tempLink.click();
    }
    setLoading(false)
  })

  return (
    <Box my={2}>
      <Grid container spacing={1}>
        <Grid item lg={2} md={2} sm={6} xs={12}>
          <FormControl
            className={clsx(
              marginClasses.mt0,
              marginClasses.mb0,
              marginClasses.mr1
            )}
            margin='dense'
            variant='outlined'
            fullWidth
          >
            <InputLabel>Select</InputLabel>
            <Select label='Select' value={dateRange?.filter} onChange={  (e: any) => setDateRange({ ...dateRange, filter: e.target.value }) } >
              <MenuItem value={'w_Creation_Date'}>Creation Date</MenuItem>
              <MenuItem value={'w_sql_appointment_date'}>Appointment Date</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <DateRangePicker
            initialSettings={settings}
            onApply={handleApply}
            onCancel={handleCancel}
          >
            <TextField
              label="Date Range"
              InputProps={{
                readOnly: true,
              }}
              value={label}
              variant="outlined"
              size="small"
              fullWidth={true}
            />
          </DateRangePicker>
        </Grid>

        <Grid item lg={1} md={1} sm={6} xs={12}>
          <Button 
            color='primary' 
            variant='contained' 
            size='medium' 
            fullWidth
            onClick={ () => setFilter({ ...filter, start: dateRange?.start, end: dateRange?.end, filter: dateRange?.filter }) }
          >
            Search
          </Button>
        </Grid>
        <Grid item lg={2} md={3} sm={6} xs={12}>
          <Button 
            color='primary' 
            variant='contained' 
            size='medium' 
            fullWidth
            disabled={loading}
            onClick={getScanbackCsv}
          >
            Download Report CSV
          </Button>
        </Grid>
      </Grid>
      <Box mt={2}>
        <Grid container spacing={1}>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <FormControl
              className={clsx(
                marginClasses.mt0,
                marginClasses.mb0,
                marginClasses.mr1
              )}
              margin='dense'
              variant='outlined'
              fullWidth
            >
              <InputLabel>Select Client</InputLabel>
              <Select label='Select Client' onChange={ (e: any) => setFilter({ ...filter, division: null, contact: null, client: e.target.value}) }>
                <MenuItem value={null}>Select Client</MenuItem>
                {clientsNamesList}
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <FormControl
              className={clsx(
                marginClasses.mt0,
                marginClasses.mb0,
                marginClasses.mr1
              )}
              margin='dense'
              variant='outlined'
              fullWidth
            >
              <InputLabel>Select Division</InputLabel>
              <Select label='Select Division' onChange={ (e: any) => setFilter({ ...filter, contact: null, division: e.target.value}) }>
                <MenuItem value={null}>Select Division</MenuItem>
                {divisionsNamesList}
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <FormControl
              className={clsx(
                marginClasses.mt0,
                marginClasses.mb0,
                marginClasses.mr1
              )}
              margin='dense'
              variant='outlined'
              fullWidth
            >
              <InputLabel>Select Contact</InputLabel>
              <Select label='Select Contact' onChange={ (e: any) => setFilter({ ...filter,  contact: e.target.value}) }>
                <MenuItem value={null}>Select Contact</MenuItem>
                {contactsNamesList}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export { OrderRangeActions };
