import React from 'react';
import { useSetRecoilState, useRecoilCallback } from 'recoil';

import { FormikComponent } from '../../Components/Formik';
import { UserForm } from '../../Components/Admin/UserForm';

import { refreshUsers } from '../../Recoil/Atoms/User';
import { saveUser, updateUser } from '../../Recoil/Selectors/User';

import { initialValues, validationSchema, validationSchemaUpdate } from '../../Common/Rules/UserForm';

export const NewUser = props => {
  const callRefresh = useSetRecoilState(refreshUsers);
  const refresh = () => callRefresh(n => n + 1);

  const onSubmit = useRecoilCallback(
    ({ snapshot }) => async (values: any, {setSubmitting }) => {
      let res: any = {};
      let message = '';
      if (values.id) {
        res = await snapshot.getPromise(updateUser(values));
        message = 'User has been updated';
      } else {
        res = await snapshot.getPromise(saveUser(values));
        message = 'User has been created';
      }
      setSubmitting(false);
      if (res.success) {
        refresh();
        props.openModal(false);
        props.setToast({
          message,
          type: 'success',
          open: true
        });
      }
    }
  );

  return (
    <FormikComponent
      onSubmit={onSubmit}
      initialValues={
        props.selectedUser.id ? props.selectedUser : initialValues
      }
      validationSchema={props.selectedUser.id ? validationSchemaUpdate : validationSchema}
      enableReinitialize={props.selectedUser.id ? true : false}
    >
      <UserForm />
    </FormikComponent>
  );
};