import React from 'react';

import {
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SvgIcon
} from '@material-ui/core';

import { Save, Trash } from 'react-feather';
import clsx from 'clsx';


import { useStyles as fieldStyles } from '../Styles/Fields';
import { useStyles } from '../Styles/FeeAndInstructions';
import { displayStyles } from '../../../Common/Styles/Display';

const AddFee = (props: any) => {

  const fieldClasses = fieldStyles()
  const displayClasses = displayStyles();
  const classes = useStyles();

  const { 
    isViewOnlyMode, 
    rows, 
    feeType, 
    selectedFee, 
    newFee, 
    handleNewFee, 
    setSelectedFee,
    saveFee,
    closeModal } = props

  return (
    <>
      {!newFee ?
          <Button
            disabled={isViewOnlyMode}
            color='primary'
            variant='contained'
            onClick={() => !isViewOnlyMode ? handleNewFee(true) : undefined}>
              Add New
            </Button> :
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={12} md={5}>
              <FormControl className={fieldClasses.removeSelectMargin} margin='dense' variant='outlined' fullWidth>
                <InputLabel>Service Type</InputLabel>
                <Select
                  disabled={isViewOnlyMode}
                  label='Service Type'
                  name='fee'
                  onChange={ e => setSelectedFee(e.target.value) }
                  fullWidth
                >
                  {
                    rows.map((fee: any) => {
                      return (
                        <MenuItem value={ fee }>{fee.fee_name}</MenuItem>
                      )
                    })
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={5} md={3}>
              <TextField
                disabled={isViewOnlyMode}
                placeholder='Fee'
                variant='outlined'
                size='small'
                type='number'
                value={ selectedFee[feeType] }
                onChange={ (e) => setSelectedFee({ ...selectedFee, [feeType]: e.target.value }) }
                fullWidth
              />
            </Grid>
            <Grid item xs={4} md={2}>
              <TextField
                disabled
                variant='outlined'
                size='small'
                type="number"
                value = { feeType === 'fee_amount' ? selectedFee.bill_amount : selectedFee.attorney_bill }
                fullWidth
              />
            </Grid>
            <Grid className={clsx(displayClasses.flexRow, displayClasses.justifyAround)} item xs={3} md={2}>
              <SvgIcon fontSize='small' className={classes.pointer} color='action' onClick={saveFee} >
                <Save />
              </SvgIcon>
              <SvgIcon fontSize='small' className={classes.pointer} color='action' onClick={closeModal}>
                <Trash />
              </SvgIcon>
            </Grid>
          </Grid>
        }
    </>
  )
}

export { AddFee }