import moment from 'moment';

// import { getTimezone } from './getTimezone';

export const createPayload = async (data) => {
  const params: any = { ...data };
  const locationOneDate = moment(data.location_one.w_Appointment_Date).format('MM/DD/YYYY');
  const locationOneTime = moment(data.location_one.w_Appointment_Time).format('hh:mm A');

  params.location_one.cansubmitinvoice = 1;
  params.location_one.f_offering_fee = 60.00;
  params.location_one.w_sql_appointment_date = moment(`${locationOneDate} ${locationOneTime}`, 'MM/DD/YYYY hh:mm A').format('YYYY-MM-DD');
  params.location_one.w_Appointment_Date = locationOneDate;
  params.location_one.f_appointment_date = locationOneDate;
  params.location_one.f_borrower_first_name = params.location_one.w_Borrower_First_Name;
  params.location_one.f_borrower_last_name = params.location_one.w_Borrower_Last_Name;
  params.location_one.w_record_state = 'New';
  params.location_one.w_creation_date = moment().format('MM/DD/YYYY');
  params.location_one.w_creation_time = moment().format('hh:mm a');
    if (params.location_one.w_Appointment_Time_type === 'OT') {
    params.location_one.w_Appointment_Time = '12:00 AM';
    params.location_one.f_appointment_time = '12:00 AM';
  } else {
    params.location_one.w_Appointment_Time = locationOneTime;
    params.location_one.f_appointment_time = locationOneTime;
  }
  // const locationOneTimezone = await getTimezone({
  //   date: locationOneDate,
  //   time: params?.location_one?.w_Appointment_Time || '12:00 AM',
  //   latitude: params.location_one.latitude,
  //   longitude: params.location_one.longitude
  // })
  // params.location_one.w_appt_time_adj_local_formatted =
  //   moment(`${locationOneDate} ${locationOneTime}`).tz(locationOneTimezone.timeZoneId).format('HH:mm:ss');

  if (params.location_two) {
    const locationTwoDate = moment(data.location_two.w_Appointment_Date).format('MM/DD/YYYY');
    const locationTwoTime = moment(data.location_two.w_Appointment_Time).format('hh:mm A');
    params.location_two.w_record_state = 'New';
    params.location_two.w_creation_date = moment().format('MM/DD/YYYY');
    params.location_two.w_creation_time = moment().format('hh:mm a');

    params.location_two.cansubmitinvoice = 1;
    params.location_two.f_offering_fee = 75.00;
    params.location_two.f_loan_number = 75.00;
    params.location_two.w_sql_appointment_date = moment(`${locationTwoDate} ${locationTwoTime}`, 'MM/DD/YYYY hh:mm A').format('YYYY-MM-DD')
    params.location_two.w_Appointment_Date = locationTwoDate;
    params.location_two.f_appointment_date = locationTwoDate;
    params.location_two.f_borrower_first_name = params.location_two.w_Borrower_First_Name;
    params.location_two.f_borrower_last_name = params.location_two.w_Borrower_Last_Name;
    if (params.location_two.w_Appointment_Time_type === 'OT') {
      params.location_two.w_Appointment_Time = '12:00 AM';
      params.location_two.f_appointment_time = '12:00 AM';
    } else {
      params.location_two.w_Appointment_Time = locationTwoTime;
      params.location_two.f_appointment_time = locationTwoTime;
    }

    if (params.location_two.latitude && params.location_two.longitude ) {
      // const locationTwoTimezone = await getTimezone({
      //   date: locationTwoDate,
      //   time: params?.location_two?.w_Appointment_Time || '12:00 AM',
      //   latitude: params.location_two.latitude,
      //   longitude: params.location_two.longitude
      // })
      // params.location_two.w_appt_time_adj_local_formatted =
      //   moment(`${locationTwoDate} ${locationTwoTime}`).tz(locationTwoTimezone.timeZoneId).format('hh:mm A');
    }
  }

  return params;
};
