import React from 'react';
import { Grid, Button } from '@material-ui/core';

const ParentInformation = ({ values }) => {

  return (
    <Grid item xs={12} sm={12} md={6} lg={6}>
      <Button 
        variant='outlined'
        color='primary'
        fullWidth
        onClick={() => window.open(`/signers/edit/${values?.Parent_ID}`)}
      >
        {`${values?.parent?.Signer_First} ${values?.parent?.Signer_Last}`}
      </Button>
    </Grid>
  );
};

export { ParentInformation };
