import React from 'react';
import { Box, Typography } from '@material-ui/core';

import { PieChart } from '../Charts/Pie';

import { displayStyles } from '../../Common/Styles/Display';
import { marginStyles } from '../../Common/Styles/Margins';

const SatisfactionRating = () => {
  const displayClasses = displayStyles();
  const marginClasses = marginStyles();

  const data = {
    labels: ['Current Week', 'Last Week', 'Last Month'],
    datasets: [{
      data: [300, 50, 100],
      backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
    }]
  };

  return (
    <Box className={displayClasses.themeBG} p={2}>
      <Typography className={marginClasses.mb2} variant='h5'>SATISFACTION RATING</Typography>
      <PieChart data={data} />
    </Box>
  );
}

export { SatisfactionRating };
