import { selector, selectorFamily } from 'recoil';

import { verifyAuthToken } from './Auth'
import {
    volumeReport, nightlyReportPagination, matchedReportsPagination, resendNightlyReport,
    processorsReportPagination,rangeReportsPagination,refetchProcessorReportCSV, refetchVolumeReportPdf,
    resendVolumeReportPdf
} from '../Atoms/Reports';

import { api ,fileapi} from '../../Services/api';


const getVolumeReports = selector({
  key: 'getVolumeReports',
  get: async ({ get }) => {
    try {
        const params = get(volumeReport)
        await verifyAuthToken()
        const result: any = await api.GET(`reports/get/volumereport`, { params });
        result.rows.forEach( row => {
            row.id = row.clientID
        })
        return {
            result: result,
            success: true
        };
        } catch (err) {
        return {
            result: [],
            success: false
        };
    }
  }
});

const getVolumeReportPdf = selectorFamily({
  key: 'getVolumeReportPdf',
  get: (params: any) => async ({ get }) => {
    if (params) {
      try {
        get(refetchVolumeReportPdf)
        await verifyAuthToken()
        const res: any = await api.GET_DOWNLOAD('reports/get/volumereportpdf', { params });
        return {
          result: res,
          success: true
        };
      } catch (error) {
        return {
          result: error,
          success: false
        };
      }
    }
  }
});

const sendVolumeReportPdf = selectorFamily({
  key: 'sendVolumeReportPdf',
  get: (params: any) => async ({ get }) => {
    if (params) {
      try {
        get(resendVolumeReportPdf)
        await verifyAuthToken()
        const res: any = await api.GET('reports/send/volumereportpdf', { params });
        return {
          result: res,
          success: true
        };
      } catch (error) {
        return {
          result: error,
          success: false
        };
      }
    }
  }
});

const getNightlyReports = selector({
    key: 'getNightlyReports',
    get: async ({ get }) => {
      try {
          const params = get(nightlyReportPagination)
          await verifyAuthToken()
          const result: any = await api.GET(`reports/nightlyreport`, { params });
          result.rows.forEach( (row: any,index:any) => { row.id = index+1 })

        return {
            result: result,
            success: true
        };
        } catch (err) {
        return {
            result: [],
            success: false
        };
    }
  }
});

  const getMatchedReport = selector({
    key: 'getMatchedReport',
    get: async ({ get }) => {
      try {
          const params = get(matchedReportsPagination)
          await verifyAuthToken()
          const result: any = await api.GET(`reports/matchedreport`, { params });
          result.rows.forEach( (row: any) => {
              row.id = row.Id
          })
          return {
              result: result,
              success: true
          };
          } catch (err) {
          return {
              result: [],
              success: false
          };
      }
    }
  });

  const sendNightlyReportEmail = selectorFamily({
    key: 'sendNightlyReportEmail',
    get: (params: any) => async ({ get }) => {
      if (params) {
        try {
          get(resendNightlyReport)
          await verifyAuthToken()
          const res: any = await api.GET('reports/nightlyReportEmail', { params });
          return {
            result: res,
            success: true
          };
        } catch (error) {
          return {
            result: error,
            success: false
          };
        }
      }
    }
  });
  
  const getMatchersReportPdf = selectorFamily({
    key: 'matchedReportsPaginationPdf',
    get: (params: any) => async ({ get }) => {

      const params = get(matchedReportsPagination)
      try {
        await verifyAuthToken()
        const res: any = await fileapi.GET_DOWNLOAD('reports/matchedReportPdf', {params});

        return {
          result: res,
          success: true
        };
      } catch (error) {
        return {
          result: error,
          success: false
        };
      }
    }
  });

  const getRangeReportPdf = selectorFamily({
    key: 'getRangeReportPdf',
    get: (params: any) => async ({ get }) => {

      const pagination = get(rangeReportsPagination)
      const params = { ...pagination, limit: undefined, page: undefined }

      try {
        await verifyAuthToken()
        const res: any = await fileapi.GET_DOWNLOAD('reports/rangereportPdf', {params});
       
        return {
          result: res,
          success: true
        };
      } catch (error) {
        return {
          result: error,
          success: false
        }
      }
    }
  })

  
const getRangeReportCsv = selectorFamily({
  key: 'getRangeReportCsv',
  get: (params: any) => async ({ get }) => {

    const pagination = get(rangeReportsPagination)
    const params = { ...pagination, limit: undefined, page: undefined }
    try {
      await verifyAuthToken()
      const res: any = await fileapi.GET_DOWNLOAD('reports/rangereportCsv', { params });

      return {
        result: res,
        success: true
      };
    } catch (error) {
      return {
        result: error,
        success: false
      };
    }
  }
});

const getRangeReport = selector({
  key: 'getRangeReport',
  get: async ({ get }) => {
    try {
        const params = get(rangeReportsPagination)
        await verifyAuthToken()
        const result: any = await api.GET(`reports/rangereport`, { params });

        result.rows.forEach( (row: any,index: any) => {
            row.id = index+1
        })
        return {
            result: result,
            success: true
        };
        } catch (err) {
        return {
            result: [],
            success: false
        };
    }
  }
});

const getProcessorsReport = selector({
  key: 'getProcessorsReport',
  get: async ({ get }) => {
    try {
        const params = get(processorsReportPagination)
        await verifyAuthToken()
        const result: any = await api.GET(`reports/get/processors-report`, { params });
        result.rows.forEach( (row: any,index:any) => { row.id = row.Id })

      return {
          result: result,
          success: true
      };
      } catch (err) {
      return {
          result: [],
          success: false
      };
  }
}
});

  
const getProcessorsReportCSV = selectorFamily({
  key: 'getProcessorsReportCSV',
  get: (params: any) => async ({ get }) => {

    try {
      get(refetchProcessorReportCSV)
      await verifyAuthToken()
      const res: any = await fileapi.GET_DOWNLOAD('reports/get/processors-reportcsv', { params });

      return {
        result: res,
        success: true
      };
    } catch (error) {
      return {
        result: error,
        success: false
      };
    }
  }
});

export {
    getVolumeReports,
    getNightlyReports,
    getMatchedReport,
    sendNightlyReportEmail,
    getMatchersReportPdf,
    getRangeReportPdf,
    getRangeReportCsv,
    getRangeReport,
    getProcessorsReport,
    getProcessorsReportCSV,
    getVolumeReportPdf,
    sendVolumeReportPdf
};
