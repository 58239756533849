import React, { useEffect } from 'react';
import { Layers } from 'react-feather';
import { useRecoilState, useRecoilValueLoadable } from 'recoil'

import { unassignedOrders } from './../../Recoil/Selectors/Statistics'
import { unassignedCount } from '../../Recoil/Atoms/Stats';
import { orderChannel } from './../../Common/NotificationService'

import { Icon } from './Icon';

const Unassigned = () => {
  const [unsignedOrdersCount, setUnassinedOrders] = useRecoilState(unassignedCount);
  const count: any = useRecoilValueLoadable(unassignedOrders)
  
  useEffect(() => {
    if (count.state !== 'loading' && unsignedOrdersCount === 0) {
      setUnassinedOrders(count?.contents?.result)
    }
  })
  orderChannel.bind('unsignedOrdersCount', data => {
    setUnassinedOrders(data.count)
  });

  return (
    <Icon title='Unassigned' count={unsignedOrdersCount}>
      <Layers />
    </Icon>
  );
};

export { Unassigned };
