import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { Box, IconButton, Tooltip, Typography } from '@material-ui/core';
import { Edit, X, Check, Menu, Eye } from 'react-feather';
import moment from 'moment-timezone';
import ColorLegend from '../ColorLegends/ColorLegend';

import { currentOrder } from '../../Recoil/Atoms/Orders';

import { Table } from '../Table';
import { GeneralModal } from '../Modal';
import { FileUploadModal } from '../FileUpload/FileUploadModal';

import { NewOrder } from './../../Pages/Orders/AddEditViewOrder';
import { SlidingPane } from './../SlidingPane';

import { useStyles } from './Styles/unassigned';
import { SignerInfo } from './SignerInfo';

const UnassignedOrdersData = ({
  rows,
  total,
  pageInfo,
  setPageInfo,
  loading,
  handleCancelOrder,
  setAssignAttempts
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { orderBy, orderType } = pageInfo;
  const [fileModal, handleFileModal] = useState(false);
  const [matchActivityModal, setMatchActivityModal] = useState(null);
  const [assignAttempts, AssignAttempts] = useState(null);
  const [signerInfoModal, handleSignerInfoModal] = useState(null);
  const [paneOpen, setPaneOpen] = useState(false);
  const setOrder = useSetRecoilState(currentOrder);
  const [currentOrderId, setCurrentOrderId] = useState(null);

  const handlePaneOpen = (orderId) => {
    setCurrentOrderId(orderId);
    setPaneOpen(true);
  };

  const handlePaneClose = () => {
    setPaneOpen(false);
    setCurrentOrderId(null); // Reset current order ID when closing the pane
  };

  const onInitCancelOrder = (row) => {
    setOrder(row);
    handleCancelOrder();
  };

  const msToMinutesAndSeconds = (seconds) => {
    const minutes = Math.floor(seconds / 60);

    if (minutes >= 60) {
      return `over ${Math.floor(minutes / 60) || ''}hr`;
    }
    return `${minutes} : ${(seconds % 60).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}`;
  };

  const getInitialLatters = (employee = { firstName: '', lastName: '' }) => {
    return `${employee.firstName.split('')[0].toUpperCase()}${employee.lastName.split('')[0].toUpperCase()}`;
  };

  const documentActionFormatter = ({ row }) => {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        width='100%'
      >
        <Tooltip title={`${row?.documentCount || 0}  View/Upload Documents`}>
          <Typography
            color='primary'
            variant='body2'
            className={classes.cursorPointer}
            onClick={(e) => {
              setOrder({
                ...row,
                w_Appointment_Time: moment(row.w_Appointment_Time, 'hh:mm A')
              });
              handleFileModal(true);
            }}
          >
            <Eye width='25' height='25' />
          </Typography>
        </Tooltip>
      </Box>
    );
  };

  const actionsFormatter = (params) => {
    return (
      <Box display='flex'>
        <Tooltip title='Edit'>
          <IconButton
            size='small'
            color='primary'
            // onClick={() => { history.push(`/orders/edit/${params?.row?.Id}`) }}
            onClick={() => {
              handlePaneOpen(params?.row?.Id);
            }}
          >
            <Edit width='25' height='25' />
          </IconButton>
        </Tooltip>
        <Tooltip title='Assign'>
          <IconButton
            size='small'
            color='primary'
            onClick={() => {
              navigate(`/orders/assignsigner/${params?.row?.Id}`);
            }}
          >
            <Check width='25' height='25' />
          </IconButton>
        </Tooltip>
        <Tooltip title='Cancel'>
          <IconButton
            size='small'
            color='primary'
            onClick={(e) => onInitCancelOrder(params?.row)}
          >
            <X width='25' height='25' />
          </IconButton>
        </Tooltip>
        {params?.row?.MatchedActivity?.length &&
        params?.row.MatchedActivity.filter((m) => m.result === 'OPTN')
          .length ? (
          <Tooltip title='View Activity'>
            <IconButton
              size='small'
              color='primary'
              onClick={(e) =>
                setMatchActivityModal(
                  params?.row.MatchedActivity.filter((m) => m.result === 'OPTN')
                )
              }
            >
              <Menu width='25' height='25' />
            </IconButton>
          </Tooltip>
        ) : (
          ''
        )}
      </Box>
    );
  };
  const renderMatchAttempts = (row) => {
    const attempts =
      row?.matchModel && row?.matchModel?.length ? row?.matchModel : [];
    const slice = attempts.length > 3 ? 2 : 3;
    return (
      <div>
        {attempts.slice(0, slice).map((a) => (
          <Typography
            className={classes.cursorPointer}
            variant='body2'
            color='primary'
            key={a.id}
            onClick={(e) =>
              navigate(
                `/orders/assignAttempts/${a.employee_id}/${a.order_id}`,
                {
                  state: {
                    orderDetails: row,
                    orderFees: row?.f_signer_fees,
                    loanNumber: row?.w_Loan_Number,
                    apptDateTime: `${row?.w_Appointment_Date || ''} ${row?.w_Appointment_Time || ''}`
                  }
                }
              )
            }
          >
            {getInitialLatters(a?.employeesModel)} -{' '}
            {msToMinutesAndSeconds(a.duration)}
          </Typography>
        ))}

        {attempts.length > 3 && (
          <Typography
            onClick={(e) =>
              AssignAttempts(
                attempts.map((a) => {
                  return { ...a, row };
                })
              )
            }
            className={classes.cursorPointer}
            variant='body2'
            color='primary'
          >
            View More
          </Typography>
        )}
      </div>
    );
  };
  const columns = [
    {
      field: '',
      headerName: 'Index',
      disableColumnMenu: true,
      align: 'center',
      headerAlign: 'left',
      headerClassName: 'datagrid-header',
      cellClassName: 'datagrid-cell',
      sortable: false,
      width: 70,
      renderCell: ({ row }) => <span>{row.w_row_number}</span>
    },
    {
      field: 'w_Loan_Number',
      headerName: 'Loan/Order #',
      disableColumnMenu: true,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'datagrid-header',
      cellClassName: 'datagrid-cell',
      sortable: false,
      width: 120
    },
    {
      field: 'w_Borrower_Last_Name',
      headerName: 'Last Name',
      disableColumnMenu: true,
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'datagrid-header',
      sortable: false,
      cellClassName: 'datagrid-cell'
    },
    {
      field: 'w_Signing_City',
      headerName: 'City',
      disableColumnMenu: true,
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'datagrid-header',
      sortable: false,
      cellClassName: 'datagrid-cell'
    },
    {
      field: 'w_Signing_State',
      headerName: 'State',
      disableColumnMenu: true,
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'datagrid-header',
      sortable: false,
      cellClassName: 'datagrid-cell'
    },
    {
      field: 'w_Appointment_Date',
      headerName: 'Appt Date',
      disableColumnMenu: true,
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'datagrid-header',
      cellClassName: 'datagrid-cell',
      sortable: false
    },
    {
      field: 'w_Appointment_Time',
      headerName: 'Appt Time',
      disableColumnMenu: true,
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'datagrid-header',
      sortable: false,
      cellClassName: 'datagrid-cell',
      renderCell: ({ row }) => (
        <div>
          <div>
            {row.w_Appointment_Time_type === 'OT'
              ? 'Open Time'
              : row.w_Appointment_Time}
          </div>
          <div>
            {row.w_Appointment_Time_type === 'OT'
              ? 'Open Time'
              : `${moment(row.w_appt_time_adj_local_formatted, 'HH:mm:ss').format('hh:mm A')} PST`}
          </div>
        </div>
      )
    },
    {
      field: 'f_status_web',
      headerName: 'Status',
      disableColumnMenu: true,
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'datagrid-header',
      sortable: false,
      cellClassName: 'datagrid-cell',
      renderCell: ({ row }) => {
        return (
          <>
            <SignerInfo
              signerInfoModal={signerInfoModal}
              handleSignerInfoModal={handleSignerInfoModal}
            />
            <Box display='flex' flexDirection='column'>
              <Typography>{row.f_status_web}</Typography>
              {row.old_signer && (
                <Typography
                  color='primary'
                  variant='body2'
                  className={classes.cursorPointer}
                  onClick={() =>
                    handleSignerInfoModal({ ...row, ...row.old_signer })
                  }
                >
                  {`${row?.old_signer?.Signer_First} ${row?.old_signer?.Signer_Last}`}
                </Typography>
              )}
            </Box>
          </>
        );
      }
    },
    {
      field: 'Assign_Attempts',
      headerName: 'Assign Attempts',
      disableColumnMenu: true,
      sortable: false,
      flex: 1.5,
      align: 'left',
      padding: 0,
      headerAlign: 'left',
      headerClassName: 'datagrid-header',
      cellClassName: 'datagrid-cell',
      renderCell: ({ row }) => renderMatchAttempts(row)
    },
    {
      field: 'Closing_Type',
      headerName: 'Closing Type',
      disableColumnMenu: true,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'datagrid-header',
      cellClassName: 'datagrid-cell'
    },
    {
      field: 'w_docs_received',
      sortable: false,
      headerName: 'Documents',
      disableColumnMenu: true,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'datagrid-header',
      cellClassName: 'datagrid-cell',
      renderCell: documentActionFormatter
    },
    {
      flex: 1.5,
      align: 'left',
      headerAlign: 'left',
      field: 'action',
      headerName: 'Action',
      sortable: false,
      disableColumnMenu: true,
      renderCell: actionsFormatter,
      headerClassName: 'datagrid-header',
      cellClassName: 'datagrid-cell',
      width: 160
    }
  ];

  const handlePage = (e: unknown, newPage: number) => {
    setPageInfo({
      ...pageInfo,
      page: newPage
    });
  };

  const handleRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPageInfo({
      ...pageInfo,
      limit: parseInt(e.target.value, 10)
    });
  };

  const handleSort = (e: any) => {
    if (e.sortModel && e.sortModel[0].field) {
      const { field, sort } = e.sortModel[0];

      setPageInfo({
        ...pageInfo,
        orderBy: field,
        orderType: sort
      });
    }
  };
  const getRowBackGroundColor = (row, colors) => {
    let color = '';
    const difference = moment(
      `${row?.w_Appointment_Date} ${row?.w_Appointment_Time}`,
      'MM/DD/YYYY hh:mm A'
    ).diff(moment().tz('America/Los_Angeles'), 'hours');
    if (
      row?.matchModel?.length &&
      row?.matchModel.find((m) => m.duration / 60 > 60)
    ) {
      color = 'yellow';
    } else if (
      row?.MatchedActivity?.length &&
      row.MatchedActivity.filter((m) => m.result === 'OPTN').length
    ) {
      color = 'riptide';
    } else if (row?.matchModel?.length) {
      color = 'madang';
    } else if (difference <= 3) {
      color = 'red';
    }
    return color;
  };
  const matchActivityColumns = [
    {
      field: 'employee',
      sortable: false,
      headerName: 'User Name',
      flex: 1,
      renderCell: ({ row }) => (
        <div>
          {row?.employee ? (
            <>
              {row?.employee?.firstName}&nbsp;{row?.employee?.lastName}
            </>
          ) : null}
        </div>
      )
    },
    {
      field: 'created_on',
      sortable: false,
      headerName: 'Time',
      flex: 1,
      renderCell: ({ row }) => (
        <div>{moment(row?.created_on).format('MM/DD/YYYY HH:mm')}</div>
      )
    },
    {
      field: 'signer_id',
      sortable: false,
      headerName: 'Signer',
      flex: 1,
      renderCell: ({ row }) => (
        <div>
          {row?.signer ? (
            <>
              {row?.signer.Signer_First}&nbsp;{row?.signer.Signer_Last}
            </>
          ) : null}
        </div>
      )
    },
    {
      field: 'comment',
      sortable: false,
      headerName: 'Comments',
      flex: 1
    },
    {
      field: 'option_fee',
      sortable: false,
      headerName: 'Option Fee',
      flex: 1
    },
    {
      field: 'option_datetime',
      sortable: false,
      headerName: 'Option Date Time',
      flex: 1,
      renderCell: ({ row }) => (
        <div>
          {moment(row?.option_datetime, 'YYYY-MM-DD HH:mm:ss').format(
            'MM-DD-YYYY hh:mm A'
          )}
        </div>
      )
    }
  ];

  let totalRecordsWithMoreThenOneAttempts = 0;

  rows.map((row) => {
    totalRecordsWithMoreThenOneAttempts = row?.matchModel?.length
      ? totalRecordsWithMoreThenOneAttempts + (row?.matchModel?.length - 1)
      : totalRecordsWithMoreThenOneAttempts;
    return row;
  });

  return (
    <>
      {fileModal && (
        <FileUploadModal open={fileModal} handleFileModal={handleFileModal} />
      )}
      <GeneralModal
        title='Match Activity Options'
        open={matchActivityModal}
        openModal={setMatchActivityModal}
        noSubmit
      >
        <Table
          useCustomStyles={false}
          pagination={false}
          columns={matchActivityColumns}
          rows={matchActivityModal || []}
        />
      </GeneralModal>
      {assignAttempts && (
        <GeneralModal
          title='Assign Attempts'
          open={true}
          openModal={(e) => AssignAttempts(null)}
          noSubmit
        >
          <ul className={classes.list}>
            {assignAttempts.map((a) => (
              <li className={classes.listItem} key={a.id}>
                <Typography
                  className={classes.cursorPointer}
                  variant='body2'
                  color='primary'
                  onClick={(e) =>
                    navigate(
                      `/orders/assignAttempts/${a.employee_id}/${a.order_id}`,
                      {
                        state: {
                          orderDetails: a.row,
                          orderFees: a.row?.f_signer_fees,
                          loanNumber: a.row?.w_Loan_Number,
                          apptDateTime: `${a.row?.w_Appointment_Date || ''} ${a.row?.w_Appointment_Time || ''}`
                        }
                      }
                    )
                  }
                >
                  {getInitialLatters(a?.employeesModel)} -{' '}
                  {msToMinutesAndSeconds(a.duration)}
                </Typography>
              </li>
            ))}
          </ul>
        </GeneralModal>
      )}

      <Table
        increaseTableHeight={totalRecordsWithMoreThenOneAttempts * 5}
        customRowHeight={85}
        getRowBackGroundColor={getRowBackGroundColor}
        sortModel={[{ field: orderBy, sort: orderType }]}
        useCustomStyles={false}
        loading={loading}
        columns={columns}
        rows={rows}
        total={total}
        pageInfo={pageInfo}
        handlePage={handlePage}
        handleRowsPerPage={handleRowsPerPage}
        onSortingChange={handleSort}
        rowsPerPageOptions={[40, 50, 60]}
      />
      <ColorLegend />
      <SlidingPane
        title={'Edit Order'}
        open={paneOpen}
        handlePane={handlePaneClose}
        width='95%'
      >
        {paneOpen && (
          <NewOrder
            key={currentOrderId || 'new'}
            id={currentOrderId}
            open={paneOpen}
          />
        )}
      </SlidingPane>
    </>
  );
};

export { UnassignedOrdersData };
