import React from 'react';
import { Grid, TextField } from '@material-ui/core';

const BorrowerEmail = ({ isViewOnlyMode, location, values, errors, touched, handleChange, handleBlur }) => {
  return (
    <Grid item xs={12} md={12}>
      <TextField
        disabled={isViewOnlyMode}
        label='Borrower Email'
        name={`${location}.f_borrower_email`}
        variant='outlined'
        size='small'
        fullWidth
        value={values.f_borrower_email}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={(touched[location] && errors[location]) && errors[location].f_borrower_email}
        error={Boolean((touched[location] && errors[location]) && errors[location].f_borrower_email)}
      />
    </Grid>
  );
}

export { BorrowerEmail };
