import React, { useState, useEffect } from 'react';
import { useRecoilState, useSetRecoilState, useRecoilCallback } from 'recoil';

import { FormikComponent } from '../../Components/Formik';
import { ContactForm } from '../../Components/Client/ContactForm';

import { userInfo } from '../../Recoil/Atoms/Login';
import { refreshContacts, selectedClient } from '../../Recoil/Atoms/Contacts';
import { saveContact } from '../../Recoil/Selectors/Contacts';
import { addClientToContact } from '../../Recoil/Selectors/Clients';
import { getDivisionsByClient } from '../../Recoil/Selectors/Divisions';

import { initialValues, validationSchema } from '../../Common/Rules/ContactForm';
import { SnackbarProvider } from 'notistack';

export const NewClientContact = ((props: any) => {
  const { contact, openModal, setToast } = props;
  const [user] = useRecoilState(userInfo);
  const [client, selectClient] = useRecoilState(selectedClient);
  const [divisions, setDivisions] = useState([]);

  const callRefresh = useSetRecoilState(refreshContacts);

  const refresh = () => callRefresh((n) => n + 1);

  const createPayload = (values) => {
    const selectedDivisions = divisions.filter((d) => d.privilege);
    const params = {
      ...values,
      Company_ID: client,
      divisions: selectedDivisions.map((d) => ({
        createdBy: user.id,
        divisionID: d.divisionID,
        status: 'A',
        privilege: d.privilege
      })) || []
    };
    return params;
  }

  const onSubmit = useRecoilCallback(({ snapshot }) => async (values: any, { setSubmitting }) => {
    let res: any = {};
    let message = '';
    const { isEditing } = props
    const params = createPayload(values);

    params.isEditing = isEditing
    res = await snapshot.getPromise(saveContact(params));

    if (res.success) {
      message = `Contact ${isEditing ? 'updated' : 'created'} successfully!`;
    } else {
      props.enqueueSnackbar(
        res?.result?.data?.message || 'Please fill all the required fields',
        {
          variant: 'error'
        }
      )
    }

    setSubmitting(false);
    if (res.success) {
      refresh();
      if (props.refresh) {
        props.refresh()
      }

      openModal(false);
      setToast({
        message,
        type: 'success',
        open: true
      });
    }
  });

  const getDivisions = useRecoilCallback(({ snapshot }) => async (id: any) => {
    await addClient(id)
    selectClient(id);

    const res: any = await snapshot.getPromise(getDivisionsByClient(id));



    if (contact.Id) {
      let data = res?.result?.rows.map(division => {
        let privilege = contact.ContactDivisionsModel.filter(privilegeDivision => division.divisionID === privilegeDivision.divisionID)
        if (privilege.length) {
          return { ...division, privilege: privilege[0].privilege }
        } else {
          return division
        }
      })
      setDivisions(data);
    } else {
      setDivisions(res?.result?.rows || []);
    }
  });

  useEffect(() => {
    getDivisions(contact.Company_ID)
    // eslint-disable-next-line
  }, [])
  const addClient = useRecoilCallback(({ snapshot }) => async (id: any) => {
    await snapshot.getPromise(addClientToContact({ clientID: id, contactID: contact.Id }));
  });

  return (
    <SnackbarProvider>
      <FormikComponent
        onSubmit={onSubmit}
        initialValues={contact.Id ? contact : { ...initialValues }}
        validationSchema={validationSchema}
      >
        <ContactForm
          isEditMode={contact.Id}
          {...props}
          divisions={divisions}
          client={client}
          selectClient={selectClient}
          setDivisions={setDivisions}
          getDivisions={getDivisions}
          addClient={addClient}
        />
      </FormikComponent>
    </SnackbarProvider>

  );
})
