import React, { useEffect } from 'react';
import { FileText } from 'react-feather';
import { useRecoilState, useRecoilValueLoadable } from 'recoil'

import { mtdSigned } from '../../Recoil/Selectors/Statistics';
import { mtdOrderCount } from '../../Recoil/Atoms/Stats';
import { orderChannel } from './../../Common/NotificationService'

import { Icon } from './Icon';

const MTDSigned = () => {
  const [monthToDateCount, setMonthToDateCount] = useRecoilState(mtdOrderCount);
  const count: any = useRecoilValueLoadable(mtdSigned);

  useEffect(() => {
    if (count.state !== 'loading' && monthToDateCount === 0) {
      setMonthToDateCount(count?.contents?.result)
    }
  })
  
  orderChannel.bind('totalMTDSignedOrder', data => {
    setMonthToDateCount(data.count)
  });


  return (
    <Icon title='MTD Signed' count={monthToDateCount}>
      <FileText />
    </Icon>
  );
};

export { MTDSigned };
