import * as Yup from 'yup';
import { PasswordRegex } from '../Regex/Password';

const initialValues = {
  firstName: '',
  lastName:'',
  email:'',
  phone_no:'',
  password:'',
  roleID:''
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is Required.'),
  lastName: Yup.string().required('Last Name is Required.'),
  phone_no: Yup.string().required('Phone no  is Required.'),
  email:Yup.string().email('Must be a valid email').max(255).required('Email is required'),
  password:Yup.string().required('Password is required').matches(PasswordRegex,'Must contain 8 characters, one uppercase, one lowercase, one number and one special case character'
  ),
  roleID:Yup.string().required('Role is Required.')
});

const validationSchemaUpdate = Yup.object().shape({
  firstName: Yup.string().required('First Name is Required.'),
  lastName: Yup.string().required('Last Name is Required.'),
  phone_no: Yup.string().required('Phone no  is Required.'),
  email:Yup.string().email('Must be a valid email').max(255).required('Email is required'),
  roleID:Yup.string()
});


export {
  initialValues,
  validationSchema,
  validationSchemaUpdate
}; 