import React, { useState } from 'react';
import { useRecoilValueLoadable, useRecoilState, useSetRecoilState, useRecoilCallback } from 'recoil';
import { Box, Grid, Button, TextField, MenuItem, TextareaAutosize, SvgIcon, IconButton, Tooltip, Typography } from '@material-ui/core';
import { Save, Edit, Trash } from 'react-feather';
import { SnackbarProvider } from 'notistack';
import clsx from 'clsx';
import moment from 'moment';

import { Table } from '../../Table';

import { getOrderNotes, newNote, updateNote } from '../../../Recoil/Selectors/Orders';
import { userInfo } from '../../../Recoil/Atoms/Login';
import { refetchNotes } from '../../../Recoil/Atoms/Orders';
import { notesParams } from '../../../Recoil/Atoms/Orders';

import { useStyles as fieldStyles } from '../Styles/Fields';
import { useStyles } from '../Styles/FeeAndInstructions';
import { displayStyles } from '../../../Common/Styles/Display';

const Notes = ((props: any) => {
  const classes = useStyles();
  const fieldClasses = fieldStyles();
  const displayClasses = displayStyles();

  const [note, handleNewNote] = useState(null);

  const [user] = useRecoilState(userInfo);
  const [order] = useRecoilState(notesParams);

  const notesData: any = useRecoilValueLoadable(getOrderNotes(order.Id));
  const notes = notesData?.contents?.result || [];

  const refreshNotes = useSetRecoilState(refetchNotes);
  const refresh = () => refreshNotes(i => i + 1);

  const columns = [{
    field: 'noteTo',
    headerName: 'Note Addressed To'
  }, {
    field: 'note',
    headerName: 'Note',
    renderCell: (data) => (
      <Typography className={classes.rowStyle}>
        {data?.row?.note || ''}
      </Typography>
    )
  }, {
    field: 'name',
    headerName: 'Added By'
  }, {
    field: 'createdAt',
    headerName: 'Added On',
    valueGetter: (e => {
      const d = new Date(e.row.createdAt);
      return moment(d).format('MM/DD/YYYY');
    })
  }, {
    field: 'actions',
    headerName: 'Actions',
    renderCell: ({ row }) => {
      return (
        <>
          <Tooltip title='Edit'>
            <IconButton size='small' color='primary' onClick={() => handleEdit(row)}>
              <Edit width='25' height='25' />
            </IconButton>
          </Tooltip>
          <Tooltip title='Delete'>
            <IconButton size='small' color='primary' onClick={() => handleDelete(row)}>
              <Trash width='25' height='25' />
            </IconButton>
          </Tooltip>
        </>
      )
    }
  }];

  const handleSaveNote = useRecoilCallback(({ snapshot }) => async () => {
    if (note.id) {
      await snapshot.getPromise(updateNote({
        ...note,
        noteTo: note.noteTo.join(),
      }));
    } else {
      await snapshot.getPromise(newNote({
        ...note,
        noteTo: note.noteTo.join(),
        borrower_ID: order.Id,
        createdBy: user.id
      }));
    }
    refresh();
    handleNewNote(null);
  });

  const handleNoteValue = (e) => {
    handleNewNote({
      ...note,
      [e.target.name]: e.target.value
    });
  }

  const handleEdit = (row) => {
    handleNewNote({
      noteTo: [row.noteTo],
      note: row.note,
      id: row.id
    });
  }

  const handleDelete = useRecoilCallback(({ snapshot }) => async (row: any) => {
    await snapshot.getPromise(updateNote({
      id: row.id,
      isDeleted: 1
    }));
    refresh();
    handleNewNote(null);
  });

  return (
    <SnackbarProvider>
      <div className={classes.root}>
        <Box px={1} my={2} display='flex' alignItems='center' justifyContent='flex-end'>
          {!note ?
            <Button
              disabled={props.isViewOnlyMode}
              color='primary'
              variant='contained'
              onClick={() => handleNewNote({
                noteTo: [],
                note: ''
              })}
            >
              New Note
            </Button> :
            <Grid container spacing={2} alignItems='center' justifyContent='flex-end'>
              <Grid item xs={12} md={5}>
                <TextField
                  disabled={props.isViewOnlyMode}
                  select
                  name='noteTo'
                  variant='outlined'
                  label='Note To'
                  size='small'
                  fullWidth
                  SelectProps={{
                    disabled: props.isViewOnlyMode,
                    multiple: true,
                    MenuProps: {
                      style: { zIndex: 1302 }
                    },
                    value: note.noteTo,
                    onChange: handleNoteValue
                  }}
                >
                  <MenuItem value='OP'>Operation</MenuItem>
                  <MenuItem value='CL'>Client</MenuItem>
                  <MenuItem value='ACC'>Accounting</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={9} md={6}>
                <TextareaAutosize
                  disabled={props.isViewOnlyMode}
                  className={fieldClasses.textArea}
                  placeholder='Note'
                  name='note'
                  rowsMin={1}
                  value={note.note}
                  onChange={handleNoteValue}
                />
              </Grid>
              <Grid className={clsx(displayClasses.flexRow, displayClasses.justifyBetween)} item xs={3} md={1}>
                <SvgIcon fontSize='small' color='action' onClick={props.isViewOnlyMode ? undefined : handleSaveNote}>
                  <Save />
                </SvgIcon>
                <SvgIcon
                  fontSize='small'
                  color='action'
                  onClick={props.isViewOnlyMode ? undefined : () => handleNewNote(null)}
                >
                  <Trash />
                </SvgIcon>
              </Grid>
            </Grid>
          }
        </Box>
        <Table columns={columns} rows={notes} pagination={false} />
      </div>
    </SnackbarProvider>

  );
})
export { Notes };
