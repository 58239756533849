import React,{ useEffect } from 'react';
import { Typography, Box, Divider, Grid, } from '@material-ui/core';

import { Page } from '../../Components/Page';
import { AppemptsData } from '../../Components/Orders/AssignAttempts';

import { useStyles } from '../Styles/Page';
import { useSetRecoilState, useRecoilValueLoadable } from 'recoil';
import { assignAttemptsOrderId, refetchAssignAttempts } from '../../Recoil/Atoms/Orders'
import { getAssignAttempts } from '../../Recoil/Selectors/Orders'
import { useParams } from 'react-router';


export const AssignAttempts = (props) => {
  const classes = useStyles();
  const params:any = useParams()
  const setOrderId = useSetRecoilState(assignAttemptsOrderId)
  const refetch = useSetRecoilState(refetchAssignAttempts)

  const attemptsData : any = useRecoilValueLoadable(getAssignAttempts)
  const attempt = attemptsData?.contents?.result?.rows || [];
  const orderDetails = attemptsData?.contents?.result?.orderDetails || {}
useEffect(() => {
  if(params.created_by && params.borrower_id){
    setOrderId({
      created_by: params.created_by,
      borrower_id: params.borrower_id
    })
    refetch(n=>n++)
  }
  // eslint-disable-next-line
}, [params.created_by, params.borrower_id])


 const {orderFees, loanNumber, apptDateTime, onBack,  } = props?.location?.state || {}

  return (
    <Page className={classes.paper} title='Assign Attempts'>
      <Grid container justifyContent='space-between'>
        <Typography variant='h3'>Assigning Attempts</Typography>
      </Grid>
      <Box my={2}>
        <Divider variant='fullWidth' />
      </Box>
      <Grid container spacing={4}>
        <Grid xs={4} item>
          <Typography><b>Loan Number  : </b> {loanNumber}</Typography>
        </Grid>
        <Grid xs={4} item>
          <Typography> <b>Appointment Date/Time : </b>   {apptDateTime}</Typography>
        </Grid>
        <Grid xs={12} item>
          <AppemptsData 
            onBack={onBack} 
            rows={attempt} 
            orderFees={orderFees} 
            orderDetails={orderDetails} 
            loading={attemptsData.state === 'loading'}
          />
        </Grid>
      </Grid>
    </Page>
  );
}

