import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    gridRoot: {
      flexGrow: 1
    },
    addressItem: {
      color: '#000',
      padding: '7px 8px',
      cursor: 'pointer',
      '&:hover': {
        background: '#e7e7e7'
      }
    },
    removeSelectMargin: {
      marginTop: 0,
      marginBottom: 0
    },
    selectWidth: {
      width:'100%',
    },
    cursorPointer:{
      cursor:'pointer'
    },
  })
);

export { useStyles };
