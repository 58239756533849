import React, { useState } from 'react';
import { useRecoilValueLoadable,useSetRecoilState } from 'recoil'

import {
  Grid,
  FormControl,
  FormHelperText,
  TextField,
  CircularProgress
} from '@material-ui/core';
import {  Autocomplete } from '@material-ui/lab';
import { Search } from 'react-feather'

import { vendorListPagination } from './../../../Recoil/Atoms/Signers'
import { fetchVendorList } from './../../../Recoil/Selectors/Signer'

import { useStyles } from '../Styles/Fields';

const SignerQbListID = ({ values, errors, setFieldValue, touched }) => {
  
    const classes = useStyles();

    const [search, setSearch] = useState(values?.vendorName || '')
    const setVendorListPagination = useSetRecoilState(vendorListPagination)

    const vendorListRequest: any = useRecoilValueLoadable(fetchVendorList)
    const vendorList = vendorListRequest?.contents?.result || []

    return (
        <Grid item xs={12} md={6}>
            <FormControl
                variant='outlined'
                size='small'
                classes={{ root: classes.selectWidth }}
                error={errors.QB_RefListID && touched.QB_RefListID}
            >
            <Autocomplete
                id='QB_RefListID'
                getOptionLabel={(option: any) => option?.Name}
                options={vendorList}
                clearOnBlur={false}
                loading={Boolean(vendorListRequest?.state === 'loading')}
                defaultValue={{Name: values?.vendorName, ListID: values?.QB_RefListID}}
                onChange={(event, newValue) => {
                    setFieldValue('QB_RefListID', newValue?.ListID)
                }}
                size='small'
                renderInput={(params) => (
                    <TextField
                        label='Quickbook ID' 
                        variant='outlined' 
                        value={search}
                        onChange={(e: any) => setSearch(e.target.value)}
                        {...params}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                            <React.Fragment>
                                {(vendorListRequest?.state === 'loading') ? <CircularProgress color="inherit" size={20} /> : <Search className={classes.cursorPointer} onClick={ () => setVendorListPagination({ search, limit: 500 }) } size={20} /> }
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                            ),
                        }}
                    />
                )}
            />

            {errors.QB_RefListID && touched.QB_RefListID && (
            <FormHelperText error>
                { errors.QB_RefListID}
            </FormHelperText>

            )}
            </FormControl>
        </Grid>
    );
};

export { SignerQbListID };
