import React from 'react';
import { Grid, TextField } from '@material-ui/core';

const BorrowerName = ({ isViewOnlyMode, location, values, errors, touched, handleChange, handleBlur }) => {
  return (
    <>
      <Grid container item xs={12} md={6}>
        <TextField
          disabled={isViewOnlyMode}
          label='Borrower First Name'
          name={`${location}.w_Borrower_First_Name`}
          variant='outlined'
          size='small'
          fullWidth
          margin='dense'
          value={values.w_Borrower_First_Name}
          onChange={ e => { 
            e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.substr(1).toLowerCase();
            handleChange(e)}}
          onBlur={handleBlur}
          helperText={(touched[location] && errors[location]) && errors[location].w_Borrower_First_Name}
          error={Boolean((touched[location] && errors[location]) && errors[location].w_Borrower_First_Name)}
        />
      </Grid>
      <Grid container item xs={12} md={6}>
        <TextField
          disabled={isViewOnlyMode}
          label='Borrower Last Name'
          name={`${location}.w_Borrower_Last_Name`}
          variant='outlined'
          size='small'
          fullWidth
          margin='dense'
          value={values.w_Borrower_Last_Name}
          onChange={ e => { 
            e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.substr(1).toLowerCase();
            handleChange(e)}}
          onBlur={handleBlur}
          helperText={(touched[location] && errors[location]) && errors[location].w_Borrower_Last_Name}
          error={Boolean((touched[location] && errors[location]) && errors[location].w_Borrower_Last_Name)}
        />
      </Grid>
    </>
  );
}

export { BorrowerName };
