import React, { useState ,useEffect} from 'react'
import { useRecoilCallback, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import { Box, Button, Dialog, DialogActions, DialogContent, Typography, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

import { Filedropzone } from './Filedropzone';
import { FileList } from './FileList';
import { UploadedBySelect } from './../UploadedBySelect/UploadedBySelect'

import { uploadFile, FetchFileList, DeleteFile, FetchFile, fetchScanbacks } from '../../Recoil/Selectors/Orders';
import { forceUpdateFiles, currentOrder, uploadedBy ,refetchOrders, forceDownload } from '../../Recoil/Atoms/Orders';

import { useStyles } from './Style/Style';
import { displayStyles } from '../../Common/Styles/Display';

const FileUploadModal = (props: any) => {
  const { open, handleFileModal,isUpdateOrder } = props
  const classes = useStyles();
  const displayClasses = displayStyles();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [alert, setAlert] = useState(null);
  const todoUpdates = useSetRecoilState(forceUpdateFiles);
  const redownload = useSetRecoilState(forceDownload);
  const setRefetchOrders = useSetRecoilState(refetchOrders);
  const selectedOrder: any = useRecoilValue(currentOrder);
  const completedStatuses: any = ['Signed', 'Completed', 'Cancelled']

  const uploadedByAtom = useRecoilValue(uploadedBy)

  const forceUpdate = () => todoUpdates((n) => n + 1);
  const forceUpateOrder=()=>{
    if(isUpdateOrder)
    {
       setRefetchOrders((n) => ++n)
    }
  }

  const uploadedFiles: any = useRecoilValueLoadable(FetchFileList(selectedOrder));
  const files = uploadedFiles?.contents?.result?.data || [];
  const total = files.length;
  const scanbacksRequest: any = useRecoilValueLoadable(fetchScanbacks(selectedOrder));
  const scanbacks = scanbacksRequest?.contents?.result?.data || [];
  const scanbacksTotal = scanbacks.length;

  useEffect(forceUpdate,[])
  
  const uploadRequest = useRecoilCallback(({ snapshot }) => async (
    file: any,
  ) => {
    setAlert(null);
    const res: any = await snapshot.getPromise(uploadFile({ 
      file, 
      destination: `borrower/${selectedOrder.Id}`, 
      type: uploadedByAtom.type, 
      id: uploadedByAtom.id 
    }));
    forceUpateOrder()
    forceUpdate();
    if (!res.success) {
      setAlert({
        status: 'error',
        message: res?.result?.data?.Error
      });
    }
  })

  const removeFile = useRecoilCallback(({ snapshot }) => async (filename: any) => {
    await snapshot.getPromise(DeleteFile({
      folder: `borrower/${selectedOrder.Id}`, filename
    }));
    forceUpdate();
    forceUpateOrder()
  });

  const getFile = useRecoilCallback(({ snapshot }) => async (filename: any) => {
    const res: any = await snapshot.getPromise(
      FetchFile({
        folder: `borrower/${selectedOrder.Id}`, 
        filename, 
        action: 'view/download', 
        orderid: selectedOrder.Id
      })
    );
    redownload( n => n + 1 )
    // forceUpdate();
    if (res.success) {
      const fetchedFile = window.URL.createObjectURL(res.result);
      const tempLink = document.createElement('a');
      tempLink.href = fetchedFile;
      tempLink.setAttribute('download', filename);
      tempLink.click();
    }
  })

  const getScanback = useRecoilCallback(({ snapshot }) => async (filename: any) => {
    const res: any = await snapshot.getPromise(
      FetchFile({
        folder: `borrower/${selectedOrder.Id}/signers/scanbacks`, 
        filename, 
        action: 'view/download', 
        orderid: selectedOrder.Id
      })
    );
    redownload( n => n + 1 )
    // forceUpdate();
    if (res.success) {
      const fetchedFile = window.URL.createObjectURL(res.result);
      const tempLink = document.createElement('a');
      tempLink.href = fetchedFile;
      tempLink.setAttribute('download', filename);
      tempLink.click();
    }
  })

  return (
    <Dialog
      open={open}
      maxWidth='md'
      fullWidth={true}
      onClose={() => {
        handleFileModal(false)
      }}
    >
      <MuiDialogTitle disableTypography className={classes.dialogTitle}>
        <Typography variant='h4'>Upload File</Typography>
      </MuiDialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container md={4}>
          <UploadedBySelect 
            Contact_ID={ selectedOrder.Contact_ID } 
            clientID={ selectedOrder.clientID } 
          />
        </Grid>
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='center'
        >
          <Filedropzone
            disable={Boolean(uploadedByAtom.id === 0) || completedStatuses.includes(selectedOrder?.f_status_web) || props.isViewOnlyMode}
            selectedFiles={selectedFiles}
            uploadRequest={uploadRequest}
            Id={selectedOrder.Id}
            setSelectedFiles={setSelectedFiles}
          />
          {alert &&
            <Box className={displayClasses.w100} my={3}>
              <Alert severity={alert.status}>{alert.message}</Alert>
            </Box>
          }
          <FileList
            title='List of Scanbacks'
            uploadedFiles={scanbacksRequest}
            total={scanbacksTotal}
            files={scanbacks}
            getFile={getScanback}
            removeFile={null}
            scanback={true}
            nofiles='No Scanback Has Been Uploaded Yet'
          />
          <FileList
            uploadedFiles={uploadedFiles}
            total={total}
            files={files}
            getFile={getFile}
            removeFile={!completedStatuses.includes(selectedOrder?.f_status_web) ? removeFile : null}
          />
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          color='secondary'
          onClick={() => {
            setSelectedFiles([])
            handleFileModal(false)
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export { FileUploadModal };
