import React from 'react';
import { useRecoilValueLoadable, useRecoilState } from 'recoil';
import { TextField, CircularProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { searchSignerWithId } from 'src/Recoil/Selectors/Signer';
import { searchSignerPagination } from 'src/Recoil/Atoms/Signers';


export default function SearchSigner({ setToSigner }) {
    const [open, setOpen] = React.useState(false);
    const [pageInfo, setPageinfo] = useRecoilState(searchSignerPagination)

    let timeout;
    const signerData: any = useRecoilValueLoadable(searchSignerWithId)
    const signers = signerData?.contents?.result?.rows || [{}];
    const onInputChange = (e, value, reason) => {
        if (reason === 'input') {
            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(() => {
                setPageinfo({
                    ...pageInfo,
                    search: value
                })
            }, 2000)
        }
    }
    const onChange = (e, value, reason) => {
        if (reason === 'select-option') {
            setToSigner(value)
        }
    }


    return (
        <Autocomplete
            onInputChange={onInputChange}
            onChange={onChange}
            size='small'
            style={{ width: 300 }}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            getOptionSelected={(option: { signer_id: Number }, value) => option.signer_id === value.signer_id}
            getOptionLabel={(option) => String(option.signer_id)}
            options={signers}
            loading={signerData.state === 'loading'}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label='Merge With Signer Id:'
                    variant='outlined'
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {signerData.state === 'loading' ? <CircularProgress color='inherit' size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}
