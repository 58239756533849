import React from 'react';
import { Typography, Box, Divider } from '@material-ui/core';

const SectionTitle = (props) => {
  const { title, variant } = props;
  return (
    <Box pt={2}>
      <Typography variant={variant}>{title}</Typography>
      <Box mt={1}>
        <Divider variant='fullWidth' />
      </Box>
    </Box>
  );
}

export { SectionTitle };
