import React, { useState } from 'react';
import { useRecoilValueLoadable } from 'recoil'
import clsx from 'clsx';

import {
    MenuItem,
    Select,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableHead,
    TableBody,
    Paper,
    Typography
} from '@material-ui/core';

import { payBillPopup } from './../../../Recoil/Selectors/Accounting'

import { GeneralModal } from '../../Modal';
import { PageLoad } from 'src/Components/LoadingScreen/PageLoad';

import { marginStyles } from '../../../Common/Styles/Margins';

const PayBill = ({ payBillModal, submitPayBill, handlePayBillModal }) => {

    const marginClasses = marginStyles();
    const [accountPayable, setAccountPayable] = useState<String>('')

    const paybillPopupRequest: any = useRecoilValueLoadable(payBillPopup)
    const paybill = paybillPopupRequest?.contents?.result || {};
    const result = paybillPopupRequest?.contents?.success

    return (
        <GeneralModal
            loading={paybillPopupRequest.state === 'loading'}
            open={payBillModal?.id ? true : false}
            title='PacDocSign : Pay Bill'
            openModal={() => handlePayBillModal({})}
            noSubmit={!result || false}
            noFooter={false}
            maxWidth='md'
            submitLabel='Submit'
            handleSubmit={() => submitPayBill({
                account: accountPayable,
                TxnID: paybill?.billTxnId,
                Id: payBillModal?.id,
                RefNumber: `${String(payBillModal?.w_Borrower_Last_Name.substring(0, 20 - String(payBillModal?.id).length)).trim()}${payBillModal?.id}`
            })}
        >
            {
                paybillPopupRequest.state === 'loading'
                    ?
                    <PageLoad />
                    :
                    !result
                        ?
                        <Typography variant='body1' color='textSecondary'>{paybill?.data?.message}</Typography>
                        :
                        <>
                            <Typography className={clsx(marginClasses.mt3, marginClasses.mb3)}>
                                {paybill?.vendorName}. {payBillModal?.f_signer_address}, {payBillModal?.f_signer_city}, {payBillModal?.f_signer_state}, {payBillModal?.f_signer_zip}.
                            </Typography>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Vendor</TableCell>
                                            <TableCell>Ref.Number</TableCell>
                                            <TableCell>Bill Date</TableCell>
                                            <TableCell>Bill Due</TableCell>
                                            <TableCell>Amount Due</TableCell>
                                            <TableCell>Account</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>{paybill?.vendorName}</TableCell>
                                            <TableCell>{paybill?.refNumber}</TableCell>
                                            <TableCell>
                                                {paybill?.billDate}
                                            </TableCell>
                                            <TableCell>
                                                {paybill?.billDue}
                                            </TableCell>
                                            <TableCell>{paybill?.amount}</TableCell>
                                            <TableCell>
                                                <Select
                                                    labelId="Select-Amount"
                                                    id="Select-Amount"
                                                    fullWidth
                                                    onChange={(e: any) => setAccountPayable(e.target.value)}
                                                >
                                                    {paybill?.accounts.map((account: any) => {
                                                        return <MenuItem value={account.ListID}>{account.FullName}</MenuItem>
                                                    })}
                                                </Select>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
            }

        </GeneralModal>
    );
};

export { PayBill };