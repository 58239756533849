import React from 'react';
import { FormControl, MenuItem, InputLabel, Select, TextField, } from '@material-ui/core';
import clsx from 'clsx';
import map from 'lodash/map';

import { marginStyles } from '../../../Common/Styles/Margins';

import { states } from '../../../Common/States';
import { statuses } from '../../../Common/Statuses';
import { sortASC } from '../../../Common/Utilities/helper';

import { useStyles } from '../Styles/OrdersList';

const SearchInput = ({ type, value, onChange, handleSearchClick }) => {
  const classes = useStyles();
  const marginClasses = marginStyles();

  const searchInputs = {
    borrowerName: {
      type: 'text',
      label: 'Name'
    },
    loanNumber: {
      type: 'text',
      label: 'Number'
    },
    orderId: {
      type: 'text',
      label: 'Id'
    },
    invoiceRef: {
      type: 'text',
      label: 'Number'
    },
    city: {
      type: 'text',
      label: 'City'
    },
    state: {
      type: 'select',
      label: 'State',
      options: map(states, ({ code, name }) => <MenuItem key={code} value={code}>{name}</MenuItem>)
    },
    orderDate: {
      type: 'text',
      label: 'MM/DD/YYYY'
    },
    docsReceived: {
      type: 'text',
      label: 'Yes/No'
    },
    apptDate: {
      type: 'text',
      label: 'MM/DD/YYYY'
    },
    status: {
      type: 'select',
      label: 'Status',
      options: map(
        sortASC(statuses, 'label'), ({ value, label }) => <MenuItem key={value} value={value}>{label}</MenuItem>
      )
    },
  }

  const inputType = searchInputs[type]?.type || 'text'
  const inputLabel = searchInputs[type]?.label || 'Input'
  const selectOptions = searchInputs[type]?.options ||
    <MenuItem key='no-data' disabled value='no-data'>No data</MenuItem>

  const select = (
    <FormControl className={clsx(marginClasses.my0, classes.select)} margin='dense' variant='outlined'>
      <InputLabel>{inputLabel}</InputLabel>
      <Select label={inputLabel} value={value} onChange={onChange}>
        {selectOptions}
      </Select>
    </FormControl>
  )

  const input = (
    <FormControl className={clsx(marginClasses.my0)} margin='dense' variant='outlined'>
      <form onSubmit={handleSearchClick} >
        <TextField
          className={clsx(marginClasses.my0)}
          type={inputType}
          variant='outlined'
          label={inputLabel}
          margin='dense'
          value={value}
          onChange={onChange}
        />
      </form>
    </FormControl>
  )

  return (
    inputType === 'select' ? select : input
  );
};

export { SearchInput };

