import React, { useState } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button
} from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment-timezone'
import { useRecoilState, useRecoilValueLoadable,useRecoilCallback } from 'recoil'
import { getMatchersReportPdf } from './../../Recoil/Selectors/Reports'
import { matchedReportsPagination} from './../../Recoil/Atoms/Reports'
import { getAllEmployees } from './../../Recoil/Selectors/Auth'

import { useStyles } from './Styles/Actions';
import { marginStyles } from '../../Common/Styles/Margins';

import { MatchersDateRange } from './MatchersDateRange';

const MatchersReportActions = (props) => {
  const classes = useStyles();
  const marginClasses = marginStyles();
  const [page, setPage] = useRecoilState(matchedReportsPagination)
  const employeesRequest: any = useRecoilValueLoadable(getAllEmployees)
  const employees = employeesRequest?.contents?.result?.rows || []
  const [loading, setLoading] = useState(false)

  const getScanback = useRecoilCallback(({ snapshot }) => async (filename: any) => {   
    setLoading(true)
    const res: any = await snapshot.getPromise(
    getMatchersReportPdf({
      action: 'view/download', 
    }))
    if (res.success) {
      const fetchedFile = window.URL.createObjectURL(res.result);
      const tempLink = document.createElement('a');
      tempLink.href = fetchedFile;
      tempLink.setAttribute('download', "Matcher Report.pdf");
      tempLink.click();
    }
    setLoading(false)
  })

  const handleDates = (start, end) => {
    setPage({
      ...page,
      start: start.format('MM/DD/YYYY'),
      end: end.format('MM/DD/YYYY'),
    })
  };

  const clearDates = () => {
    setPage({
      ...page,
      start: moment().format('MM/DD/YYYY'), 
      end: moment().format('MM/DD/YYYY'),
      limit: 20, 
      page: 1
    })
  };

  const setEmployee = (e) => {
    setPage({
      ...page,
      matched_by: e.target.value
    })
  }

  return (
    <Box my={2} display='flex'>
      <Box width={'20%'} mr={2}>
        <MatchersDateRange handleDates={handleDates} clearDates={clearDates} />
      </Box>
      <FormControl
        className={clsx(
          marginClasses.mt0,
          marginClasses.mb0,
          marginClasses.mr1,
          classes.select
        )}
        margin='dense'
        variant='outlined'
      >
        <InputLabel>Employee</InputLabel>
        <Select label='Employee' onChange={setEmployee}>
          {employees.map( employee => {
            return(
              <MenuItem value={employee.id}>{`${employee.firstName} ${employee.lastName}`}</MenuItem>
            )
          })}
        </Select>
      </FormControl>
      <Box  ml={2}>
      <Button variant="contained" color="primary" disabled={props.buttonDisable || loading} onClick={getScanback}>
        Download Matchers Report PDF
      </Button>
      </Box>
    </Box>
  );
};

export { MatchersReportActions };
