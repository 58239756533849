import React, { useState } from 'react';
import { Box, Grid, Select, Typography, FormControl, MenuItem } from '@material-ui/core';

import { LineChart } from '../Charts/Line';

import { displayStyles } from '../../Common/Styles/Display';
import { marginStyles } from '../../Common/Styles/Margins';
import { useRecoilValueLoadable } from 'recoil';
import { signedOrderGraphData } from 'src/Recoil/Selectors/Statistics';

const SignedAndOrdersSummary = () => {

  const displayClasses = displayStyles();
  const marginClasses = marginStyles();
  const [type, setType] = useState('M')
  const ordersDataRequest: any = useRecoilValueLoadable(signedOrderGraphData)
  const graphData = ordersDataRequest?.contents?.result
  const selectedRange = graphData?.[type === 'Y' ? 'yearlyReports' : 'monthlyReports'] || []
  const labels = selectedRange.map(p => p[type === 'Y' ? 'year' : 'monthName'])

  const data = {
    datasets: [
      {
        label: 'Signed',
        type: 'line',
        data: selectedRange.map(p => p.signed),
        fill: true,
        borderColor: '#5fbeaa',
        backgroundColor: '#7fc3ce',
        pointBorderColor: '#5fbeaa',
        pointBackgroundColor: '#5fbeaa',
        pointHoverBackgroundColor: '#5fbeaa',
        pointHoverBorderColor: '#5fbeaa'
      },
      {
        label: 'Orders',
        type: 'line',
        data: selectedRange.map(p => p.orders),
        fill: true,
        borderColor: '#5d9cec',
        backgroundColor: '#a0c2ea',
        pointBorderColor: '#5d9cec',
        pointBackgroundColor: '#5d9cec',
        pointHoverBackgroundColor: '#5d9cec',
        pointHoverBorderColor: '#5d9cec'
      },]
  };
  const setState = (fn, value) => {
    fn(value)
  }
  return (
    <Box className={displayClasses.themeBG} p={2}>
      <Grid container justifyContent='space-between' alignItems='center'>
        <Typography className={marginClasses.mb2} variant='h5'>SIGNED/ORDERS</Typography>
        <FormControl margin='dense' variant='outlined'>
          <Select
            variant='outlined'
            value={type}
            onChange={e => setState(setType, e?.target?.value)}
          >
            <MenuItem value='M'>Monthly</MenuItem>
            <MenuItem value='Y'>Yearly</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {/* <LineChart data={data} labels={labels} /> */}
    </Box>
  );
}

export { SignedAndOrdersSummary };
