import { selector, selectorFamily } from 'recoil';
import { api, fileapi } from '../../Services/api';

import {
  refetchPendingVendorInvoice,
  pendingVendorInvoicesPagination,
  refetchDailyVendorBill,
  DailyVendorBillPagination,
  currentReferenceNumber,
  currentPaymentStatus,
  UnbilledOrdersPagination,
  refetchUnbilledOrders,
  unbilledInvoicesPagination,
  refetchUnbilledInvoices,
  DailyPayBillPagination,
  forceCreateBill,
  changePaymentStatusPopupRefetch,
  paySingleBillPopup,
  paySingleBill,
  multipleBillsPopup,
  paySelectedMultipleBills
} from '../Atoms/Accounting'

import { verifyAuthToken } from './Auth'
import { orderRangeDetailPagination } from './../Atoms/Orders'

const getPendingVendorInvoice = selector({
  key: 'getPendingVendorInvoice',
  get: async ({ get }) => {
    get(refetchPendingVendorInvoice)

    const params = get(pendingVendorInvoicesPagination);

    const withSorting = {
      ...params,
      orderBy: params.orderBy || 'Id',
      orderType: params.orderType || 'DESC',
    }
    try {
      await verifyAuthToken()
      const res: any = await api.GET('accounting/', { params: withSorting })

      res.rows.forEach(r => {
        r.id = r.Id
      })

      return {
        result: res,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const getDailyVendorBill = selector({
  key: 'getDailyVendorBill',
  get: async ({ get }) => {
    get(refetchDailyVendorBill)

    const params = get(DailyVendorBillPagination);
    try {
      await verifyAuthToken()
      const res: any = await api.GET('accounting/get/dailyvendorbill', {
        params: {
          ...params,
          orderType: params.orderType || 'DESC',
        }
      });

      res.rows.forEach(r => {
        r.id = r.Id
      })

      return {
        result: res,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const updateReferenceNumber = selectorFamily({
  key: 'updateReferenceNumber',
  get: (params: any) => async ({ get }) => {
    if (params) {
      try {
        get(currentReferenceNumber)
        await verifyAuthToken()
        const result: any = await api.PUT(`accounting/reference/${params.id}`, {
          billRefNumber: params.billRefNumber
        });
        return {
          result,
          success: true
        };
      } catch (err) {
        return {
          result: err,
          success: false
        };
      }
    }
  }
})

const updatePaymentStatus = selectorFamily({
  key: 'updatePaymentStatus',
  get: (params: any) => async ({ get }) => {
    if (params) {
      try {
        get(currentPaymentStatus)
        await verifyAuthToken()
        const result: any = await api.PUT(`accounting/payment/${params.id}`, params);
        return {
          result,
          success: true
        };
      } catch (err) {
        return {
          result: err,
          success: false
        };
      }
    }
  }
});

const getOrderRangeReportCsv = selectorFamily({
  key: 'getOrderRangeReportCsv',
  get: (params: any) => async ({ get }) => {

    const params = get(orderRangeDetailPagination)
    try {
      await verifyAuthToken()
      const res: any = await fileapi.GET_DOWNLOAD('accounting/orderRangeDetailCsv', { params });

      return {
        result: res,
        success: true
      };
    } catch (error) {
      return {
        result: error,
        success: false
      };
    }
  }
});

const getUnbilledOrders = selector({
  key: 'getUnbilledOrders',
  get: async ({ get }) => {
    get(refetchUnbilledOrders)

    const params = get(UnbilledOrdersPagination);

    const withSorting = {
      ...params,
      orderBy: params.orderBy || 'Id',
      orderType: params.orderType || 'DESC',
    }

    try {
      await verifyAuthToken()
      const res: any = await api.GET('accounting/unbilled/orders', { params: withSorting })

      res.rows.forEach(r => {
        r.id = r.Id
      })

      return {
        result: res,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const getUnbilledInvoices = selector({
  key: 'getUnbilledInvoices',
  get: async ({ get }) => {
    get(refetchUnbilledInvoices)

    const params = get(unbilledInvoicesPagination);

    const withSorting = {
      ...params,
      orderBy: params.orderBy || 'Id',
      orderType: params.orderType || 'DESC',
    }

    try {
      await verifyAuthToken()
      const res: any = await api.GET('accounting/unbilled/invoices', { params: withSorting })
      res.rows.forEach(r => {
        let billAmount = 0;
        r.id = r.Id
        if (r.f_signer_fees) {
          r.fees.map((fee) => {
            if (r.Signer_Type === 'Notary') {
              billAmount += fee.bill_amount;
            } else {
              billAmount += fee.attorney_bill;
            }
            return billAmount
          })
          r.bill_amount = billAmount;
        }
      })
      return {
        result: res,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const getOrdersRangeDetails = selector({
  key: 'getOrdersRangeDetails',
  get: async ({ get }) => {

    const params = get(orderRangeDetailPagination);
    try {
      if (params?.start && params?.end && params?.filter) {
        await verifyAuthToken()
        const res: any = await api.GET('accounting/orderRangeDetail', { params });

        res.rows.forEach(r => {
          r.id = r.Id
        })

        return {
          result: res,
          success: true
        };
      }
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const getDailyPayBill = selector({
  key: 'getDailyPayBill',
  get: async ({ get }) => {
    get(refetchUnbilledOrders)

    const params = get(DailyPayBillPagination);

    try {
      await verifyAuthToken()
      const res: any = await api.GET('accounting/dailypaybill', { params })
      res.rows.forEach(r => {
        r.id = r.Id
      })

      return {
        result: res,
        success: true
      };
    } catch (err) {
      return {
        result: [],
        success: false
      };
    }
  }
});

const createBill = selectorFamily({
  key: 'createBill',
  get: (params: any) => async ({ get }) => {
    if (params) {
      try {
        get(forceCreateBill)
        await verifyAuthToken()
        const res: any = await api.POST('orders/createbill', params);
        return {
          result: res,
          success: true
        };
      } catch (error) {
        return {
          result: error,
          success: false
        };
      }
    }
  }
});

const changePaymentStatusPopup = selector({
  key: 'changePaymentStatusPopup',
  get: async ({ get }) => {
    const params = get(changePaymentStatusPopupRefetch)
    if (params?.Id) {
      try {
        await verifyAuthToken()
        const res: any = await api.GET(`accounting/change-payment-status-popup/${params?.Id}`);
        return {
          result: res,
          success: true
        };

      } catch (error) {
        return {
          result: error,
          success: false
        };
      }
    }
  }
});

const payBill = selectorFamily({
  key: 'payBill',
  get: (params: any) => async ({ get }) => {
    if (params) {
      try {
        get(paySingleBill)
        await verifyAuthToken()
        const res: any = await api.PUT(`accounting/paybill/${params?.Id}`, params);
        return {
          result: res,
          success: true
        };
      } catch (error) {
        return {
          result: error,
          success: false
        };
      }
    }
  }
});

const payBillPopup = selector({
  key: 'payBillPopup',
  get: async ({ get }) => {
    const params = get(paySingleBillPopup)
    if (params?.Id) {
      try {
        await verifyAuthToken()
        const res: any = await api.GET(`accounting/paybillpopup/${params?.Id}`);
        return {
          result: res,
          success: true
        };

      } catch (error) {
        return {
          result: error,
          success: false
        };
      }
    }
  }
});

const payMultipleBillsPopup = selector({
  key: 'payMultipleBillsPopup',
  get: async ({ get }) => {
    const params = get(multipleBillsPopup)
    if (params?.orderIds) {
      try {
        await verifyAuthToken()
        const res: any = await api.POST('accounting/paymultiplebillspopup', { orderIds: params?.orderIds });
        return {
          result: res,
          success: true
        };

      } catch (error) {
        return {
          result: error,
          success: false
        };
      }
    }
  }
});

const paySelectedBills = selectorFamily({
  key: 'paySelectedBills',
  get: (params: any) => async ({ get }) => {
    if (params) {
      try {
        get(paySelectedMultipleBills)
        await verifyAuthToken()
        const res: any = await api.POST('accounting/paymultiplebills', params);
        return {
          result: res,
          success: true
        };
      } catch (error) {
        return {
          result: error,
          success: false
        };
      }
    }
  }
});

export {
  getPendingVendorInvoice,
  getDailyVendorBill,
  updateReferenceNumber,
  updatePaymentStatus,
  getUnbilledOrders,
  getUnbilledInvoices,
  getDailyPayBill,
  getOrdersRangeDetails,
  getOrderRangeReportCsv,
  createBill,
  changePaymentStatusPopup,
  payBillPopup,
  payBill,
  payMultipleBillsPopup,
  paySelectedBills
}
