import React from 'react'
import { Grid, TextField } from '@material-ui/core';

const ClientName = (params: any) => {

  const { clientName } = params

  return (
    <Grid item xs={12} md={12}>
      <TextField
        disabled={true}
        label='Client'
        variant='outlined'
        size='small'
        value={clientName}/>
    </Grid>
  )
}

export { ClientName }