import React, { useState } from 'react';
import { useRecoilCallback } from 'recoil'
import { Box, List, ListItem, Grid, Typography, Button, CardMedia } from '@material-ui/core';

import { Toast } from '../../../Components/Toasts';
import { GeneralModal } from './../../Modal/index';
import { Table } from '../../Table';

import { sendSignerConfirmation, viewSignerConfirmation } from '../../../Recoil/Selectors/Signer';
import { mergeDocumentsMailToSigner } from '../../../Recoil/Selectors/Orders';

import { useStyles } from '../Styles/FeeAndInstructions';
import moment from 'moment';

const SignerInfo = (props) => {
  const classes = useStyles();
  const { values, isViewOnlyMode } = props;

  const columns = [{
    field: 'Confirmation Sent At',
    headerName: 'Confirmation Sent At',
    renderCell: (data) => (
      <Typography>{moment(data?.row?.dateTime).format("MM/DD/YYYY HH:mm A")}</Typography>
    )
  }, {
    field: 'Sent By',
    headerName: 'Sent By',
    renderCell: (data) => (
      <Typography>{data?.row?.employee?.firstName} {data?.row?.employee?.lastName}</Typography>
    )
  }];

  const [submitting, setSubmitting] = useState(false)
  const [toast, setToast] = useState({ message: '', type: 'success', open: false });
  const [file, setFile] = useState({ open: false, fileURL: undefined })
  const [confirmationListModal, setConfirmationListModal] = useState(false);

  const sendConfirmation = useRecoilCallback(({ snapshot }) => async () => {
    setSubmitting(true)
    const { result, success } =
      await snapshot.getPromise(sendSignerConfirmation({
        Id: values.Id, f_signer_id: values?.signer?.signer_id, diff: new Date()
      }));

    if (success) {
      setToast({ message: result.data || 'Signer Confirmation Sent', type: 'success', open: true });
    } else {
      setToast({ message: 'Failed to send confirmation', type: 'error', open: true });
    }
    setSubmitting(false)
  });

  const viewConfirmation = useRecoilCallback(({ snapshot }) => async () => {
    setSubmitting(true)
    const { result, success } = await snapshot.getPromise(viewSignerConfirmation({ Id: values.Id, diff: new Date() }));

    if (success) {
      const fileURL = URL.createObjectURL(result);
      setFile({ open: true, fileURL })
    } else {
      setToast({ message: 'Failed to fetch confirmation', type: 'error', open: true });
    }
    setSubmitting(false)
  });



  const sendDocsReadyMail = useRecoilCallback(({ snapshot }) => async () => {
    setSubmitting(true)
    const { signer: signerDetails = {} } = values
    const { result, success } = await snapshot.getPromise(mergeDocumentsMailToSigner({
      to: signerDetails?.Signer_Email,
      signer_name: `${signerDetails?.Signer_First} ${signerDetails?.Signer_Last}`,
      f_loan_number: values?.w_Loan_Number,
      f_borrower_first_name: values?.w_Borrower_First_Name,
      f_borrower_last_name: values?.w_Borrower_Last_Name,
      f_appointment_date: values.f_appointment_date,
      f_appointment_time: moment(values.w_Appointment_Time).format('hh:mm A'),
      signing_address: `${values.w_Signing_Street_Address}, ${values.w_Signing_City}, ${values.w_Signing_State}, ${values.w_Signing_Zip}`,
      w_Signing_Phone_Number: values.w_Signing_Phone_Number
    }));
    if (success) {
      setToast({ message: result.data || 'Request sent successfully', type: 'success', open: true });
    } else {
      setToast({ message: 'Failed to send request', type: 'error', open: true });
    }
    setSubmitting(false)
  });

  const toggleConfirmationListModal = () => {
    setConfirmationListModal(!confirmationListModal);
  };

  return (
    <>
      <Box className={classes.root}>
        <List component='nav'>
          <ListItem button>
            <Grid item xs={6}>
              <Typography variant='body2'>Name</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body2'>{values?.signer?.Signer_First} {values?.signer?.Signer_Last}</Typography>
            </Grid>
          </ListItem>
          {
            values?.signer?.Signer_Account_Type === 'COMPANY' ?
              <ListItem button>
                <Grid item xs={6}>
                  <Typography variant='body2'>Sub User</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant='body2'>
                    {
                      values?.Sub_Signer_Id ?
                        `${values?.sub_signer?.Signer_First} ${values?.sub_signer?.Signer_Last}`
                      : 'N/A'
                    }
                  </Typography>
                </Grid>
              </ListItem>
              : ''
          }
          <ListItem button>
            <Grid item xs={6}>
              <Typography variant='body2'>Email</Typography>
            </Grid>
            <Grid item xs={6}>
              <a className={classes.removeTextDecoration} href={`mailto:${values?.signer?.Signer_Email}?subject=Loan # ${values.w_Loan_Number} - ${values.w_Borrower_First_Name} ${values.w_Borrower_Last_Name} - ${values.w_Appointment_Date} ${moment(values.w_Appointment_Time).format('hh:mm A')}`} >
                <Typography variant='body2'>{values?.signer?.Signer_Email}</Typography>
              </a>
            </Grid>
          </ListItem>
          <ListItem button>
            <Grid item xs={6}>
              <Typography variant='body2'>Mobile</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body2'>{values?.signer?.mobile_phone}</Typography>
            </Grid>
          </ListItem>
          <ListItem button>
            <Grid item xs={6}>
              <Typography variant='body2'>Address</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body2'>{values?.signer?.address}</Typography>
            </Grid>
          </ListItem>
          <ListItem button>
            <Grid item xs={6}>
              <Typography variant='body2'>City</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body2'>{values?.signer?.city}</Typography>
            </Grid>
          </ListItem>
          <ListItem button>
            <Grid item xs={6}>
              <Typography variant='body2'>State</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body2'>{values?.signer?.state}</Typography>
            </Grid>
          </ListItem>
          <ListItem button>
            <Grid item xs={6}>
              <Typography variant='body2'>Zip</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body2'>{values?.signer?.zip}</Typography>
            </Grid>
          </ListItem>
          <ListItem button>
            <Grid item xs={6}>
              <Typography variant='body2'>Credentials Complete</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body2'>{values?.signer?.cred_complete}</Typography>
            </Grid>
          </ListItem>
        </List>
      </Box>
      <Grid item xs={9}>
      {values.signerConfirmationList && values.signerConfirmationList.length ?
        values.signerConfirmationList.slice(0,3).map(confirmation=>  <Typography
          variant='body2'
        >Confirmed @
       {moment(confirmation?.dateTime).format("MM/DD/YYYY HH:mm A")}{" By "}
       {confirmation?.employee?.firstName} {confirmation?.employee?.lastName}
          </Typography>)
          :<Typography
          variant='body2'
        >No</Typography>}

        {values.signerConfirmationList && values.signerConfirmationList.length && values.signerConfirmationList.length > 4 ?
          <Button
          color='primary'
          onClick={toggleConfirmationListModal}
          variant='outlined'
        >
          Show More
        </Button>:""}

      </Grid>
      <Grid item xs={12}>
        <Button
          disabled={Boolean(submitting || !values?.signer)}
          color='primary'
          onClick={sendConfirmation}
          variant='outlined'
        >
          Send signer Confirmation
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          disabled={Boolean(submitting || !values?.signer)}
          color='primary'
          onClick={viewConfirmation}
          variant='outlined'
        >
          View Signer Confirmation
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          onClick={sendDocsReadyMail}
          disabled={Boolean(isViewOnlyMode || submitting || !values?.signer)}
          color='primary'
          variant='outlined'
        >
          Send Docs Ready Email
        </Button>
      </Grid>
      <Toast message={toast.message} type={toast.type} open={toast.open} setToast={setToast} />


      <GeneralModal
          open={confirmationListModal}
          title='Client Confirmations'
          loading={false}
          openModal={setConfirmationListModal}
          noSubmit={false}
          noFooter={true}
          maxWidth='sm'
        >
          <Table
            columns={columns}
            rows={values.signerConfirmationList}
          />

        </GeneralModal>

      <GeneralModal
        title='Confirmation'
        submitLabel='OK'
        noSubmit
        openModal={() => setFile({ open: false, fileURL: undefined })}
        open={file.open}
        maxWidth='xl'
      >
        <CardMedia
          component='iframe'
          src={file.fileURL}
          title='Confirmation'
          className={classes.windowHeight}
        />
      </GeneralModal>
    </>
  );
}

export { SignerInfo };
