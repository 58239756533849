import React from 'react';
import { Grid, TextField } from '@material-ui/core';

const RoleName = ({ values, errors, touched, handleChange, handleBlur }) => {
  return (
    <Grid container xs={12}>
      <TextField
        label='Role Name'
        name='name'
        variant='outlined'
        size='small'
        fullWidth
        value={values.name}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.name && errors.name}
        error={Boolean(touched.name && errors.name)}
      />
    </Grid>
  );
};

export { RoleName };
