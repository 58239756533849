import React from 'react';
import { Typography, Box, Divider } from '@material-ui/core';

import { Page } from '../../Components/Page';
import { PPNSignersData } from '../../Components/Signers/PPNSignersData';

import { useStyles } from '.././Styles/Page';

export const PPNSigners = () => {
  const classes = useStyles();

  return (
    <Page className={classes.paper} title='PPN Signers'>
      <Typography variant='h3'>PPN Signers</Typography>
      <Box my={2}>
        <Divider variant='fullWidth' />
      </Box>
      <PPNSignersData />
    </Page>
  );
}
