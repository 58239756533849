import React from 'react';
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import { Grid, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@material-ui/core';

import { getAllClients } from './../../../Recoil/Selectors/Clients'
import { selectedClient } from './../../../Recoil/Atoms/Clients'
import { selectedDivision } from './../../../Recoil/Atoms/Divisions'

import { useStyles } from '../Styles/Fields';

const ClientSelection = ({ isViewOnlyMode, location, handleChange, touched, errors, setFieldValue, values }) => {
  const classes = useStyles();

  const clientsRequest: any = useRecoilValueLoadable(getAllClients);
  const clients = clientsRequest?.contents?.result || []
  const setClient = useSetRecoilState(selectedClient)
  const setDivision = useSetRecoilState(selectedDivision)
  const hasError = Boolean(touched[location] && errors[location]) ? errors[location].clientID : ''
  return (
    <Grid item xs={12} md={6}>
      <FormControl className={classes.removeSelectMargin} margin='dense' variant='outlined' error={hasError} fullWidth>
        <InputLabel>Clients</InputLabel>
        <Select
          disabled={isViewOnlyMode}
          fullWidth
          defaultValue=''
          label='Clients'
          error={Boolean((touched[location] && errors[location]) && errors[location].clientID)}
          name={`${location}.clientID`}
          value={values.clientID}
          onChange={(e) => {
            handleChange(e)
            setClient(e.target.value)
            setDivision(-1)
            setFieldValue(`${location}.division_id`, '')
            setFieldValue(`${location}.Contact_ID`, '')
          }}
        >
          {
            clients.length > 0 ?
              clients.map((client: any) => {
                return (
                  <MenuItem key={client.clientID} value={client.clientID}>{client.clientName}</MenuItem>
                )
              }) :
              <MenuItem value=''></MenuItem>
          }
        </Select>
        {hasError &&
          <FormHelperText>{hasError}</FormHelperText>}
      </FormControl>
    </Grid>
  );
}

export { ClientSelection };
