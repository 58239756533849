import React, { useState, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import {
  Box,
  Tooltip,
  Typography,
  Button,
  TextField,
} from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import { useNavigate } from 'react-router-dom';

import { Table } from '../Table';
import { GeneralModal } from './../Modal'
import { SignerHistory } from './../Signers/SignerHistory'

import { refetchNearBySigners } from './../../Recoil/Atoms/Orders'
import { selectedClient } from 'src/Recoil/Atoms/Clients';
import { selectedDivision } from 'src/Recoil/Atoms/Divisions';
import { completedSigningsId, signerHistoryPagination } from 'src/Recoil/Atoms/Signers';
import { CallSigner } from './CallSigner'
import { useStyles } from './Styles/assignSigner'
import { orderChannel } from './../../Common/NotificationService'
// import { refetchNearBySigners } from '../../Recoil/Atoms/Orders';

const SignerList = (props) => {
  const classes = useStyles()
  const { rows, total, pageInfo, setPageInfo, loading, orderDetails } = props
  const { orderBy, orderType } = pageInfo
  const setHistory = useSetRecoilState(signerHistoryPagination)
  const completedSigning = useSetRecoilState(completedSigningsId)
  const refreshActivity = useSetRecoilState(refetchNearBySigners);
  const routeControl = useNavigate();

  const defaultValues = {
    w_Appointment_Date: '',
    w_Appointment_Time: '',
    Signer_First: '',
    Signer_Last: '',
    Signer_Email: '',
    signer_documents: [],
    Closing_Type: '',
    f_loan_number: '',
    signer_id: '',
    Id: '',
    latitude: '',
    longitude: '',
    distance: 0,
    f_signer_fees: '',
    signer_type: ''
  }

  const [selectedSigner, setSigner] = useState(defaultValues)
  const [isAssignModalOpen, OpenAssignModal] = useState(false)
  const [history, setSignerHistory] = useState(null)

  const setClient = useSetRecoilState(selectedClient)
  const setDivision = useSetRecoilState(selectedDivision)
  const [comment, setComment] = useState(null)
  const [redirect, setRedirect] = useState(undefined)

  useEffect(() => {
    setPageInfo({
      page: 1,
      limit: 20,
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!pageInfo?.cached && !loading)
      setPageInfo({ ...pageInfo, cached: true })
  }, [pageInfo, setPageInfo, loading])

  const onCall = (data) => {

    setClient(orderDetails.clientID)
    setDivision(orderDetails.division_id)
    setSigner({ ...orderDetails, ...data })
    OpenAssignModal(true)
  }

  const handlePage = (e: unknown, newPage: number) => {
    setPageInfo({
      ...pageInfo,
      page: newPage
    });
  };

  const handleRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPageInfo({
      ...pageInfo,
      limit: parseInt(e.target.value, 10)
    });
  };

  const handleSort = (e: any) => {
    if (e.sortModel && e.sortModel[0].field) {
      const { field, sort } = e.sortModel[0]

      setPageInfo({
        ...pageInfo,
        orderBy: field,
        orderType: sort,
      });
    }
  };

  const getRowBackGroundColor = (row, colors) => {
    let color = ''
    switch (row?.result) {
      case 'NA':
        color = 'madang'
        break
      case 'NAV':
        color = 'yellow'
        break
      case 'TE':
        color = 'red'
        break
      case 'OTH':
        color = 'riptide'
        break
      default:
        color = ''
        break
    }
    return color
  }

  useEffect(() => {
    if (orderDetails.Id) {
      orderChannel.bind(`assigned-${orderDetails?.Id}`, data => {
        if (data && !redirect) {
          setRedirect({ message: 'Order Has been Assigned' })
        }
      });
    }
  }, [orderDetails, redirect]);

  useEffect(() => {
    if (orderDetails.Id) {
      orderChannel.bind(`update-activity-${orderDetails?.Id}`, data => {
        if (data) {
          refreshActivity(n => n + 1)
        }
      });
    }
  }, [orderDetails, refreshActivity]);

  useEffect(() => {
    if (orderDetails.Id) {
      orderChannel.bind(`Cancelled-${orderDetails?.Id}`, data => {
        if (data && !redirect) {
          setRedirect({ message: 'Order Has been Cancelled' })
        }
      });
    }
  }, [orderDetails, redirect]);

  useEffect(() => {
    if (redirect) {
      setTimeout(() => { routeControl(`/orders/unassigned`) }, 3000);
    }
  }, [redirect, routeControl]);

  const actionsFormatter = params => {
    return (
      <Box display='flex' flexDirection='column'>
        <Box display='flex'>
          <Tooltip title='Call'>
            <Typography
              color='primary'
              className={classes.cursorPointer}
              onClick={e => onCall(params?.row)}
            >
              Call
            </Typography>
          </Tooltip>
        </Box>
        {params?.row?.activity?.comment && <Box>
          <Tooltip title='Comment'>
            <Typography
              color='primary'
              className={classes.cursorPointer}
              onClick={e => setComment(params?.row?.activity?.comment)}
            >
              Comment
            </Typography>
          </Tooltip>
        </Box>
        }
      </Box>
    );
  }
  const columns = [{
    field: 'Signer_First',
    headerName: 'Signer Name',
    disableColumnMenu: true,
    flex: '0.5',
    valueGetter: (a => `${a.row.Signer_First || ''} ${a.row.Signer_Last || ''}`)
  }, {
    field: 'signing',
    headerName: '#Signing',
    disableColumnMenu: true,
    sortable: false,
    renderCell: ({ row }) => <Button onClick={
      () => {
        setHistory({
          signer_id: row?.signer_id,
          limit: 20,
          page: 1,
          count: 0,
          orderBy: '',
          orderType: '',
        })
        completedSigning(row?.signer_id)
        setSignerHistory(row?.signer_id)
      }
    }>{row?.signing}</Button>
  },
  {
    field: 'errors',
    headerName: '#Errors',
    disableColumnMenu: true,
    sortable: false
  }, {
    field: 'signer_type',
    headerName: 'Type',
    disableColumnMenu: true
  }, {
    field: 'requested_fee',
    headerName: 'Requested Fee',
    disableColumnMenu: true
  },
  {
    field: 'distance',
    headerName: 'Distance',
    disableColumnMenu: true,
    valueGetter: ((a) => `${Number(a.row.distance).toFixed(2)} mi`),
    width: 120
  }, {
    field: 'operation_notes',
    headerName: 'Note',
    disableColumnMenu: true,
    renderCell: ({ row }) =>
      <Tooltip title={<Typography variant='body2'>{row?.operation_notes}</Typography>}><p>{row?.operation_notes}</p></Tooltip>
  }, {
    field: 'missingCredentials',
    headerName: 'Missing Credentials',
    disableColumnMenu: true,
    sortable: false
  }, {
    field: 'action',
    headerName: 'Action',
    sortable: false,
    disableColumnMenu: true,
    renderCell: actionsFormatter,
    width: 200
  }];

  return (
    <SnackbarProvider>
      <>
        <CallSigner
          selectedSigner={selectedSigner}
          isAssignModalOpen={isAssignModalOpen}
          OpenAssignModal={OpenAssignModal}
          orderDetails={orderDetails}
          setSigner={setSigner}
          defaultValues={defaultValues}
        />
        <Table
          useCustomStyles={false}
          getRowBackGroundColor={getRowBackGroundColor}
          loading={loading}
          columns={columns}
          rows={rows}
          total={total}
          sortModel={
            [{ field: orderBy, sort: orderType }]
          }
          uniqueKey='signer_id'
          pageInfo={pageInfo}
          handlePage={handlePage}
          handleRowsPerPage={handleRowsPerPage}
          onSortingChange={handleSort}
        />
        <GeneralModal
          open={comment !== null}
          openModal={() => setComment(null)}
          title='Comment'
          cancelTitle='Close'
          noSubmit
        >
          <TextField
            multiline
            rows={3}
            size='small'
            variant='outlined'
            fullWidth
            value={comment}
            disabled
          >
          </TextField>
        </GeneralModal>

        <GeneralModal
          title='Signer History'
          open={history}
          openModal={e => setSignerHistory(false)}
          noSubmit
        >
          <SignerHistory id={history} />
        </GeneralModal>
        <GeneralModal
          title='Order Status'
          open={redirect}
          openModal={e => routeControl(`/orders/unassigned`)}
          cancelTitle='OK'
          noSubmit
        >
          <Typography
            color='primary'
          >
            {redirect?.message}
          </Typography>
        </GeneralModal>

      </>
    </SnackbarProvider>

  );
};
const SignersData = (SignerList)
export { SignersData };
