import React from 'react'
import { Typography, Grid, Card, CardContent, CircularProgress, SvgIcon, Tooltip, Box } from '@material-ui/core';
import { UploadCloud } from 'react-feather';
import { useDropzone } from 'react-dropzone';

import { useStyles } from './Style/Style';
import { displayStyles } from '../../Common/Styles/Display';

const Filedropzone = ({ setSelectedFiles, selectedFiles, uploadRequest, Id, disable }) => {
  const classes = useStyles();
  const displayClasses = displayStyles();

  const onDrop = async (acceptedFiles) => {
    if (disable)
      return
    for await ( let [index, file] of acceptedFiles.entries() ) {
      setSelectedFiles(acceptedFiles);
      await uploadRequest(file);
      acceptedFiles[index].finished = true
      setSelectedFiles(acceptedFiles);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <Grid
      className={classes.fileDropzoneWrapper}
      direction='column'
      container
      {...getRootProps()}
    >
      <input disabled={disable} className={classes.fileDropInput} {...getInputProps()} />
      { isDragActive ?
        <Typography align='center'>Drop the file here ...</Typography> :
        <>
          <SvgIcon fontSize='large' color='primary'>
            <UploadCloud />
          </SvgIcon>
          <Typography color={ disable ? 'error' : 'initial' } variant='h5'>Browse Computer</Typography>
          <Typography color={ disable ? 'error' : 'initial' } variant='h4'>
            { disable ? 'Please Select Uploaded By' : `Drag 'n' drop a file here, or click to select file` }
          </Typography>
        </>
      }
      <div className={classes.selectedFilesWrapper}>
        {
          selectedFiles.map((file) => {
            return (
              <Card className={classes.fileCard}>
                <CardContent className={`${classes.cardContent} ${displayClasses.flexRowCC}`}>
                  {
                    !file.finished 
                    ?
                    <Box padding={5}>
                      <CircularProgress />
                    </Box>
                    :
                    <Tooltip title={file.hasError || file.path}>
                      <Typography variant='body2' color={file.hasError ? 'error' : undefined}>{file.path}</Typography>
                    </Tooltip>
                  }
                </CardContent>
              </Card>
            )
          })
        }
      </div>
    </Grid>
  );
}

export { Filedropzone };
