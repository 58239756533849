import React from 'react'
import { List, ListItem, Grid, DialogContentText } from '@material-ui/core'
import { GeneralModal } from '../Modal'

export function SignerInfo({ handleSignerInfoModal, signerInfoModal = {
  Signer_First: '', Signer_Last: '', Signer_Email: '' , reassign_reason: ''
} }) {

  const signerFields = [
    { name: 'w_Loan_Number', label: 'Loan #' },
    { name: 'signer_id', label: 'Signer ID' },
    {
      name: 'Signer_First',
      label: 'Signer Name',
      render: <> {signerInfoModal?.Signer_First} {signerInfoModal?.Signer_Last}</>
    },
    { name: 'address', label: 'Signer Address' },
    { name: 'city', label: 'Signer City' },
    { name: 'state', label: 'Signer State' },
    { name: 'zip', label: 'Signer Zip' },
    { name: 'mobile_phone', label: 'Signer Mobile Phone' },
    {
      name: 'Signer_Email',
      label: 'Signer Email',
      render: <><a href={`mailto:${signerInfoModal?.Signer_Email}`}>{signerInfoModal?.Signer_Email}</a></>
    },
    { name: 'send_receive_sms', label: 'Registered SMS' },
    signerInfoModal?.reassign_reason ? { name:'reassign_reason' , label: 'Reassign Reason'} : {name:null,label:null},
  ]
  return (
    <GeneralModal
      title='Signer Info'
      open={signerInfoModal}
      openModal={e => handleSignerInfoModal(null)}
      noSubmit
    >
      <List>
        {signerFields.map(f => (
          <ListItem>
            <Grid container>
              <Grid item xs={6}>
                <DialogContentText>
                  <b>{f?.label}</b>
                </DialogContentText>
              </Grid><Grid item xs={6}>
                <DialogContentText>
                  {f?.render || signerInfoModal?.[f?.name]}
                </DialogContentText>
              </Grid>
            </Grid>
          </ListItem>))}
      </List>
    </GeneralModal>
  )
}
