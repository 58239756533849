import React, { useEffect } from 'react';
import type { FC, ReactNode } from 'react';

import { useLocation, matchPath, Link as RouterLink, useNavigate } from 'react-router-dom';
import { Box, Drawer, List, makeStyles, useMediaQuery } from '@material-ui/core';
import { Home, User, Clipboard, PenTool, File, BookOpen } from 'react-feather';
import { useTheme } from '@material-ui/core/styles';
import { storage } from '../../Services/storage';
import NavItem from './NavItem';
import { RestrictedRoutes } from './../../Common/RestrictedRoutes'

import { getRole } from './../../Recoil/Selectors/Auth'

import Logo from 'src/ThemeComponents/components/Logo';

interface NavBarProps {
  onMobileClose: (close) => void;
  openMobile: boolean;
}

interface Item {
  href?: string;
  icon?: any;
  info?: ReactNode;
  items?: Item[];
  title: string;
}

interface Section {
  items: Item[];
  subheader: string;
}

interface NavItems {
  items: Item[];
  pathname: string;
  depth?: number;
}

interface ChildRoutes {
  acc: any[];
  pathname: string;
  item: Item;
  depth: number;
}

const renderNavItems = ({ items, pathname, depth = 0 }: NavItems) => {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

const reduceChildRoutes = ({ acc, pathname, item, depth }: ChildRoutes) => {
  const key = item.title + depth;

  if (item.items) {
    const path = item.items.find(i => i.href === pathname);
    const open = matchPath(path ? path?.href : item?.href, pathname);
    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open) && pathname !== '/dashboard'}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 200
  },
  desktopDrawer: {
    width: 200,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  const role = storage.get('userRole') || 'unknown'
  const theme = useTheme();
  useEffect(() => {

    RestrictedRoutes.forEach(route => {
      if (window.location?.pathname?.includes(route)) {
        getRole()
      }
    })

    if (role.toLowerCase() !== 'admin' && window.location?.pathname?.includes('admin')) {
      return navigate(`/`)
    }
    if (role.toLowerCase() !== 'accountant' && window.location?.pathname?.includes('accounting')) {
      return navigate(`/`)
    }
    if (role.toLowerCase() !== 'manager' && window.location?.pathname?.includes('client/contacts/master-login')) {
      return navigate(`/`)
    }
    // eslint-disable-next-line
  }, [location])

  const sections: Section[] = [
    {
      subheader: 'Management',
      items: [
        ...(role.toLowerCase() === 'admin' ? [
          {
            title: 'Admin',
            icon: Home,
            href: '/admin/settings',
            items: [{
              title: 'Settings',
              href: '/admin/settings'
            }, {
              title: 'Access Log',
              href: '/admin/access-logs'
            }, {
              title: 'Audit Trail',
              href: '/admin/audit-trail'
            },
            {
              title: "Document Activity",
              href: '/admin/document-activity'
            }, {
              title: 'Users',
              href: '/admin/users'
            },
            {
              title: 'Role',
              href: '/admin/role'
            },
            {
              title: 'Access Group',
              href: '/admin/access-group'
            }]
          }
        ] : []),
        {
          title: 'Client',
          icon: User,
          href: '/client/clients',
          items: [
            {
              title: 'Clients',
              href: '/client/clients'
            }, {
              title: 'Contacts',
              href: '/client/contacts'
            },
            ...(role.toLowerCase() === 'manager' ? [{
              title: 'Contact Login',
              href: '/client/contacts/master-login'
            }] : [])
          ]
        }, {
          title: 'Orders',
          icon: Clipboard,
          href: '/orders',
          items: [{
            title: 'All Orders',
            href: '/orders'
          }, {
            title: 'New Order',
            href: '/orders/new'
          }, {
            title: 'Unassigned Orders',
            href: '/orders/unassigned'
          }, {
            title: 'Monitoring Orders',
            href: '/orders-monitoring'
          }]
        }, {
          title: 'Signer',
          icon: PenTool,
          href: '/signers',
          items: [{
            title: 'All Signers',
            href: '/signers'
          }, {
            title: 'PPN Signers',
            href: '/ppn-signers'
          }, {
            title: 'Signer Communications',
            href: '/signers/communications'
          }, {
            title: 'Signer Login',
            href: '/signer-login'
          }]
        }, {
          title: 'Reports',
          icon: File,
          href: '/reports/volume',
          items: [{
            title: 'Volume Report',
            href: '/reports/volume'
          }, {
            title: 'Range Report',
            href: '/reports/range'
          }, {
            title: 'Nightly Report Email',
            href: '/reports/nightly'
          }, {
            title: 'Matchers Report',
            href: '/reports/matchers'
          }, {
            title: 'Processors Report',
            href: '/reports/processors-report'
          },
          ]
        },
        ...(role.toLowerCase() === 'accountant' ? [
          {
            title: 'Accounting',
            icon: BookOpen,
            href: '/accounting/order-range-details',
            items: [
              {
                title: 'Order Range Details',
                href: '/accounting/order-range-details',
              }, {
                title: 'Client Invoices',
                href: '/accounting/client-invoices'
              }, {
                title: 'Unbilled Invoices',
                href: '/accounting/unbilled-invoices'
              },
              {
                title: 'Pending Vendor Invoices',
                href: '/accounting/pending-vendor-invoices'
              },
              {
                title: 'Unbilled Orders',
                href: '/accounting/unbilled-orders'
              }, {
                title: 'Daily Vendor Bill',
                href: '/accounting/daily-vendor-bill'
              }, {
                title: 'Daily Client Invoices',
                href: '/accounting/client-invoices'
              }, {
                title: 'Daily Pay Bill',
                href: '/accounting/daily-pay-bill'
              }
            ]
          }
        ] : [])
      ],
    }];
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const content = (
    <Box height='100%' display='flex' flexDirection='column'>
      <>
        {
          isLgUp && <Box display='flex' justifyContent='center'>
            <RouterLink to='/'>
              <Logo height='100px' link='/static/Logo.png' />
            </RouterLink>
          </Box>
        }
      </>
      <Box p={2}>
        {sections.map((section) => (
          <List key={section.subheader}>
            {renderNavItems({
              items: section.items,
              pathname: location?.pathname
            })}
          </List>
        ))}
      </Box>
    </Box>
  );
  return (
    <>
      {isLgUp ? (
        <Drawer
          anchor='left'
          classes={{ paper: classes.desktopDrawer }}
          open
          variant='persistent'
        >
          {content}
        </Drawer>
      ) : (
        <Drawer
          anchor='left'
          classes={{ paper: classes.mobileDrawer }}
          onClose={() => onMobileClose(false)}
          open={openMobile}
          variant='temporary'
        >
          {content}
        </Drawer>
      )}
    </>
  );
};

export default NavBar;
