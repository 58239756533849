import React from 'react';
import { Box, Typography } from '@material-ui/core';

import { BarChart } from '../Charts/Bar';

import { displayStyles } from '../../Common/Styles/Display';
import { marginStyles } from '../../Common/Styles/Margins';

const SignerFeeRatio = () => {
  const displayClasses = displayStyles();
  const marginClasses = marginStyles();

  const data = {
    labels: ['Jan', 'Feb', 'Mar', 'April', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: {
      data1: [65, 59, 80, 81, 56, 55, 40],
      data2: [31, 55, 56, 81, 80, 59, 72]
    }
  };

  return (
    <Box className={displayClasses.themeBG} p={2}>
      <Typography className={marginClasses.mb2} variant='h5'>SIGNER FEE RATIO</Typography>
      <BarChart data={data.datasets} labels={data.labels} />
    </Box>
  );
}

export { SignerFeeRatio };
