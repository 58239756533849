import { selector } from 'recoil';

import {
  clientInvoicesPagination,
  refetchClientInvoices,
} from '../Atoms/ClientInvoices';
import { verifyAuthToken } from './Auth'
import { api } from '../../Services/api';

const getAllClientInvoices = selector({
  key: 'getAllClientInvoices',
  get: async ({ get }) => {
    get(refetchClientInvoices)

    const params = get(clientInvoicesPagination);
    try {
      await verifyAuthToken()
      const res: any = await api.GET('orders/clientInvoices/', {
        params: {
          ...params, orderBy: params.orderBy || 'w_sql_appointment_date',
          orderType: params.orderType || 'DESC',
        }
      });
      res.forEach(r => {
        r.id = r.Id
      })

      return {
        result: res,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

export {
  getAllClientInvoices
};
