import React from 'react';
import { Grid, TextField } from '@material-ui/core';

const NSANumber = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  disabled
}) => {
  return (
    <Grid item xs={12} sm={12} md={6} lg={6}>
      <TextField
        label='NSA Number'
        name='nsa_member_number'
        variant='outlined'
        size='small'
        fullWidth
        value={values.nsa_member_number}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.nsa_member_number && errors.nsa_member_number}
        error={Boolean(touched.nsa_member_number && errors.nsa_member_number)}
        disabled={disabled}
      />
    </Grid>
  );
};

export { NSANumber };
