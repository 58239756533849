import { atom } from 'recoil';

const unassignedCount = atom({
  key: 'unassignedCount',
  default: 0
});

const appointmentCount = atom({
  key: 'appointmentCount',
  default: 0
});

const mtdOrderCount = atom({
  key: 'mtdOrderCount',
  default: 0
});

const currentOrderCount = atom({
  key: 'currentOrderCount',
  default: 0
});

const goal = atom({
  key: 'goal',
  default: 0
});

const matchedOrders = atom({
  key: 'matchedOrders',
  default: 0
});

const forceUpdateGoal = atom({
  key: 'forceUpdateGoal',
  default: 0
});


export { unassignedCount, appointmentCount, mtdOrderCount, currentOrderCount, goal, matchedOrders, forceUpdateGoal };
