import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Box, Divider } from '@material-ui/core';

import { useRecoilValueLoadable, useSetRecoilState, useRecoilState } from 'recoil';

import { getClientContacts, getContact } from './../../Recoil/Selectors/Contacts';
import { Toast } from '../../Components/Toasts';
import { NewClientContact } from './NewContact';
import { Page } from '../../Components/Page';
import { GeneralModal } from '../../Components/Modal';
import { ContactActions } from '../../Components/Client/ContactActions';
import { ContactsData } from '../../Components/Client/ContactsData';
import { PageLoad } from '../../Components/LoadingScreen/PageLoad';

import { useStyles } from '.././Styles/Page';
import { loadContact, selectedClient, refreshClientContacts, clientContactpagination } from 'src/Recoil/Atoms/Contacts';
import { getClientNames } from 'src/Recoil/Selectors/Clients';

export const ClientContacts = () => {
  const classes = useStyles();
  const params: any = useParams();
  const [modal, handleModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const callRefresh = useSetRecoilState(refreshClientContacts)
  const refresh = () => callRefresh(v => v++)
  const [pageInfo, setPageInfo] = useRecoilState(clientContactpagination);
  const [reloadOrders, setReloadOrders] = useState(1);

  const [toast, setToast] = useState({
    message: '',
    type: 'success',
    open: false
  });
  const clientNames: any = useRecoilValueLoadable(getClientNames);
  const clients = clientNames?.contents?.result || [];

  const contacts: any = useRecoilValueLoadable(getClientContacts);
  const rows = contacts?.contents?.result?.rows || [];
  const count = contacts?.contents?.result?.count || 0;

  const contactData: any = useRecoilValueLoadable(getContact(reloadOrders));
  const contact = contactData?.contents?.result || { Company_ID: params.id };

  const selectContact = useSetRecoilState(loadContact);
  const [cilent, selectClient] = useRecoilState(selectedClient);

  const openModal = (open) => {
    selectContact(-1);
    handleModal(open);
    setIsEditing(false)
  }

  useEffect(() => {
    if (cilent !== params.id) { selectClient(params.id) }
    // eslint-disable-next-line
  }, [])

  const handleEdit = (e) => {
    setReloadOrders(reloadOrders + 1)
    selectContact(e.Id);
    handleModal(true);
    setIsEditing(true)
  }
  return (
    <Page className={classes.paper} title='Contacts'>
      <Typography variant='h3'>Contacts</Typography>
      <Box my={2}>
        <Divider variant='fullWidth' />
      </Box>
      <ContactActions handleModal={handleModal}
        setPageInfo={setPageInfo}
        pageInfo={pageInfo}
      />
      <ContactsData setPageInfo={setPageInfo}
        pageInfo={pageInfo} total={count}
        loading={contacts.state === 'loading'}
        rows={rows} handleEdit={handleEdit}
      />
      <GeneralModal
        open={modal}
        title={isEditing ? 'Edit Contact' : 'New Contact'}
        loading={false}
        openModal={openModal}
        noSubmit={true}
      >
        {contactData.state === 'loading' ?
          <PageLoad /> :
          <NewClientContact
            isEditing={isEditing}
            refresh={refresh}
            contact={contact}
            clients={clients}
            setToast={setToast}
            openModal={openModal}
          />
        }

      </GeneralModal>
      <Toast message={toast.message} type={toast.type} open={toast.open} setToast={setToast} />
    </Page>
  );
}
