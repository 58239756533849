import React, { Fragment } from 'react';
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
  SvgIcon
} from '@material-ui/core';
import { FieldArray } from 'formik';
import { Plus, XCircle } from 'react-feather';

import { states } from '../../../Common/States';

const CommissioningStates = ({
  values,
  errors,
  setFieldValue,
  handleChange,
  disabled
}) => {

  const selectedValues = Array.isArray(values) ? values : []

  return (
    <>
      <FieldArray
        name='commisioning_states'
        render={helper =>
          selectedValues.map((val, i) => (
            <Fragment key={`commisioning_states-${i}`}>
              <Grid item xs={11}>
                <FormControl
                  variant='outlined'
                  size='small'
                  fullWidth
                  error={errors.commisioning_states}
                  disabled={disabled}
                >
                  <InputLabel>Commisioning State</InputLabel>
                  <Select
                    name={`commisioning_states[${i}]`}
                    label='Commisioning State'
                    fullWidth
                    value={val}
                    onChange={handleChange}
                  >
                    {states.map(state => {
                      return (
                        <MenuItem
                          key={`commisioning_states-${i}-${state.code}`}
                          value={state.code}
                        >
                          {state.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={1}>
                <SvgIcon
                  fontSize='small'
                  color='primary'
                  onClick={() => helper.remove(i)}
                >
                  <XCircle />
                </SvgIcon>
              </Grid>
            </Fragment>
          ))
        }
      />
      <Grid item xs={12}>
        <Button
          color='primary'
          variant='outlined'
          onClick={() =>
            setFieldValue(`commisioning_states[${selectedValues.length}]`, 'AL')
          }
        >
          <SvgIcon fontSize='small'>
            <Plus />
          </SvgIcon>{' '}
          Add Commissioning States
        </Button>
      </Grid>
    </>
  );
};

export { CommissioningStates };
