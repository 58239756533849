import React from 'react';
import { Grid, TextField } from '@material-ui/core';

const ContactTeamManager = ({ values, errors, touched, handleChange, handleBlur }) => {
  return (
    <Grid item xs={12} md={6}>
      <TextField
        label='Team Manager'
        name='Contact_Team_Manager'
        variant='outlined'
        size='small'
        fullWidth
        value={values.Contact_Team_Manager}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.Contact_Team_Manager && errors.Contact_Team_Manager}
        error={Boolean(touched.Contact_Team_Manager && errors.Contact_Team_Manager)}
      />
    </Grid>
  );
}

export { ContactTeamManager };
