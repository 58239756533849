import React from 'react';
import { useRecoilState, useSetRecoilState, useRecoilCallback } from 'recoil';

import { FormikComponent } from '../../Components/Formik';
import { DivisionForm } from '../../Components/Divisions/DivisionForm';

import { userInfo } from '../../Recoil/Atoms/Login';
import { refreshDivisions } from '../../Recoil/Atoms/Divisions';
import { createDivision, updateDivision } from '../../Recoil/Selectors/Divisions';

import { initialValues, validationSchema } from '../../Common/Rules/DivisionForm';

export const NewDivision = (props) => {
  const { division, clientID, openModal, setToast } = props;
  const [user] = useRecoilState(userInfo);
  const callRefresh = useSetRecoilState(refreshDivisions);
  const refresh = () => callRefresh((n) => n + 1);

  const createPayload = (values) => {
    const params = {
      ...values,
      clientID,
      createdBy: user.id
    }
    params.fees = params.fees.map(fee => ({
      ...fee,
      createdBy: user.id
    }));
    params.contacts = params.contacts.map(contact => ({
      ...contact,
      createdBy: user.id
    }));
    // params.states=params.state.filter(s=>s.state_id)
    return params;
  }

  const updatePayload = (values) => {
    const params = {
      ...values,
      modifiedBy: user.id
    }
    params.fees = params.fees.map(fee => ({
      ...fee,
      modifiedBy: user.id
    }));
    params.contacts = params.contacts.map(contact => ({
      ...contact,
      modifiedBy: user.id
    }));
    return params;
  }

  const onSubmit = useRecoilCallback(({ snapshot }) => async (values: any, { setSubmitting }) => {
    let res: any = {};
    let message = '';
    if (values.divisionID) {
      const params = updatePayload(values);
      res = await snapshot.getPromise(updateDivision(params));

      if (res.success) {
        message = 'Division updated successfully!';
      }
    } else {
      const params = createPayload(values);
      res = await snapshot.getPromise(createDivision(params));

      if (res.success) {
        message = 'Division created successfully!';
      }
    }

    setSubmitting(false);
    if (res.success) {
      refresh();
      openModal(false);
      setToast({
        message,
        type: 'success',
        open: true
      });
    }
  });
  const isEditMode = division?.divisionID
  return (
    <FormikComponent
      onCancel={e => openModal(false)}
      cancelable
      onSubmit={onSubmit}
      initialValues={division.divisionID ? division : initialValues}
      validationSchema={validationSchema}
    >
      <DivisionForm {...props} isEditMode={isEditMode} />
    </FormikComponent>
  );
}
