import React from 'react';
import { useRecoilState } from 'recoil';
import { Box, Tooltip, IconButton, SvgIcon } from '@material-ui/core';
import { Edit } from 'react-feather';

import { pagination } from '../../Recoil/Atoms/Divisions';

import { Table } from '../Table';

const DivisionsData = (props) => {
  const [pageInfo, setPageInfo] = useRecoilState(pagination);

  const actionsFormatter = (e) => {
    return (
      <Box display='flex'>
        <Tooltip title='Edit'>
          <IconButton color='inherit' onClick={() => props.handleEdit(e.row.divisionID)}>
            <SvgIcon fontSize='small' color='primary'>
              <Edit />
            </SvgIcon>
          </IconButton>
        </Tooltip>
      </Box>
    );
  }

  const columns = [{
    field: 'divisionName',
    headerName: 'Name'
  }, {
    field: 'type',
    headerName: 'Type'
  }, {
    field: 'divisionStatus',
    headerName: 'Status'
  }, {
    field: 'actions',
    headerName: 'Actions',
    renderCell: actionsFormatter
  }];

  const handlePage = (e: unknown, newPage: number) => {
    setPageInfo({
      ...pageInfo,
      page: newPage
    });
  };

  const handleRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPageInfo({
      ...pageInfo,
      limit: parseInt(e.target.value, 10)
    });
  };

  return (
    <Table
      columns={columns}
      rows={props.data}
      total={props.total}
      pageInfo={pageInfo}
      handlePage={handlePage}
      handleRowsPerPage={handleRowsPerPage}
    />
  );
};

export { DivisionsData };
