import React from 'react';
import { Grid, TextField } from '@material-ui/core';

const UserEmail = ({ values, errors, touched, handleChange, handleBlur }) => {
  return (
    <Grid container xs={12}>
      <TextField
        label='Email'
        name='email'
        variant='outlined'
        size='small'
        fullWidth
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.email && errors.email}
        error={Boolean(touched.email && errors.email)}
      />
    </Grid>
  );
};

export { UserEmail };
