import { selectorFamily, selector } from 'recoil';
import moment from 'moment';

import { verifyAuthToken } from './Auth'
import { api, fileapi } from '../../Services/api';
import { encodeQueryData } from '../../Common/Utilities/helper';

import {
  refetchCommunication,
  searchSignerPagination,
  selectedSignerErrors,
  signerCommunicationPagination,
  refetchSigners,
  pagination,
  clpagination,
  signerHistoryPagination,
  refetchSigner,
  completedSigningsId,
  reloadCreds,
  vendorListPagination,
  refetchSignerOrder,
  refetchSignerCredHistory,
  fetchCallerProfile,
  refetchAssignSignerOrder,
  forceRemoveCallAlert,
  forceSignerLogin,
  fetchSignerDocument
} from '../Atoms/Signers';
import { assignSignerOrderId } from '../Atoms/Orders';

const getSigners = selector({
  key: 'getSigners',
  get: async ({ get }) => {
    get(refetchSigners);
    const filters = get(pagination);

    try {
      await verifyAuthToken()
      const res: any = await api.GET(
        `/signer/getSignerAll/${encodeQueryData(filters)}`
      );
      return {
        result: res,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      }
    }
  }
});

const getPreferedSigners = selector({
  key: 'getPreferedSigners',
  get: async ({ get }) => {
    get(refetchSigners);
    const filters = get(pagination);

    try {
      await verifyAuthToken()
      const res: any = await api.GET(
        `/signer/getPreferredSignerAll/${encodeQueryData(filters)}`
      );
      return {
        result: res,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      }
    }
  }
});

const getSignerById = selectorFamily({
  key: 'getSignerById',
  get: ({ signerid }: any) => async ({ get }) => {
    get(refetchSigner);
    if (signerid) {
      try {
        await verifyAuthToken()
        const res: any = await api.GET(`/signer/getById/${signerid}`);
        return {
          result: res,
          success: true
        };
      } catch (err) {
        return {
          result: err,
          success: false
        };
      }
    }
  }
});

const editSigner = selectorFamily({
  key: 'editSigner',
  get: ({ data }: any) => async () => {
    try {
      await verifyAuthToken()
      const res: any = await api.PUT('/signer/edit', data);
      return {
        result: res.data,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const saveSigner = selectorFamily({
  key: 'saveSigner',
  get: (data: any) => async () => {
    try {
      await verifyAuthToken()
      const res: any = await api.POST('/signer/new', data);
      return {
        result: res,
        success: true
      };
    } catch (err) {
      console.error(err);
      return {
        result: err,
        success: false
      };
    }
  }
});

const deleteSigner = selectorFamily({
  key: 'deleteSigner',
  get: ({ id }: any) => async () => {

    try {
      await verifyAuthToken()
      const res: any = await api.PUT('/signer/delete/' + id);
      return {
        result: res,
        success: true
      };
    } catch (err) {
      console.error(err);
      return {
        result: err,
        success: false
      };
    }
  }
});

const getCommunications = selector({
  key: 'getCommunications',
  get: async ({ get }) => {
    get(refetchCommunication);
    const filters = get(signerCommunicationPagination);
    try {
      await verifyAuthToken()
      const res: any = await api.GET(
        `/signer/communication/${encodeQueryData({ ...filters, start: moment(filters?.start).format('MM/DD/YYYY'), end: moment(filters?.end).format('MM/DD/YYYY') })}`
      );
      return {
        result: res,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const sendMessage = selectorFamily({
  key: 'sendMessage',
  get: (data: any) => async () => {
    try {
      await verifyAuthToken()
      const res: any = await api.POST('signer/send/message', data);
      return {
        result: res,
        success: true
      };
    } catch (err) {
      console.error(err);
      return {
        result: err,
        success: false
      };
    }
  }
});


const sendMessageToAllSigners = selectorFamily({
  key: 'sendMessageToAllSigners',
  get: (data: any) => async () => {
    try {
      await verifyAuthToken()
      const res: any = await api.POST('signer/send/message/all', data);
      return {
        result: res,
        success: true
      };
    } catch (err) {
      console.error(err);
      return {
        result: err,
        success: false
      };
    }
  }
});

const deactiveSigner = selectorFamily({
  key: 'deactiveSigner',
  get: ({ id }: any) => async () => {
    try {
      await verifyAuthToken()
      const res: any = await api.PUT('/signer/deactivate/' + id, id);
      return {
        result: res,
        success: true
      };
    } catch (err) {
      console.error(err);
      return {
        result: err,
        success: false
      };
    }
  }
});


const getClientsSigner = selector({
  key: 'getClientsSigner',
  get: async ({ get }) => {
    const { limit, page } = get(clpagination);
    try {
      await verifyAuthToken()
      const res: any = await api.GET(
        `client/getAllClients?limit=${limit}&page=${page}`
      );
      return {
        result: res,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const getSignerErros = selector({
  key: 'getSignerErros',
  get: async ({ get }) => {
    const { signer_id } = get(selectedSignerErrors);
    try {
      await verifyAuthToken()
      const res: any = await api.GET(
        `signer/errors/${signer_id}`
      );
      return {
        result: res,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const getSignerHistory = selector({
  key: 'getSignerHistory',
  get: async ({ get }) => {
    const { signer_id, ...params } = get(signerHistoryPagination);
    if (signer_id) {
      try {
        await verifyAuthToken()
        const res: any = await api.GET(
          `signer/history/${signer_id}`, { params }
        );
        return {
          result: res,
          success: true
        };
      } catch (err) {
        return {
          result: err,
          success: false
        };
      }
    }
  }
});

const getCompletedSigningsCount = selector({
  key: 'getCompletedSigningsCount',
  get: async ({ get }) => {
    const id = get(completedSigningsId);
    if (id) {
      try {
        await verifyAuthToken()
        const res: any = await api.GET(
          `orders/completedsignings/${id}`
        );
        return {
          result: res,
          success: true
        };
      } catch (err) {
        return {
          result: err,
          success: false
        };
      }
    }
  }
});

const mergeSignerProfiles = selectorFamily({
  key: 'mergeSigner',
  get: ({ ...params }: any) => async () => {
    try {
      await verifyAuthToken()
      const res: any = await api.POST('/signer/merge', params);
      return {
        result: res,
        success: true
      };
    } catch (err) {
      console.error(err);
      return {
        result: err,
        success: false
      };
    }
  }
});

const searchSignerWithId = selector({
  key: 'searchSignerWithId',
  get: async ({ get }) => {
    get(refetchSigners);
    const filters = get(searchSignerPagination);

    try {
      await verifyAuthToken()
      const res: any = await api.GET(
        `/signer/getSignerAll/${encodeQueryData(filters)}`
      );
      return {
        result: res,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      }
    }
  }
});

const sendSignerConfirmation = selectorFamily({
  key: 'sendSignerConfirmation',
  get: (params: any) => async () => {
    if (params) {
      try {
        await verifyAuthToken()
        const res: any = await api.POST('orders/send/signerconfirmation', params);
        return {
          result: res,
          success: true
        };
      } catch (err) {
        return {
          result: err,
          success: false
        };
      }
    }
  }
});

const viewSignerConfirmation = selectorFamily({
  key: 'viewSignerConfirmation',
  get: (params: any) => async () => {
    if (params) {
      try {
        await verifyAuthToken()
        const res: any = await api.GET_DOWNLOAD(`orders/view/signerconfirmation/${params.Id}`);
        return {
          result: res,
          success: true
        };
      } catch (err) {
        return {
          result: err,
          success: false
        };
      }
    }
  }
});

const signerPasswordReset = selectorFamily({
  key: 'signerPasswordReset',
  get: ({ Signer_Email }: any) => async () => {
    try {
      await verifyAuthToken()
      const res: any = await api.PUT('auth/signer/password/resetrequest', { Signer_Email });
      return {
        result: res,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const getSignerCreds = selectorFamily({
  key: 'getSignerCreds',
  get: (params: any) => async ({ get }) => {
    get(reloadCreds)
    try {
      await verifyAuthToken()
      const res: any = await api.GET(`signer/getSignerCreds/${params.id}`);
      return {
        result: res,
        success: true
      };
    } catch (error) {
      return {
        result: [],
        success: false
      };
    }
  }
});

const uploadSignerCreds = selectorFamily({
  key: 'uploadSignerCreds',
  get: (params: any) => async () => {
    const data = new FormData()
    data.append('doc_type', params?.doc_type)
    data.append('expire_date', params?.expire_date)
    data.append('id', params?.id)
    data.append('file', params?.file)

    try {
      await verifyAuthToken()
      const res: any = await fileapi.POST(
        'upload/signercreds',
        data
      );
      return {
        result: res,
        success: true
      };
    } catch (error) {
      return {
        result: error,
        success: false
      };
    }
  }
});

const viewDocs = selectorFamily({
  key: 'viewDocs',
  get: (params: any) => async ({ get }) => {
    try {
      get(fetchSignerDocument)
      await verifyAuthToken()
      const res: any = await fileapi.DOWNLOAD('getfile', params);
      return {
        result: res,
        success: true
      };
    } catch (error) {
      return {
        result: error,
        success: false
      };
    }
  }
});

const fetchVendorList = selector({
  key: 'fetchVendorList',
  get: async ({ get }) => {
    try {
      const params = get(vendorListPagination)
      await verifyAuthToken()
      const result: any = await api.GET('client/get/vendor', { params });

      return {
        result,
        success: true
      };
    } catch (err) {
      return {
        result: [],
        success: false
      };
    }
  }
});

const getAssignSignerOrder = selector({
  key: 'getAssignSignerOrder',
  get: async ({ get }) => {
    try {
      get(refetchSignerOrder)
      const params = get(assignSignerOrderId)
      await verifyAuthToken()
      if (params.orderId) {
        const result: any = await api.GET(`/orders/signers/getAssignSignerOrder/${params?.orderId}`);
        return {
          result,
          success: true
        };
      }
    } catch (err) {
      return {
        result: [],
        success: false
      };
    }
  }
});

const getAssignSignerOrderById = selectorFamily({
  key: 'getAssignSignerOrderById',
  get: (params: any) => async ({ get }) => {
    try {
      get(refetchAssignSignerOrder)
      await verifyAuthToken()
      if (params.orderId) {
        const result: any = await api.GET(`/orders/signers/getAssignSignerOrder/${params?.orderId}`);
        return {
          result,
          success: true
        };
      }
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const removeCallAlert = selectorFamily({
  key: 'removeCallAlert',
  get: (params: any) => async ({ get }) => {
    try {
      get(forceRemoveCallAlert)
      await verifyAuthToken()
      if (params.orderId) {
        const result: any = await api.POST(`/orders/remove/notification`, { ...params });
        return {
          result,
          success: true
        };
      }
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const masterLoginRequest = selectorFamily({
  key: 'masterLoginRequest',
  get: (params: any) => async ({ get }) => {
    try {
      get(forceSignerLogin)
      await verifyAuthToken()
      const res: any = await api.POST('auth/signer/master-login-request', params);
      return {
        result: res,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const getSignerCredHistory = selectorFamily({
  key: 'getSignerCredHistory',
  get: (params: any) => async ({ get }) => {
    try {
      get(refetchSignerCredHistory)
      await verifyAuthToken()
      const res: any = await fileapi.GET(`/signers/cred/history?`, { params });
      return {
        result: res,
        success: true
      };
    } catch (error) {
      return {
        result: error,
        success: false
      };
    }
  }
});

const getCallerProfile = selectorFamily({
  key: 'getCallerProfile',
  get: (params: any) => async ({ get }) => {
    try {
      get(fetchCallerProfile)
      await verifyAuthToken()
      const res: any = await api.GET(`/orders/signers/callerprofile/${params}`);
      return {
        result: res,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});


export {
  getSigners,
  getSignerById,
  editSigner,
  saveSigner,
  deleteSigner,
  getCommunications,
  sendMessage,
  deactiveSigner,
  getClientsSigner,
  getSignerErros,
  getSignerHistory,
  mergeSignerProfiles,
  searchSignerWithId,
  sendSignerConfirmation,
  viewSignerConfirmation,
  signerPasswordReset,
  getCompletedSigningsCount,
  getSignerCreds,
  uploadSignerCreds,
  viewDocs,
  getPreferedSigners,
  fetchVendorList,
  getAssignSignerOrder,
  sendMessageToAllSigners,
  getSignerCredHistory,
  masterLoginRequest,
  getCallerProfile,
  getAssignSignerOrderById,
  removeCallAlert
};
