import React, { useEffect } from 'react';
import { Calendar } from 'react-feather';
import { useRecoilValueLoadable, useRecoilState } from 'recoil'

import { appointments } from '../../Recoil/Selectors/Statistics';
import { appointmentCount } from '../../Recoil/Atoms/Stats';
import { orderChannel } from './../../Common/NotificationService'


import { Icon } from './Icon';

const Appointments = () => {
  const [orderAppointmentsCount, setOrderAppointmentsCount] = useRecoilState(appointmentCount);
  const count: any = useRecoilValueLoadable(appointments);

  useEffect(() => {
    if (count.state !== 'loading' && orderAppointmentsCount === 0) {
      setOrderAppointmentsCount(count?.contents?.result)
    }
  })
  orderChannel.bind('getCurrentApptCount', data => {
    setOrderAppointmentsCount(data.count)
  });

  return (
    <Icon title='Appointments' count={orderAppointmentsCount}>
      <Calendar />
    </Icon>
  );
};

export { Appointments };
