import React from 'react';
import { useSetRecoilState, useRecoilCallback } from 'recoil';

import { FormikComponent } from '../../Components/Formik';

import { refreshRoles } from '../../Recoil/Atoms/Role';
import { deleteRole } from '../../Recoil/Selectors/Role';

import { initialValues, validationSchema } from '../../Common/Rules/RoleForm';
import { Typography } from '@material-ui/core';

export const DeleteRole = props => {
  const callRefresh = useSetRecoilState(refreshRoles);
  const refresh = () => callRefresh(n => n + 1);

  const onSubmit = useRecoilCallback(
    ({ snapshot }) => async (values: any, { setSubmitting }) => {
      let res: any = {};
      let message = '';
      if (values.id) {
        values.status = "D"
        res = await snapshot.getPromise(deleteRole(values));
        message = 'Role has been Deleted';
      }
      setSubmitting(false);
      if (res.success) {
        refresh();
        props.openModal(false);
        props.setToast({
          message,
          type: 'success',
          open: true
        });
      }
    }
  );

  return (
    <FormikComponent
      onSubmit={onSubmit}
      initialValues={
        props.selectedRole.id ? props.selectedRole : initialValues
      }
      validationSchema={validationSchema}
      enableReinitialize={props.selectedRole.id ? true : false}
    >
        <Typography>Are you sure you want to delete this role?</Typography>
    </FormikComponent>
  );
};
