import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  floatRight: {
    float: 'right'
  },
  cursorPointer: {
    cursor: 'pointer'
  },
  moveBottom: {
    position: 'relative',
    top: '7px',
    left: '30px'
  }
}))
