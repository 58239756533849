import React from 'react';
import { useRecoilState } from 'recoil';
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  InputLabel,
  Select,
  IconButton,
  SvgIcon
} from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment';

import { ClientInvoicesSearch } from './ClientInvoicesSearch';
import { ClientInvoicesDateRange } from './ClientInvoicesDateRange'

import { useStyles } from './Styles/ClientInvoicesActions';
import { marginStyles } from '../../Common/Styles/Margins';

import { clientInvoicesFilters, clientInvoicesPagination } from '../../Recoil/Atoms/ClientInvoices';

import { XCircle } from 'react-feather';

const ClientInvoicesActions = () => {

  const classes = useStyles();
  const marginClasses = marginStyles();

  const [pageInfo, setPageInfo]: any = useRecoilState(clientInvoicesPagination);
  const [filter, setFilter] = useRecoilState(clientInvoicesFilters);

  const billingCycleOptions = ['Daily', 'Semi-Monthly', 'Montly'].map(
    bCycle =>
      <MenuItem key={bCycle} value={bCycle}>
        {bCycle}
      </MenuItem>
  )



  const setPageInfoFilter = (type: string, value: any) => {
    setPageInfo({
      ...pageInfo,
      billing_cycle: value ? `${value}` : undefined
    });
  }

  const setFilterIds = (type: string, value: any) => {
    const newFilter = Object.assign({}, filter, { [type]: value })
    if (!value) {
      delete newFilter.billing_cycle
    }
    setFilter(newFilter)
  }

  const handleFilter = (type: string, value: any) => {
    setPageInfoFilter(type, value);
    setFilterIds(type, value);
  };

  const handleSearch = (searchType, searchValue) => {
    let value = searchValue;
    if (searchType === 'orderDate') {
      value = moment(searchValue).format('YYYY-MM-DD');
    }
    const newPageInfo = { ...pageInfo, page: 1, limit: 20, searchType, value };

    if (!searchValue) {
      delete newPageInfo.searchType
      delete newPageInfo.searchValue
    }

    setPageInfo(newPageInfo);
  };

  const handleDates = (start, end) => {
    const startDate = moment(start).format('YYYY-MM-DD')
    const endDate = moment(end).format('YYYY-MM-DD')

    setPageInfo({ ...pageInfo, startDate, endDate });
  };

  const clearDates = () => {
    const newPageInfo = Object.assign({}, pageInfo)

    delete newPageInfo.startDate
    delete newPageInfo.endDate

    setPageInfo(newPageInfo);
  }

  const clearFilters = () => {
    const newFilter = Object.assign({})
    setFilter(newFilter)
    setPageInfo({
      ...pageInfo,
      filter: ''
    })
  }

  const isSearched = (filter && Object.keys(filter).filter(key => filter[key] ? true : false).length) || pageInfo.filter

  return (
    <Box my={2} display='flex'>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <Grid item>
          <FormControl
            className={clsx(marginClasses.mt0, marginClasses.mb0, marginClasses.mr1, classes.select)}
            margin='dense'
            variant='outlined'
          >
            <InputLabel>Billing Cycle</InputLabel>
            <Select
              value={filter.billing_cycle}
              label='Billing Cycle'
              defaultValue=''
              onChange={(e) => handleFilter('billing_cycle', e.target.value)}>
              <MenuItem value={null}>All Billing Cycles</MenuItem>
              {billingCycleOptions}
            </Select>
          </FormControl>
          <IconButton disabled={!isSearched} onClick={clearFilters} >
            <SvgIcon fontSize='small' color={isSearched ? 'error' : 'disabled'} >
              <XCircle />
            </SvgIcon>
          </IconButton>
        </Grid>
        <Grid item>
          <ClientInvoicesDateRange handleDates={handleDates} clearDates={clearDates} />
        </Grid>
        <Grid item>
          <ClientInvoicesSearch handleSearch={handleSearch} />
        </Grid>
      </Grid>
    </Box>
  )
}

export { ClientInvoicesActions };
