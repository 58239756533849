import React, { useEffect } from 'react';
import { Clipboard } from 'react-feather';
import { useRecoilState, useRecoilValueLoadable } from 'recoil'

import { monthOrder } from '../../Recoil/Selectors/Statistics';
import { currentOrderCount } from '../../Recoil/Atoms/Stats';
import { orderChannel } from './../../Common/NotificationService'

import { Icon } from './Icon';

const Orders = () => {
  const [monthOrderCount, setCurrentOrderCount] = useRecoilState(currentOrderCount);
  const count: any = useRecoilValueLoadable(monthOrder);
  
  useEffect(() => {
    if (count.state !== 'loading' && monthOrderCount === 0) {
      setCurrentOrderCount(count?.contents?.result)
    }
  })

  orderChannel.bind('getCurrentOrderCount', data => {
    setCurrentOrderCount(data.count)
  });


  return (
    <Icon title='Orders' count={monthOrderCount}>
      <Clipboard />
    </Icon>
  );
};

export { Orders };
