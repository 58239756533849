import React, { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import {
  Box,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import { Table } from '../Table';

import { selectedClient } from 'src/Recoil/Atoms/Clients';
import { selectedDivision } from 'src/Recoil/Atoms/Divisions';

import { useStyles } from './Styles/assignSigner'
import { PhoneCall } from 'react-feather'
import { CallSigner } from './CallSigner';

const AppemptsData = ((props: any) => {

  const classes = useStyles()

  const { rows, orderFees, orderDetails, onBack, loading } = props

  const defaultValues = {
    w_Appointment_Date: '',
    w_Appointment_Time: '',
    Signer_First: '',
    Signer_Last: '',
    Signer_Email: '',
    signer_documents: [],
    Closing_Type: '',
    f_loan_number: '',
    signer_id: '',
    Id: '',
    latitude: '',
    longitude: '',
    distance: 0,
    f_signer_fees: '',
    signer_type: ''
  }
  const [selectedSigner, setSigner] = useState(defaultValues)
  const [isAssignModalOpen, OpenAssignModal] = useState(false)

  const setClient = useSetRecoilState(selectedClient)
  const setDivision = useSetRecoilState(selectedDivision)
  const onCall = (data) => {
    setClient(orderDetails.clientID)
    setDivision(orderDetails.division_id)
    setSigner({ ...orderDetails, ...data, errors: data?.signer_errors?.length, signing: data?.borrowers?.length })
    OpenAssignModal(true)
  }

  const actionsFormatter = params => {
    return (
      <Box display='flex'>
        <Tooltip title='Call'>
          <Typography
            color='primary'
            className={classes.cursorPointer}
            onClick={e => onCall(params?.row?.signer)}
          >
            <PhoneCall></PhoneCall>
          </Typography>
        </Tooltip>
      </Box>
    );
  }
  const columns = [
    {
      field: 'user',
      headerName: 'User Name',
      flex: 1,
      disableColumnMenu: true,
      renderCell: ({ row }) => (<div>
        {row?.employee ? (
          <>
            {row?.employee?.firstName}&nbsp;{row?.employee?.lastName}
          </>
        ) : null}
      </div>)
    },
    {
      field: 'signer_id',
      sortable: false,
      headerName: 'Signer',
      flex: 1,
      renderCell: ({ row }) => (<div>
        {row?.signer ? (
          <>
            {row?.signer.Signer_First}&nbsp;{row?.signer.Signer_Last}
          </>
        ) : null}
      </div>)
    }, {
      field: 'signing',
      headerName: '#Signing',
      disableColumnMenu: true,
      sortable: false,
      renderCell: ({ row }) => (<div>
        {row?.signer ? (
          <>
            {row?.signer?.borrowers?.length || 0}
          </>
        ) : null}
      </div>)
    },
    {
      field: 'errors',
      headerName: '#Errors',
      disableColumnMenu: true,
      sortable: false,
      renderCell: ({ row }) => (<div>
        {row?.signer ? (
          <>
            {row?.signer.signer_errors?.length || 0}
          </>
        ) : null}
      </div>)
    }, {
      field: 'signer_type',
      headerName: 'Type',
      disableColumnMenu: true,
      renderCell: ({ row }) => (<div>
        {row?.signer ? (
          <>
            {row?.signer?.signer_type}
          </>
        ) : null}
      </div>)
    }, {
      field: 'operation_notes',
      headerName: 'Note',
      disableColumnMenu: true
    },
    {
      field: 'offering_fee',
      headerName: 'Order Fee',
      disableColumnMenu: true,
      renderCell: ({ row }) => (<div>
        {orderFees}
      </div>)
    }, {
      field: 'requested_fee',
      headerName: 'Requested Fee',
      disableColumnMenu: true
    }, {
      field: 'comment',
      headerName: 'Comment',
      disableColumnMenu: true,
      sortable: false,
      renderCell: ({ row }) => (
        <Tooltip title={<Typography>{row?.comment}</Typography>}>
          <div>
            {row?.comment}
          </div>
        </Tooltip>
      )
    }, {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      disableColumnMenu: true,
      renderCell: actionsFormatter,
    }];

  return (
    <SnackbarProvider>
      <>
        <CallSigner
          selectedSigner={selectedSigner}
          isAssignModalOpen={isAssignModalOpen}
          OpenAssignModal={OpenAssignModal}
          orderDetails={orderDetails}
          setSigner={setSigner}
          defaultValues={defaultValues}
          onBack={onBack}
        >
        </CallSigner>
        <Table
          loading={loading}
          useCustomStyles={false}
          columns={columns}
          rows={rows}
          uniqueKey='id'
        /></>
    </SnackbarProvider>

  );
});

export { AppemptsData };
