import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import { useStyles } from '../Styles/Fields';

const OrderInformation = ({ values, }) => {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={3}>
        <Typography variant='body2' className={classes.fieldHeader}>Order ID</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant='body2'>{values.Id}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant='body2' className={classes.fieldHeader}>Created At</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant='body2'>{values?.w_Creation_Date} {values?.w_creation_time}</Typography>
      </Grid>
      <Grid item xs={6} md={3}>
        <Typography variant='body2' className={classes.fieldHeader}>Status </Typography>
      </Grid>
      <Grid item xs={6} md={3}>
        <Typography variant='body2'>{values?.f_status_web}</Typography>
      </Grid>
      <Grid item xs={6} md={3}>
        <Typography variant='body2' className={classes.fieldHeader}>Status Submitted Via
       </Typography>
      </Grid>
      <Grid item xs={6} md={3}>
        <Typography variant='body2'>{values?.status_updated_via}</Typography>
      </Grid>
    </>
  );
}

export { OrderInformation };
