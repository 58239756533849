import React from 'react';
import { useRecoilState } from 'recoil';
import {
  Box,
  Tooltip,
  IconButton,
  SvgIcon,
  Typography
} from '@material-ui/core';
import { Edit ,Delete } from 'react-feather';

import { rolepagination } from '../../Recoil/Atoms/Role';

import { Table } from '../Table';

const RoleData = props => {
  const [pageInfo, setPageInfo] = useRecoilState(rolepagination);
  const { orderType, orderBy } = pageInfo;

  const onSortingChange = (e) => {
    if (e.sortModel &&e.sortModel[0].field) {
      const { field, sort } = e.sortModel[0]
      setPageInfo({
        ...pageInfo,
        orderBy: field,
        orderType: sort,
      });
    }
  }


  const actionsFormatter = params => {
    return (
      <Box display='flex'>
        <Tooltip title='Edit'>
          <IconButton
            color='inherit'
            onClick={() => props.handleEdit(params.row)}
          >
            <SvgIcon fontSize='small' color='primary'>
              <Edit />
            </SvgIcon>
          </IconButton>
        </Tooltip>

        <Tooltip title='Delete'>
          <IconButton
            color='inherit'
            onClick={() => props.handleDelete(params.row)}
          >
            <SvgIcon fontSize='small' color='primary'>
              <Delete />
            </SvgIcon>
          </IconButton>
          
        </Tooltip>
        
      </Box>
    );
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      headerClassName: 'datagrid-header',
      cellClassName: 'datagrid-cell',
      disableColumnMenu: true
    },
    {
      field: 'roleResourceGroups',
      headerName: 'Resource Groups',
      cellClassName: '',
      renderCell: ({ row }) => (
        <div style={{ display: 'block' }}>
          {row.resourceGroup.map(resource => {
            return (
              <Typography
                variant='body1'
                variantMapping={{ body1: 'p' }}
                key={resource}
              >
                {resource.name}
              </Typography>
            );
          })}
        </div>
      )
    },
    {
      field: 'modified',
      headerName: 'Modified Date/Time'
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: ({ row }) => (
        <div style={{ display: 'block' }}>
              <Typography
                variant='body1'
                variantMapping={{ body1: 'p' }}
              >
                {row.status==='A'?"Active":"Inactive"}
              </Typography>
        </div>
      )
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: actionsFormatter
    }
  ];

  const handlePage = (e: unknown, newPage: number) => {
    setPageInfo({
      ...pageInfo,
      page: newPage
    });
  };

  const handleRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPageInfo({
      ...pageInfo,
      limit: parseInt(e.target.value, 10)
    });
  };
  return (
    <Table
      loading={props.loading}
      customRowHeight={85}
      columns={columns}
      rows={props.data}
      total={props.total}
      pageInfo={{ page: pageInfo.page, limit: pageInfo.limit }}
      handlePage={handlePage}
      handleRowsPerPage={handleRowsPerPage}
      sortModel={[{
        sort: orderType, field: orderBy
      }]}
      onSortingChange={onSortingChange}
    />
  );
};

export { RoleData };
