import React from 'react';
import { Grid, TextField } from '@material-ui/core';

const NotificationSpecificEmail = ({ values, errors, touched, handleChange, handleBlur }) => {
  return (
    <Grid item xs={12} md={6}>
      <TextField
        label='Notification Email'
        name='notif_email'
        variant='outlined'
        size='small'
        fullWidth
        value={values.notif_email}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.notif_email && errors.notif_email}
        error={Boolean(touched.notif_email && errors.notif_email)}
      />
    </Grid>
  );
}

export { NotificationSpecificEmail };
