import React from 'react';

const a11yProps = (tab: any) => {
  return {
    id: `order-tab-${tab}`,
    'aria-controls': `order-tabpanel-${tab}`,
  };
};

const TabPanel = (props) => {
  const { tab, index, children } = props;

  return (
    <div
      role='tabpanel'
      hidden={tab !== index}
      id={`order-tabpanel-${tab}`}
      aria-labelledby={`order-tab-${tab}`}
    >
      {children}
    </div>
  )
}

export { TabPanel, a11yProps };
