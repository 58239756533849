import { selector, selectorFamily } from 'recoil';

import { pagination, refreshClients, customerListPagination } from '../Atoms/Clients';
import { userInfo } from '../Atoms/Login';

import { verifyAuthToken } from './Auth'
import { api } from '../../Services/api';

const getClientNames = selector({
  key: 'getClientNames',

  get: async ({ get }) => {
    get(refreshClients);

    await verifyAuthToken()
    const res: any = await api.GET('client/getClientNames');
    return {
      result: res,
      success: true
    };
  }
});

const getClients = selector({
  key: 'getClients',
  get: async ({ get }) => {
    get(refreshClients);
    const params = get(pagination);

    try {
      await verifyAuthToken()
      const res: any = await api.GET('client/getAllClients', {
        params:
          { ...params, orderBy: params.orderBy === 'status' ? 'clientStatus' : params.orderBy }
      });
      const result = res.rows.map(r => {
        return {
          ...r,
          id: r.clientID,
          status: r.clientStatus === 'A' ? 'Active' : 'Inactive'
        }
      });
      return {
        result: {
          rows: result,
          count: res.count
        },
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const getAllClients = selector({
  key: 'getAllClients',
  get: async ({ get }) => {
    get(refreshClients);
    try {
      await verifyAuthToken()
      const res: any = await api.GET('client/getAllClients');
      return {
        result: res.rows,
        success: true
      };
    } catch (err) {
      return {
        result: [],
        success: false
      };
    }
  }
});

const saveClient = selectorFamily({
  key: 'saveClient',
  get: (params: any) => async ({ get }) => {
    const user = get(userInfo);
    if (params) {
      try {
        const data = {
          ...params,
          createdBy: user.id,
          modifiedBy: null
        }
        await verifyAuthToken()
        const res: any = await api.POST('client/new', data);

        const response: any = {
          ...res,
          id: res.clientID,
          status: res.clientStatus === 'A' ? 'Active' : 'Inactive'
        }
        return {
          result: response,
          success: true
        };
      } catch (err) {
        return {
          result: err,
          success: false
        };
      }
    }
  }
});

const updateClient = selectorFamily({
  key: 'updateClient',
  get: (params: any) => async ({ get }) => {
    const user = get(userInfo);
    if (params) {
      try {
        delete params.createdAt;
        delete params.updatedAt;
        const data = {
          ...params,
          modifiedBy: user.id,
        }
        await verifyAuthToken()
        const res: any = await api.PUT('client/update', data);

        if (res[0] === 0) {
          return {
            result: {
              error: 'Unable to Update the Record!'
            },
            success: false
          }
        }

        return {
          result: res,
          success: true
        };
      } catch (err) {
        return {
          result: err,
          success: false
        };
      }
    }
  }
});

const addClientToContact = selectorFamily({
  key: 'addClientToContact',
  get: (params: any) => async () => {
    if (params) {
      try {
        await verifyAuthToken()
        const res: any = await api.POST('client/add/client', params);
        return {
          result: res,
          success: true
        };
      } catch (err) {
        return {
          result: err,
          success: false
        };
      }
    }
  }
});

const requestDocument = selectorFamily({
  key: 'requestDocument',
  get: (params: any) => async () => {
    if (params) {
      try {
        await verifyAuthToken()
        const res: any = await api.POST('client/contacts/requestDocuments', params);
        return {
          result: res,
          success: true
        };
      } catch (err) {
        return {
          result: err,
          success: false
        };
      }
    }
  }
});

const sendClientConfirmation = selectorFamily({
  key: 'sendClientConfirmation',
  get: (params: any) => async () => {
    if (params) {
      try {
        await verifyAuthToken()
        const res: any = await api.POST('orders/send/clientconfirmation', params);
        return {
          result: res,
          success: true
        };
      } catch (err) {
        return {
          result: err,
          success: false
        };
      }
    }
  }
});

const viewClientConfirmation = selectorFamily({
  key: 'viewClientConfirmation',
  get: (params: any) => async () => {
    if (params.Id) {
      try {
        await verifyAuthToken()
        const res: any = await api.GET_DOWNLOAD(`orders/view/clientconfirmation/${params.Id}`);
        return {
          result: res,
          success: true
        };
      } catch (err) {
        return {
          result: err,
          success: false
        };
      }
    }
  }
});

const getClientById = selectorFamily({
  key: 'getClientById',
  get: (params: any) => async () => {
    if (params) {
      try {
        const { clientID } = params
        await verifyAuthToken()
        const res: any = await api.GET(`client/getClientById/${clientID}`);
        return {
          result: res,
          success: true
        };
      } catch (err) {
        return {
          result: err,
          success: false
        };
      }
    }
  }
});

const fetchCustomerList = selector({
  key: 'fetchCustomerList',
  get: async ({ get }) => {
    try {
        const params = get(customerListPagination)
        await verifyAuthToken()
        const result: any = await api.GET(`client/get/customers`, { params });

        return {
            result: result,
            success: true
        };
        } catch (err) {
        return {
            result: [],
            success: false
        };
    }
  }
});

export {
  addClientToContact,
  getClientNames,
  getClients,
  saveClient,
  updateClient,
  getAllClients,
  requestDocument,
  sendClientConfirmation,
  getClientById,
  viewClientConfirmation,
  fetchCustomerList
};
