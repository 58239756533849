import React from 'react';
import { Grid, Typography, TextareaAutosize, FormControlLabel, Radio, FormHelperText } from '@material-ui/core';

import { displayStyles } from '../../Common/Styles/Display';

const SettingFields = (props) => {
  const displayClasses = displayStyles();
  const { values, errors, touched, handleChange, handleBlur } = props;
  return (
    <>
      <Grid container spacing={2} alignItems='center'>
        <Grid item md={3}>
          <Typography variant='body2'>Nightly Report Email</Typography>
        </Grid>
        <Grid item md={9}>
          <TextareaAutosize
            className={displayClasses.textArea}
            name='nightlyReportEmail'
            value={values.nightlyReportEmail}
            onChange={handleChange}
            onBlur={handleBlur}
            rowsMin={3}
          />
          {touched.nightlyReportEmail && errors.nightlyReportEmail && (
            <FormHelperText error>
              {errors.nightlyReportEmail}
            </FormHelperText>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems='center'>
        <Grid item md={3}>
          <Typography variant='body2'>Signing Request Notification</Typography>
        </Grid>
        <Grid item md={2}>
          <FormControlLabel
            label='Yes'
            control={
              <Radio
                name='signing_request'
                value='1'
                checked={values.signing_request === '1'}
                onChange={handleChange}
              />
            }
          />
        </Grid>
        <Grid item md={4}>
          <FormControlLabel
            label='No'
            control={
              <Radio
                name='signing_request'
                value='0'
                checked={values.signing_request === '0'}
                onChange={handleChange}
              />
            }
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems='center'>
        <Grid item md={3}>
          <Typography variant='body2'>Client Survey</Typography>
        </Grid>
        <Grid item md={2}>
          <FormControlLabel
            label='Yes'
            control={
              <Radio
                name='client_survey'
                value='1'
                checked={values.client_survey === '1'}
                onChange={handleChange}
              />
            }
          />
        </Grid>
        <Grid item md={4}>
          <FormControlLabel
            label='No'
            control={
              <Radio
                name='client_survey'
                value='0'
                checked={values.client_survey === '0'}
                onChange={handleChange}
              />
            }
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems='center'>
        <Grid item md={3}>
          <Typography variant='body2'>Update Orders Check</Typography>
        </Grid>
        <Grid item md={2}>
          <FormControlLabel
            label='Yes'
            control={
              <Radio
                name='update_orders_check'
                value='1'
                checked={values.update_orders_check === '1'}
                onChange={handleChange}
              />
            }
          />
        </Grid>
        <Grid item md={4}>
          <FormControlLabel
            label='No'
            control={
              <Radio
                name='update_orders_check'
                value='0'
                checked={values.update_orders_check === '0'}
                onChange={handleChange}
              />
            }
          />
        </Grid>
      </Grid>
    </>
  );
}

export { SettingFields };
