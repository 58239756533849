import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { Typography, Grid } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from 'recoil'

import { storage } from '../../Services/storage';
import { Table } from '../Table';

import { completedSigningsId, signerHistoryPagination } from 'src/Recoil/Atoms/Signers';
import { getSignerHistory, getCompletedSigningsCount } from 'src/Recoil/Selectors/Signer';
import { ViewOrderPopup } from '../Orders/ViewOrderPopup';
import { GeneralModal } from '../Modal';

import { useStyles } from './Styles/Fields';


export const SignerHistory = ((props: any) => {
  const classes = useStyles();
  const params: any = useParams()
  const role = storage.get('userRole') || 'unknown'

  const [order, setOrder] = useState(null)

  const columns = [{
    field: 'w_Loan_Number',
    headerName: 'Loan No.',
    disableColumnMenu: true,
    align: 'left',
    headerAlign: 'left',
    sortable: false,
    renderCell: ({ row }) => (
      <div style={{ display: 'block' }}>
        <Typography className={classes.cursorPointer}
          onClick={() => setOrder(row.Id)}
        >
          {row?.w_Loan_Number}
        </Typography>
      </div>
    ),
  },
  {
    field: 'w_Borrower_Last_Name',
    headerName: 'Last Name',
    disableColumnMenu: true,
    align: 'left',
    headerAlign: 'left',
    sortable: false
  },
  {
    field: 'f_signer_fees',
    headerName: 'Signing Fee',
    disableColumnMenu: true,
    align: 'left',
    headerAlign: 'left',
    sortable: false
  },
  {
    field: 'w_Appointment_Date',
    headerName: 'Appointment Date',
    disableColumnMenu: true,
    align: 'left',
    headerAlign: 'left',
    sortable: false
  },
  role.toLowerCase() !== 'accountant' ?
    {
      field: 'w_Signing_City',
      headerName: 'City',
      disableColumnMenu: true,
      align: 'left',
      headerAlign: 'left',
      sortable: false,
    } : {
      field: 'payment_status',
      headerName: 'Payment Status',
      disableColumnMenu: true,
      align: 'left',
      headerAlign: 'left',
      sortable: false,
    },

  {
    field: 'f_status_web',
    headerName: 'Status',
    disableColumnMenu: true,
    align: 'left',
    headerAlign: 'left',
    sortable: false
  },

  {
    field: 'w_Appointment_Time',
    headerName: 'Appointment Time',
    disableColumnMenu: true,
    align: 'left',
    headerAlign: 'left',
    sortable: false
  }
  ]

  const [pagination, setPagination] = useRecoilState(signerHistoryPagination)
  const setSignerId = useSetRecoilState(completedSigningsId)

  useEffect(() => {
    if (params.id && params.id !== pagination.signer_id) {
      setSignerId(params.id)
      setPagination({
        ...pagination,
        signer_id: params.id
      });
    }
    else if (props.id && props.id !== pagination.signer_id) {
      setSignerId(props.id)
      setPagination({
        ...pagination,
        signer_id: props.id
      });
    }
  }, [pagination, params.id, setPagination, setSignerId, props.id])

  const onSortingChange = data => {
    if (data.sortModel && data?.sortModel[0]?.field) {
      setPagination({
        ...pagination,
        orderBy: data?.sortModel[0]?.field,
        orderType: data?.sortModel[0]?.sort,
      })
    }
  }
  const handlePage = (e, page) => {
    setPagination({
      ...pagination,
      page
    })
  }

  const signerData: any = useRecoilValueLoadable(getSignerHistory)
  const signerHistory = signerData?.contents?.result?.rows || [];
  const totalCount = signerData?.contents?.result?.count || 0;
  const signer = signerData?.contents?.result?.signer || { signer_id: 0, Signer_First: '', Signer_Last: '' }

  const completeSigningsData: any = useRecoilValueLoadable(getCompletedSigningsCount)
  const completeSigningsCount = completeSigningsData?.contents?.result?.count


  const handleRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPagination({
      ...pagination,
      limit: parseInt(e.target.value, 10)
    });
  };

  return (
    <SnackbarProvider >
      <div>
        <Grid container>
          <Grid container item>
            <Typography variant='h5' display='inline'>
              <Typography color='textSecondary' display='inline'>Signer ID : </Typography>{signer.signer_id}
            </Typography>
          </Grid>
          <Grid container item>
            <Typography variant='h5' display='inline'>
              <Typography color='textSecondary' display='inline'>Signer Name : </Typography>
              {signer.Signer_First}  {signer.Signer_Last}
            </Typography>
          </Grid>
          <Grid container item>
            <Typography variant='h5' display='inline'>
              <Typography color='textSecondary' display='inline'>Completed Signings : </Typography>
              {completeSigningsCount}
            </Typography>
          </Grid>

        </Grid>
        <br></br>
        <Table
          loading={signerData.state === 'loading'}
          handleRowsPerPage={handleRowsPerPage}
          sortModel={[{
            field: pagination.orderBy,
            sort: pagination.orderType
          }]}
          pageInfo={pagination}
          handlePage={handlePage}
          onSortingChange={onSortingChange}
          total={totalCount}
          pagination columns={columns}
          rows={signerHistory}
        >
        </Table>
        <GeneralModal
          title='Order Details'
          maxWidth='xl'
          open={order}
          openModal={e => setOrder(false)}
          noSubmit
        >
          <ViewOrderPopup id={order} />
        </GeneralModal>
      </div>
    </SnackbarProvider>

  )
})
