import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import moment from 'moment-timezone'
import {
  useRecoilCallback,
  useSetRecoilState
} from 'recoil';
import { Table } from '../../Table';
import { UnbilledOrderDataActions } from './UnbilledOrderDataActions';
import { CreateBillModel } from './CreateBillModel';
import { refetchUnbilledOrders, forceCreateBill } from '../../../Recoil/Atoms/Accounting';
import { Toast } from '../../../Components/Toasts';
import { createBill } from '../../../Recoil/Selectors/Accounting'
import { SignerInfo } from '../SignerInfo';

import { useStyles } from '../Styles/style';

interface RowData {
  id: string | null;
  order_number: string | null;
  invoice_number: string | null;
  borrower: string | null;
  signer: string | null;
  signer_fee: number | null;
  f_appointment_date: string | null;
  inv_submitted_on: string | null;
  status: string | null;
  reference_number: string | null;
  payment_status: string | null;
  credential_complete: string | null;
}
const
  UnbilledOrdersData = ({
    unbilledOrder,
    total,
    loading,
    pageInfo,
    setPageInfo
  }) => {

    const classes = useStyles();

    const { orderBy, orderType } = pageInfo;
    const [createOrderBillCurrentRow, handleCreateOrderBillCurrentRow] = useState<RowData | any>();

    const [signerInfoModal, handleSignerInfoModal] = useState(null);
    const createBillAtom = useSetRecoilState(forceCreateBill) 

    const refresh = useSetRecoilState(refetchUnbilledOrders)
    const [toast, setToast] = useState({ message: '', type: 'success', open: false });
    const [check, setCheck] = useState(false);

    const columns = [
      {
        field: 'f_loan_number',
        headerName: 'Loan/Order #',
        headerClassName: 'datagrid-header',
        cellClassName: 'datagrid-cell-custom',
      },
      {
        field: 'billRefNumber',
        headerName: 'Reference #',
        headerClassName: 'datagrid-header',
        cellClassName: 'datagrid-cell-custom',
      },
      {
        field: 'f_borrower_first_name',
        headerName: 'Borrower',
        headerClassName: 'datagrid-header',
        cellClassName: 'datagrid-cell-custom',
        renderCell: ({ row }) => (
          <span>{row.f_borrower_first_name} {' '} {row.f_borrower_last_name}</span>
        )
      },
      {
        field: 'f_signer_name',
        headerName: 'Signer',
        headerClassName: 'datagrid-header',
        cellClassName: 'datagrid-cell-custom',
        renderCell: ({ row }) => (
          <Typography
            className={classes.cursorPointer}
            color='primary'
            variant='body1'
            variantMapping={{ body1: 'p' }}
            key={row.f_signer_name}
            onClick={() => handleSignerInfoModal(
              { ...row?.signer, w_Loan_Number: row?.f_loan_number }
              || { w_Loan_Number: row?.w_Loan_Number })
            }
          >
            {row.f_signer_name}
          </Typography>
        )
      },
      {
        field: 'f_signer_fees',
        headerName: 'Signer Fee',
        headerClassName: 'datagrid-header',
        cellClassName: 'datagrid-cell-custom'
      },
      {
        field: 'f_appointment_date',
        headerName: 'Appointment Date',
        headerClassName: 'datagrid-header',
        cellClassName: 'datagrid-cell-custom'
      },
      {
        field: 'invoice_submitted_datetime',
        headerName: 'Inv. Submitted On',
        headerClassName: 'datagrid-header',
        cellClassName: 'datagrid-cell-custom',
        renderCell: ({ row }) => (
          <span>{row?.invoice_submitted_datetime && moment(row?.invoice_submitted_datetime).format('MM/DD/YYYY hh:mm A')}</span>
        )
      },
      {
        field: 'f_status_web',
        headerName: 'Status',
        headerClassName: 'datagrid-header',
        cellClassName: 'datagrid-cell-custom',
      },
      {
        field: 'payment_status',
        headerName: 'Payment Status',
        headerClassName: 'datagrid-header',
        cellClassName: 'datagrid-cell-custom',
      },
      {
        field: 'credential_complete',
        headerName: 'Credential Complete',
        headerClassName: 'datagrid-header',
        cellClassName: 'datagrid-cell-custom',
        renderCell: (data: any) => {
          const { row } = data
          return <Typography>{
            row?.missingCreds ?
              row?.missingCreds?.length === 0 ? 'Y' : 'N'
              : ''
          }
          </Typography>
        }
      },
      {
        field: 'action',
        headerName: 'Action',
        headerClassName: 'datagrid-header',
        cellClassName: 'datagrid-cell-custom',
        renderCell: ({ row }) => {
          return (
            <UnbilledOrderDataActions
              key={row.Id}
              row={row}
              handleCreateOrderBillCurrentRow={handleCreateOrderBillCurrentRow}
            />
          );
        }
      }
    ];

    const handlePage = (e: unknown, newPage: number) => {
      setPageInfo({
        ...pageInfo,
        page: newPage
      });
    };

    const handleRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
      setPageInfo({
        ...pageInfo,
        limit: parseInt(e.target.value, 10)
      });
    };

    const handleSort = (e: any) => {
      try {
        const { field, sort } = e[0]
        if (!(field === pageInfo?.orderBy && sort === pageInfo?.orderType)) {
          const { field, sort } = e[0]
          setPageInfo({
            ...pageInfo,
            orderBy: field,
            orderType: sort,
          });
        }
      } catch (error) {
        // console.log('SORT MODEL ERROR!')
      }
    };

    const rows = unbilledOrder

    const saveBill = useRecoilCallback(({ snapshot }) => async (values: any) => {
      setCheck(true);
      createBillAtom(n => n + 1)
      const res: any = await snapshot.getPromise(createBill({ ...values }));
      if (res.success) {
        setToast({
          message: 'Bill Created Successfully!',
          type: 'success',
          open: true
        });
      } else {
        setToast({
          message: res?.result?.data?.message || 'Failed To Create The Bill!',
          type: 'error',
          open: true
        });
      }
      setCheck(false);
      refresh((n) => ++n);
    })

    return (
      <div>
        <CreateBillModel
          createOrderBillCurrentRow={createOrderBillCurrentRow}
          handleCreateOrderBillCurrentRow={handleCreateOrderBillCurrentRow}
          saveBill={saveBill}
          check={check}
        />
        <Table
          customRowHeight={85}
          sortModel={
            [{ field: orderBy, sort: orderType }]
          }
          useCustomStyles={false}
          loading={loading}
          columns={columns}
          rows={rows}
          total={total}
          pageInfo={pageInfo}
          handlePage={handlePage}
          handleRowsPerPage={handleRowsPerPage}
          onSortingChange={handleSort}
          rowsPerPageOptions={[10, 50, 80]}
        />
        <Toast message={toast.message} type={toast.type} open={toast.open} setToast={setToast} />
        <SignerInfo
          signerInfoModal={signerInfoModal}
          handleSignerInfoModal={handleSignerInfoModal} />
      </div>
    );
  };

export { UnbilledOrdersData };
