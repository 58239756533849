import { selectorFamily, selector } from 'recoil';

import { selectedDivision } from '../Atoms/Divisions';

import { verifyAuthToken } from './Auth'
import { loadContact, pagination, search, refreshContacts, selectedClient, clientContactpagination, resendMasterLoginRequest } from '../Atoms/Contacts';
import { rangeReportsPagination } from "../Atoms/Reports"
import { api } from '../../Services/api';
import { orderFilters, orderRangeDetailPagination } from '../Atoms/Orders';

const getContact = selectorFamily({
  key: 'getContact',
  get: (params: any) => async ({ get }) => {
    const id = get(loadContact);
    if (id !== -1) {
      try {
        await verifyAuthToken()
        const res: any = await api.GET(`client/contacts/contact/${id}`);

        return {
          result: res,
          success: true
        };
      } catch (err) {
        return {
          result: err,
          success: false
        };
      }
    }
  }
});

const getContacts = selector({
  key: 'getContacts',
  get: async ({ get }) => {
    get(refreshContacts);
    const { limit, page, orderBy, orderType } = get(pagination);
    const searchValue = get(search);

    try {
      await verifyAuthToken()
      const res: any = await api.GET(
        'client/contacts/all'
        , {
          params: {
            limit, page, orderBy, orderType, search: searchValue
          }
        });
      const result = res.rows.map(r => {
        return {
          ...r,
          id: r.Id,
          status: r.status === 'A' ? 'Active' : 'Inactive'
        }
      });
      return {
        result: {
          rows: result,
          count: res.count
        },
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const saveContact = selectorFamily({
  key: 'saveContact',
  get: (params: any) => async () => {
    const method = params.isEditing ? api.PUT : api.POST
    delete params.isEditing
    try {
      await verifyAuthToken()
      const res: any = await method('client/contacts', params);
      return {
        result: res,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const updateContact = selectorFamily({
  key: 'updateContact',
  get: (params: any) => async () => {
    try {
      await verifyAuthToken()
      const res: any = await api.PUT('client/contacts', params);

      return {
        result: res,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const getClientContacts = selector({
  key: 'getClientContacts',
  get: async ({ get }) => {
    const id = get(selectedClient)
    const params = get(clientContactpagination)
    get(refreshContacts);
    if (id) {
      try {
        await verifyAuthToken()
        const res: any = await api.GET(
          `client/contacts/${id}`
          , { params });
        const rows = res.rows.map(r => ({
          ...r,
          id: r.Id,
          status: r.status === 'A' ? 'Active' : 'Inactive'
        }));
        return {
          result: { ...res, rows },
          success: true
        };
      } catch (err) {
        return {
          result: err,
          success: false
        };
      }
    }
  }
});

const getContactsNames = selector({
  key: 'getContactsNames',
  get: async ({ get }) => {
    const { division }: any = get(orderFilters);
    if (division > -1) {
      try {
        const params = { filter: `divisionID:${division}` }
        await verifyAuthToken()
        const result: any = await api.GET('client/contacts/', { params });
        return {
          result,
          success: true
        };
      } catch (err) {
        return {
          result: err,
          success: false
        };
      }
    }
  }
});


const getOrderRangeDetailContactsNames = selector({
  key: 'getOrderRangeDetailContactsNames',
  get: async ({ get }) => {
    const { division }: any = get(orderRangeDetailPagination);
    if (division) {
      try {
        const params = { filter: `divisionID:${division}` }
        await verifyAuthToken()
        const result: any = await api.GET('client/contacts/', { params });
        return {
          result,
          success: true
        };
      } catch (err) {
        return {
          result: err,
          success: false
        };
      }
    }
  }
});


const getAllContactsNames = selector({
  key: 'getAllContactsNames',
  get: async ({ get }) => {
    try {
      await verifyAuthToken()
      const result: any = await api.GET('client/contacts');
      return {
        result,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const getAllContactsByDivision = selector({
  key: 'getAllContactsByDivision',
  get: async ({ get }) => {
    const id = get(selectedDivision);
    if (id && id > -1) {
      try {
        await verifyAuthToken()
        const res: any = await api.GET(`client/contacts?filter=divisionid:${id}`);
        return {
          result: res.rows,
          success: true
        };
      } catch (err) {
        return {
          result: [],
          success: false
        };
      }
    }
  }
});

const getContactById = selectorFamily({
  key: 'getContactById',
  get: (params: any) => async () => {
    delete params.isEditing
    try {
      const { Id } = params
      await verifyAuthToken()
      const res: any = await api.GET(`client/contacts/contact/${Id}`);
      return {
        result: res,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const getContactsNamesRange = selector({
  key: 'getContactsNamesRange',
  get: async ({ get }) => {
    const { division }: any = get(orderFilters);
    if (division > -1) {
      try {
        const params = { filter: `divisionID:${division}` }
        await verifyAuthToken()
        const result: any = await api.GET('client/contacts/', { params });
        return {
          result,
          success: true
        };
      } catch (err) {
        return {
          result: err,
          success: false
        };
      }
    }
  }
});


const getContactByDevisionForRangeReport = selector({
  key: 'getContactByDivisionForRangeReport',
  get: async ({ get }) => {
    const { divisionID }: any = get(rangeReportsPagination);
    if (divisionID > -1) {
      try {
        const params = { filter: `divisionID:${divisionID}` }
        await verifyAuthToken()
        const result: any = await api.GET('client/contacts/', { params });
        return {
          result,
          success: true
        };
      } catch (err) {
        return {
          result: err,
          success: false
        };
      }
    }
  }
})

const masterLoginRequest = selectorFamily({
  key: 'masterLoginRequest',
  get: (params: any) => async ({ get }) => {
    try {
      get(resendMasterLoginRequest)
      await verifyAuthToken()
      const res: any = await api.POST(`auth/client/master-login-request`, params);
      return {
        result: res,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

export {
  getContact,
  getContacts,
  saveContact,
  updateContact,
  getClientContacts,
  getContactsNames,
  getAllContactsByDivision,
  getContactById,
  getAllContactsNames,
  getContactsNamesRange,
  getContactByDevisionForRangeReport,
  getOrderRangeDetailContactsNames,
  masterLoginRequest
};
