import React from 'react';
import { useRecoilState } from 'recoil'
import moment from 'moment-timezone';

import { matchedReportsPagination } from './../../Recoil/Atoms/Reports'

import { Table } from '../Table';
import { Typography } from '@material-ui/core';

const MatchersData = (props: any) => {

  const { data, loading, total } = props
  const [page, setPage] = useRecoilState(matchedReportsPagination)

  const columns = [{
    field: 'w_Loan_Number',
    headerName: 'Loan Number'
  }, {
    field: 'name',
    headerName: 'Full Name',
    renderCell: data => {
      const { Signer_First, Signer_Last } = data.row.signer
      return (
        <Typography>{`${Signer_First} ${Signer_Last}`}</Typography>
      )
    }
  }, {
    field: 'date',
    headerName: 'Data/Time',
    renderCell: data => {
      const { w_Appointment_Date, w_Appointment_Time_type, w_Appointment_Time } = data.row
      return (
        <Typography>
          {`${moment(w_Appointment_Date, 'MM/DD/YYYY').format('MM/DD/YYYY')} 
          ${w_Appointment_Time_type === 'OT' ? 'Open Time' : moment(w_Appointment_Time, 'hh:mm A').format('hh:mm A')}`}
        </Typography>
      )
    }
  }, {
    field: 'activity',
    headerName: 'Activity Time',
    renderCell: data => {
      const { match_activity_timestamp } = data.row
      return (
        <Typography>{moment(match_activity_timestamp).format('MM/DD/YYYY hh:mm A')}</Typography>
      )
    }
  }, {
    field: 'f_status_web',
    headerName: 'Status'
  }];

  const handlePage = (e: unknown, newPage: number) => {
    setPage({
      ...page,
      page: newPage
    });
  };

  const handleRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPage({
      ...page,
      limit: parseInt(e.target.value, 10)
    });
  };

  return (
    <Table
      loading={loading}
      columns={columns}
      rows={data}
      total={total}
      // sortModel={
      //   [{ field: orderBy, sort: orderType }]
      // }
      // onSortingChange={handleSort}
      handlePage={handlePage}
      handleRowsPerPage={handleRowsPerPage}
    />
  );
};

export { MatchersData };
