import React from 'react';
import { useRecoilValueLoadable } from 'recoil'
import { Typography, Box, Divider } from '@material-ui/core';

import { Page } from '../../Components/Page';
import { VolumeData } from '../../Components/Reports/VolumeData';
import { VolumeDateRange } from '../../Components/Reports/VolumeDateRange';
import { getVolumeReports } from './../../Recoil/Selectors/Reports'

import { useStyles } from '../Styles/Page';

export const Volume = () => {
  const classes = useStyles();
  const volumeReport: any = useRecoilValueLoadable(getVolumeReports)
  const report = volumeReport?.contents?.result?.rows || []
  const total = volumeReport?.contents?.result?.count || 0
  
  return (
    <Page className={classes.paper} title='Contacts'>
      <Typography variant='h3'>Volume Report</Typography>
      <Box my={2}>
        <Divider variant='fullWidth' />
      </Box>
      <Box my={4} display='flex' justifyContent='flex-end'>
        <VolumeDateRange />
      </Box> 
      <VolumeData 
        loading={volumeReport.state === 'loading'}
        rows={report} 
        total={total}
      />
    </Page>
  );
}
