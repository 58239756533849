import React from 'react';
import { Grid, Box, SvgIcon, Typography } from '@material-ui/core';
import { Clipboard, Calendar, Layers, Smile } from 'react-feather';
import { useRecoilValue } from 'recoil'

import { useStyles } from './Styles/Summary';

import { unassignedCount, appointmentCount, mtdOrderCount, currentOrderCount } from '../../Recoil/Atoms/Stats';

const Summary = () => {

 const orderOfMonth: any = useRecoilValue(currentOrderCount)
 const orderAppointmentCount = useRecoilValue(appointmentCount)
 const unsignedOrdersCount = useRecoilValue(unassignedCount)
 const mtdCount = useRecoilValue(mtdOrderCount)

  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} sm={6} md={3}>
        <Box className={classes.box} display='flex' alignItems='center' justifyContent='space-between'>
          <Box>
            <Typography variant='h3'>{orderOfMonth}</Typography>
            <Typography variant='body2'>Orders</Typography>
          </Box>
          <SvgIcon className={classes.iconContainer}>
            <Clipboard color='#5d9cec' />
          </SvgIcon>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Box className={classes.box} display='flex' alignItems='center' justifyContent='space-between'>
          <Box>
            <Typography variant='h3'>{orderAppointmentCount}</Typography>
            <Typography variant='body2'>Appointments</Typography>
          </Box>
          <SvgIcon className={classes.iconContainer}>
            <Calendar color='#fb6d9d' />
          </SvgIcon>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Box className={classes.box} display='flex' alignItems='center' justifyContent='space-between'>
          <Box>
            <Typography variant='h3'>{unsignedOrdersCount}</Typography>
            <Typography variant='body2'>Unassigned</Typography>
          </Box>
          <SvgIcon className={classes.iconContainer}>
            <Layers color='#34d3eb' />
          </SvgIcon>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Box className={classes.box} display='flex' alignItems='center' justifyContent='space-between'>
          <Box>
            <Typography variant='h3'>{mtdCount}</Typography>
            <Typography variant='body2'>MTD Signed</Typography>
          </Box>
          <SvgIcon className={classes.iconContainer}>
            <Smile color='#5fbeaa' />
          </SvgIcon>
        </Box>
      </Grid>
    </>
  );
}

export { Summary };
