import React from 'react';
import { Grid, TextField } from '@material-ui/core';

const ContactName = ({ values, errors, touched, handleChange, handleBlur }) => {
  return (
    <>
      <Grid item xs={12} md={6}>
        <TextField
          label='First Name'
          name='Contact_First_Name'
          variant='outlined'
          size='small'
          fullWidth
          value={values.Contact_First_Name}
          onChange={ e => { 
            e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.substr(1).toLowerCase();
            handleChange(e)}}
          onBlur={handleBlur}
          helperText={touched.Contact_First_Name && errors.Contact_First_Name}
          error={Boolean(touched.Contact_First_Name && errors.Contact_First_Name)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label='Last Name'
          name='Contact_Last_Name'
          variant='outlined'
          size='small'
          fullWidth
          value={values.Contact_Last_Name}
          onChange={ e => { 
            e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.substr(1).toLowerCase();
            handleChange(e)}}
          onBlur={handleBlur}
          helperText={touched.Contact_Last_Name && errors.Contact_Last_Name}
          error={Boolean(touched.Contact_Last_Name && errors.Contact_Last_Name)}
        />
      </Grid>
    </>
  );
}

export { ContactName };
