import { Grid, Typography, Divider } from '@material-ui/core'
import React from 'react'
import { ChevronsRight } from 'react-feather'

import SearchSigner from './SearchSigner'

export default function MergeSigner({ selectedSigner = {
  signing: 0,
  errors: 0,
  Signer_First: '',
  Signer_Last: '',
  signer_id: ''
}, setToSigner, toSigner }) {


  const fields = [
    {
      label: '# Sining',
      name: 'signing'
    },
    {
      label: '# Errors',
      name: 'errors'
    },
    {
      label: 'NSA Number',
      name: 'nsa_member_number'
    },
    {
      label: 'Full Name',
      render: (val, row) => (<>
        {row.Signer_First}  {row.Signer_Last}
      </>
      )
    }, {
      label: 'Mobile Phone',
      name: 'mobile_phone'
    }, {
      label: 'Home Phone',
      name: 'home_phone'
    }, {
      label: 'Signer Email',
      name: 'Signer_Email'
    }, {
      label: 'Address',
      name: 'address'
    }, {
      label: 'City',
      name: 'city'
    }, {
      label: 'State',
      name: 'state'
    }, {
      label: 'Zip',
      name: 'zip'
    },
  ]
  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12} item>
          <SearchSigner setToSigner={setToSigner}></SearchSigner>
          <br></br>
          <Divider></Divider>
        </Grid>
        <Grid container item xs={5} spacing={2}>
          <Grid item xs={12}>
            <Typography variant='body1' color='error'>
              Will Be deleted
            </Typography>
          </Grid>
          {fields.map(f => (
            <Grid item xs={12} container justifyContent='space-between' >
              <Grid container item xs={6}>
                <Typography variant='h5'>
                  {f.label}
                </Typography>
              </Grid>
              <Grid container item xs={6}>
                {f.render ? f.render(selectedSigner[f.name], selectedSigner) : selectedSigner[f.name]}</Grid>
            </Grid>
          ))}
        </Grid>
        <Grid container item xs={2} direction='column' justifyContent='space-around'>
          <Grid container item justifyContent='space-around'>
            <ChevronsRight></ChevronsRight>
          </Grid>
        </Grid>
        {toSigner?.signer_id && (
          <Grid container item xs={5} spacing={2}>
            <Grid item xs={12}>
              <Typography variant='body1' >
                Will Remain
            </Typography>
            </Grid>
            {fields.map(f => (
              <Grid item xs={12} container justifyContent='space-between' >
                <Grid container item xs={6}>
                  <Typography variant='h5'>
                    {f.label}
                  </Typography>
                </Grid>
                <Grid container item xs={6}>
                  {f.render ?
                    f.render(toSigner[f.name], toSigner) : toSigner[f.name]}</Grid>
              </Grid>
            ))}
          </Grid>)}

      </Grid>
    </>
  )
}
