import React from 'react';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';

import { OutlinedSection } from '../Section';

import { BorrowerName } from './Fields/BorrowerName';
import { CoBorrowerName } from './Fields/CoBorrowerName';
import { AdditionalBorrowers } from './Fields/AdditionalBorrowers';
import { SignerType } from './Fields/SignerType';
import { PartialSigning } from './Fields/PartialSigning';
import { BorrowerEmail } from './Fields/BorrowerEmail';

import { AppointmentDateTime } from './Fields/AppointmentDateTime';
import { SignerAddress } from './Fields/SignerAddress';
import { PropertyLocation } from './Fields/PropertyLocation';
import { SignerPhone } from './Fields/SignerPhone';

import { SpecialInstruction } from './Fields/SpecialInstruction';
import { ClosingType } from './Fields/ClosingType';

import { useStyles } from './Styles/Fields';
import { displayStyles } from '../../Common/Styles/Display';

const Location2 = (props) => {
  const classes = useStyles();
  const displayClasses = displayStyles();
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    isEditMode,
    isViewOnlyMode
  } = props;
  const location = 'location_two';

  return (
    // <></>
    <Grid className={classes.gridRoot} container spacing={2}>
      <Grid className={clsx(displayClasses.flexColumn)} item xs={12} md={6}>
        <OutlinedSection title='Signing Information'>
          <Grid container spacing={2} >
            <SignerType
              isViewOnlyMode={isViewOnlyMode}
              setFieldValue={setFieldValue}
              location={location}
              values={values[location]}
            />
            <PartialSigning
              isViewOnlyMode={isViewOnlyMode}
              location={location}
              values={values[location]}
              errors={errors}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              loanNumber={values.w_Loan_Number}
            />
            <AppointmentDateTime
              isViewOnlyMode={isViewOnlyMode}
              location={location}
              values={values[location]}
              errors={errors}
              touched={touched}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
            />
            <SignerAddress
              isViewOnlyMode={isViewOnlyMode}
              location={location}
              values={values[location]}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
            />
            <SignerPhone
              isViewOnlyMode={isViewOnlyMode}
              location={location}
              values={values[location]}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <PropertyLocation
              isViewOnlyMode={isViewOnlyMode}
              isEditMode={isEditMode}
              location={location}
              values={values[location]}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
            />
            <SpecialInstruction
              isViewOnlyMode={isViewOnlyMode}
              location={location}
              values={values[location]}
              handleChange={handleChange}
            />
            <ClosingType
              isViewOnlyMode={isViewOnlyMode}
              location={location}
              values={values[location]}
              handleChange={handleChange}
              touched={touched}
              errors={errors}
            />
          </Grid>
        </OutlinedSection>
      </Grid>
      <Grid className={clsx(displayClasses.flexColumn)} item xs={12} md={6}>
        <OutlinedSection title='Borrower Information'>
          <Grid container spacing={2} >
            <BorrowerName
              isViewOnlyMode={isViewOnlyMode}
              location={location}
              values={values[location]}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            {/* <SigningPhone
              isViewOnlyMode={isViewOnlyMode}
              location={location}
              values={values[location]}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <HomePhone
              isViewOnlyMode={isViewOnlyMode}
              location={location}
              values={values[location]}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            /> */}
            <CoBorrowerName
              isViewOnlyMode={isViewOnlyMode}
              location={location}
              values={values[location]}
              handleChange={handleChange}
            />
            <BorrowerEmail
              isViewOnlyMode={isViewOnlyMode}
              location={location}
              values={values[location]}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <AdditionalBorrowers
              isViewOnlyMode={isViewOnlyMode}
              location={location}
              values={values[location]}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
            />
          </Grid>
        </OutlinedSection>
      </Grid>
    </Grid>
  );
}

export { Location2 };
