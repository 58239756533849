import React from 'react';
import { useSetRecoilState, useRecoilCallback } from 'recoil';

import { FormikComponent } from '../../Components/Formik';
import { AccessGroupForm } from '../../Components/Admin/AccessGroupForm';

import { refreshAccessGroups } from '../../Recoil/Atoms/AccessGroups';
import {
  saveAccessGroup,
  updateAccessGroup
} from '../../Recoil/Selectors/AccessGroups';

import {
  initialValues,
  validationSchema
} from '../../Common/Rules/AccessGroupForm';

export const NewAccessGroup = props => {
  const callRefresh = useSetRecoilState(refreshAccessGroups);
  const refresh = () => callRefresh(n => n + 1);

  const onSubmit = useRecoilCallback(
    ({ snapshot }) => async (values: any, { setSubmitting }) => {
      let res: any = {};
      let message = '';
      if (values.accessGroupID) {
        res = await snapshot.getPromise(updateAccessGroup(values));
        message = 'Access Group has been updated';
      } else {
        res = await snapshot.getPromise(saveAccessGroup(values));
        message = 'Access Group been created';
      }
      setSubmitting(false);
      if (res.success) {
        refresh();
        props.openModal(false);
        props.setToast({
          message,
          type: 'success',
          open: true
        });
      }
    }
  );

  return (
    <FormikComponent
      onSubmit={onSubmit}
      initialValues={
        props.selectedAccessGroup.accessGroupID
          ? props.selectedAccessGroup
          : initialValues
      }
      validationSchema={validationSchema}
      enableReinitialize={
        props.selectedAccessGroup.accessGroupID ? true : false
      }
    >
      <AccessGroupForm />
    </FormikComponent>
  );
};
