import * as Yup from 'yup';

export const initialValues = {
  email: '',
  submit: null
};

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Must be a valid email')
    .max(255)
    .required('Email is required')
});

export const ProfileUpdateSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('First Name is required'),
  lastName: Yup.string()
    .required('Last Name is required'),
  phone_no: Yup.string()
    .required('Phone Number is required'),
  team_lead: Yup.string()
    .required('Please Enter Team Lead'),
  email: Yup.string()
    .email('Must be a valid email')
    .max(255)
    .required('Email is required')
});
