import React, { useState } from 'react';
import { useRecoilState, useRecoilValueLoadable, useRecoilCallback } from 'recoil';
import { Box, Divider, TextField } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';

import { Table } from '../Table';
import Label from 'src/ThemeComponents/components/Label';
import { GeneralModal } from '../Modal';
import { SearchForm } from './SignersCommunicationSearch'
import { enqueueSnackbar } from 'notistack'


import { sendMessage, getCommunications, getClientsSigner, sendMessageToAllSigners } from '../../Recoil/Selectors/Signer';
import { signerCommunicationPagination } from '../../Recoil/Atoms/Signers';

import { useStyles } from './Styles/Fields';


const SignersCommList = (props) => {

  const [pageInfo, setPageinfo] = useRecoilState(signerCommunicationPagination)
  const classes = useStyles()
  const [openMessageModal, setOpenMessageModal] = useState(false)
  const [bulkMessage, setBulkMessage] = useState(false)
  const [selectedSigners, setSelectedSigners] = useState([])
  const [message, setMessage] = useState('')
  const [subject, setSubject] = useState('')

  const signerData: any = useRecoilValueLoadable(getCommunications)
  const signers = signerData?.contents?.result?.rows || [];
  const total = signerData?.contents?.result?.count || 0;

  const clientsData: any = useRecoilValueLoadable(getClientsSigner)
  const clients = clientsData?.contents?.result?.rows || [];

  const onSearch = (data) => {
    setPageinfo({
      ...pageInfo,
      ...data,
    })
  }

  const handleRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPageinfo({
      ...pageInfo,
      limit: parseInt(e.target.value, 10)
    });
  };

  const onInitSendmessage = () => {
    if (!selectedSigners?.length) {
      enqueueSnackbar(
        'Please select Signer(s) to send message.',
        {
          variant: 'error'
        }
      )
    } else {
      setOpenMessageModal(true)
    }
  }

  const onBulkMessageModal = () => {
    if (signers.length < 1) {
      enqueueSnackbar(
        'No Signers Found.',
        {
          variant: 'error'
        }
      )
    } else {
      setBulkMessage(true)
    }
  }

  const onSendMessage = useRecoilCallback(({ snapshot }) => async () => {
    const response = await snapshot.getPromise(sendMessage({
      signers: selectedSigners, message, subject
    }));
    if (response.success) {
      enqueueSnackbar(
        response?.result?.message || 'Message sent successfully',
        {
          variant: 'success'
        }
      )
    } else {
      enqueueSnackbar(
        response?.result?.message || 'Error occurred while sending message',
        {
          variant: 'error'
        }
      )
      console.error(response);
    }
  });

  const onSendBulkMessage = useRecoilCallback(({ snapshot }) => async () => {
    const response = await snapshot.getPromise(sendMessageToAllSigners({
      ...pageInfo, message, subject
    }));
    if (response.success) {
      enqueueSnackbar(
        response?.result?.message || 'Message sent successfully',
        {
          variant: 'success'
        }
      )
    } else {
      enqueueSnackbar(
        response?.result?.message || 'Error occurred while sending message',
        {
          variant: 'error'
        }
      )
      console.error(response);
    }
  });

  const columns = [
    {
      field: 'nna_certified',
      headerName: 'NNA',
      renderCell: (e) => (
        <>
          {e.value && e.value.toLowerCase() === 'y' ? <img alt='NNA' src='/static/images/nna_badge.png'></img> : ''}
        </>
      )
    },
    {
      field: 'signer_id',
      headerName: 'Signer ID'
    }, {
      field: 'Signer_First',
      headerName: 'First Name'
    }, {
      field: 'Signer_Last',
      headerName: 'Last Name'
    }, {
      field: 'home_phone',
      headerName: 'Phone'
    }, {
      field: 'mobile_phone',
      headerName: 'Mobile'
    }, {
      field: 'username',
      headerName: 'Email'
    }, {
      field: 'city',
      headerName: 'Address'
    }, {
      field: 'Signer_Status',
      headerName: 'Status',
      renderCell: (e) => (
        <> <Label color={e.value && e.value.toLowerCase() === 'active' ? 'success' : 'secondary'}>{e.value}</Label></>
      )
    },];
  const onSortingChange = (e) => {
    if (e.sortModel && e.sortModel[0].field) {
      setPageinfo({
        ...pageInfo, orderBy: e.sortModel[0].field, orderType: e.sortModel[0].sort
      })
    }
  }
  return (
    <SnackbarProvider >
      < >
        <GeneralModal
          maxWidth={'sm'}
          open={openMessageModal}
          title='Send Message'
          openModal={setOpenMessageModal}
          handleSubmit={onSendMessage}
          submitLabel='Send'
        >
          <TextField
            placeholder='Subject'
            fullWidth
            variant='outlined'
            value={subject}
            onChange={e => setSubject(e.target.value)}
            className={classes.mgSm}
          >
          </TextField>
          <TextField placeholder='Type your message here'
            variant='outlined'
            multiline minRows={8} value={message} onChange={e => setMessage(e.target.value)}>
          </TextField>
        </GeneralModal>

        <GeneralModal
          maxWidth={'sm'}
          open={bulkMessage}
          title='Send Message'
          openModal={setBulkMessage}
          handleSubmit={onSendBulkMessage}
          submitLabel='Send'
        >
          <TextField
            placeholder='Subject'
            fullWidth
            variant='outlined'
            value={subject}
            onChange={e => setSubject(e.target.value)}
            className={classes.mgSm}
          >
          </TextField>
          <TextField placeholder='Type your message here'
            variant='outlined'
            multiline rows={8} value={message} onChange={e => setMessage(e.target.value)}>
          </TextField>
        </GeneralModal>

        <SearchForm clients={clients} onSearch={onSearch} defaultValues={pageInfo} openMessageModal={onInitSendmessage} onBulkMessageModal={onBulkMessageModal} />
        <Box my={2}>
          <Divider variant='fullWidth' />
        </Box>
        <Table
          selectionModel={selectedSigners}
          loading={signerData?.state === 'loading'}
          handlePage={(e, p) => {
            setPageinfo({
              ...pageInfo, page: p
            })
          }}
          onSortingChange={onSortingChange}
          sortModel={[{
            field: pageInfo.orderBy,
            sort: pageInfo.orderType
          }]}
          handleRowsPerPage={handleRowsPerPage}
          pageInfo={{ page: pageInfo.page, limit: pageInfo.limit }}
          total={total}
          columns={columns}
          rows={signers}
          uniqueKey='signer_id'
          checkboxSelection={true}
          onSelectionChange={setSelectedSigners}
          onSelectionModelChange={(newSelection) => {
            setSelectedSigners(newSelection);
          }}
        />
      </>
    </SnackbarProvider>

  );
};

export const SignerCommunications = (SignersCommList)
