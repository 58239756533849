import { Theme, makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  input: {
    width: 100,
  },
  list: {
    '& li': {
      paddingLeft: 0
    },
    width: '100%'
  },
  section: {
    borderRight: '2px solid #13111142'
  },
  listItemText: {
    cursor: 'pointer',
    color: theme?.palette?.primary?.main
  },
  textBoxWidth: {
    width: '40%'
  },
  windowHeight: {
    height: '100vh'
  },
}))
