import React from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

import { useStyles } from '../Styles/Fields';

const ShippingInfo = ({ values, handleChange }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} md={6}>
      <FormControl className={classes.removeSelectMargin} margin='dense' variant='outlined'>
        <InputLabel>Courier</InputLabel>
        <Select
          label='Courier'
          name='shippingInfo'
          fullWidth
          value={values.shippingInfo}
          onChange={handleChange}
        >
          <MenuItem value='DHL'>DHL</MenuItem>
          <MenuItem value='Fedex'>Fedex</MenuItem>
          <MenuItem value='UPS'>UPS</MenuItem>
          <MenuItem value='USPS'>USPS</MenuItem>
          <MenuItem value='GSL'>GSL</MenuItem>
        </Select>
      </FormControl>
    </Grid>
  );
}

export { ShippingInfo };
