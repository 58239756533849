import { atom } from 'recoil';

const userRoles = atom({
    key: 'userRoles',
    default: [
      {
        id: 1,
        name: 'Accountant'
      },
      {
        id: 2,
        name: 'Manager'
      },
      {
        id: 3,
        name: 'Employer'
      }
    ]
  });

const userpagination = atom({
    key: 'userpagination',
    default: {
      page: 1,
      limit: 20,
      orderBy: 'firstName',
      orderType: 'ASC',
      search: ''
    }
  });

  const refreshUsers = atom({
    key: 'refreshUsers',
    default: 0
  });

  export { userRoles, userpagination,refreshUsers }; 